import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { getCookie, setCookie } from '../../../utils';

function TrialStatusModal() {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (getCookie('showTrialStatusModal') === 'true') {
      setTimeout(() => {
        setOpen(true);
      }, 3000);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setCookie(false, 'showTrialStatusModal');
  };

  if (!getCookie('accessToken') || getCookie('showTrialStatusModal') !== 'true' || location.pathname === '/callback') {
    return null;
  }

  return (
    <Modal className="trial-status-modal" onCancel={() => handleClose()} footer={false} open={open} closeIcon={false} width={604}>
      <div className="trial-status-modal-ctr">
        <div className="pending-image" />
        <div className="ts-header">{`Dear ${getCookie('firstNameTrialUser') || 'Researcher'}, your account is under review`}</div>
        <div className="ts-sub-header">We will notify you via email once your account is approved and ready to use. However, please feel free to use the application for the next 4 hrs.</div>
        <div aria-hidden onClick={() => handleClose()} className="okay-btn">Okay</div>
      </div>
    </Modal>
  );
}

export default TrialStatusModal;
