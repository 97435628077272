import React from 'react';
import Mixpanel from '../../../utils/mixpanel';

function TryNowOntosight({ setTryOpen }) {
  const tryNowButtonClick = () => {
    setTryOpen(true);
    Mixpanel.track('try_ontosight_free_event', {
      action: 'Try now button click to open try ontosigt modal',
      page: 'Landing Page',
      misc: 'Bottom Page',
    });
  };

  return (
    <div className="try-now-ctr">
      <div className="inner-ctr">
        <div className="details-try-now">
          <div className="try-header-text">
            <span>Try Ontosight</span>
            <sup>®</sup>
            &nbsp;
            <span>for free</span>
          </div>
          <div className="try-detail-text">Harness AI-powered insights tailored for clinical trials and drug development, ensuring informed decisions without bias.</div>
          <div aria-hidden className="try-now-btn pointer" onClick={() => tryNowButtonClick()}>Try Now</div>
        </div>
        <div className="try-now-image" />
      </div>
    </div>
  );
}

export default TryNowOntosight;
