/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import SvgLoader from '../../components/SvgLoader';
import {
  getTimeLineDataAction, getTimeLineDataRefreshAction, setDocIdAction,
} from './logic';
import Loader from '../../components/Loader';
import { setCurrentTabAction } from '../../../clinicalTrials/container/CTdeepDive/logic';
import { shortForm } from './data';
import Mixpanel from '../../../utils/mixpanel';
import { getTerminalName } from '../../../utils';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function TimeLine({ trialId = '', detailView = false, setTimeLine }) {
  const yearRangeRef = React.useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const [startYear, setStartYear] = React.useState(null);
  const [endYear, setEndYear] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [markers, setMarkers] = React.useState(new Set());
  const [showDocumentTooltip, setShowDocumentTooltip] = React.useState(false);
  const [documentTooltipId, setDocumentTooltipId] = React.useState('');
  const [doucmentToolTipData, setDocumentTooltipData] = React.useState({
    trialId: '',
    title: '',
    startDateTimeStamp: '',
    endDate: '',
    studyStatus: '',
  });
  const [showMarkerTooltip, setShowMarkerTooltip] = React.useState(false);
  const [markerTooltipId, setMarkerTooltipId] = React.useState('');
  const [markerTooltipData, setMarkerTooltipData] = React.useState({
    type: '',
    displayType: '',
    date: '',
    publicationId: '',
    title: '',
    dataSource: '',
  });

  const timelineData = useSelector((state) => state.timelineData);
  const currentTrialId = useSelector((state) => state.currentTrialId);
  const backButtonStatus = useSelector((state) => state.backButtonStatus);
  const currentDocId = useSelector((state) => state.currentDocId);

  if (currentDocId.trialId && currentDocId.trialId !== trialId && detailView) {
    trialId = currentDocId.trialId;
  }

  const markerType = (type) => {
    if (type === 'publications') {
      return 'Literature';
    }
    return null;
  };

  const generateFalseEmptyCompletionDate = false;

  useEffect(() => {
    if (data.length) {
      const allYears = [];

      data.forEach((document) => {
        if (document.completion_date && !generateFalseEmptyCompletionDate) {
          if (Number(document.completion_date.split('-')[0])) {
            allYears.push(Number(document.completion_date.split('-')[0]));
          }
        } else {
          const currentYear = new Date().getFullYear();
          allYears.push(currentYear + 1);
        }
        if (Number(document.start_date.split(' ')[2])) {
          allYears.push(Number(document.start_date.split(' ')[2]));
        }

        if (document.trial_id === trialId) {
          dispatch(setDocIdAction({
            docId: document.doc_id,
            trialId: document.trial_id,
          }));
        }

        document.articles.forEach((marker) => {
          allYears.push(Number(marker.date.split(' ')[2]));
        });
      });

      data.forEach((document) => {
        document.articles.forEach((marker) => {
          setMarkers((prevSet) => new Set([...prevSet, markerType(marker.type)]));
        });
      });

      const tempStartYear = Math.min(...allYears);
      const tempEndYear = Math.max(...allYears) + 1;

      setEndYear(tempEndYear);
      setStartYear(tempStartYear);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    const tempData = [];
    tempData.push(...timelineData.data);
    setData([
      ...tempData,
    ]);
  }, [JSON.stringify(timelineData)]);

  const apiCall = (ID) => {
    dispatch(getTimeLineDataAction({
      params: queryString.stringify({
        from: 1,
        size: detailView ? 10 : 3,
        trial_id: ID,
      }),
    }));
  };

  useEffect(() => {
    if ((backButtonStatus.toggleBack || trialId !== currentTrialId.trialId)) {
      dispatch(getTimeLineDataRefreshAction());
      debounceCall(() => {
        apiCall(trialId);
      }, 100);
    }
  }, [backButtonStatus.toggleBack, trialId]);

  const dontShowTooltip = true;

  let yearReangeDivWidth = 0;

  const yearDiff = endYear - startYear;
  let initial = yearDiff > 30 ? 9 : detailView || yearDiff <= 5 ? 1 : 2;
  const yearRange = [];

  let tempYear = startYear;

  if (!detailView) {
    if (yearDiff <= 8) {
      initial = 2;
      if (yearDiff % 2 !== 0) {
        setEndYear(endYear + 1);
      }
    } else if (yearDiff <= 24) {
      initial = 4;
      if (yearDiff % 4 !== 0) {
        setEndYear(endYear + 1);
      }
    } else if (yearDiff > 24) {
      initial = 8;
      if (yearDiff % 8 !== 0) {
        setEndYear(endYear + 1);
      }
    } else if (yearDiff !== 0) {
      initial = 1;
    } else {
      initial = 0;
    }
  }

  if (detailView) {
    if (yearDiff > 18) {
      initial = 6;
      if (yearDiff % 6 !== 0) {
        setEndYear(endYear + 1);
      }
    } else if (yearDiff <= 18) {
      initial = 2;
      if (yearDiff % 2 !== 0) {
        setEndYear(endYear + 1);
      }
    } else if (yearDiff !== 0) {
      initial = 1;
    } else {
      initial = 0;
    }
  }

  const step = initial;

  if (startYear !== endYear) {
    while (tempYear <= endYear) {
      yearRange.push(tempYear);
      tempYear += step;
    }
  } else {
    yearRange.push(startYear);
    yearRange.push(endYear);
  }

  if (yearRangeRef.current) {
    yearReangeDivWidth = ((yearRangeRef.current.offsetWidth) / yearRange.length);
  }

  const genrateStyle = (document, internalDiv = false) => {
    const startDAteYear = new Date(document.created_at_epoch * 1000).getFullYear();
    let endDateYear;
    let endmonth;
    if (document.completion_date && !generateFalseEmptyCompletionDate) {
      endDateYear = new Date(document.completion_date).getFullYear();
      endmonth = new Date(document.completion_date).getMonth() + 1;
    } else {
      const currentYear = new Date().getFullYear();
      endDateYear = currentYear + 1;
      endmonth = 1;
    }
    const startMonth = new Date(document.created_at_epoch * 1000).getMonth();
    const todayYear = new Date().getFullYear();
    const todayMonth = new Date().getMonth();

    let marginLeft = 0;
    let width = 0;
    let widthInternal = 0;

    yearRange.forEach((year, i) => {
      if (year === startDAteYear) {
        marginLeft += i * yearReangeDivWidth;
        marginLeft += (yearReangeDivWidth * startMonth) / (step * 12);
      } else if (startDAteYear > year && startDAteYear < year + step) {
        let j = 0;
        while (year + j < startDAteYear) {
          j += 1;
          if (year + j === startDAteYear) {
            break;
          }
        }
        marginLeft += i * yearReangeDivWidth;
        marginLeft += (yearReangeDivWidth * (startMonth + j * 12)) / (step * 12);
      }

      if (year === endDateYear) {
        width += i * yearReangeDivWidth;
        width += (yearReangeDivWidth * endmonth) / (step * 12);
      } else if (endDateYear > year && endDateYear < year + step) {
        let j = 0;
        while (year + j < endDateYear) {
          j += 1;
          if (year + j === endDateYear) {
            break;
          }
        }
        width += i * yearReangeDivWidth;
        width += (yearReangeDivWidth * (endmonth + j * 12)) / (step * 12);
      }

      if (year === todayYear && internalDiv && (!document.completion_date || generateFalseEmptyCompletionDate)) {
        widthInternal += i * yearReangeDivWidth;
        widthInternal += (yearReangeDivWidth * todayMonth) / (step * 12);
      }

      if (todayYear > year && todayYear < year + step && internalDiv && (!document.completion_date || generateFalseEmptyCompletionDate)) {
        let j = 0;
        while (year + j < todayYear) {
          j += 1;
          if (year + j === todayYear) {
            break;
          }
        }
        widthInternal += i * yearReangeDivWidth;
        widthInternal += (yearReangeDivWidth * (todayMonth + j * 12)) / (step * 12);
      }
    });

    if (internalDiv && (!document.completion_date || generateFalseEmptyCompletionDate)) {
      return {
        width: widthInternal - marginLeft,
      };
    }

    return {
      marginLeft,
      width: width - marginLeft,
    };
  };

  const generateId = (str) => {
    let tempStr = '';
    for (let i = 0; i < str.length; i += 1) {
      const ch = str[i];
      if (ch === ' ' || ch === '/') {
        tempStr = `${tempStr}-`;
      } else {
        tempStr += ch;
      }
    }
    return `${tempStr}-${detailView}`;
  };

  let fictitiousYearRange = [];

  if (step >= 1) {
    yearRange.forEach((year) => {
      fictitiousYearRange.push(year + step / 2);
    });
  } else {
    fictitiousYearRange = yearRange;
  }

  const iconName = (status, endDate) => {
    if (status === 'Completed') {
      return 'completed-trial';
    }

    if (status === 'Discontinued') {
      return 'withdrawn-trial';
    }

    if (!endDate || status === 'In Progress' || status === 'Planned Recruitment') {
      return 'in-progress-trial';
    }

    return 'completed-trial';
  };

  const trialCompliation = (status) => {
    if (status === 'In Progress') {
      return 'Expected end date';
    }
    if (status !== '') {
      return status;
    }
    return 'Withdrawn';
  };

  const handleDocumentHover = (id, document) => {
    setDocumentTooltipData({
      ...{
        trialId: document.trial_id,
        title: document.title,
        startDateTimeStamp: document.created_at_epoch,
        endDate: document.completion_date,
        studyStatus: document.study_status,
      },
    });
    setDocumentTooltipId(id);
    setShowDocumentTooltip(true);
    Mixpanel.track('hover_event', {
      action: 'mouse hover on CT Timeline trial',
      terminal_name: getTerminalName(location),
      page: 'List view',
      section_name: 'Clinical Development Timeline',
      view_type: `${detailView ? 'Expanded View' : 'Collapsible View'}`,
      Label: 'Trial Id',
    });
  };

  const handleMarkerHover = (id, marker) => {
    setMarkerTooltipData({
      ...{
        type: marker.type,
        displayType: marker.display_type === 'Publications' ? 'Publication' : marker.display_type,
        date: marker.date,
        publicationId: marker.publication_id,
        title: marker.title,
        dataSource: marker.data_source,
      },
    });
    setMarkerTooltipId(id);
    setShowMarkerTooltip(true);
    Mixpanel.track('hover_event', {
      action: 'mouse hover on marker',
      terminal_name: getTerminalName(location),
      page: 'List view',
      section_name: 'Clinical Development Timeline',
      view_type: `${detailView ? 'Expanded View' : 'Collapsible View'}`,
      Label: 'Literature Id',
    });
  };

  const handleDocumentHoverLeave = () => {
    setDocumentTooltipData({ ...{} });
    setDocumentTooltipId('');
    setShowDocumentTooltip(false);
  };

  const handleMarkerHoverLeave = () => {
    setMarkerTooltipData({ ...{} });
    setMarkerTooltipId('');
    setShowMarkerTooltip(false);
  };

  const handleDocumentclick = (document) => {
    const { doc_id, trial_id } = document;
    if (setTimeLine) {
      setTimeLine();
    }
    setMarkerTooltipData({ ...{} });
    setMarkerTooltipId('');
    setDocumentTooltipData({ ...{} });
    setDocumentTooltipId('');
    setShowMarkerTooltip(false);
    setShowDocumentTooltip(false);
    dispatch(setDocIdAction({
      docId: doc_id,
      trialId: trial_id,
    }));
    dispatch(setCurrentTabAction('timeline'));
    Mixpanel.track('button_click_event', {
      action: 'Click on any trial in the timeline view',
      terminal_name: getTerminalName(location),
      page: 'List view',
      feature_name: 'Clinical Development Timeline',
      view_type: `${detailView ? 'Expanded View' : 'Collapsible View'}`,
      button: 'Click on the clinical trial in the timeline view',
      value: trial_id,
    });
  };

  const getStatusClassName = ({ study_status, trial_id }) => {
    if (trial_id === trialId) {
      if (study_status === 'Completed') {
        return 'document-completed-active';
      }

      if (study_status === 'Discontinued') {
        return 'document-disscontinued-active';
      }

      if (study_status === 'Other') {
        return 'document-Other-active-actual';
      }

      if (study_status === 'Planned Recruitment') {
        return 'document-planned-recruitment-active';
      }

      return 'document-other-active';
    }

    return 'document-grey';
  };

  const getCompletionStatusClass = ({ completion_date, study_status, trial_id }) => {
    if (trial_id === trialId) {
      if (study_status === 'Completed') {
        return (completion_date && !generateFalseEmptyCompletionDate) ? 'document-done-green-current' : 'document-green-current';
      }

      if (study_status === 'Discontinued') {
        return (completion_date && !generateFalseEmptyCompletionDate) ? 'document-done-red-current' : 'document-red-current';
      }

      if (study_status === 'Other') {
        return (completion_date && !generateFalseEmptyCompletionDate) ? 'document-done-purple-current' : 'document-purple-current';
      }

      if (study_status === 'Planned Recruitment') {
        return (completion_date && !generateFalseEmptyCompletionDate) ? 'document-done-blue-current' : 'document-blue-current';
      }

      return (completion_date && !generateFalseEmptyCompletionDate) ? 'document-done-yellow-current' : 'document-yellow-current';
    }

    return 'document-grey';
  };

  const getColorStyle = ({ study_status, trial_id }) => {
    if (trial_id === trialId) {
      if (study_status === 'Completed') {
        return 'trial-green-current';
      }

      if (study_status === 'Discontinued') {
        return 'trial-red-current';
      }

      if (study_status === 'Other') {
        return 'trial-purple-current';
      }

      if (study_status === 'Planned Recruitment') {
        return 'trial-blue-current';
      }

      return 'trial-yellow-current';
    }

    return 'trial-grey';
  };

  const checkBotrder = ({ completion_date, trial_id }) => {
    if ((completion_date && !generateFalseEmptyCompletionDate) && trial_id !== trialId) {
      return 'border-class';
    }
    return '';
  };

  const handleMarkerClick = (marker) => {
    window.open(marker.data_source_link, '_blank');
    Mixpanel.track('visit_source_event', {
      action: 'marker click to visit source of marker',
      terminal_name: getTerminalName(location),
      page: 'List view',
      location: marker.type,
    });
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString(undefined, options);
    const dateArray = formattedDate.split(' ');
    if (dateArray[0] === 'Invalid') {
      return 'Date unavailable';
    }
    return `${dateArray[1]} ${dateArray[0]}, ${dateArray[2]}`;
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const renderDocumentTooltip = () => (
    <Tooltip className="document-timeline-tooltip" anchorSelect={`#${documentTooltipId}`} place="top" isOpen={showDocumentTooltip}>
      <div className="document-tooltip">
        <div className="document-tooltip-title">
          <SvgLoader width={30} height={30} svgName="trial-id" />
          {`Trial ID - ${doucmentToolTipData.trialId}`}
        </div>
        <div className="document-tooltip-subtitle">
          {doucmentToolTipData.title}
        </div>
        <div className="document-tooltip-status">
          <div className={`document-tooltip-status-icon ${iconName(doucmentToolTipData.studyStatus, doucmentToolTipData.endDate)}`} />
          <div className="document-tooltip-date">
            <div className="document-date-label-ctr">
              <div className="document-date-label">Start</div>
              {iconName(doucmentToolTipData.studyStatus, doucmentToolTipData.endDate) === 'in-progress-trial' ? <div className="document-date-label adjust-position-timeline-date">In-progress</div> : null}
              <div className="document-date-label">{trialCompliation(doucmentToolTipData.studyStatus)}</div>
            </div>
            <div className="document-dates">
              <div className="document-date-text">{formatDate(doucmentToolTipData.startDateTimeStamp * 1000)}</div>
              <div className="document-date-text">{formatDate(doucmentToolTipData.endDate)}</div>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );

  const renderMarkers = (document, index) => document.articles.map((marker, k) => {
    const startDAteYear = new Date(marker.date).getFullYear();
    const startMonth = new Date(marker.date).getMonth() + 1;
    let marginLeft = 0;

    yearRange.forEach((year, i) => {
      if (year === startDAteYear) {
        marginLeft += i * yearReangeDivWidth;
        marginLeft += (yearReangeDivWidth * startMonth) / (step * 12);
      } else if (startDAteYear > year && startDAteYear < year + step) {
        let j = 0;
        while (year + j < startDAteYear) {
          j += 1;
          if (year + j === startDAteYear) {
            break;
          }
        }
        marginLeft += i * yearReangeDivWidth;
        marginLeft += (yearReangeDivWidth * (startMonth + j * 12)) / (step * 12);
      }
    });

    return (
      <div aria-hidden onClick={() => handleMarkerClick(marker)} id={`${generateId(markerType(marker.type))}-${index}-${k}`} className="marker-timeline pointer" style={{ marginLeft: `${marginLeft}px` }} onMouseEnter={() => handleMarkerHover(`${generateId(markerType(marker.type))}-${index}-${k}`, marker)} onMouseLeave={() => handleMarkerHoverLeave()}>
        <div className={`marker-timeline-icon ${markerType(marker.type).toLocaleLowerCase()}`} />
      </div>
    );
  });

  const renderDocuments = () => data.map((document, index) => (
    <div className="document-timeline-bg" key={document.trial_id}>
      <div aria-hidden onClick={() => handleDocumentclick(document)} className={`${trialId === document.trial_id ? 'document-timeline-active' : 'document-timeline'} ${getStatusClassName(document)} ${checkBotrder(document)} ${getCompletionStatusClass(document)} pointer`} id={generateId(document.trial_id)} key={document.documentTrialId} style={genrateStyle(document)} onMouseEnter={() => handleDocumentHover(generateId(document.trial_id), document)} onMouseLeave={() => handleDocumentHoverLeave()}>
        {/* <div className={`dots-left-${getCompletedStatusDotClass(document, true)}`} /> */}
        <div className={`document-name ${(document.completion_date && !generateFalseEmptyCompletionDate) ? '' : getStatusClassName(document)}-inner`} style={(document.completion_date && !generateFalseEmptyCompletionDate) ? {} : genrateStyle(document, true)}>
          <div className={`document-ctr-2 ${getColorStyle(document)}`}>
            <div title={`${document.trial_id} ${document.phase_short !== 'NA' && document.phase_short && document.phase_short !== 'Other' ? `(${document.phase_short})` : ''}`} />
            {/* {document.trial_id}
            <span className="document-trailphase">
              &nbsp;
              {`${document.phase_short !== 'NA' && document.phase_short && document.phase_short !== 'Other' ? `(${document.phase_short})` : ''}`}
            </span> */}
          </div>
        </div>
        {/* <div className={`dots-right-${getCompletedStatusDotClass(document)}`} style={{ left: genrateStyle(document).width < 31 ? 0 : '4px' }} /> */}
      </div>
      {renderMarkers(document, index)}
    </div>
  ));

  const renderVerticalLine = () => {
    const tempArray = [];
    for (let i = 0; i < step - 1; i += 1) {
      tempArray.push(i);
    }
    return tempArray.map(() => (<div className="vertical-line" />));
  };

  const renderYears = () => fictitiousYearRange.map((year, i) => (
    <div className="flex items-center w-full">
      {i === 0 ? <div className="vertical-half-line" /> : null}
      <div className="year-timeline-label flex flex-col" key={year}>
        <div className="flex items-center justify-center">{year}</div>
        <div className="flex justify-evenly w-full">
          {renderVerticalLine()}
        </div>
      </div>
      <div className="vertical-half-line" />
    </div>
  ));

  const renderMarkerLegendes = () => Array.from(markers).map((marker) => (
    <>
      <div className={`marker-timeline-icon ${marker.toLocaleLowerCase()}`} />
      <div className="marker-label">{marker}</div>
    </>
  ));

  const renderMarkerTooltip = () => (
    <Tooltip className="document-timeline-tooltip" anchorSelect={`#${markerTooltipId}`} place="left" isOpen={showMarkerTooltip}>
      <div className="marker-tooltip">
        <div className="marker-tooltip-header-ctr">
          <div className="marker-tooltip-type-ctr">
            <SvgLoader width={30} height={30} svgName={markerType(markerTooltipData.type).toLowerCase()} />
            <div className="marker-tooltip-type">{capitalizeFirstLetter(markerTooltipData.displayType)}</div>
          </div>
          <div className="marker-tooltip-date">
            {markerTooltipData.type === 'Patent' ? <span>Granted on</span> : null}
            {' '}
            {markerTooltipData.date}
          </div>
        </div>
        <div className="marker-tooltip-trial-id" title={markerTooltipData.publicationId}>{markerTooltipData.publicationId}</div>
        <div className="marker-tooltip-trial-title">{markerTooltipData.title}</div>
        {markerTooltipData.dataSource ? <div className="marker-tooltip-trial-author">{markerTooltipData.dataSource}</div> : null}
      </div>
    </Tooltip>
  );

  const renderYaxisLabel = () => data.map((document, i) => (
    <div key={document.trial_id} className={`adjust-pos-labels-timeline flex items-center justify-start  ${i !== data.length - 1 ? '' : 'adjust-last-element'}`}>
      {i !== data.length - 1 && <div className="dotted-line-up" />}
      <div className={`dotted-line-hor ${i !== data.length - 1 ? '' : 'adjust-last-element'}`} />
      {
        trialId === document.trial_id && data.length > 1
          ? <div className="label-trial-id color-ontosight">This trial</div>
          : (
            <div className="label-trial-id">
              {document.trial_id}
              &nbsp;
              <span className="label-phase">{`(${document.phase_short || 'Other'})`}</span>
            </div>
          )
      }
    </div>
  ));

  const renderYaxisLabelPreviewView = () => data.map((document) => (
    <div key={document.trial_id} className="adjust-pos-labels-timeline flex items-center justify-start">
      <div className={`label-phase ${trialId === document.trial_id ? 'color-ontosight' : ''}`}>{shortForm[document.phase_short] || 'Other'}</div>
    </div>
  ));

  return (
    <div className="timeline-container flex">
      <Loader
        eror={timelineData.eror}
        loading={timelineData.loading}
        noData={timelineData.data.length === 0 && timelineData.flag}
        height="200px"
      >
        <div className={`flex flex-col ${detailView ? 'adjust-pos-detail-view w-2/12' : 'w-1/12'}`}>
          {
            detailView ? null : (
              <div key={document.trial_id} className="adjust-pos-ph-timeline flex items-center justify-start">
                <div className="ph-label-text">Ph</div>
              </div>
            )
          }
          {detailView ? renderYaxisLabel() : <div className="adjust-label-preview-pos">{renderYaxisLabelPreviewView()}</div>}
        </div>
        <div className={`${detailView ? 'w-10/12' : 'w-11/12'}`}>
          <div className="year-range" ref={yearRangeRef}>{yearRange[0] ? renderYears() : null}</div>
          {data.length ? renderDocuments() : null}
          {dontShowTooltip && showDocumentTooltip && documentTooltipId !== '' && renderDocumentTooltip()}
          <div className="marker-timeline-legends">
            {renderMarkerLegendes()}
            <div className="marker-timeline-icon oval-shape" />
            <div className="marker-label">Clinical Trial</div>
          </div>
        </div>
        {dontShowTooltip && showMarkerTooltip && markerTooltipId !== '' && renderMarkerTooltip()}
      </Loader>
    </div>
  );
}

export default TimeLine;
