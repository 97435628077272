import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_ORG_DATA = 'GET_ORG_DATA';
const GET_ORG_DATA_SUCCESS = 'GET_ORG_DATA_SUCCESS';
const GET_ORG_DATA_FAILURE = 'GET_ORG_DATA_FAILURE';
const GET_ORG_DATA_REFRESH = 'GET_ORG_DATA_REFRESH';

export const getOrgUserLIstAction = createAction(GET_ORG_DATA);

const getOrgUserLIstSuccess = createAction(GET_ORG_DATA_SUCCESS);

const getOrgUserLIstFaliure = createAction(GET_ORG_DATA_FAILURE);

export const getOrgUserLIstRefreshAction = createAction(GET_ORG_DATA_REFRESH);

export const getOrgUserLIstEpic = (actions$) => actions$.pipe(
  ofType(GET_ORG_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/organizations?${action.payload.headers}`, 'GET')).pipe(
    map((res) => getOrgUserLIstSuccess(res)),
    catchError((err) => of(getOrgUserLIstFaliure(err))),
  )),
);

const ongetOrgUserLIstAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetOrgUserLIstSuccess = (state, { response }) => ({
  ...state,
  total: response.total,
  data: [...state.data, ...response.data],
  loading: false,
  flag: true,
});

const ongetOrgUserLIstFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetOrgUserLIstRefreshAction = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const getOrgUserLIstReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getOrgUserLIstAction, (state) => ongetOrgUserLIstAction(state))
      .addCase(getOrgUserLIstSuccess, (state, action) => ongetOrgUserLIstSuccess(state, action.payload))
      .addCase(getOrgUserLIstFaliure, (state) => ongetOrgUserLIstFailure(state))
      .addCase(getOrgUserLIstRefreshAction, () => ongetOrgUserLIstRefreshAction())
      .addDefaultCase((state) => state);
  },
);

export default getOrgUserLIstReducer;
