import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Table } from 'antd';
import { createStyles } from 'antd-style';
import queryString from 'query-string';
import moment from 'moment';
import {
  currentDataModalAction, editTerminalsAction, getSingleUserDetailsAction, getUserListForAdminAction, getUserListForAdminRefreshAction,
  updateUserListAction,
  updateUserOrganizationNameAction,
  updateUserOrganizationNameRefreshAction,
  updateUserTypeAction,
  updateUserTypeRefreshAction,
} from './logic';
import { modifyDataForDropdown, rolesToAppName } from '../../../utils';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import EditTErminalsAccess from '../EditTerminalsAccess';
import EditCurrentAccessDate from '../EditCurrentAccessDate';
import { filtersKeys } from '../FilterAdmin/data';
import usertType from './data';
import SvgLoader from '../../../common/components/SvgLoader';
import { sendNotification } from '../../../common/components/Notification/logic';
import { getOrgUserLIstAction, getOrgUserLIstRefreshAction } from '../AdminFilterAutoSuggest/logic';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: unset;
          }
        }
      }
    `,
  };
});

export default function UserList() {
  const dispatch = useDispatch();
  const { styles } = useStyle();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrgListPage, setCurrentOrgListPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState('');
  const [userTypePopoverId, setUserTypePopoverId] = useState('');
  const [userTypePopoverType, setUserTypePopoverType] = useState('');
  const [editType, setEditType] = useState('');
  const [userTypeDetails, setUserTypeDetails] = useState({
    userId: '',
    email: '',
  });

  const userListAdmin = useSelector((state) => state.userListAdmin);
  const adminUserFilter = useSelector((state) => state.adminUserFilter.currentAdminFilter);
  const applyBtn = useSelector((state) => state.applyBtn);
  const singleUserDetail = useSelector((state) => state.singleUserDetail);
  const updateUserTypeStatus = useSelector((state) => state.updateUserTypeStatus);
  const orgUserList = useSelector((state) => state?.orgUserList);
  const updateUserOrgNameStatus = useSelector((state) => state.updateUserOrgNameStatus);

  const orgNamesList = modifyDataForDropdown(orgUserList.data.filter((item) => !item.is_deleted), 'domain', 'organization_id');

  const defaultState = () => {
    setUserTypeDetails({ ...{} });
    setUserTypePopoverId('');
    setUserTypePopoverType('');
    setSelectedOption('');
  };

  useEffect(() => {
    if (updateUserOrgNameStatus.flag) {
      if (updateUserOrgNameStatus.data.message === 'User organization updated successfully') {
        dispatch(sendNotification({
          type: 'UPDATE_ORG_NAME_TYPE_SUCCESSFULLY',
          notificationType: 'success',
          message: 'User organization updated successfully',
          showNotification: true,
        }));
        debounceCall(() => {
          dispatch(getSingleUserDetailsAction({
            headers: queryString.stringify({
              user_name: userTypeDetails.email,
            }),
          }));
          defaultState();
        }, 100);
      }
    }
  }, [JSON.stringify(updateUserOrgNameStatus)]);

  useEffect(() => {
    if (updateUserTypeStatus.flag) {
      if (updateUserTypeStatus.data.message === 'User account type updated successfully') {
        dispatch(sendNotification({
          type: 'UPDATE_USER_TYPE_SUCCESSFULLY',
          notificationType: 'success',
          message: 'User account type updated successfully',
          showNotification: true,
        }));
        debounceCall(() => {
          dispatch(getSingleUserDetailsAction({
            headers: queryString.stringify({
              user_name: userTypeDetails.email,
            }),
          }));
          defaultState();
        }, 100);
      }
    }
  }, [JSON.stringify(updateUserTypeStatus)]);

  useEffect(() => {
    if (singleUserDetail.flag) {
      dispatch(updateUserListAction({
        data: singleUserDetail.data,
      }));
    }
  }, [JSON.stringify(singleUserDetail)]);

  const loadList = (page, sortQuery = {}) => {
    const tempFilters = {};

    filtersKeys.forEach((item) => {
      if (adminUserFilter[item].length && item !== 'license_date') {
        tempFilters[item] = adminUserFilter[item].join(',');
      }

      if (adminUserFilter[item].length && item === 'license_date') {
        const startTimeStamp = adminUserFilter[item][0];
        const endTimeStamp = adminUserFilter[item][1];
        tempFilters.license_start_date = startTimeStamp;
        tempFilters.license_end_date = endTimeStamp;
      }
    });

    const request = {
      headers: queryString.stringify({
        from: page,
        size: 500,
        ...tempFilters,
        ...sortQuery,
      }),
    };
    debounceCall(() => {
      dispatch(getUserListForAdminAction(request));
      setCurrentPage((current) => current + 1);
    }, 200);
  };

  const handleTermianlEditAccess = (data) => {
    dispatch(editTerminalsAction({
      open: true,
      data,
    }));
  };

  const handleCurrentAccess = (data) => {
    dispatch(currentDataModalAction({
      open: true,
      data,
    }));
  };

  const convertTimestampToGMT = (timestamp, flag = false, data = {}) => {
    if (timestamp === 0) {
      return null;
    }
    const date = moment(Number(timestamp));

    if (date.isValid()) {
      const getDate = date.format('DD MMM, YYYY');
      const getTime = date.format('hh:mm A');

      return (
        <div>
          <div className="show-date">
            {getDate}
            {' '}
            {flag && <div aria-hidden onClick={() => handleCurrentAccess(data)} className="edit-icon-terminal" />}
          </div>
          <div>{getTime}</div>
        </div>
      );
    }
    return null;
  };

  const loadOrgList = (page) => {
    debounceCall(() => dispatch(getOrgUserLIstAction({
      headers: queryString.stringify({
        from: page,
        size: 100,
      }),
    })), 30);
    setCurrentOrgListPage(currentOrgListPage + 1);
  };

  const handleUserTypeClick = (a, type) => {
    setEditType(type);
    setUserTypePopoverId(a.email);
    setUserTypePopoverType(a.userOnboardingType);
    setUserTypeDetails({
      ...{
        userId: a.user_id,
        email: a.email,
      },
    });

    if (type === 'org-name') {
      dispatch(getOrgUserLIstRefreshAction());
      setCurrentOrgListPage(1);
      loadOrgList(1);
    }
  };

  const handleCrossClick = () => {
    setUserTypePopoverId('');
    setUserTypePopoverType('');
  };

  const handleUserTypeSubmitClick = () => {
    dispatch(updateUserTypeRefreshAction());
    dispatch(updateUserTypeAction({
      headers: queryString.stringify({
        user_id: userTypeDetails.userId,
        email: userTypeDetails.email,
        type: selectedOption,
      }),
    }));
  };

  const handleUserOrgNameSubmitClick = () => {
    dispatch(updateUserOrganizationNameRefreshAction());
    dispatch(updateUserOrganizationNameAction({
      headers: queryString.stringify({
        user_id: userTypeDetails.userId,
        email: userTypeDetails.email,
        organization: selectedOption,
      }),
    }));
  };

  const renderSelectUserType = () => (
    <div>
      <div className="label-header-user px-4 gap-3 flex items-center justify-between">
        <div>Select Account Type</div>
        <SvgLoader onClick={() => handleCrossClick()} width={18} height={18} svgName="modal-cross" pointer />
      </div>
      <div className="max-h-60 overflow-y-auto">
        {usertType.map((item) => (
          <div aria-hidden key={item.label} onClick={() => setSelectedOption(item.label)} className="label-user-box flex gap-2 h-12 items-center px-4 pointer">
            <input type="radio" checked={selectedOption === item.label} />
            <div className="label-user-checkbox">{item.label}</div>
          </div>
        ))}
      </div>
      <div className="update-user-type-buttons">
        <div aria-hidden onClick={() => handleCrossClick()} className="reset-all-btn pointer">Cancel</div>
        <div aria-hidden onClick={() => handleUserTypeSubmitClick()} className="filter-btn pointer">Update</div>
      </div>
    </div>
  );

  const renderSelecUserOrgName = () => (
    <div>
      <div className="label-header-user px-4 gap-3 flex items-center justify-between">
        <div>Select Organization</div>
        <SvgLoader onClick={() => handleCrossClick()} width={18} height={18} svgName="modal-cross" pointer />
      </div>

      <div className="max-w-96">
        <div className="max-h-60 overflow-y-auto">
          <LazyLoadDiv
            className="user-list-org-name-edit-modal"
            id="org-list-4"
            total={orgUserList?.total}
            currentTotal={(orgUserList.data || []).length}
            loadMoreRows={() => loadOrgList(currentOrgListPage)}
            height="200px"
          >
            {orgNamesList.map((item) => (
              <div aria-hidden key={item.label} onClick={() => setSelectedOption(item.label)} className="label-user-box flex gap-2 min-h-12 items-center px-4 pointer">
                <input type="radio" checked={selectedOption === item.label} />
                <div className="label-user-checkbox">{item.label}</div>
              </div>
            ))}
          </LazyLoadDiv>
        </div>
      </div>
      <div className="update-user-type-buttons">
        <div aria-hidden onClick={() => handleCrossClick()} className="reset-all-btn pointer">Cancel</div>
        <div aria-hidden onClick={() => handleUserOrgNameSubmitClick()} className="filter-btn pointer">Update</div>
      </div>
    </div>
  );

  const columns = [
    {
      title: 'Email ID',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      sorter: (a, b) => {
        const emailA = a.email.toLowerCase();
        const emailB = b.email.toLowerCase();

        return emailA.localeCompare(emailB);
      },
      fixed: 'left',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: 'User Onboarding Type',
      dataIndex: 'userOnboardingType',
      width: 200,
    },
    {
      title: 'User Account Type',
      key: 'userAccountType',
      render: (a) => (
        <div className="flex items-center gap-2">
          <div>{a.userAccountType}</div>
          <Popover overlayClassName="user-type-popover" content={renderSelectUserType} arrow={false} open={userTypePopoverId !== '' && a.email === userTypePopoverId && a.userOnboardingType === userTypePopoverType && editType === 'user-type'}>
            <div aria-hidden onClick={() => handleUserTypeClick(a, 'user-type')} className="edit-icon-terminal" />
          </Popover>
        </div>
      ),
      width: 200,
    },
    {
      title: 'Organization name',
      key: 'organizationName',
      render: (a) => (
        <div className="flex items-center gap-2">
          <div>{a.organizationName}</div>
          <Popover overlayClassName="user-type-popover" content={renderSelecUserOrgName} arrow={false} open={userTypePopoverId !== '' && a.email === userTypePopoverId && a.userOnboardingType === userTypePopoverType && editType === 'org-name'}>
            <div aria-hidden onClick={() => handleUserTypeClick(a, 'org-name')} className="edit-icon-terminal" />
          </Popover>
        </div>
      ),
      width: 200,
    },
    {
      title: 'Account activation date',
      dataIndex: 'createdOnDate',
      key: 'createdOnDate',
      sorter: (a, b) => a.createdOnDate - b.createdOnDate,
      render: (a) => convertTimestampToGMT(a || 0),
      width: 200,
    },
    {
      title: 'Last activity date and time',
      key: 'lastActivityOn',
      sorter: (a, b) => a.lastActivityOn - b.lastActivityOn,
      render: (a) => convertTimestampToGMT(a.lastActivityOn * 1000 || 0),
      width: 200,
    },
    {
      title: 'Current access start date and time',
      key: 'licenseStartDate',
      sorter: (a, b) => a.licenseStartDate - b.licenseStartDate,
      render: (a) => convertTimestampToGMT(a.licenseStartDate || 0, true, a),
      width: 200,
    },
    {
      title: 'Current access end date and time',
      key: 'licenseEndDate',
      sorter: (a, b) => a.licenseEndDate - b.licenseEndDate,
      render: (a) => (
        <div>
          {convertTimestampToGMT(a.licenseEndDate || 0, true, a)}
        </div>
      ),
      width: 200,
    },
    {
      title: 'List of features/Terminals',
      width: 200,
      render: (a) => (
        <div className="termainls-col">
          {a?.terminals?.map((terminal) => (
            <div className="terminal-box">{rolesToAppName[terminal] || terminal}</div>
          ))}
          <div aria-hidden onClick={() => handleTermianlEditAccess(a)} className="edit-icon-terminal" />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setCurrentPage(1);
    dispatch(getUserListForAdminRefreshAction());
    loadList(1);
  }, [applyBtn]);

  useEffect(() => {
    loadList(1);
  }, []);

  const handleChange = (pagination, filters, sorter) => {
    dispatch(getUserListForAdminRefreshAction());
    setCurrentPage(1);
    let sortQuery = {};
    if (sorter.order && sorter.columnKey) {
      sortQuery = {
        sort: sorter.columnKey,
        order: sorter.order === 'ascend' ? 'asc' : 'desc',
      };
    }
    loadList(1, sortQuery);
  };

  return (
    <div className="user-list-container">
      <LazyLoadDiv
        className="explore-list-results"
        id="document-list"
        total={userListAdmin?.total}
        currentTotal={(userListAdmin.data || []).length}
        height="calc(100vh - 200px)"
        loadMoreRows={() => loadList(currentPage)}
      >
        <Table
          borderec
          className={styles.customTable}
          columns={columns}
          dataSource={userListAdmin?.data}
          scroll={{
            x: 'max-content',
          }}
          sticky
          pagination={false}
          showSorterTooltip={false}
          onChange={handleChange}
        />
      </LazyLoadDiv>
      <EditTErminalsAccess />
      <EditCurrentAccessDate />
    </div>
  );
}
