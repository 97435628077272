const usertType = [
  { label: 'Free User', value: 'free' },
  { label: 'Enterprise User', value: 'enterprise' },
  { label: 'Paid User', value: 'paid' },
  { label: 'Internal User', value: 'internal' },
  { label: 'Internal Team User', value: 'team' },
  { label: 'Internal Admin User', value: 'admin' },
];

export default usertType;
