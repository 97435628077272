import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

const EDIT_COMMENT = 'EDIT_COMMENT';
const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS';
const EDIT_COMMENT_FAILURE = 'EDIT_COMMENT_FAILURE';
const EDIT_COMMENTS_REFRESH = 'EDIT_COMMENTS_REFRESH';

export const editCommentAction = createAction(EDIT_COMMENT);

const editCommentSuccess = createAction(EDIT_COMMENT_SUCCESS);

const editCommentFaliure = createAction(EDIT_COMMENT_FAILURE);

export const editCommentRefreshAction = createAction(EDIT_COMMENTS_REFRESH);

export const editCommentEpic = (actions$) => actions$.pipe(
  ofType(EDIT_COMMENT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/bookmark/editComment`, 'PATCH', action.payload.params)).pipe(

    map((res) => editCommentSuccess(res)),
    catchError((err) => of(editCommentFaliure(err))),
  )),
);

const oneditCommentAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const oneditCommentSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  showNotification: true,
  flag: true,
});

const oneditCommentFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const oneditCommentRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const editCommentReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(editCommentAction, (state) => oneditCommentAction(state))
      .addCase(editCommentSuccess, (state, action) => oneditCommentSuccess(state, action.payload))
      .addCase(editCommentFaliure, (state, action) => oneditCommentFaliure(state, action.payload))
      .addCase(editCommentRefreshAction, () => oneditCommentRefresh())
      .addDefaultCase((state) => state);
  },
);

export { editCommentReducer };
