import { Checkbox, Radio, Button } from 'antd';
import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emailPreferenceAction, emailPreferenceRefresh } from '../SavedSearches/logic';
import { sendNotification } from '../../components/Notification/logic';
import { makeFirstLetterCapital } from '../../../utils';

function SavePreferences({
  setShowSavePreferences, hash, setUserPreference, preferenceData,
}) {
  const dispatch = useDispatch();
  const emailPreference = useSelector((state) => state.emailPreference);
  const [isMailEnabled, setIsMailEnabled] = useState(false);
  const [preference, setPreference] = useState('');

  const data = ['Weekly', 'Daily'];

  useEffect(() => {
    if (preferenceData !== null) {
      setIsMailEnabled(preferenceData?.isMailEnable);
      setPreference(preferenceData?.emailPreference);
    } else {
      setIsMailEnabled(false);
      setPreference('');
    }
  }, [JSON.stringify(preferenceData)]);

  const handleSavePreferences = () => {
    dispatch(emailPreferenceAction(
      {
        body: {
          hash,
          isMailEnable: isMailEnabled,
          email_preference: preference,
        },
      },
    ));
    setShowSavePreferences(false);
    setUserPreference(preference);
  };

  useEffect(() => {
    if (emailPreference?.flag) {
      dispatch(
        sendNotification({
          type: 'EMAIL_PREFERENCE_SUCCESS',
          notificationType: 'success',
          message: emailPreference?.data?.message,
          showNotification: true,
        }),
      );

      dispatch(emailPreferenceRefresh());
    }
  }, [JSON.stringify(emailPreference)]);
  return (
    <div className="save-preferences-container">
      <div className="save-preferences-header">
        <div className="save-preferences-title">Keep me up-to date</div>
        <div className="cross-icon" onClick={() => setShowSavePreferences(false)} role="presentation" />
      </div>
      <div className="save-preferences-body">
        <Checkbox
          value={isMailEnabled}
          checked={isMailEnabled}
          className="save-preferences-checkbox"
          onChange={(e) => setIsMailEnabled(e.target.checked)}
        >
          Get an Email for new results
        </Checkbox>
      </div>
      <div className="save-preferences-radio-group">
        <Radio.Group value={preferenceData === 'null' ? 'Weekly' : makeFirstLetterCapital(preference)} onChange={(e) => setPreference(e.target.value)} disabled={!isMailEnabled}>
          {data?.map((item) => (
            <div className="radio-item">
              <Radio value={item} />
              <div className="radio-text">{item}</div>
            </div>
          ))}
        </Radio.Group>
      </div>
      <div className="save-preferences-footer">
        <Button className="cancel-btn" onClick={() => setShowSavePreferences(false)}>Cancel</Button>
        <Button className="save-btn" onClick={() => handleSavePreferences()}>Save</Button>
      </div>
    </div>
  );
}

export default SavePreferences;
