const terminalContent = {
  'ot-ct-access': {
    content: {
      comingSoon: {
        header: 'Coming Soon!',
        subHeader: 'Our team is currently working on Clinical Trials Terminal and we will update you as soon as it is completed.',
      },
      requestAccess: {
        header: 'You do not have access to Clinical Trials Terminal',
      },
    },
    label: 'ct-card',
    image: 'clinical-without-header.png',
  },
  'ot-search-exp-access': {
    content: {
      comingSoon: {
        header: 'Coming Soon!',
        subHeader: 'Our team is currently working on Search & Explore Terminal and we will update you as soon as it is completed.',
      },
      requestAccess: {
        header: 'You do not have access to Search & Explore Terminal',
      },
    },
    label: 'search-card',
    image: 'search-without-header.png',
  },
  'ot-therapy-access': {
    content: {
      comingSoon: {
        header: 'Coming Soon!',
        subHeader: 'Our team is currently working on Therapy Area and we will update you as soon as it is completed.',
      },
      requestAccess: {
        header: 'You do not have access to Therapy Area',
      },
    },
    label: 'therapy-card',
    image: 'app-img.png',
  },
  'ot-protein-access': {
    content: {
      comingSoon: {
        header: 'Coming Soon!',
        subHeader: 'Our team is currently working on Protein Terminal and we will update you as soon as it is completed.',
      },
      requestAccess: {
        header: 'You do not have access to Protein Terminal',
      },
    },
    label: 'protein-card',
    image: 'app-img.png',
  },
  'ot-cl-access': {
    content: {
      comingSoon: {
        header: 'Coming Soon!',
        subHeader: 'Our team is currently working on Competitive Landscape Terminal and we will update you as soon as it is completed.',
      },
      requestAccess: {
        header: 'You do not have access to Competitive Landscape Terminal',
      },
    },
    label: 'cl-card',
    image: 'app-img.png',
  },
  'ot-pl-access': {
    content: {
      comingSoon: {
        header: 'Coming Soon!',
        subHeader: 'Our team is currently working on Preclinical Landscape Terminal and we will update you as soon as it is completed.',
      },
      requestAccess: {
        header: 'You do not have access to Preclinical Landscape Terminal',
      },
    },
    label: 'pl-card',
    image: 'app-img.png',
  },
  'ot-intervention-access': {
    content: {
      comingSoon: {
        header: 'Coming Soon!',
        subHeader: 'Our team is currently working on Intervention Terminal and we will update you as soon as it is completed.',
      },
      requestAccess: {
        header: 'You do not have access to Intervention Terminal',
      },
    },
    label: 'intervention-card',
    image: 'app-img.png',
  },
  'ot-disease-access': {
    content: {
      comingSoon: {
        header: 'Coming Soon!',
        subHeader: 'Our team is currently working on Disease Terminal and we will update you as soon as it is completed.',
      },
      requestAccess: {
        header: 'You do not have access to Disease Terminal',
      },
    },
    label: 'disease-card',
    image: 'app-img.png',
  },
  influence_app_access: {
    content: {
      comingSoon: {
        header: 'Coming Soon!',
        subHeader: 'Our team is currently working on Disease Terminal and we will update you as soon as it is completed.',
      },
      requestAccess: {
        header: 'You do not have access to Disease Terminal',
      },
    },
    label: 'influence-card',
    image: 'influence-image.png',
  },
  xplore_app_access: {
    content: {
      comingSoon: {
        header: 'Coming Soon!',
        subHeader: 'Our team is currently working on Disease Terminal and we will update you as soon as it is completed.',
      },
      requestAccess: {
        header: 'You do not have access to Disease Terminal',
      },
    },
    label: 'explore-card',
    image: 'explore-image.png',
  },
};

export default terminalContent;
