/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateFilterAction } from '../../container/PowerSearch/logic';
import { getTerminalName } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';

function Calender({
  setShow, id, selectedValue, removedValues, datePickerClassName, containerClassName,
}) {
  const dispatch = useDispatch();
  dayjs.extend(customParseFormat);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const query = searchParams.get('query');
  const [dateValues, setDateValues] = useState([]);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  useEffect(() => {
    if (query && !removedValues?.includes(selectedValue)) {
      const q = JSON.parse(query);
      q.forEach((item) => {
        if (item?.name === selectedValue) {
          if (item?.currentFilter) {
            const date = new Date(item.currentFilter[0] * 1000);
            const date1 = new Date(item.currentFilter[1] * 1000);
            if (item.currentFilter[0]) {
              setStart(dayjs(`${date.toISOString().slice(0).replace(/-/g, '-')}`));
            }
            if (item.currentFilter[1]) {
              setEnd(dayjs(`${date1.toISOString().slice(0).replace(/-/g, '-')}`));
            }
            setDateValues([(item.currentFilter[0] || ''), (item.currentFilter[1] || '')]);
            dispatch(updateFilterAction({
              filterOption: { currentFilter: [(item.currentFilter[0] || ''), (item.currentFilter[1] || '')], currentKeys: [(item.currentFilter[0] || ''), (item.currentFilter[1] || '')] },
              id,
            }));
          }
        }
      });
    }
  }, []);

  const onChange = (date, type) => {
    if (type === 'start') {
      const startDate = date ? moment(date.$d).startOf('day').unix() : '';
      setDateValues([startDate, dateValues[1] || '']);
      setStart(date);
      dispatch(updateFilterAction({
        filterOption: { currentFilter: [startDate, (dateValues[1] || '')], currentKeys: [startDate, (dateValues[1] || '')] },
        id,
      }));
    } else {
      const endDate = date ? moment(date.$d).endOf('day').unix() : '';
      setEnd(date);
      setDateValues([dateValues[0] || '', endDate]);
      dispatch(updateFilterAction({
        filterOption: { currentFilter: [dateValues[0] || '', endDate], currentKeys: [dateValues[0] || '', endDate] },
        id,
      }));
    }
    setShow('power');
    return false;
  };

  const handleTap = () => {
    Mixpanel.track('field_tap_event', {
      action: 'user taped on search box to fill data for power search',
      field_name: selectedValue,
      terminal_name: getTerminalName(location),
      page_name: 'Search Page',
      search_type: 'Power Search',
    });
  };

  return (
    <div aria-hidden className={`calender-container ${containerClassName}`} onClick={() => handleTap()}>
      <DatePicker
        value={start}
        placeholder="Start date"
        onChange={(date) => { onChange(date, 'start'); }}
        className={datePickerClassName}
      />
      <DatePicker
        value={end}
        placeholder="End date"
        onChange={(date) => { onChange(date, 'end'); }}
        className={datePickerClassName}
      />
    </div>
  );
}

Calender.propTypes = {
  setShow: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  selectedValue: PropTypes.string.isRequired,
  removedValues: PropTypes.instanceOf(Array).isRequired,
  datePickerClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

Calender.defaultProps = {
  datePickerClassName: '',
  containerClassName: '',
};

export default Calender;
