import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { getTerminalName } from '../../../utils';
import SemanticSearch from '../SemanticSearch';
import PowerSearch from '../../container/PowerSearch';
import SvgLoader from '../SvgLoader';
import Mixpanel from '../../../utils/mixpanel';

function SearchComponent({ terminalName, apiUrl, autoSuggestUrl }) {
  const ctrSemantic = useAnimation();
  const ctrPower = useAnimation();
  const ctrGreetings = useAnimation();
  const ctrGoBtn = useAnimation();
  const location = useLocation();

  const [isSemanticSearch, setIsSemanticSearch] = useState(false);
  const [goClick, setGoClick] = useState(false);
  const [goBtnDissable, setGoBtnDissable] = useState(true);
  const [show, setShow] = useState('both');

  const transition = {
    duration: 0.5,
  };

  const backBtnClick = () => {
    setShow('both');
    Mixpanel.track('back_button_event', {
      action: `Try ${show === 'power' ? 'semantic' : 'power'} search button click`,
      terminal_name: getTerminalName(location),
      page: 'Search Page',
      misc: `Try ${show === 'power' ? 'semantic' : 'power'} search`,
    });
  };

  useEffect(() => {
    ctrSemantic.start({
      opacity: show === 'power' ? 0 : 1,
      y: show === 'semantic' ? 50 : 0,
      transition,
    });

    ctrGreetings.start({
      y: 0,
      transition,
    });

    ctrPower.start({
      opacity: show === 'semantic' ? 0 : 1,
      y: show === 'power' ? '-70px' : 0,
      transition,
    });

    ctrGoBtn.start({
      y: show === 'both' ? 0 : '-50px',
      transition,
    });
    if (show === 'power') {
      setIsSemanticSearch(false);
    }
  }, [show]);

  return (
    <div className="ct-home-page-main">
      <div className="ct-home-page">
        <motion.div
          className="greetings"
          initial={{
            y: 0,
          }}
          animate={ctrGreetings}
        >
          {`Hello, Welcome to ${terminalName} Terminal`}
        </motion.div>
        <div aria-hidden className="search-main">
          {show !== 'both' && (
            <motion.div
              initial={{
                y: 0,
              }}
              animate={ctrGreetings}
              className="back-btn"
              onClick={() => backBtnClick()}
            >
              <SvgLoader width={20} height={20} svgName="left-arrow" />
              {show === 'power' ? 'Try Semantic Search' : 'Try Power Search'}
            </motion.div>
          )}
          <motion.div
            initial={{
              opacity: 1,
              y: 0,
            }}
            animate={ctrSemantic}
          >
            {show === 'both' && <div className="header">Semantic Search</div>}
            <SemanticSearch setShow={setShow} goClick={goClick} goBtnDissable={goBtnDissable} setGoBtnDissable={setGoBtnDissable} setIsSemanticSearch={setIsSemanticSearch} show={show} />
          </motion.div>
          {show === 'both' && (
            <div className="or-container">
              <div className="divider" />
              <div className="ot-text">OR</div>
              <div className="divider" />
            </div>
          )}
          <motion.div
            className={`search-section-main ${show === 'semantic' ? 'search-main-semantic' : ''}`}
            initial={{
              opacity: show === 'semantic' ? 0 : 1,
              y: 0,
            }}
            animate={ctrPower}
          >
            {show === 'both' && <div className="header">Power Search</div>}
            <PowerSearch
              apiUrl={apiUrl}
              autoSuggestUrl={autoSuggestUrl}
              goBtnDissable={goBtnDissable}
              goClick={goClick}
              setShow={setShow}
              setGoBtnDissable={setGoBtnDissable}
              isSemanticSearch={isSemanticSearch}
              setIsSemanticSearch={setIsSemanticSearch}
            />
          </motion.div>
          <motion.span
            animate={ctrGoBtn}
            whileHover={{
              scale: 1.1,
            }}
            className={`go-button ${goBtnDissable ? 'disabled' : ''}`}
            onClick={() => setGoClick(true)}
          >
            Go
          </motion.span>
        </div>
      </div>
    </div>
  );
}

SearchComponent.propTypes = {
  terminalName: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
  autoSuggestUrl: PropTypes.string.isRequired,
};

export default SearchComponent;
