import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  appPermission, checkPermission, getCookie, windowHrefToNavigate,
} from '../../../utils';
import { sendNotification } from '../../components/Notification/logic';

function RedirectPage() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const app = searchParams.get('app');

    if (app && getCookie('accessToken') && checkPermission(appPermission[app])) {
      if (app === 'influence' && getCookie('influenceLoggedIn') === 'true') {
        window.location.href = `${process.env.terminalURL[app]}`;
      } else if (app === 'influence') {
        window.location.href = `${process.env.terminalURL[app]}?alreadyLoggedIn=true`;
      } else {
        window.location.href = process.env.terminalURL[app];
      }
    } else if (!checkPermission(appPermission[app]) && getCookie('accessToken')) {
      dispatch(sendNotification({
        type: 'APP_ACCESS_FAILURE',
        notificationType: 'warning',
        message: `You don't have ${app} access, please contact support`,
        showNotification: true,
      }));
      setTimeout(() => {
        windowHrefToNavigate('/home');
      }, 3000);
    }
  });

  return (
    <div className="white-page" />
  );
}

export default RedirectPage;
