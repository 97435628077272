import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import useAppSelector from '../../../utils/redux-hook';
import Loader from '../../../common/components/Loader';
import Card from '../../components/Card';
import { getTerminalsAction } from './logic';
import { getCookie } from '../../../utils';

function Selection({ setIsOpenRequestDemo }) {
  const dispatch = useDispatch();
  const terminals = useAppSelector((state) => state.terminals);

  let timer = null;
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      dispatch(getTerminalsAction({
        headers: queryString.stringify({
          email: getCookie('userEmail'),
        }),
      }));
    }, 50);
  }, []);

  const renderCards = (type) => terminals?.data?.map((item) => (
    // eslint-disable-next-line no-underscore-dangle
    <Card key={`${item._id}_${item.terminal}`} terminal={item.terminal} description={item.description} type={type} status={item.status} setOpen={() => setIsOpenRequestDemo(true)} />
  ));

  return (
    <Loader
      error={terminals.error}
      loading={terminals.loading}
      noData={terminals.data?.length === 0}
      height="84vh"
      // className="white-loader"
    >
      <div className="home-page">
        <div className="home-bg-img">
          <div className="blue-bg-eclips-left" />
          <div className="blue-bg-eclips-right" />
          <div className="blue-bg-eclips-left-bottom" />
          <div className="blue-bg-eclips-right-bottom" />
        </div>
        <div className="home-page-content">
          <div className="unclocked">
            <div className="header">Recommended terminals based on your previous session</div>
            <div className="terminal-container">
              {renderCards('unlocked')}
            </div>
          </div>
          <div className="unclocked">
            <div className="header">Unlock Additional Terminals </div>
            <div className="terminal-container">
              {renderCards('locked')}
            </div>
          </div>
          <div className="unclocked">
            <div className="header">Coming Soon!</div>
            <div className="terminal-container">
              {renderCards('comming soon')}
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
}

Selection.propTypes = {
  setIsOpenRequestDemo: PropTypes.func.isRequired,
};

export default Selection;
