const inforTrialColorDetails = [
  { label: 'Completed', color: '#80D2C5' },
  { label: 'Completed & current trial', color: '#00A58A' },
  { label: 'Withdrawn', color: '#E89494' },
  { label: 'Withdrawn & current trial', color: '#D12A2A' },
  { label: 'In progress', color: '#F0CC80' },
  { label: 'In progress & current trial', color: '#E29900' },
];

export const shortForm = {
  'Early Ph-I': 'I',
  'Ph-0': '0',
  'Ph-I': 'I',
  'Ph-II': 'II',
  'Ph-III': 'III',
  'Ph-IV': 'IV',
  'Ph-I/ II': 'II',
  'Ph-I/ III': 'III',
  'Ph-I/ IV': 'IV',
  'Ph-I/ II/ III': 'III',
  'Ph-I/ II/ IV': 'II',
  'Ph-I/ III/ IV': 'III',
  'Ph-I/ II/ III/ IV': 'III',
  'Ph-II/ III/ IV': 'III',
  'Ph-II/ III': 'III',
  'Ph-II/ IV': 'II',
  'Ph-III/ IV': 'III',
  NA: 'NA',
  Other: 'Other',
};

export default inforTrialColorDetails;
