import React, { useEffect, useState } from 'react';
import { checkPermission, getCookie, windowHrefToNavigate } from '../../../utils';
import Header from '../../../common/components/Header';
import AdminSubHeader from '../../container/AdminSubHeader';
import UserList from '../../container/UserList';
import OrgList from '../../container/OrgList';
import Mixpanel from '../../../utils/mixpanel';

export default function AdminPage() {
  const [listType, setListType] = useState('user');

  const checkAccess = checkPermission('ot-admin');

  useEffect(() => {
    if (getCookie('accessToken') && !checkAccess) {
      windowHrefToNavigate('/home');
    }
  });

  useEffect(() => {
    if (listType === 'user') {
      Mixpanel.track('page_entry_event', {
        page: 'Admin users list page',
        terminal_name: 'Admin Panel',
      });
    }
  }, [listType]);

  const handleListTypeClick = (type) => setListType(type);

  const renderListTypeHeader = () => (
    <div className="list-type-sub-header">
      <div aria-hidden onClick={() => handleListTypeClick('user')} className={`${listType === 'user' ? 'active-list' : 'not-active-list'} pointer`}>Users List</div>
      <div aria-hidden onClick={() => handleListTypeClick('org')} className={`${listType === 'org' ? 'active-list' : 'not-active-list'} pointer`}>Organization List</div>
    </div>
  );

  return (
    <div className="admin-page">
      <Header className="results-header-light" type="light" />
      {renderListTypeHeader()}
      {listType === 'user' ? <AdminSubHeader /> : null}
      <div className="admin-main-page-v2">
        {listType === 'user' ? <UserList /> : null}
        {listType === 'org' ? <OrgList /> : null}
      </div>
    </div>
  );
}
