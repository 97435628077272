import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_GRAPH_DATA = 'GET_GRAPH_DATA';
const GET_GRAPH_DATA_SUCCESS = 'GET_GRAPH_DATA_SUCCESS';
const GET_GRAPH_DATA_FAILURE = 'GET_GRAPH_DATA_FAILURE';
const GET_GRAPH_DATA_REFRESH = 'GET_GRAPH_DATA_REFRESH';

export const getGraphDataAction = createAction(GET_GRAPH_DATA);

const getGraphDataSuccess = createAction(GET_GRAPH_DATA_SUCCESS);

const getGraphDataFaliure = createAction(GET_GRAPH_DATA_FAILURE);

export const getGraphDataRefreshAction = createAction(GET_GRAPH_DATA_REFRESH);

export const getGraphDataEpic = (actions$) => actions$.pipe(
  ofType(GET_GRAPH_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getGraphDataSuccess(res)),
    catchError((err) => of(getGraphDataFaliure(err))),
  )),
);

const ongetGraphDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetGraphDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  total: response.total,
  loading: false,
  flag: true,
});

const ongetGraphDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onGetGraphDataRefreshAction = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const getGraphDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getGraphDataAction, (state) => ongetGraphDataAction(state))
      .addCase(getGraphDataSuccess, (state, action) => ongetGraphDataSuccess(state, action.payload))
      .addCase(getGraphDataFaliure, (state) => ongetGraphDataFailure(state))
      .addCase(getGraphDataRefreshAction, () => onGetGraphDataRefreshAction())
      .addDefaultCase((state) => state);
  },
);

export default getGraphDataReducer;
