/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import Loader from '../../components/Loader';
import webAuth from '../../../utils/webAuth';
import { sendNotification } from '../../components/Notification/logic';
import { getCookie, setCookie, windowHrefToNavigate } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';
import { removeSession, setSession } from '../Status/logic';
import { getLicenceAction } from '../../../app/container/Main/logic';
import { updateReferralUserAction } from './logic';

export default function Callback() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  let timer = null;

  const updateReferralStatus = useSelector((state) => state.updateReferralStatus);

  const accessToken = searchParams.get('access_token');

  const getToken = async (token) => {
    try {
      const response = await axios.get(`${process.env.apiUrl}/common/v0/auth/callback?refresh_token=${getCookie('refreshToken')}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      const fullName = `${response.data.data.profile.first_name} ${response.data.data.profile.last_name}`;
      const userName = `${response.data.data.profile.user_id}`;
      const bearer = `Bearer ${response.data.data.accessToken}`;
      localStorage.setItem('permissions', response.data.data.permissions);
      setCookie(userName, 'userName');
      setCookie(fullName, 'fullName');
      setCookie(response.data.data.profile.organization || 'individual', 'org');
      dispatch(setSession(bearer));
      setCookie(response.data.data.refreshToken, 'refreshToken');
      setCookie(response.data.data.user_account_type, 'user_account_type');
      setCookie('', 'expiresIN');
      Mixpanel.identify(`${userName}@${response.data.data.profile.organization || 'individual'}`);
      dispatch(getLicenceAction({
        headers: queryString.stringify({
          email: getCookie('userEmail'),
        }),
      }));
      if (getCookie('personaBuild') === 'false') {
        Mixpanel.track('persona_event', {
          action: 'user persona building process has began',
          data: getCookie('userEmail'),
        });
        if (getCookie('refreshTokenGetState') !== 'true') {
          windowHrefToNavigate('/persona');
        } else {
          setCookie(false, 'refreshTokenGetState');
          windowHrefToNavigate('/persona');
        }
      } else if (getCookie('currentTerminalUrl')) {
        if (getCookie('refreshTokenGetState') !== 'true') {
          windowHrefToNavigate(getCookie('currentTerminalUrl'));
        } else {
          setCookie(false, 'refreshTokenGetState');
          windowHrefToNavigate(getCookie('currentTerminalUrl'));
        }
      } else if (getCookie('loggedUrl')) {
        if (getCookie('refreshTokenGetState') !== 'true') {
          windowHrefToNavigate(getCookie('loggedUrl'));
        } else {
          setCookie(false, 'refreshTokenGetState');
          windowHrefToNavigate(getCookie('loggedUrl'));
        }
      }
      return 0;
    } catch (err) {
      Mixpanel.track('loginFailureEvent', {
        action: 'Login failure',
        error: `${err?.message} || ${err}`,
      });
      setCookie(false, 'pageLoading');
      windowHrefToNavigate('/');
      return 0;
    }
  };

  useEffect(() => {
    if (updateReferralStatus.flag) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        getToken(accessToken);
        const bearer = `Bearer ${accessToken}`;
        setCookie(bearer, 'accessToken');
        setCookie(false, 'pageLoading');
      }, 200);
    }

    if (updateReferralStatus.error) {
      Mixpanel.track('get_refresh_token_failure_event', {
        action: 'error while fetching the refresh token',
        previousRefreshToken: getCookie('refreshToken'),
      });
      dispatch(removeSession());
      windowHrefToNavigate('/', { replace: true });
    }
  }, [JSON.stringify(updateReferralStatus)]);

  useEffect(() => {
    if (accessToken && getCookie('utm_campaign') === 'Referral') {
      dispatch(updateReferralUserAction({
        accessToken: `Bearer ${accessToken}`,
        headers: queryString.stringify({
          referral_id: getCookie('utm_source'),
          email: getCookie('utm_content'),
        }),
      }));
    }
    if (accessToken && getCookie('utm_campaign') !== 'Referral') {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        getToken(accessToken);
        const bearer = `Bearer ${accessToken}`;
        setCookie(bearer, 'accessToken');
      }, 100);
    }
  }, [accessToken]);

  return (
    <Loader height="100vh" loading />
  );
}
