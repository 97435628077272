import React from 'react';
import { useDispatch } from 'react-redux';
import bookmarkSortByOptions from './data';
import { getBookmarkAction } from '../Bookmark/logic';

function BookmarkSortOptions({
  bookmarkFilters,
  bookmarkFilterUsers,
  projectId,
  setOpenSortPopover,
  setSortByOptions,
  currentPage,

}) {
  const dispatch = useDispatch();

  function convertToLowerCaseWithUnderscores(filterData) {
    return filterData.map((filter) => filter.toLowerCase().replace(/ /g, '_'));
  }
  const handleSortByClick = (sortOption) => {
    setSortByOptions({
      field: sortOption.field,
      order: sortOption.order,
    });
    setOpenSortPopover(false);
    dispatch(
      getBookmarkAction({
        body: {
          project_id: projectId,
          asset_class: convertToLowerCaseWithUnderscores(bookmarkFilters),
          sort: {
            field: sortOption.field,
            order: sortOption.order,
          },

          user_ids: bookmarkFilterUsers,
          export: false,
          file_type: '',
          page: currentPage,
          size: 10,
        },
      }),
    );
  };

  const handleResetClick = () => {
    setOpenSortPopover(false);
    setSortByOptions({});

    dispatch(
      getBookmarkAction({
        body: {
          project_id: projectId,
          asset_class: [],
          sort: {},
          user_ids: bookmarkFilterUsers,
          export: false,
          file_type: '',
        },
      }),
    );
  };
  return (
    <div className="bookmark-sort-container">
      <div className="bookmark-sort-container-header">
        <div className="bookmark-sort-container-header-title">Sort By</div>
        <div
          role="presentation"
          className="bookmark-sort-container-header-reset"
          onClick={() => handleResetClick()}
        >
          Reset
        </div>
      </div>
      <div className="bookmark-sort-container-body">
        {bookmarkSortByOptions.map((i) => (
          <div
            role="presentation"
            className="bookmark-sort-container-body-item"
            onClick={() => handleSortByClick(i)}
          >
            <div className="bookmark-sort-container-body-item-title">
              {i.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BookmarkSortOptions;
