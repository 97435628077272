import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SvgLoader from '../../../common/components/SvgLoader';
import { getCtSectionListAction, getCtRelatedArticlesAction, getDocsSEAction } from './logic';
import Loader from '../../../common/components/Loader';
import ExploreList from '../../container/ExploreList';
import Mixpanel from '../../../utils/mixpanel';
import { getTerminalName } from '../../../utils';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function PreviewSectionCard({
  trialData, setShowDeepdive, showDataFor, isdeepdive, showDeepdive, terminal = '', from,
}) {
  let tabs = [
    {
      icon: 'similar-trials-tab-icon',
      name: 'Similar Trials',
      value: 'similar_trials',
    },
    {
      icon: 'duplicate-trials-tab-icon',
      name: 'Also Registered As',
      value: 'duplicate_trials',
    },
    {
      icon: 'related-trials-tab-icon',
      name: 'Related Trials',
      value: 'related_trials',
    },
    {
      icon: 'related-articles-tab-icon',
      name: 'Related Articles',
      value: 'related_articles',
    },
  ];

  if (terminal === 'SE') {
    tabs = [
      {
        icon: 'similar-trials-tab-icon',
        name: 'Similar Articles',
        value: 'similar_trials',
      },
      // {
      //   icon: 'duplicate-trials-tab-icon',
      //   name: 'Also Registered As',
      //   value: 'duplicate_trials',
      // },
      // {
      //   icon: 'related-trials-tab-icon',
      //   name: 'Related Trials',
      //   value: 'related_trials',
      // },
      // {
      //   icon: 'related-articles-tab-icon',
      //   name: 'Related Articles',
      //   value: 'related_articles',
      // },
    ];
  }

  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const deepdivepage = searchParams.get('deepdivepage');

  const getCtSectionList = useSelector((state) => state.getCtSectionList);
  const getCtRelatedArticles = useSelector((state) => state.getCtRelatedArticles);
  const seDocs = useSelector((state) => state.seDocs);
  const currentDocId = useSelector((state) => state.currentDocId);

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [relatedArticlesListData, setRelatedArticlesListData] = useState([]);
  const [handleClickedVal, setHandleClickedVal] = useState({});
  const [previewSimilartDocData, setPreviewSimilarDocData] = useState({});

  useEffect(() => {
    if (deepdivepage) {
      setActiveTab(JSON.parse(deepdivepage).tab);
    }
  }, [deepdivepage]);

  useEffect(() => {
    if (getCtSectionList.flag && terminal !== 'SE') {
      setPreviewSimilarDocData({ ...getCtSectionList, data: getCtSectionList.data.data });
    }
  }, [terminal, JSON.stringify(getCtSectionList)]);

  useEffect(() => {
    if (terminal === 'SE' && seDocs.flag) {
      setPreviewSimilarDocData({ ...seDocs });
    }
  }, [terminal, JSON.stringify(seDocs)]);

  const getTrialId = (data) => {
    const type = data.type.toLowerCase();

    switch (type) {
      case 'congresses':
        return data.congress_id;
      case 'patents':
        return data.patent_id;
      case 'press_releases':
        return data.press_id;
      case 'guidelines':
        return data.guideline_id;
      case 'grants':
        return data.grant_id;
      default:
        return data.publication_id;
    }
  };

  useEffect(() => {
    if (terminal !== 'SE') {
      if (trialData?.trial_id && activeTab && !Object.keys(handleClickedVal)?.length) {
        if (activeTab.value === 'related_articles') {
          dispatch(getCtRelatedArticlesAction({
            params: {
              headers: queryString.stringify({
                from: 1,
                size: isdeepdive ? 50 : 5,
                trial_id: trialData?.trial_id,
              }),
            },
          }));
        } else {
          debounceCall(() => {
            dispatch(getCtSectionListAction({
              params: {
                url: '/common/v0/search/clinical_trials/related',
                headers: queryString.stringify({
                  from: 1,
                  size: isdeepdive ? 50 : 5,
                  trial_id: currentDocId.trialId || trialData?.trial_id,
                  relation: activeTab.value,
                }),
              },
            }));
          }, 100);
        }
      }
    }

    if (terminal === 'SE' && trialData) {
      if (JSON.stringify(trialData) !== '{}' && trialData.type !== 'regulatory_data' && trialData.type !== 'hta_recommendations') {
        dispatch(getDocsSEAction({
          apiUrl: '/common/v0/search/similar_documents',
          headers: queryString.stringify({
            from: 1,
            size: isdeepdive ? 50 : 5,
            id: getTrialId(trialData),
            assetClass: trialData.type.toLowerCase(),
          }),
          method: 'GET',
        }));
      }
    }
  }, [JSON.stringify(trialData), JSON.stringify(currentDocId), activeTab, showDeepdive, terminal]);

  useEffect(() => {
    if (getCtRelatedArticles.flag) {
      const temp = [];
      if (getCtRelatedArticles?.data?.data) {
        temp.push(...getCtRelatedArticles.data.data);
        setRelatedArticlesListData(temp);
      }
    }
  }, [JSON.stringify(getCtRelatedArticles)]);

  useEffect(() => {
    if (handleClickedVal && Object.keys(handleClickedVal).length) {
      if (isdeepdive) {
        const temp = {
          type: 'clinical_trials',
          doc_id: handleClickedVal?.doc_id,
          trial_id: handleClickedVal?.trial_id,
          tab: activeTab,
        };
        const url = `${location.pathname}${location.search}&deepdivepage=${JSON.stringify(temp)}`;
        window.open(url, '_blank');
      } else {
        setShowDeepdive(true);
        showDataFor({
          type: 'clinical_trials',
          doc_id: handleClickedVal?.doc_id,
          trial_id: handleClickedVal?.trial_id,
        });
      }
      setHandleClickedVal({});
    }
  }, [handleClickedVal]);

  const handleTab = (tab) => {
    setActiveTab(tab);
    Mixpanel.track('associated_docs_event', {
      action: `${tab.name} click`,
      terminal_name: getTerminalName(location),
      page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
      document_type: tab.name,
    });
  };

  const renderList = () => (
    <Loader loading={previewSimilartDocData.loading} error={previewSimilartDocData.error} height={`${isdeepdive ? '300px' : '130px'}`} noData={!previewSimilartDocData?.data?.length} message="No associated documents">
      <div className="list-container preview-section">
        {previewSimilartDocData?.data?.map((list) => (
          <div className="search-and-explore-results-container-list ct-prev-sec">
            <ExploreList fromPreviewSE key={list?.doc_id} data={list} isPrevSection={terminal !== 'SE'} setHandleClickedVal={setHandleClickedVal} from={from} />
          </div>
        ))}
      </div>
    </Loader>
  );

  const renderCardSection = () => (
    <>
      <div className={`section-list-card-header ${isdeepdive ? '' : 'preview'}`}>
        <div className="section-list-card-tabs">
          {tabs.map((tab) => (
            <div
              className={`tab-item ${tab.name === activeTab.name ? 'active' : ''}`}
              role="presentation"
              onClick={() => handleTab(tab)}
              key={tab.name}
            >
              <div className="tab-icon">
                {tab.name === activeTab.name
                  ? <SvgLoader className="ra-arrow" width={24} height={24} svgName={`${tab.icon}-active`} />
                  : <SvgLoader className="ra-arrow" width={24} height={24} svgName={tab.icon} />}
              </div>
              {isdeepdive ? <div className={`tab-name ${activeTab.name === tab.name ? 'active' : ''}`}>{tab.name}</div>
                : tab.name === activeTab.name ? <div className={`tab-name ${activeTab.name === tab.name ? 'active' : ''}`}>{tab.name}</div> : null}
            </div>
          ))}
        </div>
      </div>
      <div className="section-list-card-list-container">
        {activeTab.value === 'related_articles'
          ? (
            <Loader loading={getCtRelatedArticles.loading} error={getCtRelatedArticles.error} height={`${isdeepdive ? '300px' : '130px'}`} noData={!relatedArticlesListData.length} message="No associated documents">
              {relatedArticlesListData?.map((list) => (
                <div className="search-and-explore-results-container-list ct-prev-sec">
                  <ExploreList key={list?.doc_id} data={list} isRelatedArticles from={from} />
                </div>
              ))}
            </Loader>
          )
          : renderList()}
      </div>
    </>
  );

  return (
    <div className="explore-section-list-card">
      {renderCardSection()}
    </div>
  );
}

PreviewSectionCard.propTypes = {
  trialData: PropTypes.instanceOf(Object).isRequired,
  setShowDeepdive: PropTypes.func.isRequired,
  showDataFor: PropTypes.instanceOf(Object).isRequired,
};

export default PreviewSectionCard;
