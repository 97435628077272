import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../../common/components/Loader';
import SvgLoader from '../../../common/components/SvgLoader';
import { getLiteratureSummaryDataAction } from './logic';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function LiteratureSummaryCard({ params, selected, currentExpanded }) {
  const dispatch = useDispatch();
  const getLiteratureSummaryData = useSelector((state) => state.getLiteratureSummaryData);
  const [searchParams] = useSearchParams();
  const semanticQuery = searchParams.get('semanticquery');
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    const request = {
      ...params,
      headers: queryString.stringify({
        semantic_search: semanticQuery?.length ? JSON.parse(semanticQuery)[0]?.currentFilter[0] : undefined,
      }),
    };
    debounceCall(() => {
      dispatch(getLiteratureSummaryDataAction(request));
    }, 50);
  }, []);

  useEffect(() => {
    if (getLiteratureSummaryData?.flag) {
      const temp = getLiteratureSummaryData?.data?.summary.split('*').join('');
      if (temp?.length) {
        const modifiedSummary = temp.split('\n');
        setSummaryData(modifiedSummary.filter((itm) => itm.length));
      }
    }
  }, [JSON.stringify(getLiteratureSummaryData)]);

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  return (
    <div className="Widget summary">
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">
            {selected.label}
            <sup className="partex-ai-icon">* Powered by Gen AI</sup>
            {' '}
          </div>
        </div>
      </div>
      <Loader
        loading={getLiteratureSummaryData?.loading}
        error={getLiteratureSummaryData?.error}
        noData={!getLiteratureSummaryData?.data?.summary}
        height="250px"
        className="white-loader"
        message="No records found"
      >
        <div className="summary-card">
          {summaryData?.map((itm, idx) => {
            if (itm?.length && idx <= 5) {
              return (
                <div className="summary-itm" key={itm}>
                  {' '}
                  {itm.replace('-', '')}
                </div>
              );
            }
            return null;
          })}
        </div>
      </Loader>
    </div>
  );
}

LiteratureSummaryCard.propTypes = {
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
    body: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired,
  currentExpanded: PropTypes.string.isRequired,
};

export default LiteratureSummaryCard;
