import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_ADMIN_FILTES = {
  organization_name: [],
  onboarding_type: [],
  account_type: [],
  user_name: [],
  license_date: [],
};

const UPDATE_ADMIN_FILTER = 'UPDATE_ADMIN_FILTER';
const RESET_ADMIN_FILTER = 'RESET_ADMIN_FILTER';

export const updateAdminFiltersAction = createAction(UPDATE_ADMIN_FILTER);
export const resetAdminFiltersAction = createAction(RESET_ADMIN_FILTER);

const onUpdateAdminFilters = (state, payload) => {
  state.currentAdminFilter[payload.key] = [...payload.options];
  return state;
};

const onResetAdminFilters = (state) => {
  state.currentAdminFilter = INITIAL_ADMIN_FILTES;
  return state;
};

export const adminFiltersReducer = createReducer(
  {
    currentAdminFilter: INITIAL_ADMIN_FILTES,
  },
  (builder) => {
    builder.addCase(updateAdminFiltersAction, (state, action) => onUpdateAdminFilters(state, action.payload))
      .addCase(resetAdminFiltersAction, (state) => onResetAdminFilters(state))
      .addDefaultCase((state) => state);
  },
);

const UPDATE_ADMIN_FILTERS_FROM_REDUX = 'UPDATE_ADMIN_FILTERS_FROM_REDUX';

export const filterUpdateFromReduxAction = createAction(UPDATE_ADMIN_FILTERS_FROM_REDUX);

export const filterUpdateFromReduxReducer = createReducer(
  {
    update: false,
  },
  (builder) => {
    builder.addCase(filterUpdateFromReduxAction, (state) => {
      state.update = !state.update;
      return state;
    })
      .addDefaultCase((state) => state);
  },
);

const APPLY_BTN_CLICK = 'APPLY_BTN_CLICK';

export const applyBtnClickAction = createAction(APPLY_BTN_CLICK);

export const applyBtnClickReducer = createReducer(
  {
    apply: false,
  },
  (builder) => {
    builder.addCase(applyBtnClickAction, (state) => {
      state.apply = !state.apply;
      return state;
    })
      .addDefaultCase((state) => state);
  },
);
