import React from 'react';
import TextComponent from '../../../../components/TextComponent';
import { formatEpochTimeToDayTime } from '../../../../../utils';

function ChatHistoryContainer({ selectedSessionId, chatData }) {
  const selectedSession = chatData?.find((item) => item.session_id === selectedSessionId);

  if (chatData.length === 0) {
    return (
      <div className="no-data-found" />
    );
  }

  return (
    <div className="chatbot-history-content">
      <div className="chat-body">
        {selectedSession ? (
          selectedSession.session.map((message) => (
            // eslint-disable-next-line no-underscore-dangle
            <div key={message._id} className="chat-message-container">
              <div className="searched-query-text">
                <div className="chat-time">{formatEpochTimeToDayTime(message.updatedAt)}</div>
                <div className="chat-content">
                  <TextComponent text={message.searched_query} />
                </div>
              </div>
              <div className="chatbot-response-text">
                <div className="onto-logo" />
                <div className="chatbot-response-chat-content">
                  <div className="chat-time">{formatEpochTimeToDayTime(message.updatedAt)}</div>
                  <div className="chat-content">
                    <TextComponent text={message.chatbot_response} />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          null
        )}
      </div>
    </div>
  );
}

export default ChatHistoryContainer;
