import requestDemoReducer from './ModalRequestDemo/logic';
import getTerminalsReducer from './Selection/logic';
import checkUserReducer, { registerUserReducer } from './TryOntosightModal/logics';
import addUserConcentReducer from './Declarations/logic';
import getQuestionsReducer, { addPreferencesReducer } from './Persona/logic';
import { isLoginModalOpenReducer, verifyOTPReducer } from './LoginModalV2/logic';
import getLicenceReducer from './Main/logic';
import { updateUserStatusReducer } from './Admin/logic';
import requestOtpReducer from './LoginModal/logics';
import addRolesReducer, { removeRolesReducer } from './AssignRemoveRoles/logic';
import getUserListForAdminReducer, {
  currentDataModalReducer, editTerminalsReducer, getSingleUserDetailsReducer, updateUserOrganizationNameReducer, updateUserTypeReducer,
} from './UserList/logic';
import getOrgUserLIstReducer from './AdminFilterAutoSuggest/logic';
import { adminFiltersReducer, applyBtnClickReducer, filterUpdateFromReduxReducer } from './FilterAdmin/logics';
import { deleteOrgReducer, getAssociatedUsersReducer } from './DeleteOrg/logic';
import { addFeedBackReducer } from './FeedbackModal/logic';
import checkUserMultiReducer, { addReferralReducer, feedbackAndReferralModalOpenReducer, referralModalOpenReducer } from './Referral/logic';

const rootAppReducer = ({
  statusRequestDemo: requestDemoReducer,
  terminals: getTerminalsReducer,
  checkUser: checkUserReducer,
  registerUserStatus: registerUserReducer,
  userConcentStatus: addUserConcentReducer,
  questions: getQuestionsReducer,
  preferencesStatus: addPreferencesReducer,
  isLoginModalOpen: isLoginModalOpenReducer,
  getLicence: getLicenceReducer,
  userStatusUpdate: updateUserStatusReducer,
  requestOtpStatus: requestOtpReducer,
  verifyOTPSTatus: verifyOTPReducer,
  addRoleStatus: addRolesReducer,
  removeRolesStatus: removeRolesReducer,
  userListAdmin: getUserListForAdminReducer,
  orgUserList: getOrgUserLIstReducer,
  adminUserFilter: adminFiltersReducer,
  filtersUpdate: filterUpdateFromReduxReducer,
  applyBtn: applyBtnClickReducer,
  editTerminal: editTerminalsReducer,
  singleUserDetail: getSingleUserDetailsReducer,
  editCurrentAccesDate: currentDataModalReducer,
  associatedUsers: getAssociatedUsersReducer,
  deleteOrgStatus: deleteOrgReducer,
  feedbackStatus: addFeedBackReducer,
  referralModalOpen: referralModalOpenReducer,
  checkUserMultiStatus: checkUserMultiReducer,
  addRefferalStatus: addReferralReducer,
  feedbackAndReferralOpen: feedbackAndReferralModalOpenReducer,
  updateUserTypeStatus: updateUserTypeReducer,
  updateUserOrgNameStatus: updateUserOrganizationNameReducer,
});

export default rootAppReducer;
