import { getRefreshTokenReducer, sessionReducer } from './Status/logic';
import filtersReducer, { getPowerSearchTypesReducer, goBtnZIndexUpdateReducer } from './PowerSearch/logic';
import { getTypesValuesReducer, updateCurrentDropdownReducer } from './PowerSearchBox/logic';
import { terminalFiltersReducer, currentFilterSelectedRedcuer } from './FilterModal/logics';
import {
  getOrganizationValuesReducer, inviteUsersReducer, getUsersListReducer, createOrganizationReducer, editOrganizationReducer,
} from '../../app/container/Admin/logic';
import { getTimeLineDataReducer, getTrialIdReducer, getDocIdReducer } from './TimeLine/logic';
import { getChatbotAnswerReducer, chatbotReducer } from './Chatbot/logic';
import { postSubmitFormReducer } from './ContactUsForm/logics';
import {
  getProjectsReducer, createProjectReducer, updateProjectReducer, shareProjectReducer, getBookmarkProjectsReducer, getSavedSearchProjectsReducer,
} from './WorkSpace/logic';
import {
  createBookmarkReducer, getBookmarkReducer, deleteBookmarkReducer, shareBookmarkReducer,
} from './Bookmark/logic';
import { archiveProjectReducer } from './ProjectDropdown/logic';
import {
  createSavedSearchReducer, getSavedSearchesReducer, shareSaveSearchReducer, hashGeneratorReducer, emailPreferenceReducer, getEmailPreferenceReducer,
} from './SavedSearches/logic';
import { editCommentReducer } from './WorkSpace/Comment/logic';
import { getChatHistoryReducer } from './WorkSpace/ChatHistory/logic';
import updateReferralUserReducer from './Callback/logic';

const rootCommonReducer = ({
  getSession: sessionReducer,
  filters: filtersReducer,
  types: getPowerSearchTypesReducer,
  optionValues: getTypesValuesReducer,
  currentZIndex: goBtnZIndexUpdateReducer,
  currentDropdown: updateCurrentDropdownReducer,
  currentFilters: terminalFiltersReducer,
  currentFilterSelected: currentFilterSelectedRedcuer,
  organizationValues: getOrganizationValuesReducer,
  inviteUsers: inviteUsersReducer,
  usersList: getUsersListReducer,
  timelineData: getTimeLineDataReducer,
  currentTrialId: getTrialIdReducer,
  currentDocId: getDocIdReducer,
  chatbotAnswers: getChatbotAnswerReducer,
  statusContactUsFormSubmit: postSubmitFormReducer,
  projectsData: getProjectsReducer,
  createProject: createProjectReducer,
  updateProject: updateProjectReducer,
  createBookmark: createBookmarkReducer,
  archiveProject: archiveProjectReducer,
  bookmarkData: getBookmarkReducer,
  shareProject: shareProjectReducer,
  createOrganization: createOrganizationReducer,
  editOrganization: editOrganizationReducer,
  createSavedSearch: createSavedSearchReducer,
  deleteBookmark: deleteBookmarkReducer,
  shareBookmark: shareBookmarkReducer,
  editComment: editCommentReducer,
  bookmarkProjectsData: getBookmarkProjectsReducer,
  savedSearchesData: getSavedSearchesReducer,
  savedSearchesProjectsData: getSavedSearchProjectsReducer,
  shareSaveSearch: shareSaveSearchReducer,
  hashGenerator: hashGeneratorReducer,
  emailPreference: emailPreferenceReducer,
  getEmailPreference: getEmailPreferenceReducer,
  getAccessTokenFromRefreshToken: getRefreshTokenReducer,
  chatHistory: getChatHistoryReducer,
  chatbot: chatbotReducer,
  updateReferralStatus: updateReferralUserReducer,
});

export const CommonReducersWithList = [];

export default rootCommonReducer;
