import { React, useEffect } from 'react';
import Header from '../../../common/components/Header';
import SelectionV2 from '../../container/SelectionV2';
import Mixpanel from '../../../utils/mixpanel';

function HomePageV2() {
  useEffect(() => {
    Mixpanel.track('page_entry_event', {
      action: 'User has entered home page',
      page_name: 'Home Page',
    });
  }, []);
  return (
    <div className="main-page">
      <Header className="header-home" fromPath="home" />
      <SelectionV2 />
      <div>Terminals</div>
    </div>
  );
}

export default HomePageV2;
