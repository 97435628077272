import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SvgLoader from '../../../common/components/SvgLoader';
import { getCookie, setCookie } from '../../../utils';
import { setFeedBackOpenModalAction } from '../../container/FeedbackModal/logic';
import { setFeedBackAndReferralModalOpenAction, setReferralOpenModalAction } from '../../container/Referral/logic';

export default function FeedbackAndRefer() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const feedbackAndReferralOpen = useSelector((state) => state.feedbackAndReferralOpen);

  useEffect(() => {
    setOpen(feedbackAndReferralOpen.trigger);
  }, [JSON.stringify(feedbackAndReferralOpen)]);

  useEffect(() => {
    dispatch(setFeedBackAndReferralModalOpenAction(
      getCookie('feedbackReferModal') === 'true' && getCookie('accessToken') && getCookie('refreshTokenGetState') !== 'true',
    ));
    setOpen();
  }, []);

  const handleClose = () => {
    setCookie('', 'previousTimeStamp');
    dispatch(setFeedBackAndReferralModalOpenAction(false));
  };

  const handleFeedbackClick = () => {
    dispatch(setFeedBackOpenModalAction(true));
    dispatch(setFeedBackAndReferralModalOpenAction(false));
    handleClose();
  };

  const handleReferralClick = () => {
    dispatch(setReferralOpenModalAction(true));
    dispatch(setFeedBackAndReferralModalOpenAction(false));
    handleClose();
  };

  return (
    <Modal className="trial-status-modal" footer={false} open={open} closeIcon={false} width={604}>
      <div>
        <div className={`flex justify-end w-full pr-3 ${getCookie('feedbackReferModal') === 'true' ? 'pt-3' : 'pt-6'}`}>
          {
            getCookie('feedbackReferModal') === 'true' ? null
              : <SvgLoader width={24} height={24} svgName="cross-login" pointer onClick={handleClose} />
          }
        </div>
        <div className="flex flex-col items-center pb-9 px-9">
          <div className="expired-image" />
          <div className="ts-header">Oh No! Your trial period has expired</div>
          <div className="ts-sub-header flex flex-col justify-center">
            <div>
              {' '}
              Trial period has ended. To continue using Ontosight
              <sup>®</sup>
              &nbsp;
              Terminal for
            </div>
            <div>free, please leave feedback or refer a colleague to extend the licence.</div>
          </div>
          <div className="flex items-center">
            <div aria-hidden onClick={() => handleFeedbackClick()} className="feedback-btn  w-52 mr-4 hover:mr-4">Give Feedback</div>
            <div aria-hidden onClick={() => handleReferralClick()} className="feedback-btn  w-52">Refer a Colleague</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
