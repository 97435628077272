import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import * as d3 from 'd3';
import * as cloud from 'd3-cloud';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import SvgLoader from '../../../common/components/SvgLoader';
import Wordgraph from '../Wordgraph';
import { getWordCloudDataAction } from './logic';
import Loader from '../../../common/components/Loader';
import Mixpanel from '../../../utils/mixpanel';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

const categoryColors = [
  {
    category: 'Intervention',
    color: '#fc8d62',
  },
  {
    category: 'Gene',
    color: '#8da0cb',
  },
  {
    category: 'Indication',
    color: '#66c2a5',
  },
  {
    category: 'Protein',
    color: '#e78ac3',
  },
];

function Wordcloud({
  params, selected, currentExpanded, setExpanded,
}) {
  const dispatch = useDispatch();
  const windowWidth = useRef(window.innerWidth);
  const wordCloudData = useSelector((state) => state.wordCloudData);
  const [isExpanded, setIsExpanded] = useState(false);
  const [wordData, setWordData] = useState([]);
  const words = [];
  const [type, seType] = useState('correlation');
  const [wordss, setWordss] = useState([]);
  const [searchParams] = useSearchParams();
  const semanticQuery = searchParams.get('semanticquery');
  const query = searchParams.get('query');

  const renderCloud = () => {
    const xScale = d3.scaleLinear()
      .domain(d3.extent(wordData, (d) => d.value))
      .range(isExpanded ? [14, 70] : [12, 40]);
    cloud()
      .size(isExpanded ? (windowWidth.current < 1400 ? [780, 280] : [1100, 400]) : [630, 220])
      .timeInterval(10)
      .words(wordData)
      .rotate(0)
      .padding(2)
      .font('sans-serif')
      .fontSize((d) => xScale(d.value))
      .on('end', (word) => {
        setWordss(word);
      })
      .start();
  };

  useEffect(() => {
    renderCloud();
  }, [wordData]);

  useEffect(() => {
    if (wordCloudData) {
      wordCloudData?.data?.forEach((item) => {
        item?.summary?.forEach((subItem) => {
          words.push({
            text: subItem.name,
            value: subItem.count,
            category: item.category,
            id: subItem.id,
          });
        });
      });
      setWordData(words);
    }
  }, [JSON.stringify(wordCloudData)]);

  useEffect(() => {
    const request = {
      ...params,
      headers: queryString.stringify({
        size: isExpanded ? undefined : 10,
        type,
        semantic_search: semanticQuery?.length ? JSON.parse(semanticQuery)[0]?.currentFilter[0] : undefined,
      }),
    };
    debounceCall(() => {
      dispatch(getWordCloudDataAction(request));
    }, 50);
  }, [type, isExpanded]);

  const handleShrinkClick = () => {
    Mixpanel.track('explore_wordcloud_collapse', {
      action: 'collapse wordcloud widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Word cloud of biological concepts',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
    Mixpanel.track('switch_widget_view', {
      action: 'widget view',
      widget_name: 'Word cloud of biological concepts',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
    });
    setIsExpanded(false);
    setExpanded('');
  };

  const renderText = categoryColors?.map((d, i) => (
    <div className="wordCloud-legend" key={d.category}>
      <div className={`legend-icon ${d.category}`} />
      <div
      // eslint-disable-next-line react/no-array-index-key
        key={i}
        x={30}
        y={i * 15 + 15}
        className="legend-name"
      >
        {d?.category}
      </div>
    </div>
  ));

  const renderExpandedView = () => (
    <div className="Widget expanded wordcloud">
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">
            {selected.label}
          </div>
        </div>
        <div className="icons">
          <div className="radio-container">
            <label htmlFor="correlation">
              <input type="radio" id="correlation" name="fav_language" value="correlation" checked={type === 'correlation'} onChange={(e) => seType(e.target.value)} />
              Correlation
            </label>
            <label htmlFor="frequency">
              <input type="radio" id="frequency" name="fav_language" value="frequency" checked={type === 'frequency'} onChange={(e) => seType(e.target.value)} />
              Frequency
            </label>
          </div>
          <div className="ai-note-word-cloud-expand partex-ai-icon" />
          <SvgLoader onClick={() => handleShrinkClick()} width={22} height={22} svgName="contract" hoverIconName="contract-hover" />
        </div>
      </div>

      {wordss?.length ? (
        <Wordgraph
          wordss={wordss}
          isExpanded={isExpanded}
        />
      ) : null}
      <div className="legends-wordcloud">
        {renderText}
      </div>
      <div className="drag-expaned-icon" />
    </div>
  );

  if (isExpanded) {
    return (
      <div className="expanded-widget">
        <Loader
          error={wordCloudData.error}
          loading={!wordCloudData.flag}
          noData={wordCloudData?.data?.length === 0}
          height="420px"
          width="calc(100vw - 100px)"
          className="white-loader"
          message="No records found"
        >
          {renderExpandedView()}
        </Loader>
      </div>
    );
  }

  const handleTypeChange = (e) => {
    seType(e.target.value);

    Mixpanel.track('button_click_event', {
      action: `Wordcloud type ${e.target.value} clicked`,
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Word cloud of biological concepts',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
  };

  const expandGraph = () => {
    Mixpanel.track('explore_wordcloud_expand', {
      action: 'expand wordcloud widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Word cloud of biological concepts',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
    setExpanded(selected.label);
    setIsExpanded(true);
    Mixpanel.track('expandWidgetEvent', {
      action: 'expanded wordcloud widget',
      terminal: 'Search and explore',
      view: semanticQuery?.length ? 'SE semantic search' : 'SE power search',
      searchFilters: semanticQuery?.length ? semanticQuery : query,
    });
    Mixpanel.track('switch_widget_view', {
      action: 'expanded view',
      widget_name: 'Word cloud of biological concepts',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
    });
  };

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  return (
    <div className={isExpanded ? 'expanded-widget' : 'Widget'}>
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">
            {selected.label}
          </div>
        </div>
        <div className="icons">
          <div className="radio-container">
            <label htmlFor="correlation">
              <input type="radio" id="correlation" name="fav_language" value="correlation" checked={type === 'correlation'} onChange={(e) => handleTypeChange(e)} />
              Correlation
            </label>
            <label htmlFor="frequency">
              <input type="radio" id="frequency" name="fav_language" value="frequency" checked={type === 'frequency'} onChange={(e) => handleTypeChange(e)} />
              Frequency
            </label>
          </div>
          <SvgLoader width={18.7} height={18.7} svgName="expand" hoverIconName="expand-hover" onClick={() => { expandGraph(); }} />
          {/* <SvgLoader width={18.7} height={18.7} svgName="info-icon" hoverIconName="info-icon-hover" /> */}
          {/* <SvgLoader width={22} height={22} svgName="widget-option" hoverIconName="widget-option-hover" /> */}
        </div>
      </div>
      <Loader
        error={wordCloudData.error}
        loading={!wordCloudData.flag}
        noData={wordCloudData?.data?.length === 0}
        height="250px"
        className="white-loader"
        message="No records found"
      >
        {wordss?.length ? (
          <Wordgraph
            wordss={wordss}
            isExpanded={isExpanded}
          />
        ) : null}
        <div className="legends-wordcloud">
          {renderText}
        </div>
      </Loader>
    </div>
  );
}

Wordcloud.propTypes = {
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    graphData: PropTypes.PropTypes.shape([
      {
        value: PropTypes.number.isRequired,
      },
    ]),
  }).isRequired,
  currentExpanded: PropTypes.string.isRequired,
  setExpanded: PropTypes.func.isRequired,
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default Wordcloud;
