import { combineEpics } from 'redux-observable';
import { getPowerSearchTypesEpic } from './PowerSearch/logic';
import { getTypesValuesEpic } from './PowerSearchBox/logic';
import { getSearchResultsEpic, getMoreSearchResultsEpic } from './List/logic';
import {
  getOrganizationValuesEpic, inviteUsersEpic, getUsersListEpic, createOrganizationEpic, editOrganizationEpic,
} from '../../app/container/Admin/logic';
import { getTimeLineDataEpic } from './TimeLine/logic';
import { getChatbotAnswerEpic } from './Chatbot/logic';
import { postSubmitFormEpic } from './ContactUsForm/logics';
import {
  getProjectsEpic, createProjectEpic, updateProjectEpic, shareProjectEpic, getBookmarkProjectsEpic, getSavedSearchProjectsEpic,
} from './WorkSpace/logic';
import {
  createBookmarkEpic, getBookmarkEpic, deleteBookmarkEpic, shareBookmarkEpic, getMoreBookmarkEpic,
} from './Bookmark/logic';
import { archiveProjectEpic } from './ProjectDropdown/logic';
import {
  createSavedSearchEpic, getSavedSearchesEpic, shareSaveSearchEpic, hashGeneratorEpic, emailPreferenceEpic, getEmailPreferenceEpic,
} from './SavedSearches/logic';
import { editCommentEpic } from './WorkSpace/Comment/logic';
import { getRefreshTokenEpic } from './Status/logic';
import { getChatHistoryEpic } from './WorkSpace/ChatHistory/logic';
import { updateReferralUserEpic } from './Callback/logic';

const rootCommonEpic = combineEpics(
  getSearchResultsEpic,
  getPowerSearchTypesEpic,
  getTypesValuesEpic,
  getMoreSearchResultsEpic,
  getOrganizationValuesEpic,
  inviteUsersEpic,
  getUsersListEpic,
  getTimeLineDataEpic,
  getChatbotAnswerEpic,
  postSubmitFormEpic,
  getProjectsEpic,
  createProjectEpic,
  updateProjectEpic,
  createBookmarkEpic,
  archiveProjectEpic,
  getBookmarkEpic,
  getMoreBookmarkEpic,
  shareProjectEpic,
  createOrganizationEpic,
  editOrganizationEpic,
  createSavedSearchEpic,
  deleteBookmarkEpic,
  shareBookmarkEpic,
  editCommentEpic,
  getBookmarkProjectsEpic,
  getSavedSearchesEpic,
  getSavedSearchProjectsEpic,
  shareSaveSearchEpic,
  hashGeneratorEpic,
  emailPreferenceEpic,
  getEmailPreferenceEpic,
  getRefreshTokenEpic,
  getChatHistoryEpic,
  updateReferralUserEpic,
);

export default rootCommonEpic;
