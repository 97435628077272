import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import { Button } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { trackOutSideEvents, windowHrefToNavigate } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';

function Wordgraph({
  wordss, isExpanded,
}) {
  const toolTipRef = useRef(null);
  const [searchParams] = useSearchParams();
  const windowWidth = useRef(window.innerWidth);
  const [currentTooltipId, setCurrentTooltipId] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [toolTipData, setToolTipData] = useState({});
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');

  const fillColors = (d) => {
    switch (d) {
      case 'Gene':
        return '#8da0cb';
      case 'Indication':
        return '#66c2a5';
      case 'Protein':
        return '#e78ac3';
      case 'Intervention':
        return '#fc8d62';
      case 'Intervention - Marketed':
        return '#fc8d62';
      case 'Intervention - Clinical phase':
        return '#fc8d62';
      default:
        return null;
    }
  };
  const handleWordClick = (value) => {
    const q = JSON.parse(query);
    const p = JSON.parse(semanticQuery);
    const newQuery = [];

    if (q) {
      newQuery.push({
        currentKeys: [value.id],
        currentFilter: [value.text],
        field_name: (value.category).toLowerCase(),
        label: (value.category).toLowerCase(),
        name: (value.category).toLowerCase(),
        value: (value.category).toLowerCase(),
      });
    }
    if (semanticQuery) {
      p.forEach((item) => {
        item.currentFilter = [value.text];
        newQuery.push(item);
      });
    }

    Mixpanel.track('optimize_search_event', {
      action: 'Search',
      page: 'Analytics Page',
      terminal_name: 'Search and Explore',
      id: value.id,
      view_type: !isExpanded ? 'Widget view' : 'Expanded view',
    });

    if (semanticQuery) {
      windowHrefToNavigate(`/searchAndExplore/analytics?semanticquery=${JSON.stringify(newQuery)}`);
    } else {
      windowHrefToNavigate(`/searchAndExplore/analytics?query=${JSON.stringify(newQuery)}`);
    }
  };

  const handleAddToSearch = (value) => {
    const q = JSON.parse(query);
    const p = JSON.parse(semanticQuery);
    const nQuery = q?.filter((itm) => itm.value !== (value.category).toLowerCase()) || p?.filter((itm) => itm.value !== (value.category).toLowerCase() && itm.type !== 'sematic');
    const temp = q?.filter((itm) => itm.value === (value.category).toLowerCase()) || p?.filter((itm) => itm.value === (value.category).toLowerCase() && itm.type !== 'sematic');
    if (temp?.length) {
      temp[0].currentFilter = [...temp[0].currentFilter, value.text];
      temp[0].currentKeys = [...temp[0].currentKeys, value.id];
      nQuery.push(temp[0]);
    } else {
      nQuery.push({
        currentKeys: [value.id],
        currentFilter: [value.text],
        field_name: (value.category).toLowerCase(),
        label: (value.category).toLowerCase(),
        name: (value.category).toLowerCase(),
        value: (value.category).toLowerCase(),
        type: 'autosuggest-multi',
      });
    }

    Mixpanel.track('optimize_search_event', {
      action: 'Add to search',
      page: 'Analytics Page',
      terminal_name: 'Search and Explore',
      id: value.id,
      view_type: !isExpanded ? 'Widget view' : 'Expanded view',
    });

    if (semanticQuery) {
      windowHrefToNavigate(`/searchAndExplore/analytics?semanticquery=${JSON.stringify(p)}&query=${JSON.stringify(nQuery)}`);
    } else {
      windowHrefToNavigate(`/searchAndExplore/analytics?query=${JSON.stringify(nQuery)}`);
    }
  };

  const renderClassName = (value) => {
    const formatString = value.replace(/[\s~`!@#$%^&*(){}[\];:"'<,.>?/\\|_+=-]/g, '');
    const valArr = formatString.split(' ');
    let temp = '';
    valArr.forEach((item) => {
      if (item !== '/') {
        temp += item;
      }
    });
    return temp;
  };

  const draw = () => {
    const dimensions = {
      width: isExpanded ? (windowWidth.current < 1400 ? 800 : 1120) : 650,
      height: isExpanded ? (windowWidth.current < 1400 ? 300 : 420) : 220,
      margin: {
        top: isExpanded ? 20 : 10,
        left: 10,
        right: 10,
        bottom: 10,
      },
    };

    dimensions.ctrWidth = dimensions.width - dimensions.margin.left - dimensions.margin.right;
    dimensions.ctrHeight = dimensions.height - dimensions.margin.top - dimensions.margin.bottom + 20;

    const svg = d3.select('#word-cloud');

    const SVG = svg.append('svg')
      .attr('id', isExpanded ? 'wordcloud-expanded' : 'wordcloud')
      .attr('width', dimensions.width)
      .attr('height', dimensions.height);

    const ctr = SVG.append('g').attr('transfrom', `translate(${dimensions.margin.left}, ${dimensions.margin.top})`)
      .attr('width', dimensions.ctrWidth)
      .attr('height', dimensions.ctrHeight);

    const wordCloud = ctr.append('g');

    wordCloud.append('g')
      .attr('transform', `translate(${(dimensions.ctrWidth / 2)}, ${dimensions.ctrHeight / 2})`)
      .selectAll('text')
      .data(wordss)
      .enter()
      .append('text')
      .attr('id', (d) => `word-${renderClassName(d.text)}`)
      .style('font-size', (d) => `${d.size}px`)
      .style('fill', (d) => fillColors(d.category))
      .style('display', 'block')
      .attr('text-anchor', 'middle')
      .style('font-family', 'sans-serif')
      .style('font-style', 'normal')
      .style('cursor', 'pointer')
      .attr('transform', (d) => `translate(${[d.x, d.y]})`)
      .text((d) => d.text)
      .on('click', (event, datum) => {
        setShowTooltip(true);
        setToolTipData(datum);
        setCurrentTooltipId(`word-${renderClassName(datum?.text)}`);
      });
  };

  useEffect(() => {
    trackOutSideEvents(toolTipRef, () => setShowTooltip(false));
  }, []);

  useEffect(() => {
    d3.select('#wordcloud').remove();
    d3.select('#wordcloud-expanded').remove();
    draw();
  }, [wordss]);

  const renderTooltip = () => (
    <div>
      <Tooltip className="word-cloud-tooltip" anchorSelect={`#${currentTooltipId}`} clickable isOpen={showTooltip}>
        <div ref={toolTipRef} className="word-cloud-tooltip-btn-wrap">
          <Button className="word-cloud-tooltip-btn" onClick={() => handleAddToSearch(toolTipData)}>Add to search</Button>
          <Button className="word-cloud-tooltip-btn" onClick={() => handleWordClick(toolTipData)}>Search</Button>
        </div>
      </Tooltip>
    </div>
  );

  return (
    <div>
      <div id="word-cloud" />
      {renderTooltip()}
    </div>
  );
}

Wordgraph.propTypes = {
  wordss: PropTypes.instanceOf(Array).isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

export default Wordgraph;
