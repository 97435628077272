/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input, Button, Modal,
} from 'antd';
import { useSearchParams } from 'react-router-dom';
import List from '../../../common/container/List';
// import SvgLoader from '../../../common/components/SvgLoader';
// import { dateFormat, wordLimit } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';
// import Bookmark from '../../../common/container/Bookmark';
// import { getProjectsAction } from '../../../common/container/WorkSpace/logic';
import {
  shareBookmarkAction,
  shareBookmarkRefreshAction,
} from '../../../common/container/Bookmark/logic';
import { sendNotification } from '../../../common/components/Notification/logic';
import ResultCard from '../ResultsCard';

function ResultsList({
  setCardIndex, activeProject, currentIndex, from, ...rest
}) {
  const dispatch = useDispatch();
  const shareBookmark = useSelector((state) => state.shareBookmark);
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');
  const searchResult = useSelector((state) => state.searchResult);
  const [searchResultData, setSearchResultData] = useState([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emailTags, setEmailTags] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [selectedCard, setIsSelectedCard] = useState([]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleKeyDown = (e) => {
    if (currentEmail.trim() === '') return;
    if (e.key === 'Enter') {
      const emails = currentEmail.split(/[, ]+/);
      const validEmails = [];
      emails.forEach((email) => {
        if (emailRegex.test(email)) {
          validEmails.push(email);
        } else {
          setEmailError('Invalid email format');
        }
      });
      if (validEmails.length > 0) {
        setEmailTags([...emailTags, ...validEmails]);
        setCurrentEmail('');
        setEmailError('');
      }
    }
  };
  useEffect(() => {
  }, [currentIndex]);

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
  };
  const getClassName = (str) => {
    const modifiedStr = str.replace(/ /g, '-').toLowerCase();
    return modifiedStr;
  };

  const handleTagRemove = (emailToRemove) => {
    setEmailTags(emailTags.filter((email) => email !== emailToRemove));
  };

  useEffect(() => {
    if (searchResult?.flag) {
      setSearchResultData(searchResult?.data?.data);
      if (currentIndex && !Object.keys(currentIndex)?.length) {
        setCardIndex(searchResult?.data?.data[0]);
      }
    }
  }, [JSON.stringify(searchResult)]);

  const trackEvent = (data) => {
    Mixpanel.track('trialsListViewEvent', {
      action: 'list view card click',
      search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      query: JSON.parse(semanticQuery || query),
      page: 'List View Page',
      terminal_name: 'Clinical Trials',
      document_data: data,
    });
    setCardIndex(data);
  };

  const handleInviteClick = () => {
    if (emailTags.length > 0 && !emailError) {
      dispatch(
        shareBookmarkAction({
          body: {
            email_ids: emailTags,
            doc_id: selectedCard[0],
            asset_class: selectedCard[1],
          },
        }),
      );
      setOpenShareModal(!openShareModal);
      setEmailTags([]);
    }
  };

  useEffect(() => {
    if (shareBookmark.flag && Object.keys(shareBookmark?.data).length) {
      if (shareBookmark.data.message.includes('not')) {
        dispatch(
          sendNotification({
            type: 'BOOKMARK_SHARED_FAILURE',
            notificationType: 'error',
            message: shareBookmark.data.message,
            showNotification: true,
          }),
        );
      } else {
        dispatch(
          sendNotification({
            type: 'BOOKMARK_SHARED_SUCCESS',
            notificationType: 'success',
            message: shareBookmark.data.message,
            showNotification: true,
          }),
        );
      }
    }
    dispatch(shareBookmarkRefreshAction());
  }, [JSON.stringify(shareBookmark)]);

  const renderListValues = () => searchResultData?.map((item) => (
    <ResultCard cardData={item} currentIndex={currentIndex} trackEvent={trackEvent} getClassName={getClassName} setIsSelectedCard={setIsSelectedCard} openShareModal={openShareModal} setOpenShareModal={setOpenShareModal} />
  ));
  const handleBlur = () => {
    if (currentEmail.trim() === '') return;
    const emails = currentEmail.split(/[, ]+/);
    const validEmails = [];
    emails.forEach((email) => {
      if (emailRegex.test(email)) {
        validEmails.push(email);
      } else {
        setEmailError('Invalid email format');
      }
    });
    if (validEmails.length > 0) {
      setEmailTags([...emailTags, ...validEmails]);
      setCurrentEmail('');
      setEmailError('');
    }
  };
  return (
    <>
      <List {...rest} data={searchResultData} from={from} activeProject={activeProject}>
        {renderListValues()}

      </List>

      {openShareModal && (
        <Modal
          className="share-with-modal"
          open={openShareModal}
          closable
          footer={false}
          onCancel={() => { setOpenShareModal(false); setEmailTags([]); }}
        >
          <div className="share-modal-content">
            <div className="share-modal-content-input">
              <div className="share-with-title">Share with</div>
              <div
                className="email-tags-container"
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  marginBottom: '10px',
                }}
              >
                <div className="email-tags">
                  {emailTags.map((email, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="email-tag">
                      {email}
                      <span
                        role="presentation"
                        className="remove-tag"
                        onClick={() => handleTagRemove(email)}
                      />
                    </div>
                  ))}
                  <Input
                    value={currentEmail}
                    onBlur={handleBlur}
                    onChange={handleEmailInputChange}
                    onKeyDown={(e) => handleKeyDown(e)}
                    placeholder="Email ID"
                  />
                  {emailError && (
                    <div style={{ color: 'red' }}>{emailError}</div>
                  )}
                </div>
              </div>
              <div className="share-with-message-input">
                <Input placeholder="Message (Optional)" />
              </div>
              <div className="invite-btn">
                <Button
                  // disabled={
                  //   !isEnterPressed || emailError || emailTags.length === 0
                  // }
                  type="primary"
                  onClick={() => handleInviteClick()}
                >
                  Share
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

    </>
  );
}

ResultsList.propTypes = {
  setCardIndex: PropTypes.func.isRequired,
  currentIndex: PropTypes.instanceOf(Object).isRequired,
};

export default ResultsList;
