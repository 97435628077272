import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import FilterInputType from '../../components/FilterInputType';
import SvgLoader from '../../components/SvgLoader';
import CTFilterOption, { CTsearchFilters, SEfilterOptions, SEsearchFilters } from './data';
import { updateFilterInitialStateAction } from '../PowerSearch/logic';
import { selectFilterAction } from './logics';
import { getTypesValuesRefreshAction } from '../PowerSearchBox/logic';
import Mixpanel from '../../../utils/mixpanel';
import { getAppliedFiltersName, getTerimalPage, getTerminalName } from '../../../utils';

function FilterModal({
  open, handleClose, isDefault, setdefault, autoSuggestUrl,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [currentFilterAssetClass, setCurrentFilterAssetClass] = useState([]);
  const [currentFilterRegulatoryData, setCurrentFilterRegulatoryData] = useState([]);
  const [currentShowFilters, setCurrentShowFilters] = useState([]);
  const [currentShowSubFilters, setCurrentShowSubFilters] = useState([]);
  const [resetSection, setResetSection] = useState('none');
  const [update, setUpdate] = useState(false);
  const deepdivequery = searchParams.get('deepdivequery');

  const currentFilterSelected = useSelector((state) => state.currentFilterSelected);
  const currentFilters = useSelector((state) => state.filters);

  const filterType = location.pathname.includes('searchAndExplore') || location.pathname.includes('intervention') ? 'SE' : 'CT';

  useEffect(() => {
    dispatch(updateFilterInitialStateAction(filterType === 'SE' ? SEsearchFilters : CTsearchFilters));
    setUpdate(!update);
  }, []);

  useEffect(() => {
    if (currentFilters.filters[0].name === 'asset_classes' && currentFilters.filters[0]?.currentFilter) {
      setCurrentShowFilters([...currentFilters.filters[0].currentFilter]);
      setCurrentFilterAssetClass([...currentFilters.filters[0].currentKeys]);
    } else if (currentFilters.filters[0].name === 'asset_classes') {
      setCurrentShowFilters([...[]]);
      setCurrentFilterAssetClass([...[]]);
    }
  }, [JSON.stringify(currentFilters)]);

  useEffect(() => {
    if (currentFilterAssetClass.includes('regulatory_data')) {
      if (currentFilters.filters[43].name === 'sub_asset_classes' && currentFilters.filters[43]?.currentFilter) {
        setCurrentShowSubFilters([...currentFilters.filters[43].currentFilter]);
        setCurrentFilterRegulatoryData([...currentFilters.filters[43].currentKeys]);
      } else if (currentFilters.filters[43].name === 'sub_asset_classes') {
        setCurrentShowSubFilters([...[]]);
        setCurrentFilterRegulatoryData([...[]]);
      }
    }
  }, [JSON.stringify(currentFilters)]);

  const handleReset = () => {
    const workspaceQuery = searchParams.get('workspaceQuery');
    const workspaceFilters = searchParams.get('workspaceFilters');
    const sort = searchParams.get('sort');
    const query = searchParams.get('query');
    const semanticQuery = searchParams.get('semanticquery');
    const patentsQuery = searchParams.get('patentsQuery');
    const sponsorQuery = searchParams.get('sponsorQuery');
    const statusQuery = searchParams.get('statusQuery');

    Mixpanel.track('reset_filter_event', {
      action: 'filter reset all button click',
      terminal_name: getTerminalName(location),
      page: getTerimalPage(location),
      filter_name: getAppliedFiltersName(currentFilters).toString() || undefined,
    });

    if (location.pathname.includes('intervention')) {
      navigate(`${location.pathname}?query=${query || '[]'}${patentsQuery ? `&patentsQuery=${patentsQuery}` : ''}${sort ? `&sort=${sort}` : ''}`);
      navigate(0);
    } else if (semanticQuery?.length) {
      navigate(`${location.pathname}?semanticquery=${semanticQuery || '[]'}${statusQuery ? `&statusQuery=${statusQuery}` : ''}${sort ? `&sort=${sort}` : ''}`);
      navigate(0);
    } else {
      if (location.search.includes('workspaceQuery')) {
        navigate(`${location.pathname}?workspaceQuery=${workspaceQuery || '[]'}${workspaceFilters?.length > 0 ? `&workspaceFilters=${encodeURIComponent(workspaceFilters)}` : ''}${sort ? `&sort=${sort}` : ''}${statusQuery ? `&statusQuery=${statusQuery}` : ''}${sponsorQuery ? `&sponsorQuery=${sponsorQuery}` : ''}${deepdivequery ? `&deepdivequery=${deepdivequery}` : ''}`);
      } else {
        navigate(`${location.pathname}?query=${query || '[]'}${statusQuery ? `&statusQuery=${statusQuery}` : ''}${sponsorQuery ? `&sponsorQuery=${sponsorQuery}` : ''}${sort ? `&sort=${sort}` : ''}${deepdivequery ? `&deepdivequery=${deepdivequery}` : ''}`);
      }
      navigate(0);
    }
    return false;
  };

  useEffect(() => {
    if (!open) {
      const filters = currentFilters.filters.filter((item) => item.currentKeys && item.currentKeys.length);
      setdefault(!filters.length);
    }
  }, [open]);

  const handleApplyFilter = () => {
    const workspaceQuery = searchParams.get('workspaceQuery');
    const workspaceFilters = searchParams.get('workspaceFilters');
    const workspaceSemanticQuery = searchParams.get('workspaceSemanticQuery');
    const sort = searchParams.get('sort');
    const query = searchParams.get('query');

    const patentsQuery = searchParams.get('patentsQuery');
    const semanticQuery = searchParams.get('semanticquery');
    const statusQuery = searchParams.get('statusQuery');
    const sponsorQuery = searchParams.get('sponsorQuery');
    const filters = [];

    currentFilters.filters.forEach((item) => {
      if (item.currentKeys && item.currentKeys.length && item.type !== 'number' && item.type !== 'number-range') {
        filters.push(item);
      }

      if ((item.type === 'number' || item.type === 'number-range') && item.currentKeys) {
        if (item.currentKeys[0] !== '' || item.currentKeys[1] !== '') {
          filters.push(item);
        }
      }
    });

    Mixpanel.track('apply_button_event', {
      action: 'filter apply button click',
      terminal_name: getTerminalName(location),
      page: getTerimalPage(location),
      filterData: filters,
    });

    if (location.pathname.includes('intervention') && query?.length) {
      navigate(`${location.pathname}?query=${query || '[]'}&filters=${JSON.stringify(filters)}${sort ? `&sort=${sort}` : ''}${patentsQuery ? `&patentsQuery=${patentsQuery}` : ''}`);
      navigate(0);
    } else if (semanticQuery?.length) {
      navigate(`${location.pathname}?semanticquery=${semanticQuery || '[]'}&filters=${JSON.stringify(filters)}${sort ? `&sort=${sort}` : ''}${statusQuery ? `&statusQuery=${statusQuery}` : ''}${deepdivequery ? `&deepdivequery=${deepdivequery}` : ''}`);
      navigate(0);
    } else if (workspaceSemanticQuery?.length) {
      navigate(`${location.pathname}?workspaceSemanticQuery=${workspaceSemanticQuery || '[]'}&workspaceFilters=${JSON.stringify(workspaceFilters)}${sort ? `&sort=${sort}` : ''}${statusQuery ? `&statusQuery=${statusQuery}` : ''}${deepdivequery ? `&deepdivequery=${deepdivequery}` : ''}`);
      navigate(0);
    } else {
      if (location.search.includes('workspaceQuery')) {
        navigate(`${location.pathname}?workspaceQuery=${workspaceQuery || '[]'}&filters=${JSON.stringify(filters)}${workspaceFilters?.length > 0 ? `&workspaceFilters=${encodeURIComponent(workspaceFilters)}` : ''}${sort ? `&sort=${sort}` : ''}${statusQuery ? `&statusQuery=${statusQuery}` : ''}${sponsorQuery ? `&sponsorQuery=${sponsorQuery}` : ''}${deepdivequery ? `&deepdivequery=${deepdivequery}` : ''}`);
      } else {
        navigate(`${location.pathname}?query=${query || '[]'}&filters=${JSON.stringify(filters)}${sort ? `&sort=${sort}` : ''}${statusQuery ? `&statusQuery=${statusQuery}` : ''}${sponsorQuery ? `&sponsorQuery=${sponsorQuery}` : ''}${deepdivequery ? `&deepdivequery=${deepdivequery}` : ''}`);
      }
      navigate(0);
    }
    return false;
  };

  const handleFilterAssetClasse = (event, key) => {
    dispatch(selectFilterAction(''));
    dispatch(getTypesValuesRefreshAction());
    if (currentFilterAssetClass.includes(key)) {
      const temp = currentFilterAssetClass.filter((item) => item !== key);
      setCurrentFilterAssetClass([...temp]);
    } else {
      setCurrentFilterAssetClass([...currentFilterAssetClass, key]);
    }
    event.stopPropagation();
  };

  const handleResetSection = (section) => {
    setResetSection(section);
    dispatch(selectFilterAction(''));
    dispatch(getTypesValuesRefreshAction({}));
    setTimeout(() => setResetSection('none'));
  };

  const renderSubFilters = (subFiltersList) => subFiltersList.map((item) => (
    <div key={item.sectionName}>
      <FilterInputType filterType={filterType} selected={item} apiUrl={autoSuggestUrl} currentSelected={currentFilterSelected.data} isDefault={isDefault} setdefault={setdefault} update={update} resetSection={resetSection} />
    </div>
  ));

  const renderRegulatorySubFilters = (subFiltersList) => subFiltersList.map((item) => (
    <div>
      <div key={item.sectionName}>
        <FilterInputType filterType={filterType} selected={item} apiUrl={autoSuggestUrl} currentSelected={currentFilterSelected.data} isDefault={isDefault} setdefault={setdefault} update={update} resetSection={resetSection} />
      </div>

    </div>
  ));

  const renderFilters = () => SEfilterOptions.map((item) => {
    if (item.AssetType === 'Regulatory Data') {
      return (
        item.children.map((itm) => (
          <div className="handle-all-filters " key={item.AssetType}>
            {item.AssetType && currentShowFilters.includes(item.AssetType) ? (
              <div className="filters-header">
                {itm.subAssetType && currentShowSubFilters.includes(itm.subAssetType) ? (<div className="main-sub-filter-name">{itm.subAssetType}</div>) : (!itm.subAssetType && !currentShowSubFilters.includes(itm.subAssetType) ? <div className="main-filter-name">{item.AssetType}</div> : null)}
                {itm.subAssetType ? null : (
                  <div className="sub-filter-header">
                    <span aria-hidden className="reset-section-text pointer" onClick={() => handleResetSection(item.key)}>Reset section</span>
                    <SvgLoader pointer onClick={(e) => handleFilterAssetClasse(e, item.key)} width={24} height={24} className={`${currentFilterAssetClass.includes(item.key) ? 'rotate90' : ''}`} svgName={`${currentFilterAssetClass.includes(item.key) ? 'filter-arrow-down-hover' : 'filter-arrow-down'}`} hoverIconName="filter-arrow-down-hover" />
                  </div>
                )}

              </div>
            ) : null}
            {(currentFilterRegulatoryData.includes(itm.key) && currentShowSubFilters.includes(itm.subAssetType)) || (!itm.subAssetType && currentShowFilters.includes('Regulatory Data')) ? <div className={`filter-container default-filter-dim ${itm.subAssetType}`}>{renderRegulatorySubFilters(itm.children)}</div> : null}

          </div>
        ))
      );
    }
    return (
      <div className="handle-all-filters " key={item.AssetType}>
        {item.AssetType && currentShowFilters.includes(item.AssetType) ? (
          <div className="filters-header">
            <div className="main-filter-name">{item.AssetType}</div>
            <div className="sub-filter-header">
              <span aria-hidden className="reset-section-text pointer" onClick={() => handleResetSection(item.key)}>Reset section</span>
              <SvgLoader pointer onClick={(e) => handleFilterAssetClasse(e, item.key)} width={24} height={24} className={`${currentFilterAssetClass.includes(item.key) ? 'rotate90' : ''}`} svgName={`${currentFilterAssetClass.includes(item.key) ? 'filter-arrow-down-hover' : 'filter-arrow-down'}`} hoverIconName="filter-arrow-down-hover" />
            </div>
          </div>
        ) : null}
        {(currentFilterAssetClass.includes(item.key) && currentShowFilters.includes(item.AssetType)) || !item.AssetType ? <div className={`filter-container default-filter-dim ${item.AssetType}`}>{renderSubFilters(item.children)}</div> : null}
      </div>
    );
  });

  return (
    <div className="filter-modal-container">
      <Modal className="filter-modal-container" onCancel={() => handleClose()} footer={false} open={open} closeIcon={false} width={1275}>
        <div className="filter-header">
          <div>Filter by</div>
          <SvgLoader onClick={() => handleClose()} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
        </div>
        <div className={`${filterType === 'CT' ? 'filter-container' : ''}`}>
          {filterType === 'SE' ? renderFilters() : renderSubFilters(CTFilterOption)}
        </div>
        <div className="filter-buttons">
          <div aria-hidden onClick={() => handleReset()} className="reset-all-btn pointer">Reset All</div>
          <div aria-hidden onClick={() => handleApplyFilter()} className="filter-btn pointer">Apply</div>
        </div>
      </Modal>
    </div>
  );
}

FilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isDefault: PropTypes.bool.isRequired,
  setdefault: PropTypes.func.isRequired,
  autoSuggestUrl: PropTypes.string.isRequired,
};

export default FilterModal;
