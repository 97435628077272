import React, { useState } from 'react';
import OrgSubHeader from '../../components/OrgSubHeader';
import OrgTable from '../OrgTable';

export default function OrgList() {
  const [currentSearchInput, setCurrentSearchInput] = useState('');
  const [update, setUpdate] = useState(false);

  const handleSearchClick = () => {
    setUpdate(true);
    setTimeout(() => setUpdate(false), 100);
  };

  return (
    <div>
      <div><OrgSubHeader searchValue={currentSearchInput} setSearchVAlue={setCurrentSearchInput} handleSearchClick={handleSearchClick} /></div>
      <OrgTable search={currentSearchInput} update={update} />
    </div>
  );
}
