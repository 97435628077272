import { React } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCookie, getTerimalPage } from '../../utils';
import { removeSession } from '../container/Status/logic';
import Mixpanel from '../../utils/mixpanel';

function SwitchProfileModal() {
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <div className="switch-profile-container">
      <div className="email-sec">{getCookie('userEmail')}</div>
      <Button
        className="switch-profile-btn"
        onClick={() => {
          dispatch(removeSession());
          Mixpanel.track('switch_profile_event', {
            action: ' user switches between home Page',
            page_name: getTerimalPage(location),
          });
        }}
      >
        Login with different account
      </Button>
    </div>
  );
}

export default SwitchProfileModal;
