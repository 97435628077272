import React from 'react';
import AdminFilterAutoSuggest from '../AdminFilterAutoSuggest';
import AdminFilterMultiSelect from '../AdminFilterMultiSelect';
import AdminDateAndTimeInput from '../AdminDateAndTimeInput';
import AdmintextInput from '../AdminTextInput';

export default function AdminFilterInputType({ filter, reset, dateAndtimeObj }) {
  const renderFilters = () => {
    switch (filter.type) {
      case 'autosuggest-multi':
        return <AdminFilterAutoSuggest reset={reset} filter={filter} className={['autosuggest-multi', 'autosuggest-panel-filter', 'autosuggest-options']} />;
      case 'multiselect':
        return <AdminFilterMultiSelect reset={reset} filter={filter} />;
      case 'date-time-range':
        return <AdminDateAndTimeInput reset={reset} filter={filter} dateAndtimeObj={dateAndtimeObj} />;
      case 'input':
        return <AdmintextInput reset={reset} filter={filter} />;
      default:
        return null;
    }
  };

  return (
    <div className="filter-sub-container">
      <div className="filter-name">{filter.label}</div>
      <div className="sub-list">
        {renderFilters()}
      </div>
    </div>
  );
}
