import { combineEpics } from 'redux-observable';
import { requestDemoEpic } from './ModalRequestDemo/logic';
import { getTerminalsEpic } from './Selection/logic';
import { requestOtpEpic } from './LoginModal/logics';
import { checkUserEpic, registerUserEpic } from './TryOntosightModal/logics';
import { addUserConcentEpic } from './Declarations/logic';
import { getQuestionsEpic, addPreferencesEpic } from './Persona/logic';
import { addLicenseEpic, verifyOTPEpic } from './LoginModalV2/logic';
import { getLicenceEpic } from './Main/logic';
import { updateUserStatusEpic } from './Admin/logic';
import { addRolesEpic, removeRolesEpic } from './AssignRemoveRoles/logic';
import {
  getSingleUserDetailsEpic, getUserListForAdminEpic, updateUserOrganizationNameEpic, updateUserTypeEpic,
} from './UserList/logic';
import { getOrgUserLIstEpic } from './AdminFilterAutoSuggest/logic';
import { deleteOrgEpic, getAssociatedUsersEpic } from './DeleteOrg/logic';
import { addFeedBackEpic, getFeedbackQuestionsEpic } from './FeedbackModal/logic';
import { addReferralEpic, checkUserMultiEpic } from './Referral/logic';

const rootAppEpic = combineEpics(
  requestDemoEpic,
  getTerminalsEpic,
  requestOtpEpic,
  checkUserEpic,
  registerUserEpic,
  addUserConcentEpic,
  getQuestionsEpic,
  addPreferencesEpic,
  addLicenseEpic,
  getLicenceEpic,
  updateUserStatusEpic,
  verifyOTPEpic,
  addRolesEpic,
  removeRolesEpic,
  getUserListForAdminEpic,
  getOrgUserLIstEpic,
  getSingleUserDetailsEpic,
  getAssociatedUsersEpic,
  deleteOrgEpic,
  getFeedbackQuestionsEpic,
  addFeedBackEpic,
  checkUserMultiEpic,
  addReferralEpic,
  updateUserTypeEpic,
  updateUserOrganizationNameEpic,
);

export default rootAppEpic;
