import React, { memo, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import SvgLoader from '../../../common/components/SvgLoader';
import adminFilterDetails from './data';
import AdminFilterInputType from '../AdminFilterInputType';
import DateAndTimeRange from '../DateAndTimeRange';
import { applyBtnClickAction, resetAdminFiltersAction } from './logics';

const getCurrentDateTime = () => {
  const now = moment();
  const formattedDate = now.format('DD MMM, YYYY');
  const formattedTime = now.format('hh:mm A');

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

function FilterAdmin({ open, setOpen }) {
  const dispatch = useDispatch();
  const currentDate = getCurrentDateTime().date;
  const currentTime = getCurrentDateTime().time;

  const [showDateTimePickerModal, setShowDatePickerModal] = useState(false);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [startTime, setStartTime] = useState(currentTime);
  const [endTime, setEndTime] = useState(currentTime);
  const [resetAll, setResetAll] = useState(false);

  const dateTimeModalTitle = 'Licence period';

  const handleApplyFilter = () => {
    dispatch(applyBtnClickAction());
    setOpen(false);
  };

  const handleReset = () => {
    dispatch(resetAdminFiltersAction());
    setResetAll(true);
    setStartDate(currentDate);
    setEndDate(currentDate);
    setStartTime(currentTime);
    setEndTime(currentTime);
    setTimeout(() => setResetAll(false), 50);
    setTimeout(() => {
      handleApplyFilter();
    }, 80);
  };

  const renderFilters = () => adminFilterDetails.map((filter) => (
    <AdminFilterInputType
      key={filter.label}
      filter={filter}
      reset={resetAll}
      dateAndtimeObj={{
        endDate, startDate, endTime, startTime, showDateTimePickerModal, setShowDatePickerModal,
      }}
    />
  ));

  const handleCancelBtnClick = () => {
    if (showDateTimePickerModal) {
      setShowDatePickerModal(false);
    } else {
      setOpen(false);
    }
  };

  return (
    <div className="filter-modal-container">
      <Modal className="filter-modal-container" footer={false} open={open} closeIcon={false} width={850}>
        {
          !showDateTimePickerModal
            ? (
              <>
                <div className="filter-header">
                  <div>Filter by</div>
                  <SvgLoader onClick={() => handleCancelBtnClick()} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
                </div>
                <div className="filter-container-admin">
                  {renderFilters()}
                </div>
                <div className="filter-buttons">
                  <div aria-hidden onClick={() => handleReset()} className="reset-all-btn pointer">Reset All</div>
                  <div aria-hidden onClick={() => handleApplyFilter()} className="filter-btn pointer">Apply</div>
                </div>
              </>
            )
            : (
              <DateAndTimeRange
                open={showDateTimePickerModal}
                setOpen={setShowDatePickerModal}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                startTime={startTime}
                setStartTime={setStartTime}
                endTime={endTime}
                setEndTime={setEndTime}
                title={dateTimeModalTitle}
                filterAdmin
              />
            )
        }
      </Modal>
    </div>
  );
}

export default memo(FilterAdmin);
