/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import SvgLoader from '../../../common/components/SvgLoader';
import webAuth from '../../../utils/webAuth';
import { sendNotification } from '../../../common/components/Notification/logic';
import { debounceCall, getCookie, setCookie } from '../../../utils';
import { checkUserAction, checkUserRefreshAction } from '../TryOntosightModal/logics';
import Mixpanel, { encrypt } from '../../../utils/mixpanel';

function LoginModal({ open, setOpen, setTryOntosightModal }) {
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const [inputEmail, setInputEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [loginBtnDissabled, setLoginBtnDissabled] = useState(true);
  const [otpSent, setOTPSent] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [notSigned, setNotSigned] = useState(false);

  const checkUser = useSelector((state) => state.checkUser);

  const baseWidth = (window.innerWidth * 55) / 100;
  const baseHeight = baseWidth * 0.8;

  const setAllTodefault = () => {
    setInputEmail('');
    setValidEmail(true);
    setLoginBtnDissabled(true);
    setOTPSent(false);
    setOtp(['', '', '', '', '', '']);
    setNotSigned(false);
    dispatch(checkUserRefreshAction());
  };

  const processEmail = (email) => {
    const plusIndex = email.indexOf('+');
    if (plusIndex !== -1) {
      return email.substring(0, plusIndex) + email.substring(email.indexOf('@'));
    }
    return email;
  };

  useEffect(() => {
    if (checkUser.flag) {
      setCookie(checkUser.data.isPersonaBuild, 'personaBuild');
      if (checkUser.data.registrationStatus === 'rejected') {
        setCookie(processEmail(inputEmail), 'userEmail');
        setCookie(true, 'rejectedApprovedl');
        navigate('/home');
      }

      if (checkUser.data.userExists) {
        if (checkUser.data.isPersonaBuild === false) {
          setCookie(processEmail(inputEmail), 'userEmail');
          setCookie(false, 'personaBuild');
          Mixpanel.track('persona_event', {
            action: 'user persona building process has began',
            data: getCookie('userEmail'),
          });
          navigate('/persona');
          navigate(0);
        }
        setCookie(processEmail(inputEmail), 'userEmail');
        setOTPSent(true);
        navigate('/home');
      } else {
        setNotSigned(true);
      }
    }
  }, [checkUser]);

  useEffect(() => {
    const emptyCheck = inputEmail === '';
    setLoginBtnDissabled(!validEmail || emptyCheck);
  }, [inputEmail, validEmail]);

  const imageSize = {
    width: baseWidth / 2,
  };

  const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleEmailIdValidation = (email) => {
    debounceCall(() => {
      setValidEmail(check.test(email));
      if (notSigned) {
        setNotSigned(false);
      }
      Mixpanel.track('login_email_input_event', {
        action: 'User entering email on login modal',
        pseudonymised_email_id: encrypt(email),
        page: 'Landing Page',
      });
    }, 2000);
    setInputEmail(email);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    const checkEmailValid = check.test(inputEmail) && inputEmail !== '';
    if (checkEmailValid) {
      dispatch(checkUserAction({
        headers: queryString.stringify({
          email: inputEmail.toLowerCase(),
        }),
      }));

      Mixpanel.track('log_in_event', {
        action: 'user clicked login button after entering email on login modal',
        page: 'Landing Page',
        pseudonymised_email_id: encrypt(inputEmail),
      });
    }
  };

  const verifyOTP = () => {
    setCookie(inputEmail, 'userEmail');
    webAuth.passwordlessLogin({
      client_id: process.env.auth0.clientId,
      credential_type: 'passwordless_otp',
      username: inputEmail,
      otp: otp.join(''),
      realm: 'email',
    }, (err) => {
      if (err) {
        dispatch(sendNotification({
          type: 'OTP_VERIFICATION_FAILED',
          notificationType: 'error',
          message: err?.message || 'Oops something went wrong, please try again',
          showNotification: true,
        }));
      }
    });
  };

  const handleInputChange = (index, value) => {
    if (value.length === 6) {
      setOtp([...value.split('')]);
    } else {
      const newOTP = [...otp];
      newOTP[index] = value.slice(0, 1);
      setOtp(newOTP);
    }
  };

  const handleTryOntosightClick = () => {
    setOpen(false);
    setNotSigned(false);
    setTryOntosightModal(true);
  };

  const handleModalClose = () => {
    setAllTodefault();
    setOpen(false);
    dispatch(checkUserRefreshAction());
  };

  const renderOTPinput = () => (
    <div className="otp-container">
      {otp.map((digit, index) => (
        <input
          key={`${digit}-${Math.random()}`}
          type="text"
          value={digit}
          onChange={(e) => handleInputChange(index, e.target.value)}
          ref={(ref) => {
            inputRefs.current[index] = ref;
          }}
        />
      ))}
    </div>
  );

  const handleCloseBtnClic = () => {
    setOpen(false);
  };

  const handleDivClick = () => {
    Mixpanel.track('field_tap_event', {
      action: 'user tapped on input box to fill email',
      value: 'Email',
      page_name: 'Login Page pop up',
    });
  };

  return (
    <Modal className="login-modal-ctr" onCancel={() => handleModalClose()} footer={false} open={open} closeIcon={false} width={baseWidth} height={baseHeight}>
      <div className="login-modal">
        <div className="side-image" style={imageSize} />
        <SvgLoader width={24} height={24} svgName="cross-login" onClick={() => handleCloseBtnClic()} pointer />
        {/* <Button className="switch-profile-btn" onClick={() => handleSwitchProfile()}>Switch Profile</Button> */}
        <div className="login-header">
          <div className="login-form">
            <div className="welcome-text">Welcome</div>
            { otpSent && <div className="otp-inbox-message">Please check your inbox we have sent you an email with OTP</div> }
            { notSigned && (
            <div className="otp-inbox-message">
              <SvgLoader width={16} height={16} svgName="not-icon" />
              <span className="not-signed">
                Not signed up yet?
              </span>
              {' '}
              <span aria-hidden className="not-signed-blue pointer" onClick={() => handleTryOntosightClick()}>Try Ontosight for free!</span>
            </div>
            ) }
            {otpSent ? <div className="otp-verfification-text">OTP Verification</div> : <div className="email-text">Email</div>}
            {
              otpSent
                ? (
                  <div>
                    { renderOTPinput() }
                    <div className="otp-resend-text">
                      Didn’t you receive the OTP?
                      <span aria-hidden className="otp-resend-text-blue pointer" onClick={() => handleLoginClick()}>
                        &nbsp;
                        Resend OTP
                      </span>
                    </div>
                    <div className="otp-not-received-text">
                      Having trouble finding your OTP? Try checking your spam or junk email folder
                    </div>
                  </div>
                )
                : (
                  <form onSubmit={(e) => handleLoginClick(e)} autoComplete="on">
                    <input
                      onClick={() => handleDivClick()}
                      value={inputEmail}
                      className="Login-email-input"
                      type="email" // Changed to 'email' type for better validation
                      placeholder="name@xyz.com"
                      autoComplete="email" // Enables auto-save and suggestions
                      onChange={(e) => handleEmailIdValidation(e.target.value)} // Update state on change
                      required
                    />
                    <button className={`${loginBtnDissabled ? 'login-button-dissabled' : 'login-button pointer'}`} type="submit">Login</button>
                  </form>
                )
            }
            {
              !validEmail
                ? <div className="not-valid">Please enter valid email</div>
                : null
            }
            {
              otpSent && <div aria-hidden onClick={verifyOTP} className="login-button pointer">Verify OTP</div>
                // : <div aria-hidden onClick={() => handleLoginClick()} className={`${loginBtnDissabled ? 'login-button-dissabled' : 'login-button pointer'}`}>Login</div>
            }
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LoginModal;
