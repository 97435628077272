/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import { Drawer, Button } from "antd";
import { getBookmarkAction } from "../../Bookmark/logic";
import queryString from "query-string";
import BookmarkCardTwo from "./BookmarkCardTwo";
import { getExploreSearchSummaryAction } from "../../../../searchAndExplore/container/ExploreList/logic";
import ExploreSummaryCard from "../../../../searchAndExplore/components/ExploreSummaryCard";
import CardDetail from "../../../../clinicalTrials/container/CardDetail";

function BookmarkList({
  projectId,
  setBookmarkFilters,
  setSortByOptions,
  setBookmarkFilterUsers,
}) {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const getExploreSearch = useSelector(
    (state) => state.getExploreSearchSummary
  );
  const bookmarkData = useSelector((state) => state.bookmarkData);

  const [selectedCard, setSelectedCard] = useState({});
  const [previewCardData, setPreviewCardData] = useState({});
  const [drawerWidth, setDrawerWidth] = useState(600);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isBookmarkClicked, setIsBookmarkClicked] = useState(false);

  const onDrawerClose = () => {
    setSelectedCard({});
  };

  useEffect(() => {
    if (getExploreSearch?.flag) {
      setPreviewCardData(getExploreSearch?.data?.data);
    }
  }, [JSON.stringify(getExploreSearch)]);

  useEffect(() => {
    if (selectedCard && Object?.keys(selectedCard).length) {
      const url = `/explore/v0/search/${selectedCard.type}/resource/${selectedCard.doc_id}`;
      dispatch(
        getExploreSearchSummaryAction({
          params: {
            url,
            headers: queryString.stringify({
              asset_class: selectedCard.type,
              view: "preView",
              sub_asset_class:
                selectedCard?.type === "regulatory_data"
                  ? selectedCard?.sub_type
                  : undefined,
            }),
          },
        })
      );
    }
  }, [selectedCard]);

  useEffect(
    () => {
      setBookmarkFilters([]);
      setBookmarkFilterUsers([]);
      setSortByOptions({});

      if (projectId !== "") {
        dispatch(
          getBookmarkAction({
            body: {
              project_id: projectId,
              asset_class: [],
              user_ids: [],
              export: false,
              file_type: "",
              sort: {},
            },
          })
        );
      }
    },
    [projectId],
    [isBookmarkClicked]
  );

  const renderSummaryCard = () => (
    <ExploreSummaryCard activeList={previewCardData} />
  );

  const renderNoBookmarkData = () => (
    <div className="no-bookmark-container">
      <div className="no-bookmark-icon" />
      <div className="no-bookmark-title">No bookmark found</div>
      <div className="no-bookmark-content">
        It seems that you have not bookmarked any document yet in your current
        project. We recommend utilising the bookmark feature to conveniently
        save and view your favourite documents at any time.
      </div>
      <Button className="home-btn" onClick={() => navigate("/home")}>Return to home</Button>
    </div>
  );

  const renderBookmarkList = () => {
    if (projectId === "" || bookmarkData?.data?.length === 0) {
      return <div className="no-bookmark-data">{renderNoBookmarkData()}</div>;
    } else {
      return bookmarkData?.data?.map((item) => {
        return (
          <BookmarkCardTwo
            data={item}
            setSelectedCard={setSelectedCard}
            projectId={projectId}
            setOpenDrawer={setOpenDrawer}
            setIsBookmarkClicked={setIsBookmarkClicked}
          />
        );
      });
    }
  };

  return (
    <div className="list-container">
      <Loader
        loading={bookmarkData?.loading || !bookmarkData?.flag}
        error={false}
      >
        {renderBookmarkList()}
      </Loader>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onDrawerClose}
        height={100}
        width={drawerWidth}
        open={Object.keys(selectedCard).length > 0 && openDrawer}
        key="right"
      >
        {previewCardData?.type === "clinical_trials" ? (
          <CardDetail data={previewCardData} setDrawerWidth={setDrawerWidth} />
        ) : (
          renderSummaryCard()
        )}
      </Drawer>
    </div>
    // </Loader>
  );
}

export default BookmarkList;
