import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { merge } from 'webpack-merge';
import Header from '../../../common/components/Header';
import AnalyticsNavOptions from '../../../common/container/AnalyticsNavOptions';
import ExploreAnatyticsContainer from '../../container/ExploreAnalyticsContainer';
import { hashGeneratorAction } from '../../../common/container/SavedSearches/logic';
import { getExploreSearchResultsAction, getExploreSearchResultsRefreshAction } from '../../container/ExploreList/logic';
import { modifyDataForGetResultsQuery, getCookie } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function SEAnalyticsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const getExploreSearchResults = useSelector((state) => state.getExploreSearchResults);
  const [searchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const semanticQuery = searchParams.get('semanticquery');
  const query = searchParams.get('query');
  const sort = searchParams.get('sort');
  const autoSuggestUrl = '/explore/v0/search/getFieldValues';
  const q = JSON.parse(query);
  const qUrl = location.pathname + location.search;
  const requestData = modifyDataForGetResultsQuery(q, true);
  const filters = searchParams.get('filters');
  const f = JSON.parse(filters);
  const filtersData = modifyDataForGetResultsQuery(JSON.parse(filters, true));
  const projId = getCookie('projectId');
  const terminalName = 'Search & Explore';
  const currentFilter = semanticQuery !== null ? (JSON.parse(semanticQuery)[0]?.currentFilter[0]) : (null);
  const semanticQueryRequest = {
    body: {
      body: currentFilter,
    },
  };
  const out = merge(requestData, filtersData);
  const semanticOut = merge(semanticQueryRequest, filtersData);
  const nonEmptyRequestBody = semanticQuery ? semanticOut.body : out.body;

  let view = '';
  if (!location.search.includes('semantic_search')) {
    view = 'Power Search';
  } else {
    view = 'Semantic Search';
  }

  const params = {
    headers: {
      from: 1,
      size: 10,
      project_id: projId,
    },
    body: { filters: out.body },
  };

  const hashApiCall = () => {
    dispatch(hashGeneratorAction(
      {
        body: {
          query: nonEmptyRequestBody,
          project_id: projId,
        },
      },
    ));
  };

  useEffect(() => {
    if (getCookie('projectId')) {
      hashApiCall();
    }
  }, [getCookie('projectId')]);

  useEffect(() => {
    if (getExploreSearchResults?.flag) {
      setTotalCount(getExploreSearchResults?.data?.total);
    }
  }, [JSON.stringify(getExploreSearchResults)]);

  useEffect(() => {
    Mixpanel.track('page_entry_event', {
      action: 'User has entered Search & Explore Analytics page',
      terminal_name: 'Search and Explore',
      page_name: 'Analytics Page',
    });
  }, []);

  useEffect(() => {
    if (semanticQuery?.length) {
      const request = {
        apiUrl: '/explore/v0/search/getSearchResults',
        ...params,
        headers: queryString.stringify({
          ...params.headers,
          semantic_search: JSON.parse(semanticQuery)[0]?.currentFilter,
          from: 1,
          size: 1,
          project_id: projId,

        }),
        body: params.body,
      };
      if (sort && sort !== 'null') {
        params.body = { ...params.body, sort: [JSON.parse(sort)] };
      }
      debounceCall(() => {
        dispatch(getExploreSearchResultsAction(request));
      }, 50);
    } else {
      const request = {
        apiUrl: '/explore/v0/search/getSearchResults',
        headers: queryString.stringify({
          from: 1,
          size: 10,
        }),
        body: params.body,
      };

      if (sort && sort !== 'null') {
        params.body = { ...params.body, sort: [JSON.parse(sort)] };
      }
      debounceCall(() => {
        dispatch(getExploreSearchResultsAction(request));
      }, 50);
    }

    return () => {
      dispatch(getExploreSearchResultsRefreshAction({}));
    };
  }, []);

  return (
    <div className="main-page">
      <Header className="results-header-light" isResultsPage type="light" reqBody={requestData} filtersData={filtersData} terminalName={terminalName} view={view} totalCount={totalCount} semanticQueryRequest={semanticQueryRequest} url={qUrl} q={q} f={f} hashApiCall={hashApiCall} />
      <div className="se-results-page">
        <div className="explore-results-nav">
          <div className="explore-results-text">
            {getExploreSearchResults?.data?.total ? `${getExploreSearchResults?.data?.total} Results found` : null}
          </div>
          {/* <SearchNavOptions isExplorePage /> */}
          <AnalyticsNavOptions autoSuggestUrl={autoSuggestUrl} />
        </div>
        <div className="search-analytics-container">
          <ExploreAnatyticsContainer params={params} />
        </div>
      </div>
    </div>
  );
}

export default SEAnalyticsPage;
