import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission, getCookie, windowHrefToNavigate } from '../../../utils';
import { sendNotification } from '../../../common/components/Notification/logic';

const TerminalAccessBasedOnPermissions = {
  '/clinicalTrials': 'ot-ct-access',
  '/clinicalTrials/searchResults': 'ot-ct-access',
  '/clinicalTrials/analyticsResults': 'ot-ct-access',
  '/searchAndExplore': 'ot-search-exp-access',
  '/searchAndExplore/results': 'ot-search-exp-access',
  '/searchAndExplore/analytics': 'ot-search-exp-access',
  '/intervention': 'ot-intervention-access',
  '/intervention/results': 'ot-intervention-access',
  '/intervention/analytics': 'ot-intervention-access',
};

const protectPaths = ['/clinicalTrials/searchResults', '/clinicalTrials/analyticsResults', '/searchAndExplore/results', '/searchAndExplore/analytics', '/intervention/results', '/intervention/analytics'];

export default function CheckPermissionTerminal() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { type } = useSelector((state) => state.notification);

  useEffect(() => {
    if (getCookie('accessToken') && type !== 401 && protectPaths.includes(location.pathname) && !checkPermission(TerminalAccessBasedOnPermissions[location.pathname])) {
      dispatch(sendNotification({
        type: 'ACCESS_DENIED_FAILURE',
        notificationType: 'warning',
        message: 'you don\'t have permission to access this terminal',
        showNotification: true,
      }));
      setTimeout(() => {
        windowHrefToNavigate('/home');
      }, 2000);
    }
  }, [location]);
  return null;
}
