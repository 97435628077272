/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import SvgLoader from '../../../common/components/SvgLoader';
import { getProjectsAction } from '../../../common/container/WorkSpace/logic';
import { dateFormat, wordLimit } from '../../../utils';
import Bookmark from '../../../common/container/Bookmark';

function ResultCard({
  cardData, currentIndex, trackEvent, getClassName, setIsSelectedCard, openShareModal, setOpenShareModal,
}) {
  const dispatch = useDispatch();
  const [bookmarkUpdate, setBookmarkUpdate] = useState({ docId: null, bookmarkFlag: false, otherBookmarkFlag: false });
  const [bookmarkId, setBookmarkid] = useState([]);
  const [activeBookmarkId, setActiveBookmarkId] = useState(null);
  const [otherBookmarkIds, setOtherBookmarkIds] = useState([]);

  useEffect(() => {
    setBookmarkUpdate({
      ...bookmarkUpdate, docId: cardData?.doc_id, bookmarkFlag: cardData?.isBookmark, otherBookmarkFlag: cardData?.isBookmarkInOther,
    });
  }, [cardData]);

  const apiCall = (docId) => {
    dispatch(
      getProjectsAction({
        params: {
          doc_id: docId,
        },
      }),
    );
  };
  const showShareModal = (item) => {
    const temp = [];
    temp.push(item?.doc_id);
    temp.push(item?.type);

    setIsSelectedCard(temp);
    setOpenShareModal(!openShareModal);
  };
  const renderTooltipValue = (dataset) => dataset?.map((item) => `${item}`);

  const handleTooltipformate = (dataset) => {
    const data = renderTooltipValue(dataset);
    let temp = '';
    if (data.length === 1) {
      return data;
    }

    data.forEach((item, i) => {
      if (i === data.length - 1) {
        temp += `${item}`;
      } else {
        temp += `${item}, `;
      }
    });

    return temp;
  };

  const renderClass = ({ prediction_score }) => {
    if (prediction_score > 65) {
      return 'pos-high';
    }
    if (prediction_score > 35 && prediction_score <= 65) {
      return 'pos-med';
    }
    return 'pos-low';
  };

  const renderField = (value, dataset, len = 0) => (
    <span className="filter">
      <span className="filter-value" title={handleTooltipformate(dataset)}>
        {' '}
        {wordLimit(value, 20)}
      </span>
      <span>{`${len > 1 ? ` +${len - 1}` : ''}`}</span>
    </span>
  );

  const renderSubDetailsDocument = ({
    event_sponsor,
    indication,
    intervention,
    study_start_date,
  }) => (
    <div className="sub-details-container">
      {event_sponsor?.length ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="sponsor-icon" />
          {renderField(event_sponsor[0], event_sponsor, event_sponsor.length)}
        </div>
      ) : null}
      {indication?.length ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="indication-icon" />
          {renderField(indication[0], indication, indication.length)}
        </div>
      ) : null}
      {intervention?.length ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="intervention-icon" />
          {renderField(intervention[0], intervention, intervention.length)}
        </div>
      ) : null}
      {study_start_date ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="calender-icon" />
          <span className="filter-value">
            {' '}
            {dateFormat(study_start_date * 1000)}
          </span>
        </div>
      ) : null}
    </div>
  );

  const renderTooltip = (predictionScore) => (
    <div className="prediction-tooltip-container">
      <div className="prediction-tooltip-data">
        <div
          className={
                predictionScore >= 65
                  ? 'high'
                  : predictionScore >= 35 && predictionScore < 65
                    ? 'medium'
                    : 'low'
              }
        />
        <div className="prediction-tooltip-sub-container">
          <div className="prediction-score">{`${predictionScore} %`}</div>
          <div className="predition-title">Probability of success</div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      aria-hidden
      onClick={() => trackEvent(cardData)}
      className={`card-details ${
        currentIndex?.trial_id === cardData?.trial_id ? 'active' : ''
      }`}
      key={cardData.laymen_title}
    >
      <div className="status">
        <div className="status-content">
          <div className="normal">{cardData.source}</div>
          {cardData.trial_id || cardData.phase || cardData.status ? (
            <div className="dot" />
          ) : null}
          <div className="normal">{cardData.trial_id}</div>
          {cardData.phase || cardData.status ? <div className="dot" /> : null}
          <div className="normal">{cardData.phase}</div>
          {cardData.status ? <div className="dot" /> : null}
          <div className={getClassName(cardData.status)}>
            {cardData.status === 'Completed' ? (
              <div className="completed-icon" />
            ) : null}
            {cardData.status}
          </div>
          {cardData.prediction_score ? <div className="dot" /> : null}
          {cardData.prediction_score ? (
            <Tooltip
              placement="left"
              overlayClassName="prediction-tooltip position-zero"
              title={() => renderTooltip(cardData.prediction_score)}
              arrow={false}
            >
              <div
                role="presentation"
                className={renderClass(cardData)}
                id={`score${cardData.trial_id}`}
              >
                <SvgLoader
                  width={11}
                  height={8}
                  svgName={renderClass(cardData)}
                />
                PoS
              </div>
            </Tooltip>
          ) : null}
        </div>

        <div className="icon-section">
          <Popover
            overlayClassName="bookmark-popover"
            placement="bottomRight"
            open={activeBookmarkId === cardData?.trial_id}
            trigger="click"
            onOpenChange={(open) => setActiveBookmarkId(open ? cardData?.trial_id : null)}
            content={(
              <Bookmark
                docId={cardData.doc_id}
                trialId={cardData.trial_id}
                onClose={() => setActiveBookmarkId(null)}
                type={cardData.type}
                bookmarkId={bookmarkId}
                setBookmarkid={setBookmarkid}
                otherBookmarkIds={otherBookmarkIds}
                setOtherBookmarkIds={setOtherBookmarkIds}
                from="clinicalTrials"
                currentIndexDocId={currentIndex?.doc_id}
                setBookmarkUpdate={setBookmarkUpdate}
              />
              )}
          >
            <div
              className={
                // data?.isBookmark === true || bookmarkId.includes(data?.doc_id)
                bookmarkUpdate.bookmarkFlag
                  ? 'bookmark-icon-active-list'
                  // : otherBookmarkIds.includes(data?.doc_id) || (data?.isBookmarkInOther === true && !data?.isBookmark)
                  : bookmarkUpdate.otherBookmarkFlag
                    ? 'other-bookmark-icon'
                    : 'bookmark-icon'
              }
              role="presentation"
              onClick={() => {
                apiCall(cardData?.doc_id);
              }}
            />
          </Popover>
          <div
            className="share-icon"
            role="presentation"
            onClick={() => showShareModal(cardData)}
          />
        </div>
      </div>
      <div className="title">
        {cardData.scientific_title || cardData.laymen_title}
      </div>
      {renderSubDetailsDocument(cardData)}
    </div>
  );
}

export default ResultCard;
