import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { NOTIFICATION_CANCEL } from './logic';

const doNotShowNotification = ['VERIFY_OTP_FAILURE', 'CREATE_PROJECT_FAILURE', 'CREATE_ORGANIZATION_FAILURE'];

function Notification() {
  const {
    type, message, notificationType, showNotification,
  } = useSelector((state) => state.notification);
  useEffect(() => {
    if (type !== NOTIFICATION_CANCEL && !doNotShowNotification.includes(type) && showNotification) {
      toast(message, {
        position: 'top-center',
        autoClose: 1000,
        pauseOnHover: false,
        draggable: true,
        newestOnTop: true,
        type: notificationType,
        hideProgressBar: true,
        closeButton: false,
      });
    }
  }, [type]);
  return (
    <ToastContainer />
  );
}

export default Notification;
