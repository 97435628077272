import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const UPDATE_REFERRAL_USER = 'UPDATE_REFERRAL_USER';
const UPDATE_REFERRAL_USER_SUCCESS = 'UPDATE_REFERRAL_USER_SUCCESS';
const UPDATE_REFERRAL_USER_FAILURE = 'UPDATE_REFERRAL_USER_FAILURE';
const UPDATE_REFERRAL_USER_REFRESH = 'UPDATE_REFERRAL_USER_REFRESH';

export const updateReferralUserAction = createAction(UPDATE_REFERRAL_USER);

const updateReferralUserSuccess = createAction(UPDATE_REFERRAL_USER_SUCCESS);

const updateReferralUserFaliure = createAction(UPDATE_REFERRAL_USER_FAILURE);

export const updateReferralUserRefreshAction = createAction(UPDATE_REFERRAL_USER_REFRESH);

export const updateReferralUserEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_REFERRAL_USER),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/updateReferral?${action.payload.headers}`, 'POST', {}, false, { Authorization: action.payload.accessToken })).pipe(
    map((res) => updateReferralUserSuccess(res)),
    catchError((err) => of(updateReferralUserFaliure(err))),
  )),
);

const onupdateReferralUserAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onupdateReferralUserSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onupdateReferralUserFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onupdateReferralUserRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

const updateReferralUserReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(updateReferralUserAction, (state) => onupdateReferralUserAction(state))
      .addCase(updateReferralUserSuccess, (state, action) => onupdateReferralUserSuccess(state, action.payload))
      .addCase(updateReferralUserFaliure, (state) => onupdateReferralUserFailure(state))
      .addCase(updateReferralUserRefreshAction, () => onupdateReferralUserRefreshAction())
      .addDefaultCase((state) => state);
  },
);

export default updateReferralUserReducer;
