const dummyData = [
  {
    _id: '0',
    terminal: 'ot-ct-access',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '1',
    terminal: 'ot-disease-access',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '2',
    terminal: 'ot-therapy-access',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '3',
    terminal: 'ot-protein-access',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '4',
    terminal: 'ot-drug-access',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '5',
    terminal: 'ot-cl-access',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'active',
  },
  {
    _id: '6',
    terminal: 'ot-pl-access',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '7',
    terminal: 'ot-ra-access',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '8',
    terminal: 'ot-gene-access',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Coming Soon',
  },
];

export const appPermission = {
  'ot-ct-access': 'Clinical Trials',
  'ot-intervention-access': 'Intervention',
  'ot-disease-access': 'Disease',
  'ot-therapy-access': 'Therapy Area',
  'ot-protein-access': 'Protein',
  'ot-drug-access': 'Drug',
  'ot-cl-access': 'Competitive Landscape',
  'ot-pl-access': 'Preclinical Landscape',
  'ot-ra-access': 'Research Analytics',
  'ot-gene-access': 'Gene',
  'ot-search-exp-access': 'Search & Explore',
  influence_app_access: 'Key Opinion Leaders',
  xplore_app_access: 'Bio-network',
};

export const terminalsPath = {
  'ot-ct-access': '/clinicalTrials',
  'ot-intervention-access': '/intervention',
  'ot-search-exp-access': '/searchAndExplore',
  influence_app_access: '/redirect?app=influence',
  xplore_app_access: '/redirect?app=explore',
};

export default dummyData;
