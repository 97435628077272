import { createAction, createReducer } from '@reduxjs/toolkit';

const SET_BACK_BUTTON_ON = 'SET_BACK_BUTTON_ON';

export const setBackButtonStatusAction = createAction(SET_BACK_BUTTON_ON);

const onSetBackButtonStatus = (state) => ({
  ...state,
  toggleBack: !state.toggleBack,
});

export const getSetBackButtonStatusReducer = createReducer(
  {
    toggleBack: false,
  },
  (builder) => {
    builder.addCase(setBackButtonStatusAction, (state) => onSetBackButtonStatus(state))
      .addDefaultCase((state) => state);
  },
);

const SET_CURRENT_TAB = 'SET_CURRENT_TAB';

export const setCurrentTabAction = createAction(SET_CURRENT_TAB);

const onSetCurrentTab = (state, payload) => ({
  ...state,
  currentTab: payload,
});

export const getSetCurrentTabReducer = createReducer(
  {
    currentTab: '',
  },
  (builder) => {
    builder.addCase(setCurrentTabAction, (state, action) => onSetCurrentTab(state, action.payload))
      .addDefaultCase((state) => state);
  },
);
