/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip, Popover, Modal, Input, Button,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SvgLoader from '../../../common/components/SvgLoader';
import { ucFirst } from '../../../utils';
import Bookmark from '../../../common/container/Bookmark';
import { getProjectsAction } from '../../../common/container/WorkSpace/logic';
import {
  shareBookmarkAction,
  deleteBookmarkRefreshAction,
} from '../../../common/container/Bookmark/logic';
import { sendNotification } from '../../../common/components/Notification/logic';

function ExploreList({
  data,
  activeList,
  setActiveList,
  isRelatedArticles,
  isPrevSection,
  setHandleClickedVal,
  fromPreviewSE = false,

}) {
  const [activeBookmarkId, setActiveBookmarkId] = useState(null);
  const [selectedCard, setIsSelectedCard] = useState([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emailTags, setEmailTags] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [bookmarkId, setBookmarkid] = useState([]);
  const [otherBookmarkIds, setOtherBookmarkIds] = useState([]);
  const [bookmarkUpdate, setBookmarkUpdate] = useState({ bookmarkFlag: false, otherBookmarkFlag: false });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const dispatch = useDispatch();
  const shareBookmark = useSelector((state) => state.shareBookmark);
  const deleteBookmark = useSelector((state) => state.deleteBookmark);

  const apiCall = () => {
    dispatch(
      getProjectsAction({
        params: {
          doc_id: data?.doc_id,
        },
      }),
    );
  };
  useEffect(() => {
    if (shareBookmark.flag && Object.keys(shareBookmark?.data).length) {
      if (shareBookmark.data.message.includes('not')) {
        dispatch(
          sendNotification({
            type: 'BOOKMARK_SHARED_FAILURE',
            notificationType: 'error',
            message: shareBookmark.data.message,
            showNotification: true,
          }),
        );
      } else {
        dispatch(
          sendNotification({
            type: 'BOOKMARK_SHARED_SUCCESS',
            notificationType: 'success',
            message: shareBookmark.data.message,
            showNotification: true,
          }),
        );
      }
    }
  }, [JSON.stringify(shareBookmark)]);

  useEffect(() => {
    if (data) {
      setBookmarkUpdate({
        ...bookmarkUpdate, docId: data?.doc_id, bookmarkFlag: data?.isBookmark, otherBookmarkFlag: data?.isBookmarkInOther,
      });
    }
  }, [data]);

  useEffect(() => {
  }, [bookmarkUpdate]);

  useEffect(() => {
    if (deleteBookmark.flag) {
      dispatch(
        sendNotification({
          type: ' BOOKMARK_DELETE_SUCCESS',
          notificationType: 'success',
          message: 'Bookmark deleted successfully',
          showNotification: true,
        }),
      );
      dispatch(deleteBookmarkRefreshAction({}));
    }
  }, [JSON.stringify(deleteBookmark)]);

  const handleKeyDown = (e) => {
    if (currentEmail.trim() === '') return;
    if (e.key === 'Enter') {
      const emails = currentEmail.split(/[, ]+/);
      const validEmails = [];
      emails.forEach((email) => {
        if (emailRegex.test(email)) {
          validEmails.push(email);
        } else {
          setEmailError('Invalid email format');
        }
      });
      if (validEmails.length > 0) {
        setEmailTags([...emailTags, ...validEmails]);
        setCurrentEmail('');
        setEmailError('');
      }
    }
  };
  const handleBlur = () => {
    if (currentEmail.trim() === '') return;
    const emails = currentEmail.split(/[, ]+/);
    const validEmails = [];
    emails.forEach((email) => {
      if (emailRegex.test(email)) {
        validEmails.push(email);
      } else {
        setEmailError('Invalid email format');
      }
    });
    if (validEmails.length > 0) {
      setEmailTags([...emailTags, ...validEmails]);
      setCurrentEmail('');
      setEmailError('');
    }
  };

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
  };
  const renderClass = (predictionScore) => {
    if (predictionScore > 65) {
      return 'pos-high';
    }
    if (predictionScore > 35 && predictionScore <= 65) {
      return 'pos-med';
    }
    return 'pos-low';
  };

  const handleListClick = () => {
    if (!isRelatedArticles) {
      setActiveList(data);
    }
  };

  const renderTooltip = (predictionScore) => (
    <div className="prediction-tooltip-container">
      <div className="prediction-tooltip-data">
        <div
          className={
            predictionScore >= 65
              ? 'high'
              : predictionScore >= 35 && predictionScore < 65
                ? 'medium'
                : 'low'
          }
        />
        <div className="prediction-tooltip-sub-container">
          <div className="prediction-score">{`${predictionScore} %`}</div>
          <div className="predition-title">Probability of success</div>
        </div>
      </div>
    </div>
  );
  const showShareModal = (item) => {
    const temp = [];
    temp.push(item?.doc_id);
    temp.push(item?.type);

    setIsSelectedCard(temp);
    setOpenShareModal(!openShareModal);
  };

  const handleInviteClick = () => {
    if (emailTags.length > 0 && !emailError) {
      dispatch(
        shareBookmarkAction({
          body: {
            email_ids: emailTags,
            doc_id: selectedCard[0],
            asset_class: selectedCard[1],
          },
        }),
      );
      setOpenShareModal(!openShareModal);
      setEmailTags([]);
    }
  };

  const handleTagRemove = (emailToRemove) => {
    setEmailTags(emailTags.filter((email) => email !== emailToRemove));
  };

  const getClassName = (str) => {
    const modifiedStr = str.replace(/ /g, '-').toLowerCase();
    return modifiedStr;
  };

  const renderTooltipValue = (dataset) => dataset?.map(
    (item) => `${item?.name || item?.kol_name || item?.author_name || item} `,
  );
  const renderField = (value, dataset, len = 0) => (
    <span className="filter">
      <span className="filter-value" title={renderTooltipValue(dataset)}>
        {' '}
        {value?.name ?? value}
      </span>
      <span>{`${len > 1 ? ` +${len - 1}` : ''}`}</span>
    </span>
  );

  const condition = (authors) => (fromPreviewSE
    ? authors[0]?.kol_name || authors[0]?.author_name
    : authors[0]?.kol_name || authors[0]?.author_name || authors[0]);

  const showPos = (status) => status !== 'Completed' && status !== 'Discontinued' && status !== 'Withdrawn' && status !== 'Not active';

  const renderSubDetailsDocument = ({
    route_of_administration,
    dosage_form,
    committee,
    sub_type,
    active_ingredient,
    sponsors,
    indications,
    interventions,
    start_date,
    authors,
  }) => (
    <div
      className={`sub-details-container ${isPrevSection ? 'prev-section' : ''}`}
    >
      {authors && condition(authors) ? (
        <div
          className={`sub-details ${
            sponsors?.length || indications?.length
              ? 'extra-fileds-present'
              : ''
          }`}
        >
          <SvgLoader width={16} height={16} svgName="authors-icon" />
          <span className="filter-value">
            {' '}
            {renderField(
              authors[0]?.kol_name || authors[0]?.author_name || authors[0],
              authors,
              authors.length,
            )}
          </span>
        </div>
      ) : null}
      {sponsors?.length ? (
        <div
          className={`sub-details ${
            indications?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <SvgLoader width={16} height={16} svgName="sponsor-icon" />
          <span className="filter-value">
            {' '}
            {sponsors}
          </span>
        </div>
      ) : null}
      {indications?.length ? (
        <div
          className={`sub-details ${
            indications?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <SvgLoader width={16} height={16} svgName="indication-icon" />
          {renderField(indications[0], indications, indications.length)}
        </div>
      ) : null}
      {interventions?.length ? (
        <div
          className={`sub-details ${
            interventions?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <SvgLoader width={16} height={16} svgName="intervention-icon" />
          {renderField(interventions[0], interventions, interventions.length)}
        </div>
      ) : null}
      {interventions?.length && data?.type === 'regulatory_data' ? (
        <div
          className={`sub-details ${
            start_date?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <SvgLoader width={16} height={16} svgName="intervention-icon" />
          {typeof interventions === 'string' ? (
            <span className="filter-value">
              {' '}
              {active_ingredient}
            </span>
          ) : (
            renderField(
              interventions[0]?.name,
              interventions,
              interventions.length,
            )
          )}
        </div>
      ) : null}
      {sub_type === 'advisory_committee' ? (
        <div
          className={`sub-details ${
            start_date?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <span className="filter-value" title={committee}>
            {' '}
            {committee}
          </span>
        </div>
      ) : null}
      {sub_type === 'drug_specific_guidances' ? (
        <div
          className={`sub-details ${
            start_date?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <span className="filter-value">
            {' '}
            {dosage_form}
          </span>
        </div>
      ) : null}
      {sub_type === 'drug_specific_guidances' ? (
        <div
          className={`sub-details ${
            start_date?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <span className="filter-value">
            {' '}
            {route_of_administration}
          </span>
        </div>
      ) : null}
      {data?.type === 'hta_recommendations' ? (
        <div
          className={`sub-details ${
            start_date?.length ? 'extra-fileds-present' : ''
          }`}
        >
          {active_ingredient?.length ? (
            <SvgLoader width={16} height={16} svgName="indication-icon" />
          ) : null}
          <span className="filter-value">
            {' '}
            {renderField(
              active_ingredient[0]?.name,
              active_ingredient,
              active_ingredient.length,
            )}
          </span>
        </div>
      ) : null}
      {start_date ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="calender-icon" />
          <span className="filter-value">
            {' '}
            {start_date}
          </span>
        </div>
      ) : null}
    </div>
  );

  if (isPrevSection) {
    return (
      <div
        className="card-details"
        role="presentation"
        onClick={() => {
          setHandleClickedVal(data);
        }}
        key={data?.trial_id}
      >
        <div className="status">
          <div className="normal">{data.data_source}</div>
          {data.trial_id || data.phase || data?.study_status ? (
            <div className="dot" />
          ) : null}
          <div className="normal">{data?.trial_id}</div>
          {data.phase || data.study_status ? <div className="dot" /> : null}
          <div className="normal">{data.phase_short}</div>
          {data.study_status ? <div className="dot" /> : null}
          <div className={getClassName(data.study_status)}>
            {data.study_status === 'Completed' ? (
              <div className="completed-icon" />
            ) : null}
            {data.study_status}
          </div>
          {data.prediction_score ? <div className="dot" /> : null}
          {data.prediction_score && showPos(data.study_status) ? (
            <Tooltip
              placement="left"
              overlayClassName="prediction-tooltip position-zero"
              title={() => renderTooltip(data.prediction_score)}
              arrow={false}
            >
              <div
                role="presentation"
                className={renderClass(data)}
                id={`score${data.trial_id}`}
              >
                <SvgLoader width={11} height={8} svgName={renderClass(data)} />
                PoS
              </div>
            </Tooltip>
          ) : null}
        </div>
        <div className="title">{data.title}</div>
        {renderSubDetailsDocument(data)}
      </div>
    );
  }

  return (
    <div
      role="presentation"
      className={`search-and-explore-results-container-list-content-main explore ${
        activeList?.doc_id === data?.doc_id ? 'active' : ''
      } `}
      onClick={() => handleListClick()}
    >
      <div className="list-content-header">
        <div className="list-content-section">
          <div className="list-content-asset-class-icon">
            <Tooltip
              placement="bottomLeft"
              color="#646464"
              overlayClassName="explore-list-doc-type"
              title={data?.display_type}
            >
              <SvgLoader
                width={16}
                height={16}
                svgName={`${data?.type}-icon`}
              />
            </Tooltip>
          </div>
          <div className="list-content-asset-name" title={data?.data_source || data?.regulatory_body}>
            <span>{data?.data_source || data?.regulatory_body}</span>
            <span className="redirect-icon-wrapper">
              <a
                target="_blank"
                href={data?.data_source_link}
                rel="noreferrer noopener"
                label
              >
                <SvgLoader width={16} height={16} svgName="redirect-icon" />
              </a>
            </span>
          </div>

          {data?.date === '' || data?.published_date === '' ? null : (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-published-date">
                <div className="published-date-value">
                  {data?.date || data?.published_date}
                </div>
              </div>
            </>
          )}
          {data?.guideline_type?.length ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-published-date">
                <div className="published-date-value">
                  {renderField(
                    data?.guideline_type[0],
                    data?.guideline_type,
                    data?.guideline_type?.length,
                  )}
                </div>
              </div>
            </>
          ) : null}
          {data?.type === 'patents' && data?.expiry_date !== '' ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-published-date">
                <div className="published-date-value">
                  {data?.expiry_date}
                </div>
              </div>
            </>
          ) : null}
          {data?.type === 'patents' || data?.type === 'grants' ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-published-date">
                <div className="published-date-value">{data?.status}</div>
              </div>
            </>
          ) : null}
          {data?.type === 'publications' && data?.full_text !== '' ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-full-text">
                <div className="full-text-value" />
              </div>
            </>
          ) : null}
          {' '}
          {data?.decision ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-published-date">
                <div className="published-date-value">{data?.decision}</div>
              </div>
            </>
          ) : null}
          {data?.prediction_score
            ? ((
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
            ),
            (
              <div className="list-content-published-date">
                <div className="published-date-value">
                  {data?.date || data?.published_date}
                </div>
              </div>
            ))
            : null}
          {/* {data?.indications?.length ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-published-date">
                <div className="published-date-value">
                  {renderField(
                    'Indication',
                    data.indications,
                    data.indications.length,
                  )}
                </div>
              </div>
            </>
          ) : null} */}
          {data?.decision ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-published-date">
                <div className="published-date-value">{data?.decision}</div>
              </div>
            </>
          ) : null}
          {data?.prediction_score ? (
            <SvgLoader
              className="divider"
              width={6}
              height={6}
              svgName="seperator-icon"
            />
          ) : null}
          {data?.phase_short ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-published-date">
                {data?.phase_short}
              </div>
            </>
          ) : null}
          {data?.study_status ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="progress-status">
                <div className={getClassName(data.study_status)}>
                  {data.study_status === 'Completed' ? (
                    <div className="completed-icon" />
                  ) : null}
                  {data.study_status}
                </div>
              </div>
            </>
          ) : null}
          {data.prediction_score ? (
            <div className="pos-val-container">
              <Tooltip
                placement="left"
                overlayClassName="prediction-tooltip position-zero"
                title={() => renderTooltip(data.prediction_score)}
                arrow={false}
              >
                <div
                  role="presentation"
                  className={renderClass(data.prediction_score)}
                  id={`score${data.trial_id}`}
                >
                  <SvgLoader
                    width={11}
                    height={8}
                    svgName={renderClass(data.prediction_score)}
                  />
                  PoS
                </div>
              </Tooltip>
            </div>
          ) : null}
          {data?.study_results?.length ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-published-date">
                <div className="published-date-value">
                  {data?.full_text?.length
                    ? 'Free full text available'
                    : 'Results available'}
                </div>
              </div>
            </>
          ) : null}
          {data?.sub_type?.length ? (
            <>
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
              <div className="list-content-published-date">
                <div className="published-date-value">
                  {ucFirst(data?.sub_type)}
                </div>
              </div>
            </>
          ) : null}
        </div>
        {!fromPreviewSE && !isRelatedArticles ? (
          <div className="list-icon-section">
            <Popover
              overlayClassName="bookmark-popover"
              placement="bottomRight"
              open={activeBookmarkId === data?.trial_id}
              trigger="click"
              onOpenChange={(open) => setActiveBookmarkId(open ? data?.trial_id : null)}
              content={(
                <Bookmark
                  docId={data.doc_id}
                  trialId={data.trial_id}
                  onClose={() => setActiveBookmarkId(null)}
                  type={data.type}
                  bookmarkId={bookmarkId}
                  setBookmarkid={setBookmarkid}
                  otherBookmarkIds={otherBookmarkIds}
                  setOtherBookmarkIds={setOtherBookmarkIds}
                  bookmarkUpdate={bookmarkUpdate}
                  setBookmarkUpdate={setBookmarkUpdate}
                />
              )}
            >

              <div
                className={
                  bookmarkUpdate.bookmarkFlag
                    ? 'bookmark-icon-active-list'
                    : bookmarkUpdate.otherBookmarkFlag
                      ? 'other-bookmark-icon'
                      : 'bookmark-icon'
                }
                role="presentation"
                onClick={() => {
                  apiCall();
                }}
              />
            </Popover>
            <div
              className="share-icon"
              role="presentation"
              onClick={() => showShareModal(data)}
            />
          </div>
        ) : null}
      </div>
      <div
        className="list-content-title"
        title={data.title || data?.title_publication}
      >
        {data?.title || data?.title_publication}
      </div>
      <div className="list-content-data">{renderSubDetailsDocument(data)}</div>
      {openShareModal && (
        <Modal
          className="share-with-modal"
          open={openShareModal}
          closable
          footer={false}
          onCancel={() => { setOpenShareModal(false); setEmailTags([]); }}
        >
          <div className="share-modal-content">
            <div className="share-modal-content-input">
              <div className="share-with-title">Share with</div>
              <div
                className="email-tags-container"
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  marginBottom: '10px',
                }}
              >
                <div className="email-tags">
                  {emailTags.map((email, index) => (
                    <div key={index} className="email-tag">
                      {email}
                      <span
                        role="presentation"
                        className="remove-tag"
                        onClick={() => handleTagRemove(email)}
                      />
                    </div>
                  ))}
                  <Input
                    //  mode="tags"
                    onBlur={handleBlur}
                    value={currentEmail}
                    onChange={handleEmailInputChange}
                    onKeyDown={(e) => handleKeyDown(e)}
                    placeholder="Email ID"
                    //   style={{ flex: '0 0 auto', marginLeft: '5px' }}
                  />
                  {emailError && (
                    <div style={{ color: 'red' }}>{emailError}</div>
                  )}
                </div>
              </div>
              <div className="share-with-message-input">
                <Input placeholder="Message (Optional)" />
              </div>
              <div className="invite-btn">
                <Button
                  type="primary"
                  onClick={() => handleInviteClick()}
                >
                  Share
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

ExploreList.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  activeList: PropTypes.instanceOf(Object).isRequired,
  setActiveList: PropTypes.instanceOf(Object).isRequired,
};

export default ExploreList;
