import { createAction, createReducer } from '@reduxjs/toolkit';

import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import webAuth from '../../../utils/webAuth';
import apiCall, { getCookie, removeCookies } from '../../../utils';

const SET_SESSION = 'SET_SESSION';
const REMOVE_SESSION = 'REMOVE_SESSION';

const INTIAL_STATE = {
  session: getCookie('accessToken') || '',
};

export const setSession = createAction(SET_SESSION);

export const removeSession = createAction(REMOVE_SESSION);

export const sessionReducer = createReducer(INTIAL_STATE, (builder) => {
  builder
    .addCase(setSession, (state, action) => {
      state.session = action.payload;
    })
    .addCase(removeSession, (state) => {
      const params = {
        returnTo: process.env.appUrl,
        clientID: process.env.auth0.clientId,
      };
      webAuth.logout(params);
      removeCookies();
      state.session = '';
    })
    .addDefaultCase((state) => state);
});

const GET_REFRESH_TOKEN = 'GET_REFRESH_TOKEN';
const GET_REFRESH_TOKEN_SUCCESS = 'GET_REFRESH_TOKEN_SUCCESS';
const GET_REFRESH_TOKEN_FAILURE = 'GET_REFRESH_TOKEN_FAILURE';
const GET_REFRESH_TOKEN_REFRESH = 'GET_REFRESH_TOKEN_REFRESH';

export const getRefreshTokenAction = createAction(GET_REFRESH_TOKEN);

const getRefreshTokenSuccess = createAction(GET_REFRESH_TOKEN_SUCCESS);

const getRefreshTokenFaliure = createAction(GET_REFRESH_TOKEN_FAILURE);

export const getRefreshTokenRefresh = createAction(GET_REFRESH_TOKEN_REFRESH);

export const getRefreshTokenEpic = (actions$) => actions$.pipe(
  ofType(GET_REFRESH_TOKEN),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/getAccessToken?${action.payload.headers}`, 'POST')).pipe(
    map((res) => getRefreshTokenSuccess(res)),
    catchError((err) => of(getRefreshTokenFaliure(err))),
  )),
);

const ongetRefreshTokenAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetRefreshTokenSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
  error: false,
});

const ongetRefreshTokenFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetRefreshTokenRefresh = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const getRefreshTokenReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getRefreshTokenAction, (state) => ongetRefreshTokenAction(state))
      .addCase(getRefreshTokenSuccess, (state, action) => ongetRefreshTokenSuccess(state, action.payload))
      .addCase(getRefreshTokenFaliure, (state) => ongetRefreshTokenFailure(state))
      .addCase(getRefreshTokenRefresh, () => ongetRefreshTokenRefresh())
      .addDefaultCase((state) => state);
  },
);
