import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import ChatHistoryContainer from './ChatHistoryContainer';
import ChatHistorySidebar from './ChatHistorySidebar';
import { getChatHistoryAction } from './logic';
import Loader from '../../../components/Loader';

function ChatHistory({ projectId }) {
  const dispatch = useDispatch();
  const chatHistory = useSelector((state) => state.chatHistory);
  const isChatbotOpen = useSelector((state) => state.chatbot.isOpen);

  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [chatData, setChatData] = useState([]);

  useEffect(() => {
    if (chatHistory.data.length > 0) {
      setSelectedSessionId(chatHistory.data[0].session_id);
    }
  }, [JSON.stringify(chatHistory)]);

  useEffect(() => {
    if (chatHistory.flag) {
      setChatData(chatHistory.data);
    }
  }, [JSON.stringify(chatHistory)]);

  useEffect(() => {
    if (projectId !== '') {
      dispatch(
        getChatHistoryAction({
          params: {
            headers: queryString.stringify({
              project_id: projectId,
            }),
          },
        }),
      );
    }
  }, [projectId, isChatbotOpen === false]);
  return (
    <>
      <div className="analytics-container-content-value-chat-history-left">
        <ChatHistorySidebar
          setSelectedSessionId={setSelectedSessionId}
          chatData={chatData}
          selectedSessionId={selectedSessionId}
        />
      </div>
      {/* </Loader> */}
      <Loader
        loading={chatHistory.loading}
        error={chatHistory.error}
      >
        <div className="analytics-container-content-value-chat-history-right">
          <ChatHistoryContainer
            selectedSessionId={selectedSessionId}
            chatData={chatData}
          />
        </div>
      </Loader>
    </>
  );
}

export default ChatHistory;
