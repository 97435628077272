import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

// get organization values
const GET_ORGANIZATION_VALUES = 'GET_ORGANIZATION_VALUES';
const GET_ORGANIZATION_VALUES_SUCCESS = 'GET_ORGANIZATION_VALUES_SUCCESS';
const GET_ORGANIZATION_VALUES_FAILURE = 'GET_ORGANIZATION_VALUES_FAILURE';
const GET_ORGANIZATION_VALUES_REFRESH = 'GET_ORGANIZATION_VALUES_REFRESH';

export const getOrganizationValuesAction = createAction(GET_ORGANIZATION_VALUES);

const getOrganizationValuesSuccess = createAction(GET_ORGANIZATION_VALUES_SUCCESS);

const getOrganizationValuesFaliure = createAction(GET_ORGANIZATION_VALUES_FAILURE);

export const getOrganizationValuesRefreshAction = createAction(GET_ORGANIZATION_VALUES_REFRESH);

export const getOrganizationValuesEpic = (actions$) => actions$.pipe(
  ofType(GET_ORGANIZATION_VALUES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/organizations?${action.payload.headers}`, 'get')).pipe(
    map((res) => getOrganizationValuesSuccess(res)),
    catchError((err) => of(getOrganizationValuesFaliure(err))),
  )),
);

const ongetOrganizationValuesAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetOrganizationValuesSuccess = (state, { response }) => ({
  ...state,
  data: [...state.data, ...response.data],
  total: response.total,
  loading: false,
  flag: true,
});

const onGetOrganizationValuesRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const ongetOrganizationValuesFaliure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const getOrganizationValuesReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getOrganizationValuesAction, (state) => ongetOrganizationValuesAction(state))
      .addCase(getOrganizationValuesSuccess, (state, action) => ongetOrganizationValuesSuccess(state, action.payload))
      .addCase(getOrganizationValuesFaliure, (state) => ongetOrganizationValuesFaliure(state))
      .addCase(getOrganizationValuesRefreshAction, () => onGetOrganizationValuesRefresh())
      .addDefaultCase((state) => state);
  },
);

// invite user

const INVITE_USER = 'INVITE_USER';
const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
const INVITE_USERS_REFRESH = 'INVITE_USERS_REFRESH';

export const inviteUsersAction = createAction(INVITE_USER);

const inviteUsersSuccess = createAction(INVITE_USER_SUCCESS);

const inviteUsersFaliure = createAction(INVITE_USER_FAILURE);

export const inviteUsersRefreshAction = createAction(INVITE_USERS_REFRESH);

export const inviteUsersEpic = (actions$) => actions$.pipe(
  ofType(INVITE_USER),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users/invite`, 'POST', action.payload.body)).pipe(
    map((res) => inviteUsersSuccess(res)),
    catchError((err) => of(inviteUsersFaliure(err))),
  )),
);

const ongetInviteUsersAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetInviteUsersSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  showNotification: true,
  flag: true,
});

const ongetInviteUsersFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onInviteUsersRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const inviteUsersReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(inviteUsersAction, (state) => ongetInviteUsersAction(state))
      .addCase(inviteUsersSuccess, (state, action) => ongetInviteUsersSuccess(state, action.payload))
      .addCase(inviteUsersFaliure, (state, action) => ongetInviteUsersFaliure(state, action.payload))
      .addCase(inviteUsersRefreshAction, () => onInviteUsersRefresh())
      .addDefaultCase((state) => state);
  },
);

// get users list

const GET_USERS_LIST = 'GET_USERS_LIST';
const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE';
const GET_USERS_LIST_REFRESH = 'GET_USERS_LIST_REFRESH';

export const getUsersListAction = createAction(GET_USERS_LIST);

const getUsersListSuccess = createAction(GET_USERS_LIST_SUCCESS);

const getUsersListFaliure = createAction(GET_USERS_LIST_FAILURE);

export const getUsersListRefreshAction = createAction(GET_USERS_LIST_REFRESH);

export const getUsersListEpic = (actions$) => actions$.pipe(
  ofType(GET_USERS_LIST),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users?${action.payload}`, 'get')).pipe(
    map((res) => getUsersListSuccess(res)),
    catchError((err) => of(getUsersListFaliure(err))),
  )),
);

const ongetUsersListAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetUsersListSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetUsersListFaliure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetUsersListRefresh = (state) => ({
  ...state,
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const getUsersListReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getUsersListAction, (state) => ongetUsersListAction(state))
      .addCase(getUsersListSuccess, (state, action) => ongetUsersListSuccess(state, action.payload))
      .addCase(getUsersListFaliure, (state) => ongetUsersListFaliure(state))
      .addCase(getUsersListRefreshAction, (state) => ongetUsersListRefresh(state))
      .addDefaultCase((state) => state);
  },
);

// create organization

const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';
const CREATE_ORGANIZATIONS_REFRESH = 'CREATE_ORGANIZATIONS_REFRESH';

export const createOrganizationAction = createAction(CREATE_ORGANIZATION);

const createOrganizationSuccess = createAction(CREATE_ORGANIZATION_SUCCESS);

const createOrganizationFaliure = createAction(CREATE_ORGANIZATION_FAILURE);

export const createOrganizationRefreshAction = createAction(CREATE_ORGANIZATIONS_REFRESH);

export const createOrganizationEpic = (actions$) => actions$.pipe(
  ofType(CREATE_ORGANIZATION),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/organizations`, 'POST', action.payload.body)).pipe(
    map((res) => createOrganizationSuccess(res)),
    catchError((err) => of(createOrganizationFaliure(err))),
  )),
);

const onCreateOrganizationAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onCreateOrganizationSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  showNotification: true,
  flag: true,
});

const onCreateOrganizationFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onCreateOrganizationRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const createOrganizationReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(createOrganizationAction, (state) => onCreateOrganizationAction(state))
      .addCase(createOrganizationSuccess, (state, action) => onCreateOrganizationSuccess(state, action.payload))
      .addCase(createOrganizationFaliure, (state, action) => onCreateOrganizationFaliure(state, action.payload))
      .addCase(createOrganizationRefreshAction, () => onCreateOrganizationRefresh())
      .addDefaultCase((state) => state);
  },
);

const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
const EDIT_ORGANIZATION_SUCCESS = 'EDIT_ORGANIZATION_SUCCESS';
const EDIT_ORGANIZATION_FAILURE = 'EDIT_ORGANIZATION_FAILURE';
const EDIT_ORGANIZATIONS_REFRESH = 'EDIT_ORGANIZATIONS_REFRESH';

export const editOrganizationAction = createAction(EDIT_ORGANIZATION);

const editOrganizationSuccess = createAction(EDIT_ORGANIZATION_SUCCESS);

const editOrganizationFaliure = createAction(EDIT_ORGANIZATION_FAILURE);

export const editOrganizationRefreshAction = createAction(EDIT_ORGANIZATIONS_REFRESH);

export const editOrganizationEpic = (actions$) => actions$.pipe(
  ofType(EDIT_ORGANIZATION),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/organizations?${action.payload.headers}`, 'PUT', action.payload.body)).pipe(
    map((res) => editOrganizationSuccess(res)),
    catchError((err) => of(editOrganizationFaliure(err))),
  )),
);

const onEditOrganizationAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onEditOrganizationSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  showNotification: true,
  flag: true,
});

const onEditOrganizationFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onEditOrganizationRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const editOrganizationReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(editOrganizationAction, (state) => onEditOrganizationAction(state))
      .addCase(editOrganizationSuccess, (state, action) => onEditOrganizationSuccess(state, action.payload))
      .addCase(editOrganizationFaliure, (state, action) => onEditOrganizationFaliure(state, action.payload))
      .addCase(editOrganizationRefreshAction, () => onEditOrganizationRefresh())
      .addDefaultCase((state) => state);
  },
);

const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
const UPDATE_USER_STATUS_FAILURE = 'UPDATE_USER_STATUS_FAILURE';
const UPDATE_USER_STATUS_REFRESH = 'UPDATE_USER_STATUS_REFRESH';

export const updateUserStatusAction = createAction(UPDATE_USER_STATUS);

const updateUserStatusSuccess = createAction(UPDATE_USER_STATUS_SUCCESS);

const updateUserStatusFaliure = createAction(UPDATE_USER_STATUS_FAILURE);

export const updateUserStatusRefreshAction = createAction(UPDATE_USER_STATUS_REFRESH);

export const updateUserStatusEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_USER_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users/updateUserStatus?${action.payload.headers}`, 'PUT')).pipe(
    map((res) => updateUserStatusSuccess(res)),
    catchError((err) => of(updateUserStatusFaliure(err))),
  )),
);

const onupdateUserStatusAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onupdateUserStatusSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onupdateUserStatusFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onupdateUserStatusRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const updateUserStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(updateUserStatusAction, (state) => onupdateUserStatusAction(state))
      .addCase(updateUserStatusSuccess, (state, action) => onupdateUserStatusSuccess(state, action.payload))
      .addCase(updateUserStatusFaliure, (state) => onupdateUserStatusFailure(state))
      .addCase(updateUserStatusRefreshAction, () => onupdateUserStatusRefreshAction())
      .addDefaultCase((state) => state);
  },
);

export {
  inviteUsersReducer, getOrganizationValuesReducer, getUsersListReducer, createOrganizationReducer, editOrganizationReducer,
};
