import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { Tooltip } from 'react-tooltip';
import useD3 from 'hook-use-d3';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { Slider } from 'antd';
import { useLocation, useSearchParams } from 'react-router-dom';
import SvgLoader from '../../../common/components/SvgLoader';
import countryData from './data/custom-geo.json';
import {
  modifeyNumber, trackOutSideEvents, getTerminalName, windowHrefToNavigate,
} from '../../../utils';
import USACountryData from './data/usa.json';
import {
  getGeographicalDataAction, getGeographicalDataRefreshAction, getGeographicalStateDataAction, getGeographicalStateDataRefreshAction, dateRangeUpdateAction, showBackButtonAction,
} from './logic';
import Loader from '../../../common/components/Loader';
import Mixpanel from '../../../utils/mixpanel';

function Geographical({
  selected, currentExpanded, setExpanded, params,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');
  const toolTip2Ref = useRef(null);
  const path = location.search;

  const [isExpanded, setIsExpanded] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentTooltipId, setCurrentTooltipId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [zoomOutBtnHover, setZoomOutBtnHover] = useState(false);
  const [currentCountry, setCurrentCountry] = useState({});
  const [currentZoomLevelMap, setCurrentZoomLevelMap] = useState([0, 0]);
  const [currentState, setCurrentState] = useState({});
  const [currentSelectedDates, setCurrentSelectedDates] = useState([]);
  const [tooltipData, setTooltipData] = useState({
    countryName: '',
    trialCount: 0,
  });
  const [hoveredLabelData, setHoveredLabelData] = useState({});
  const [triggerLabelTransition, setTriggerLabelTransition] = useState(false);

  const geoGraphicalCountryData = useSelector((state) => state.geoGraphicalCountryData);
  const geoGraphicalStateData = useSelector((state) => state.geoGraphicalStateData);
  const getDateRangeData = useSelector((state) => state.getDateRangeData);
  const searchResult = useSelector((state) => state.searchResult);
  const showBackButton = useSelector((state) => state.showBackButton);

  const defaultState = () => {
    setShowTooltip(false);
    setCurrentTooltipId(null);
    setSelectedCountry(null);
    setZoomOutBtnHover(false);
    setCurrentState({ ...{} });
  };

  const formatId = (id) => id?.replace(/ /g, '-');

  useEffect(() => {
    if (!showBackButton.isExpanded) {
      setIsExpanded(false);
      setExpanded('');
    }
    if (showBackButton.isExpanded) {
      window.addEventListener('popstate', () => {
        dispatch(showBackButtonAction(false));
        windowHrefToNavigate(`/clinicalTrials/analyticsResults${path}`);
      });
    }
  }, [JSON.stringify(showBackButton)]);

  const startYear = getDateRangeData.data[0] || Number(geoGraphicalCountryData?.minYear);
  const endYear = getDateRangeData.data[1] || Number(geoGraphicalCountryData?.maxYear);

  let tempYear = startYear;
  const yearRange = [];
  const yearDiff = endYear - startYear;

  let initial = yearDiff > 30 ? 9 : 2;

  while (yearDiff % initial !== 0 && initial < yearDiff) {
    initial += 1;
  }

  if (yearDiff <= 1) {
    initial = 1;
  }

  const step = initial;

  if (startYear !== endYear) {
    while (tempYear <= endYear) {
      yearRange.push(tempYear);
      tempYear += step;
    }
  } else {
    yearRange.push(startYear);
    yearRange.push(endYear);
  }

  const conditionCountry = JSON.stringify(currentCountry) !== '{}';
  const conditionState = JSON.stringify(currentState) !== '{}';

  const baseWidth = window.innerWidth - 150;
  const values = geoGraphicalCountryData?.data.map((element) => element.trialCount);
  let unitedStatesData = [];

  if (geoGraphicalStateData?.flag) {
    geoGraphicalStateData.data.forEach((item) => {
      if (item.country === 'United States') {
        unitedStatesData = item.states;
      }
    });
  }

  const valuesState = unitedStatesData?.map((element) => element.trials);
  const combined = [...values, ...valuesState, 0];

  combined.sort((a, b) => b - a);

  const colorScale = d3.scaleLinear()
    .domain(d3.extent(combined))
    .interpolate(d3.interpolateHcl)
    .range(['#DCDCFE', '#5252FA']);

  const countryColor = useMemo(() => {
    const tempColor = {};
    if (geoGraphicalCountryData.flag) {
      geoGraphicalCountryData.data.forEach((element) => {
        tempColor[element.countryName] = colorScale(element.trialCount);
      });
    }
    return tempColor;
  }, [JSON.stringify(geoGraphicalCountryData)]);

  const countryTrial = useMemo(() => {
    const tempColor = {};
    if (geoGraphicalCountryData.flag) {
      geoGraphicalCountryData.data.forEach((element) => {
        tempColor[element.countryName] = element.trialCount;
      });
    }
    return tempColor;
  }, [JSON.stringify(geoGraphicalCountryData)]);

  const colorScaleState = d3.scaleLinear()
    .domain([0, d3.max(valuesState)])
    .interpolate(d3.interpolateHcl)
    .range(['#DCDCFE', '#5252FA']);

  const stateColor = useMemo(() => {
    const tempColor = {};
    if (unitedStatesData.length) {
      unitedStatesData.forEach((element) => {
        tempColor[element.name] = colorScaleState(element.trials);
      });
    }
    return tempColor;
  }, [JSON.stringify(unitedStatesData)]);

  const stateTrial = useMemo(() => {
    const tempTrials = {};
    if (unitedStatesData.length) {
      unitedStatesData.forEach((element) => {
        tempTrials[element.name] = element.trials;
      });
    }
    return tempTrials;
  }, [JSON.stringify(unitedStatesData)]);

  const handleTrial = (value) => {
    if (value.properties.admin === 'United States of America') {
      return countryTrial['United States'] || 0;
    }
    if (countryColor[value.properties.admin]) {
      return countryTrial[value.properties.admin];
    }

    if (value?.properties?.NAME && stateColor[value?.properties?.NAME]) {
      return stateTrial[value.properties.NAME];
    }
    return 0;
  };

  const getTimeStamp = (type, dates) => {
    if (type === 'start') {
      const dateString = `${dates[0]}-01-01`;
      const dateObject = new Date(dateString);
      const timestamp = dateObject.getTime();
      return timestamp / 1000;
    }
    const dateString = `${dates[1]}-12-31`;
    const dateObject = new Date(dateString);
    const timestamp = dateObject.getTime();
    return timestamp / 1000;
  };

  const isCountryUSA = () => currentCountry?.properties?.admin === 'United States of America';

  const handleListViewClick = (type, data) => {
    const geoGraphQuery = {
      filters: {},
    };

    if (type === 'country') {
      if (!isExpanded) {
        geoGraphQuery.filters = {
          site_country: [
            {
              value: data?.properties?.admin === 'United States of America' ? 'United States' : data?.properties?.admin,
              operator: 'OR',
            },
          ],
        };
      } else {
        geoGraphQuery.filters = {
          site_country: [
            {
              value: data?.properties?.admin === 'United States of America' ? 'United States' : data?.properties?.admin,
              operator: 'OR',
            },
          ],
          study_start_date: [
            {
              value: getTimeStamp('start', currentSelectedDates),
              operator: 'GTE',
            },
            {
              value: getTimeStamp('end', currentSelectedDates),
              operator: 'LTE',
            },
          ],
        };
      }

      Mixpanel.track('widget_deepdive_event', {
        action: 'country click for list view search',
        search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
        widget_name: 'Geographical View*',
        terminal_name: 'Clinical Trials',
        query: JSON.parse(semanticQuery || query),
        page: 'Analytics Page',
        country_data: {
          country_name: data.properties.admin,
          number_of_trials: handleTrial(data),
        },
      });
      Mixpanel.track('page_entry_event', {
        action: 'country click for list view search',
        terminal_name: 'Clinical Trials',
        page_name: 'Document Listing Page - Geographical View',
      });
    }

    if (type === 'state') {
      geoGraphQuery.filters = {
        site_state: [
          {
            value: data.properties.NAME,
            operator: 'OR',
          },
        ],
        study_start_date: [
          {
            value: getTimeStamp('start', currentSelectedDates),
            operator: 'GTE',
          },
          {
            value: getTimeStamp('end', currentSelectedDates),
            operator: 'LTE',
          },
        ],
      };
      Mixpanel.track('widget_deepdive_event', {
        action: 'state click for list view search',
        search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
        widget_name: 'Geographical View',
        terminal_name: 'Clinical Trials',
        query: JSON.parse(semanticQuery || query),
        page: 'Analytics Page',
        view_type: 'Widget View',
        label: data?.properties?.name,
        misc: 'Level 0',
        state_data: {
          state_name: data.properties.NAME,
          number_of_trials: handleTrial(data),
        },
      });
    }

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
    });
    if (semanticQuery) {
      windowHrefToNavigate(`/clinicalTrials/searchResults?semanticquery=${semanticQuery}&geoGraphQuery=${JSON.stringify(geoGraphQuery)}${'&from=\'Geographical view\''}`);
    } else {
      windowHrefToNavigate(`/clinicalTrials/searchResults?query=${query}&geoGraphQuery=${JSON.stringify(geoGraphQuery)}${'&from=\'Geographical view\''}`);
    }
  };

  const handleColor = (value) => {
    if (value.properties.admin === 'United States of America') {
      if (countryColor['United States']) {
        return countryColor['United States'];
      }
    }
    if (value?.properties?.admin && countryColor[value?.properties?.admin]) {
      return countryColor[value.properties.admin];
    }

    if (value?.properties?.NAME && stateColor[value?.properties?.NAME]) {
      return stateColor[value.properties.NAME];
    }
    return '#EEEEFF';
  };

  const dimensions = {
    width: isExpanded ? baseWidth : 600,
    height: isExpanded ? 610 : 240,
    margin: {
      top: 20,
      left: 20,
      right: 20,
      bottom: 20,
    },
  };

  dimensions.ctrWidth = dimensions.width - dimensions.margin.left - dimensions.margin.right;
  dimensions.ctrHeight = dimensions.height - dimensions.margin.top - dimensions.margin.bottom;

  const projection = d3.geoMercator()
    .scale(dimensions.ctrWidth / 5 / Math.PI + 40)
    .translate([dimensions.ctrWidth / 2, dimensions.ctrHeight / 2 + 30]);

  const handleCurrentFocus = () => {
    if (JSON.stringify(currentState) !== '{}') {
      return currentState;
    }
    if (JSON.stringify(currentCountry) !== '{}') {
      return currentCountry;
    }
    return countryData;
  };

  const projectionExpanded = d3.geoMercator()
    .fitSize([dimensions.ctrWidth, dimensions.ctrHeight], handleCurrentFocus())
    .precision(100);

  const projectionCountry = d3.geoMercator()
    .scale(dimensions.ctrWidth / 3 / Math.PI + 60)
    .translate([dimensions.ctrWidth / 2, dimensions.ctrHeight / 2 + 80]);

  const pathGenerator = d3.geoPath()
    .projection(isExpanded ? selectedCountry ? projectionExpanded : projectionCountry : projection);

  let maxArea = 0;
  let minArea = Infinity;

  const labels = [];
  if (isExpanded) {
    countryData.features.forEach((feature) => {
      if (pathGenerator.area(feature) > 800) {
        const [x, y] = pathGenerator.centroid(feature);
        const area = pathGenerator.area(feature);

        const tempLabel = {
          x,
          y,
          text: `${feature.properties.admin}`,
          trialCount: `${modifeyNumber(handleTrial(feature))}`,
          area,
          feature,
        };

        if (feature.properties.admin === 'United States of America') {
          tempLabel.x += 60;
          tempLabel.y += 60;
          tempLabel.text = 'USA';
        }

        if (feature.properties.admin === 'Canada') {
          tempLabel.x -= 40;
          tempLabel.y += 60;
        }

        if (feature.properties.admin === 'Norway') {
          tempLabel.x -= 40;
          tempLabel.y += 110;
        }

        if (feature.properties.admin === 'Greenland') {
          tempLabel.y += 40;
        }

        if (!isCountryUSA() || feature.properties.admin !== 'United States of America') {
          labels.push(tempLabel);
        }

        minArea = Math.min(minArea, area);
        maxArea = Math.max(maxArea, area);
      }
    });

    const padding = 4;
    labels.forEach((label, i) => {
      for (let j = i + 1; j < labels.length; j += 1) {
        const otherLabel = labels[j];
        if (Math.abs(label.x - otherLabel.x) < padding * 10 && Math.abs(label.y - otherLabel.y) < padding * 2) {
          otherLabel.y += padding * 2;
        }
      }
    });
  }

  const fontSizeScale = d3.scaleSqrt()
    .domain([minArea, maxArea])
    .range([8, 24]);

  const getFonteSize = (area) => {
    if ((currentZoomLevelMap[0] === 0 && currentZoomLevelMap[1] === 1) || (currentZoomLevelMap[0] === 2 && currentZoomLevelMap[1] === 1)) {
      return fontSizeScale(area) + 5;
    }
    return fontSizeScale(area);
  };

  const stateLabels = [];
  if (isExpanded && isCountryUSA()) {
    USACountryData.features.forEach((feature) => {
      if (pathGenerator.area(feature) > 800) {
        const [x, y] = pathGenerator.centroid(feature);
        const area = pathGenerator.area(feature);

        const tempLabel = {
          x,
          y,
          text: `${feature.properties.NAME}`,
          trialCount: `${modifeyNumber(handleTrial(feature))}`,
          area,
          feature,
        };

        stateLabels.push(tempLabel);
      }
    });

    const statePadding = 4;
    stateLabels.forEach((label, i) => {
      for (let j = i + 1; j < stateLabels.length; j += 1) {
        const otherLabel = stateLabels[j];
        if (Math.abs(label.x - otherLabel.x) < statePadding * 10 && Math.abs(label.y - otherLabel.y) < statePadding * 2) {
          otherLabel.y += statePadding * 2;
        }
      }
    });
  }

  const handleStateFontSize = (area) => {
    if (currentZoomLevelMap[0] === 1 && currentZoomLevelMap[1] === 2) {
      return getFonteSize(area) + 5;
    }
    return getFonteSize(area) - 5;
  };

  const draw = (svg) => {
    svg.select('.svg-container')
      .attr('transform', `translate(${dimensions.margin.left}, ${dimensions.margin.top})`);

    const worldLayer = d3.select('.world-layer');
    const stateLayer = d3.select('.state-layer');

    worldLayer.selectAll('path')
      .data(countryData.features)
      .join('path')
      .on('click', (event, datum) => {
        if (isExpanded) {
          setTriggerLabelTransition(true);
          setSelectedCountry(datum);
          setCurrentCountry({ ...datum });
          setCurrentState({ ...{} });
          setCurrentZoomLevelMap([...[0, 1]]);
          setTimeout(() => {
            setTriggerLabelTransition(false);
          }, 500);
          Mixpanel.track('zoom_view_event', {
            action: 'Country clicked zeoomed to view states',
            search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
            widget_name: 'Geographical View*',
            terminal_name: 'Clinical Trials',
            page: 'Analytics Page',
            query: JSON.parse(semanticQuery || query),
            country_data: {
              country_name: datum.properties.admin,
              country_trials: handleTrial(datum),
            },
          });
        }

        if (!isExpanded) {
          handleListViewClick('country', datum);
        }
        setShowTooltip(false);
      })
      .on('mouseenter', (event, datum) => {
        setTooltipData({
          ...{
            countryName: datum.properties.admin,
            trialCount: modifeyNumber(handleTrial(datum)),
          },
        });
        setCurrentTooltipId(`${formatId(datum.properties.admin)}-geo-tooltip`);
        setShowTooltip(true);
      })
      .on('mouseleave', () => {
        setShowTooltip(false);
      })
      .transition()
      .attr('id', (d) => `${formatId(d.properties.admin)}-geo-tooltip`)
      .attr('d', pathGenerator)
      .attr('class', 'pointer')
      .attr('fill', (d) => handleColor(d));

    const handleDelay = () => {
      if (currentZoomLevelMap[0] === 0 && currentZoomLevelMap[1] === 1) {
        return 600;
      }
      if (currentZoomLevelMap[0] === 1 && currentZoomLevelMap[1] === 2) {
        return 0;
      }
      return 0;
    };

    if (isCountryUSA()) {
      stateLayer.selectAll('path')
        .data(USACountryData.features)
        .join('path')
        .on('click', (event, datum) => {
          if (isExpanded) {
            setSelectedCountry(datum);
            setCurrentState({ ...datum });
            setCurrentZoomLevelMap([...[1, 2]]);
            Mixpanel.track('zoom_view_event', {
              action: 'State click to zoomed to view regions',
              search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
              widget_name: 'Geographical View*',
              terminal_name: 'Clinical Trials',
              page: 'Analytics Page',
              query: JSON.parse(semanticQuery || query),
              state_data: {
                state_name: datum.properties.NAME,
                number_of_trials: handleTrial(datum),
              },
            });
          }
          setShowTooltip(false);
        })
        .on('mouseenter', (event, datum) => {
          setTooltipData({
            ...{
              countryName: datum.properties.NAME,
              trialCount: handleTrial(datum),
            },
          });
          setCurrentTooltipId(`${formatId(datum.properties.NAME)}-geo-tooltip`);
          setShowTooltip(true);
        })
        .on('mouseleave', () => {
          setShowTooltip(false);
        })
        .transition()
        .delay(handleDelay())
        .attr('id', (d) => `${formatId(d.properties.NAME)}-geo-tooltip`)
        .attr('d', pathGenerator)
        .attr('class', 'pointer')
        .attr('fill', (d) => handleColor(d))
        .attr('stroke', 'white')
        .attr('stroke-width', 1);
    } else {
      stateLayer.selectAll('path').remove();
      stateLayer.selectAll('text').remove();
    }

    if (!isExpanded) {
      stateLayer.selectAll('path').remove();
      if (conditionCountry) {
        setCurrentCountry({ ...{} });
      }
      if (conditionState) {
        setCurrentState({ ...{} });
      }
    }
  };

  const d3ref = useD3((svg) => {
    if (geoGraphicalCountryData.flag && geoGraphicalStateData.flag) {
      draw(svg);
    }
  }, [isExpanded, selectedCountry, currentCountry, currentState, JSON.stringify(geoGraphicalCountryData), JSON.stringify(geoGraphicalStateData)]);

  useEffect(() => {
    trackOutSideEvents(toolTip2Ref, () => setShowTooltip(false));
  }, []);

  const handleShrinkClick = () => {
    setIsExpanded(false);
    setExpanded('');
    defaultState();
    dispatch(showBackButtonAction(false));
    Mixpanel.track('collapseWidgetEvent', {
      action: 'collapse Geographical widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Geographical View*',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
    Mixpanel.track('switch_widget_view', {
      action: 'widget view',
      widget_name: 'Geographical ',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
    });
  };

  const handleExpandClick = () => {
    setIsExpanded(true);
    setExpanded(selected.label);
    dispatch(showBackButtonAction(true));
    Mixpanel.track('expandWidgetEvent', {
      action: 'expand Geographical widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Geographical View*',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
    Mixpanel.track('switch_widget_view', {
      action: 'expanded view',
      widget_name: 'Geographical ',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
    });
  };

  useEffect(() => {
    if ((currentExpanded === '' && searchResult.flag) || currentExpanded === selected.label) {
      dispatch(getGeographicalStateDataRefreshAction());
      const request = {
        ...params,
        apiUrl: '/clinical-trials/v0/search/getInsightsByPhase',
        headers: queryString.stringify({
          ...params.headers,
          field_name: 'state',
        }),
      };
      dispatch(getGeographicalStateDataAction(request));
    }
  }, [isExpanded, JSON.stringify(searchResult)]);

  useEffect(() => {
    if (geoGraphicalCountryData.flag && !getDateRangeData.data[0]) {
      const tempStartYear = Number(geoGraphicalCountryData?.minYear);
      let tempEndYear = Number(geoGraphicalCountryData?.maxYear);
      const diff = tempEndYear - tempStartYear;
      if (diff % 2 !== 0) {
        tempEndYear += 1;
      }
      dispatch(dateRangeUpdateAction([tempStartYear, tempEndYear]));
    }

    if (geoGraphicalCountryData.flag) {
      const tempStartYear = Number(geoGraphicalCountryData?.minYear);
      const tempEndYear = Number(geoGraphicalCountryData?.maxYear);
      setCurrentSelectedDates([...[tempStartYear, tempEndYear]]);
    }
  }, [JSON.stringify(geoGraphicalCountryData)]);

  const callApiUponDateChange = (dates) => {
    if (currentExpanded === '' || currentExpanded === selected.label) {
      dispatch(getGeographicalDataRefreshAction());
      const request = {
        ...params,
        apiUrl: '/clinical-trials/v0/search/getInsightsByPhase',
        headers: queryString.stringify({
          ...params.headers,
          field_name: 'country',
          isExpanded,
        }),
      };
      const newDateRequest = [
        {
          value: getTimeStamp('start', dates),
          operator: 'GTE',
        },
        {
          value: getTimeStamp('end', dates),
          operator: 'LTE',
        },
      ];
      request.body.filters.study_start_date = newDateRequest;
      dispatch(getGeographicalDataAction(request));
    }
  };

  const callStateApiUponDateChange = (dates) => {
    dispatch(getGeographicalStateDataRefreshAction());
    const request = {
      ...params,
      apiUrl: '/clinical-trials/v0/search/getInsightsByPhase',
      headers: queryString.stringify({
        ...params.headers,
        field_name: 'state',
      }),
    };
    const newDateRequest = [
      {
        value: getTimeStamp('start', dates),
        operator: 'GTE',
      },
      {
        value: getTimeStamp('end', dates),
        operator: 'LTE',
      },
    ];
    request.body.filters.study_start_date = newDateRequest;
    dispatch(getGeographicalStateDataAction(request));
  };

  useEffect(() => {
    if (searchResult.flag || isExpanded) {
      dispatch(getGeographicalDataRefreshAction());
      const request = {
        ...params,
        apiUrl: '/clinical-trials/v0/search/getInsightsByPhase',
        headers: queryString.stringify({
          ...params.headers,
          field_name: 'country',
          isExpanded,
        }),
      };
      dispatch(getGeographicalDataAction(request));
    }
  }, [isExpanded, JSON.stringify(searchResult)]);

  const handleZoomOutClick = () => {
    if (conditionState) {
      setTriggerLabelTransition(true);
      setCurrentState({ ...{} });
      setCurrentZoomLevelMap([...[2, 1]]);
      setTimeout(() => {
        setTriggerLabelTransition(false);
      }, 500);
      Mixpanel.track('zoomOutEvent', {
        action: 'zoom out to states view from region view',
        search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
        widget_name: 'Geographical View*',
        terminal_name: 'Clinical Trials',
        query: JSON.parse(semanticQuery || query),
        page: 'Analytics Page',
        state_data: {
          state_name: currentState.properties.NAME,
          number_of_trials: handleTrial(currentState),
        },
      });
    } else if (conditionCountry) {
      setTriggerLabelTransition(true);
      setCurrentCountry({ ...{} });
      setSelectedCountry(null);
      setCurrentZoomLevelMap([...[1, 0]]);
      setTimeout(() => {
        setTriggerLabelTransition(false);
      }, 500);
      Mixpanel.track('zoomOutEvent', {
        action: 'zoom out to global view from states view',
        search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
        widget_name: 'Geographical View*',
        terminal_name: 'Clinical Trials',
        query: JSON.parse(semanticQuery || query),
        page: 'Analytics Page',
        country_data: {
          country_name: currentCountry.properties.admin,
          number_of_trials: handleTrial(currentCountry),
        },
      });
    }
  };

  const handleDateModify = (dates) => {
    callApiUponDateChange(dates);
    callStateApiUponDateChange(dates);
    setCurrentSelectedDates([...[dates]]);
    Mixpanel.track('dateRangeEvent', {
      action: 'date range updated',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Geographical View*',
      terminal_name: 'Clinical Trials',
      query: JSON.parse(semanticQuery || query),
      page: 'Analytics Page',
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  const handleWidthLabelHover = ({ text }) => {
    const { length } = text;
    if (length < 6) {
      return length * 40 + 30;
    }
    if (length >= 6 && length <= 10) {
      return length * 25 + 30;
    }
    return length * 10 + 60;
  };

  const handleWidthLabel = ({ text }) => {
    const { length } = text;
    if (length <= 5) {
      return length * 8;
    }
    if (length < 6) {
      return length * 5;
    }
    return length * 10;
  };

  function renderTooltip() {
    Mixpanel.track('hover_popup_event', {
      action: 'user hovered on geographical graph tooltip',
      label: tooltipData?.countryName,
      terminal_name: getTerminalName(location),
      page_name: 'Clinical Trials Analytics page',
      widget_name: 'Geographical view',
      search_type: location.search.includes('semantic') ? 'Semantic Search' : 'Power Search',
      view_type: 'Widget_view',
    });
    return (
      <div>
        <Tooltip className="tooltip-container-status" anchorSelect={`#${currentTooltipId}`} clickable isOpen={showTooltip} place="bottom" noArrow>
          <div ref={toolTip2Ref} className="endpoints-detail-container">
            <div className="blue-vertical-line-geo" />
            <div className="endpoints-detail-sub-container">
              <div className="geo-detail">
                <span className="geo-trial">{tooltipData.trialCount}</span>
                <span className="endpoint-type adjust-trials-pos">Trials</span>
                <span className="geo-country">{tooltipData.countryName}</span>
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
    );
  }

  const handleDateRange = () => yearRange.map((year) => <div key={year}>{year}</div>);

  const renderCountryNames = (isToolTip, labelData, isCountry) => labelData.map((subject) => (
    <foreignObject
      x={isToolTip ? subject.x - 50 : subject.x}
      y={isToolTip ? subject.y - 30 : subject.y}
      height={isToolTip ? '180' : '20'}
      width={isToolTip ? handleWidthLabelHover(subject) : handleWidthLabel(subject)}
      style={{
        opacity: triggerLabelTransition ? 0 : 1, transition: 'opacity 0.5s', borderRadius: '8px', boxShadow: '0px 14px 44px 0px #7e7ecb4d;',
      }}
    >
      <div className={`${isToolTip ? 'geo-label-active' : 'geo-label'}`} onMouseEnter={() => setHoveredLabelData({ ...subject, isCountry })} onMouseLeave={() => setHoveredLabelData({ ...{} })}>
        {isToolTip ? (
          <div className="endpoints-detail-container">
            <div className="blue-vertical-line-geo" />
            <div className="endpoints-detail-sub-container">
              <div className="geo-detail">
                <span className="geo-trial">{subject.trialCount}</span>
                <span className="geo-trial-text">Trials</span>
              </div>
              <div className="label-counts-container">
                <span>{subject.text}</span>
                <SvgLoader
                  onClick={() => (isCountry ? handleListViewClick('country', subject.feature) : handleListViewClick('state', subject.feature))}
                  className="pointer geo-label-arrow"
                  width={20}
                  height={14}
                  svgName="right-arrow-tooltip"
                  hoverIconName="right-arrow-tooltip-hover"
                />
              </div>
            </div>
          </div>
        )
          : (
            <span style={{ fontSize: isCountry ? getFonteSize(subject.area) : handleStateFontSize(subject.area), color: '#000' }}>
              {subject.text}
            </span>
          )}
      </div>
    </foreignObject>
  ));

  const renderExpandedView = () => (
    <div>
      {selectedCountry ? (
        <div aria-hidden onClick={() => handleZoomOutClick()} className="zoom-out pointer" onMouseEnter={() => setZoomOutBtnHover(true)} onMouseOut={() => setZoomOutBtnHover(false)} onBlur={() => setZoomOutBtnHover(false)}>
          <SvgLoader width={15.82} height={15.82} svgName={`${zoomOutBtnHover ? 'zoom-out-hover' : 'zoom-out'}`} hoverIconName="zoom-out-hover" />
          <span aria-hidden onClick={() => handleZoomOutClick()} onMouseEnter={() => setZoomOutBtnHover(true)} onMouseOut={() => setZoomOutBtnHover(false)} onBlur={() => setZoomOutBtnHover(false)} className="zoom-out-text">Zoom out</span>
        </div>
      ) : null}
      <div id="geo">
        <svg ref={d3ref} style={{ height: dimensions.height, width: dimensions.width }}>
          <g className="svg-container" width={dimensions.ctrWidth} height={dimensions.ctrHeight}>
            <g className="world-layer" />
            <g className="state-layer" />
          </g>
          <g>
            {labels.length ? renderCountryNames(false, labels, true) : null}
            {stateLabels.length && isCountryUSA() ? renderCountryNames(false, stateLabels, false) : null}
            {JSON.stringify(hoveredLabelData) !== '{}' ? renderCountryNames(true, [hoveredLabelData], hoveredLabelData.isCountry) : null}
          </g>
        </svg>
      </div>
      <div className="color-meter">
        <div className="geo-color" />
        <div className="color-scale-text">
          <div>0</div>
          <div>#Trials</div>
          {
              conditionCountry
                ? <div>{isCountryUSA() ? conditionState ? stateTrial[currentState?.properties?.NAME] || 0 : d3.max(valuesState) : countryTrial[currentCountry?.properties?.admin] || 0}</div>
                : <div>{combined.length ? modifeyNumber(combined[0]) : null}</div>
            }
        </div>
      </div>
      <div className="drag-expaned-icon" />
      {showTooltip ? renderTooltip() : null}
    </div>
  );

  if (isExpanded) {
    return (
      <div className="expanded-widget">
        <div className="Widget expanded-geographical">
          <div className="widget-header">
            <div className="widget-sub-header">
              <SvgLoader width={17} height={34} svgName="widget-header-icon" />
              <div className="header-text flex-row">
                <div>{selected.label}</div>
                <div className="partex-ai-icon ct-align-power-ai" />
              </div>
            </div>
            <div className="icons">
              <SvgLoader onClick={() => handleShrinkClick()} width={22} height={22} svgName="contract" hoverIconName="contract-hover" />
              {/* <SvgLoader width={24} height={24} svgName="widget-option" hoverIconName="widget-option-hover" /> */}
            </div>
          </div>
          <div className="slider-container">
            <div className="year-label">
              {handleDateRange()}
            </div>
            {(startYear && endYear) ? (
              <Slider
                onChange={handleDateModify}
                tooltip={{
                  open: false,
                }}
                dotActiveBorderColor="#5252FA"
                handleActiveColor="#5252FA"
                handleColor="#5252FA"
                min={startYear}
                max={endYear}
                step={step}
                className="date-slider"
                dots
                range
                defaultValue={[startYear, endYear]}
              />
            ) : null}
          </div>
          <Loader
            error={geoGraphicalCountryData.error || geoGraphicalStateData.error}
            loading={geoGraphicalCountryData.loading && geoGraphicalStateData.loading}
            noData={(geoGraphicalCountryData.data?.length === 0 && geoGraphicalCountryData.flag) || (geoGraphicalStateData.data?.length === 0 && geoGraphicalStateData.flag)}
            height="700px"
            className="white-loader"
            message="No records found"
          >
            {renderExpandedView()}
          </Loader>
        </div>
      </div>
    );
  }

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  return (
    <div className="Widget">
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text flex-row">
            <div>{selected.label}</div>
            <div className="partex-ai-icon ct-align-power-ai" />
          </div>
        </div>
        <div className="icons">
          {/* <SvgLoader width={18.7} height={18.7} svgName="info-icon" hoverIconName="info-icon-hover" /> */}
          <SvgLoader onClick={() => handleExpandClick()} width={18.7} height={18.7} svgName="expand" hoverIconName="expand-hover" />
          {/* <SvgLoader width={22} height={22} svgName="widget-option" hoverIconName="widget-option-hover" /> */}
        </div>
      </div>
      <Loader
        error={geoGraphicalCountryData.error || geoGraphicalStateData.error}
        loading={(geoGraphicalCountryData.loading && geoGraphicalStateData.loading) || searchResult.loading}
        noData={(geoGraphicalCountryData.data?.length === 0 && geoGraphicalCountryData.flag) || (geoGraphicalStateData.data?.length === 0 && geoGraphicalStateData.flag)}
        height="250px"
        className="white-loader"
        message="No records found"
      >
        <div id="geo">
          <svg ref={d3ref} style={{ height: dimensions.height, width: dimensions.width }}>
            <g className="svg-container" width={dimensions.ctrWidth} height={dimensions.ctrHeight}>
              <g className="world-layer" />
              <g className="state-layer" />
            </g>
          </svg>
        </div>
        <div className="color-meter">
          <div className="geo-color" />
          <div className="color-scale-text">
            <div>0</div>
            <div>#Trials</div>
            {
              conditionCountry
                ? <div>{isCountryUSA() ? d3.max(valuesState) : countryTrial[currentCountry?.properties?.admin]}</div>
                : <div>{combined.length ? modifeyNumber(combined[0]) : null}</div>
            }
          </div>
        </div>
      </Loader>
      {showTooltip ? renderTooltip() : null}
    </div>
  );
}

Geographical.propTypes = {
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
  setExpanded: PropTypes.func.isRequired,
  currentExpanded: PropTypes.string.isRequired,
};

export default Geographical;
