import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import Mixpanel from '../../../utils/mixpanel';
import SvgLoader from '../../components/SvgLoader';
import Panel from '../../components/Panel';
import { viewByOptions } from './data';
import { getTerminalName, trackOutSideEvents, getTerimalPage } from '../../../utils';
import FilterModal from '../FilterModal';

function AnalyticsNavOptions({ autoSuggestUrl }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');
  const filters = searchParams.get('filters');
  const getCollapsibleGraphState = useSelector(
    (state) => state.getCollapsibleGraphState,
  );
  const [showViewByFilters, setShowViewByFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);
  const viewByRef = useRef(null);

  useEffect(() => {
    if (!filters || JSON.parse(filters)?.length === 0) {
      setIsDefaultFilter(true);
    }
  }, []);

  useEffect(() => {
    trackOutSideEvents(viewByRef, () => setShowViewByFilters(false));
  }, []);

  const handleClick = () => {
    if (location.pathname === '/searchAndExplore/analytics') {
      if (semanticQuery?.length) {
        Mixpanel.track('list_view_icon_event', {
          action: 'List View click in nav bar',
          page: 'Analytics Page',
          terminal_name: 'Search and Explore',
          search_type: 'Semantic Search',
          query: JSON.parse(semanticQuery),
          filters: JSON.parse(filters),
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'Analytics Page',
          terminal_name: 'Search and Explore',
        });

        navigate(
          `/searchAndExplore/results?semanticquery=${encodeURIComponent(semanticQuery)}${filters ? `&filters=${encodeURIComponent(filters)}` : ''}${query ? `&query=${encodeURIComponent(query)}` : ''}`,
        );
        navigate(0);
      } else {
        Mixpanel.track('list_view_icon_event', {
          action: 'List View click in nav bar',
          page: 'Analytics Page',
          terminal_name: 'Search and Explore',
          search_type: 'Power Search',
          query: JSON.parse(query),
          filters: JSON.parse(filters),
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'Analytics Page',
          terminal_name: 'Search and Explore',
        });

        navigate(
          `/searchAndExplore/results?query=${encodeURIComponent(query)}${
            filters ? `&filters=${encodeURIComponent(filters)}` : ''
          }`,
        );
        navigate(0);
      }
    } else if (location.pathname === '/clinicalTrials/analyticsResults') {
      if (semanticQuery?.length) {
        Mixpanel.track('list_view_icon_event', {
          action: 'List View click in nav bar',
          page: 'Analytics Page',
          terminal_name: 'Clinical Trials',
          search_type: 'Sematic Search',
          query: JSON.parse(semanticQuery),
          filters: JSON.parse(filters),
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'Analytics Page',
          terminal_name: 'Clinical Trials',
        });

        navigate(
          `/clinicalTrials/searchResults?semanticquery=${encodeURIComponent(
            semanticQuery,
          )}${filters ? `&filters=${encodeURIComponent(filters)}` : ''}`,
        );
        navigate(0);
      } else {
        Mixpanel.track('list_view_icon_event', {
          action: 'List View click in nav bar',
          page: 'Analytics Page',
          terminal_name: 'clinical Trials',
          search_type: 'PoWer Search',
          query: JSON.parse(query),
          filters: JSON.parse(filters),
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'Analytics Page',
          terminal_name: 'Clinical Trials',
        });

        navigate(
          `/clinicalTrials/searchResults?query=${encodeURIComponent(query)}${
            filters ? `&filters=${encodeURIComponent(filters)}` : ''
          }`,
        );
        navigate(0);
      }
    } else if (location.pathname === '/intervention/analytics') {
      if (query?.length) {
        Mixpanel.track('list_view_icon_event', {
          action: 'List View click in nav bar',
          page: 'Analytics Page',
          terminal_name: 'Intervention',
          query: JSON.parse(query),
          filters: JSON.parse(filters),
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'Analytics Page',
          terminal_name: 'Intervention',
        });

        navigate(
          `/intervention/results?query=${encodeURIComponent(query)}${
            filters ? `&filters=${encodeURIComponent(filters)}` : ''
          }`,
        );
        navigate(0);
      }
    }
  };

  const showFiltersTrack = () => {
    setShowFilters(true);
    Mixpanel.track('filter_icon_event', {
      action: 'show filter modal',
      terminal_name: getTerminalName(location),
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
  };

  const renderViewOptions = () => viewByOptions.map((option) => (
    <div className="options filters-options" aria-hidden key={option.value}>
      {option.label}
    </div>
  ));

  const renderViewByOptions = () => (
    <Panel className="panel view-by-panel">
      <div className="filter-header">View by</div>
      {renderViewOptions()}
    </Panel>
  );

  const handleClose = () => {
    setShowFilters(false);
    Mixpanel.track('cross_event', {
      action: 'user clicked on cross button',
      page_name: getTerimalPage(location),
    });
  };

  return (
    <div className="card-view-options">
      <div className="view-by" ref={viewByRef}>
        {/* <SvgLoader className="option" width={22} height={22} svgName="add-analytics" /> */}
      </div>
      <div className="view-by" ref={viewByRef}>
        {/* <SvgLoader onClick={() => setShowViewByFilters(true)} className="option" width={22} height={22} svgName="filter" /> */}
        {showViewByFilters && renderViewByOptions()}
      </div>
      {!getCollapsibleGraphState.isExpanded ? (
        <AnimatePresence>
          <motion.div
            aria-hidden
            className="ct-filters"
            onClick={() => showFiltersTrack()}
          >
            <SvgLoader
              className="option"
              width={20}
              height={20}
              svgName={`${
                isDefaultFilter ? 'filter-icon' : 'filter-checked-icon'
              }`}
            />
            <span className="filter-text-name">Filter</span>
          </motion.div>
        </AnimatePresence>
      ) : null}
      {showFilters ? (
        <FilterModal
          isDefault={isDefaultFilter}
          setdefault={setIsDefaultFilter}
          open={showFilters}
          handleClose={() => handleClose()}
          autoSuggestUrl={autoSuggestUrl}
        />
      ) : null}
      <div className="toggle-result-analytics">
        <div className="flex-row current-view">
          <SvgLoader width={20} height={20} svgName="anaytics-inactive-icon" />
          <span className="filter-text-name">Analytics View</span>
        </div>
        <div aria-hidden onClick={() => handleClick()} className="flex-row">
          <SvgLoader width={20} height={20} svgName="list-view-active" />
          <span className="filter-text-name">List View</span>
        </div>
      </div>
    </div>
  );
}

AnalyticsNavOptions.propTypes = {
  autoSuggestUrl: PropTypes.string.isRequired,
};

export default AnalyticsNavOptions;
