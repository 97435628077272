import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { addUserConcentAction } from './logic';
import { getCookie, setCookie } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';

const declarationsMap = [
  {
    label: '<div>I provide my concent for Ontosight<sup>®</sup> team to send me emails for features, newsletter and additional information </div>',
    key: 'key 1',
    type: 'required',
  },
  {
    label: '<div>I agree to participate in the 2 minute short survey at the end of the free trial.</div>',
    key: 'key 2',
    type: 'required',
  },
  {
    label: '<div>I would like to receive Ontosight<sup>®</sup> newsletters (Optional)',
    key: 'concent newsletter',
    type: 'optional',
  },
];

function Declarations({ open, setOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selected, setSelected] = useState([]);
  const [dissableSubmit, setDissableSubmit] = useState(true);

  const userConcentStatus = useSelector((state) => state.userConcentStatus);

  useEffect(() => {
    if (userConcentStatus.flag) {
      if (userConcentStatus.data.message === 'Consent updated successfully') {
        setOpen(false);
        setCookie('individual', 'freshUser');
        Mixpanel.track('persona_event', {
          action: 'user persona building process has began',
          data: getCookie('userEmail'),
        });
        navigate('/persona');
      }
    }
  }, [JSON.stringify(userConcentStatus)]);

  useEffect(() => {
    let flag = false;
    declarationsMap.forEach((item) => {
      if (item.type === 'required') {
        if (!selected.includes(item.key)) {
          flag = true;
        }
      }
    });
    setDissableSubmit(flag);
  }, [selected]);

  const handleOptionClick = (key) => {
    if (selected.includes(key)) {
      const tempSelected = selected.filter((val) => val !== key);
      setSelected([...tempSelected]);
    } else {
      setSelected([...selected, key]);
    }
  };

  const handleBtnclick = () => {
    if (!dissableSubmit) {
      dispatch(addUserConcentAction({
        headers: queryString.stringify({
          email: getCookie('userEmail'),
          consent: selected.includes('concent newsletter'),
        }),
      }));
    }
  };

  const renderOptions = () => declarationsMap.map((item) => (
    <div key={item.key} className="flex-row concent-modal-content-list">
      <input checked={selected.includes(item.key)} onChange={() => handleOptionClick(item.key)} className="checkbox-concent pointer" type="checkbox" />
      {parse(item.label)}
    </div>
  ));

  return (
    <Modal className="concent-modal-ctr" footer={false} open={open} closeIcon={false} width="480px">
      <div className="concent-modal">
        <div className="concent-modal-head">
          <div className="concent-modal-head-title">Declarations</div>
        </div>
        <div className="concent-modal-content">
          {renderOptions()}
        </div>
        <div className="concent-modal-footer">
          <button onClick={(() => handleBtnclick())} type="submit" className={`concent-btn${dissableSubmit ? '-dissable dissabled' : ''}`}>Submit</button>
        </div>
      </div>
    </Modal>
  );
}

export default Declarations;
