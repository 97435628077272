import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import QueryString from 'qs';
import { Tooltip } from 'react-tooltip';
import Mixpanel from '../../../utils/mixpanel';
import { trackOutSideEvents, ucFirst, windowHrefToNavigate } from '../../../utils';
import SvgLoader from '../../../common/components/SvgLoader';
import Loader from '../../../common/components/Loader';
import { getTrendingTopicsDataAction } from './logic';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function TrendingTopics({
  params, selected, currentExpanded, setExpanded,
}) {
  const dispatch = useDispatch();
  const toolTipRef = useRef(null);
  const trendingTopics = useSelector((state) => state.trendingTopics);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');
  const filters = searchParams.get('filters');
  const baseWidth = window.innerWidth - 200;
  const typeKeys = ['publications', 'clinical_trials', 'congresses', 'press_releases', 'patents', 'guidelines', 'grants'];
  const currentId = useRef(null);
  const [tooltipData, setToolTipData] = useState({});
  const [toolTipId, setToolTipId] = useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentSelected, setCurrentSelected] = useState([]);

  useEffect(() => {
    const request = {
      ...params,
      headers: QueryString.stringify({
        size: isExpanded ? 20 : 8,
        semantic_search: semanticQuery?.length ? JSON.parse(semanticQuery)[0]?.currentFilter[0] : undefined,
      }),
    };
    debounceCall(() => {
      dispatch(getTrendingTopicsDataAction(request));
    }, 40);
  }, [isExpanded, currentExpanded]);

  useEffect(() => {
    trackOutSideEvents(toolTipRef, () => setShowTooltip(false));
  }, []);

  const renderClassName = (value) => {
    const formatString = value?.replace(/[\s~`!@#$%^&*(){}[\];:"'<,.>?/\\|_+=-]/g, '');
    const valArr = formatString?.split(' ');
    let temp = '';
    valArr?.forEach((item) => {
      if (item !== '/') {
        temp += item;
      }
    });
    return temp;
  };

  const style = {
    backgroundColor: '#1F1F1F',
    borderRadius: '8px',
  };

  const generateTimestamps = () => {
    const today = new Date();
    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(today.getFullYear() - 5);
    const todayTimestamp = Math.floor(today.getTime() / 1000);
    const twoYearsAgoTimestamp = Math.floor(twoYearsAgo.getTime() / 1000);
    return [twoYearsAgoTimestamp, todayTimestamp];
  };

  const draw2 = (data) => {
    const filteredData = data.filter((d) => d.count > 0);
    const dimensions = {
      width: isExpanded ? baseWidth : baseWidth / 2,
      height: isExpanded ? (filteredData.length * 30) : 200,
      margin: {
        top: 10,
        left: 65,
        right: 60,
        bottom: isExpanded ? 50 : 20,
      },
    };
    dimensions.ctrWidth = dimensions.width - dimensions.margin.left - dimensions.margin.right + 100;
    dimensions.ctrHeight = dimensions.height - dimensions.margin.top - dimensions.margin.bottom;

    const stack = d3.stack()
      .keys(typeKeys)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);
    const stackedData = stack(filteredData);

    const color = d3.scaleOrdinal()
      .domain(typeKeys)
      .range(['#00BF6E', '#517CB6', '#FABE01', '#3FC8D1', '#FE8B4E', '#7D5F90', '#D25F91', '#C7B879']);

    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(d3.extent(filteredData, (d) => d?.count))])
      .range([0, dimensions.ctrWidth / 2 + 10]);

    const yScale = d3
      .scaleBand()
      .domain(filteredData?.map((d) => (d?.key)))
      .range([0, isExpanded ? (dimensions.ctrHeight) + 150 : dimensions.ctrHeight + 100])
      .padding(0.5);

    const mouseover = (d) => {
      currentId.current = d.target;
      d3.select(d.target)
        .transition()
        .duration(300)
        .attr('fill', 'lightgrey');
    };
    const mousemove = (event, d) => {
      d3.select(d.target)
        .attr('fill', 'lightgrey');
    };
    const mouseleave = (d) => {
      d3.select(d.target)
        .transition()
        .duration(300)
        .attr('fill', 'transparent');
    };

    const svg = d3
      .select(`${isExpanded ? '#topics-expanded' : '#topics-bar'}`)
      .attr('id', `${isExpanded ? 'topics-bar-expanded' : 'topicsBargraphsvg'}`)
      .append('svg')
      .attr('width', dimensions.width + 60)
      .attr('height', dimensions.height + 80);

    const container = svg.append('g')
      .attr('transform', `translate(0,${dimensions.margin.top})`);

    const yAxis = d3.axisLeft(yScale);
    const yAxisGroup = container.append('g').call(yAxis).attr('fill', 'transparent');
    yAxisGroup
      .selectAll('.bar')
      .data(filteredData)
      .enter()
      .append('rect')
      .attr('class', (d, i) => `parent-bar${i}`)
      .attr('y', (d) => (yScale(d.key) - 25))
      .attr('width', dimensions.width + 50)
      .attr('height', '35px')
      .attr('fill', 'transparent')
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);

    const ctr = svg
      .append('g')
      .attr('transform', `translate(${dimensions.width / 2}, -${dimensions.margin.top})`);

    ctr
      .append('g')
      .attr('transform', `translate(0, ${dimensions.ctrHeight})`);

    const stacked = ctr.append('g');

    if (isExpanded) {
      stacked.selectAll('g')
        .data(stackedData)
        .join('g')
        .style('fill', (d) => color(d.key))
        .on('mouseover', (event, datum) => {
          setToolTipData({
            key: datum.key,
            value: datum[(datum.index)]?.data[datum.key],
          });
        })
        .selectAll('rect')
        .data((d) => d)
        .join('rect')
        .attr('x', (d) => xScale(d[0]))
        .attr('y', (d) => yScale(d.data.key))
        .attr('width', (d) => xScale(d[1]) - xScale(d[0]))
        .attr('id', (d) => `stack${renderClassName(`${d.filteredData?.key}${d[1]}`)}`)
        .attr('class', (d, i) => i)
        .attr('height', '25px')
        .on('mouseover', (event, datum) => {
          d3.select(currentId.current)
            .transition()
            .duration(300)
            .attr('fill', 'lightgrey');
          setCurrentSelected(datum);
          setShowTooltip(true);
          setToolTipId(`stack${renderClassName(`${datum.filteredData?.key}${datum[1]}`)}`);
        })
        .on('mouseout', () => {
          d3.select(currentId.current)
            .transition()
            .duration(300)
            .attr('fill', 'transparent');
          currentId.current = null;
        });
    } else {
      ctr
        .selectAll('.bar')
        .data(filteredData)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('y', (d) => (yScale(d.key)))
        .attr('width', (d) => xScale(d.count) - xScale(0))
        .attr('height', isExpanded ? '25px' : '20px')
        .attr('fill', '#5252FA');

      ctr
        .selectAll('.bar')
        .attr('width', 0)
        .transition()
        .duration(1500)
        .attr('width', (d) => (xScale(d.count)));
    }

    const labelGroup = ctr.append('g');

    const ylabels = ctr.append('g');

    const convert = (value) => {
      let temp = value;
      if (value >= 1000000) {
        temp = `${value / 1000000}M`;
      } else if (value >= 1000) {
        temp = `${value / 1000}k`;
      }
      return temp;
    };

    ylabels
      .selectAll('text')
      .data(filteredData)
      .join('foreignObject')
      .attr('class', 'label')
      .attr('x', -((dimensions.width / 2) - 20))
      .attr('y', (d) => (yScale(d.key)))
      .style('font-size', '16px')
      .attr('width', (dimensions.width / 2 - 20))
      .attr('height', '22px')
      .attr('cursor', 'pointer')
      .on('click', (event, datum) => {
        const tempQuery = [{
          currentFilter: [datum.key],
          currentKeys: [datum.key],
          field_name: 'ot_trending_topics',
          label: 'ot_trending_topics',
          name: 'ot_trending_topics',
          value: 'ot_trending_topics',
        }];

        tempQuery.push({
          display_name: 'Date Range',
          label: 'Date Range',
          name: 'study_start_date',
          specific_asset_class: false,
          type: 'date-range',
          value: 'study_start_date',
          currentFilter: generateTimestamps(),
          currentKeys: generateTimestamps(),
        });

        Mixpanel.track('widget_deepdive_event', {
          action: 'Trending topic name click',
          search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
          query: JSON.parse(semanticQuery) || JSON.parse(query),
          widget_name: 'Trending Topics',
          page: 'Analytics Page',
          terminal_name: 'Search and Explore',
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'Analytics Page',
          terminal_name: 'Search and Explore',
        });

        if (semanticQuery) {
          windowHrefToNavigate(`/searchAndExplore/results?semanticquery=${semanticQuery}&deepdivequery=${JSON.stringify(tempQuery)}${filters ? `&filters=${filters}` : ''}${'&from=\'Trending Topics\''}`);
        } else {
          windowHrefToNavigate(`/searchAndExplore/results?query=${query}&deepdivequery=${JSON.stringify(tempQuery)}${filters ? `&filters=${filters}` : ''}${'&from=\'Trending Topics\''}`);
        }
      })
      .on('mouseover', () => {
        d3.select(currentId.current)
          .transition()
          .duration(300)
          .attr('fill', 'lightgrey');
      })
      .on('mouseleave', () => {
        d3.select(currentId.current)
          .transition()
          .duration(300)
          .attr('fill', 'transparent');
      })
      .html((d, i) => `<div class="topic-name-value" id='topic-name-${i}'><div class="icon-svg"></div><div class="topics-text" title="${d.key}">${d.key}</div></div>`);

    labelGroup
      .selectAll('text')
      .data(filteredData)
      .join('text')
      .attr('x', (d) => (xScale(d.count) + 5))
      .attr('y', (d) => (yScale(d.key) + 15))
      .style('font-size', '14px')
      .attr('fill', 'black')
      .text((d) => (convert(d.count)));
  };

  useEffect(() => {
    if (trendingTopics?.flag) {
      const data = [];
      if (isExpanded) {
        trendingTopics.data.forEach((item) => {
          const temp = {
            key: item.key,
            count: item.count,
            distribution: item.distribution,
          };
          item.distribution.forEach((ele) => {
            temp[ele.assetClass] = ele.count;
          });
          data.push({
            ...temp,
          });
        });
        draw2(data);
      } else {
        draw2(trendingTopics.data);
      }
    }
  }, [JSON.stringify(trendingTopics), isExpanded, currentId]);

  const expandGraph = () => {
    Mixpanel.track('explore_trending_topics_expand_event', {
      action: 'expand trending topics widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Trending Topics',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
    Mixpanel.track('switch_widget_view', {
      action: 'Expanded view',
      widget_name: 'Trending Topics',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
    });
    setExpanded(selected.label);
    setIsExpanded(true);
  };

  const handleShrinkClick = () => {
    Mixpanel.track('explore_trending_topics_collapse_event', {
      action: 'collapse trrending topics widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Trending Topics',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });

    Mixpanel.track('switch_widget_view', {
      action: 'widget view',
      widget_name: 'Trending Topics',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
    });

    setIsExpanded(false);
    setExpanded('');
  };

  const handleStackClick = (tooltipdata) => {
    const tempquery = [{
      currentFilter: [currentSelected?.data?.key],
      currentKeys: [currentSelected?.data?.key],
      field_name: 'ot_trending_topics',
      label: 'ot_trending_topics',
      name: 'ot_trending_topics',
      value: 'ot_trending_topics',

    }];
    tempquery.push({
      currentFilter: [tooltipdata.key],
      currentKeys: [tooltipdata.key],
      field_name: 'asset_classes',
      label: 'asset_classes',
      name: 'asset_classes',
      value: 'asset_classes',
    });

    tempquery.push({
      display_name: 'Date Range',
      label: 'Date Range',
      name: 'study_start_date',
      specific_asset_class: false,
      type: 'date-range',
      value: 'study_start_date',
      currentFilter: generateTimestamps(),
      currentKeys: generateTimestamps(),
    });

    Mixpanel.track('widget_deepdive_event', {
      action: `Trending topic tooltip click for ${tooltipData.key} asset class`,
      search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      query: JSON.parse(semanticQuery) || JSON.parse(query),
      widget_name: 'Trending Topics',
      page: 'Analytics Page',
      terminal_name: 'Search and Explore',
    });

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Search and Explore',
    });

    if (semanticQuery) {
      windowHrefToNavigate(`/searchAndExplore/results?semanticquery=${semanticQuery}&deepdivequery=${JSON.stringify(tempquery)}${filters ? `&filters=${filters}` : ''}${'&from=\'Trending Topics\''}`);
    } else {
      windowHrefToNavigate(`/searchAndExplore/results?query=${query}&deepdivequery=${JSON.stringify(tempquery)}${filters ? `&filters=${filters}` : ''}${'&from=\'Trending Topics\''}`);
    }
  };

  const renderTooltip = () => (
    <div>
      <Tooltip className="trending-topics-tooltip" style={style} anchorSelect={`#${toolTipId}`} clickable isOpen={showTooltip}>
        <div ref={toolTipRef} className="topics-tooltip-detail-container">
          <div className="blue-vertical-line" />
          <div className="topics-sub-container">
            <div className="tooltip-data-value">{tooltipData?.key ? currentSelected?.data[tooltipData?.key] : null}</div>
            <div className="tooltip-data-key">
              <div>{tooltipData?.key ? ucFirst(tooltipData.key) : null}</div>
              <div className="tooltip-arrow">
                <SvgLoader className="pointer" onClick={() => handleStackClick(tooltipData)} width={20} height={14} svgName="right-arrow-tooltip" hoverIconName="right-arrow-tooltip-hover" />
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );

  const renderExpandedView = () => (
    <div className="Widget topics expanded-topics">
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">
            {selected.label}
            <sup className="partex-ai-icon">* Powered by Gen AI</sup>
          </div>
        </div>
        <div className="icons">
          <SvgLoader onClick={() => handleShrinkClick()} width={22} height={22} svgName="contract" hoverIconName="contract-hover" />
        </div>
      </div>
      <div id="topics-expanded" />
      {renderTooltip()}
      <div className="drag-expaned-icon" />
    </div>
  );
  if (isExpanded) {
    return (
      <div className="expanded-widget" onMouseLeave={() => setShowTooltip(false)}>
        <Loader
          error={trendingTopics.error}
          loading={!trendingTopics.flag}
          noData={!trendingTopics?.data?.length || trendingTopics.data[0]?.count === 0}
          height="400px"
          width="calc(100vw - 100px)"
          className="white-loader"
          message="No records found"
        >
          {renderExpandedView()}
        </Loader>
      </div>
    );
  }

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  return (
    <div className={isExpanded ? 'expanded-widget' : 'Widget'}>
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">
            {selected.label}
            <sup className="partex-ai-icon">* Powered by Gen AI</sup>
          </div>
        </div>
        <div className="icons">
          <SvgLoader width={18.7} height={18.7} svgName="expand" hoverIconName="expand-hover" onClick={() => { expandGraph(); }} />
        </div>
      </div>

      <Loader
        error={trendingTopics.error}
        loading={trendingTopics.loading}
        noData={!trendingTopics?.data?.length || trendingTopics?.data[0]?.count === 0}
        height="250px"
        className="white-loader"
        message="No records found"
      >
        <div id="topics-bar" />
      </Loader>
    </div>
  );
}

TrendingTopics.propTypes = {
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    graphData: PropTypes.PropTypes.shape([
      {
        value: PropTypes.number.isRequired,
      },
    ]),
  }).isRequired,
  currentExpanded: PropTypes.string.isRequired,
  setExpanded: PropTypes.func.isRequired,
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default TrendingTopics;
