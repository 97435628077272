import React from 'react';
import Mixpanel from '../../../utils/mixpanel';

function IntroSection({ setTryOpen }) {
  const TryOntosightButtonClick = () => {
    setTryOpen(true);
    Mixpanel.track('try_ontosight_free_event', {
      action: 'Try Ontosight button click to open try ontosigt modal',
      page: 'Landing Page',
      misc: 'Middle Page',
    });
  };

  return (
    <div className="intro-section-ctr">
      <div className="intro-text">AI - Powered</div>
      <div className="intro-text">Intelligence for</div>
      <div className="intro-text">Drug Development</div>

      <div className="sub-intro-text adjust-margin-intro-text">Transform your biomedical research with Ontosight® Terminal. Harness AI-powered insights tailored for clinical</div>
      <div className="sub-intro-text">trials and drug development, ensuring informed decisions without bias.</div>

      <div className="header-try-ctr">
        <div aria-hidden className="header-try pointer" onClick={() => TryOntosightButtonClick()}>
          <span className="try-text">Try Ontosight</span>
          <sup className="try-text">®</sup>
          &nbsp;
          <span className="try-text"> For Free</span>
        </div>
      </div>
      <div className="intro-img" />
    </div>
  );
}

export default IntroSection;
