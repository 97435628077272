/* eslint-disable no-debugger */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'antd';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import CTDeepDivePage from '../../pages/CTDeepDivePage';
import PreviewSectionCard from '../../../searchAndExplore/components/PreviewSectionCard';
import SvgLoader from '../../../common/components/SvgLoader';
import { getTerminalName, wordLimit } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';
import { setBackButtonStatusAction } from '../CTdeepDive/logic';
import { setDocIdAction } from '../../../common/container/TimeLine/logic';
import { getSummaryDataAction, getSummaryDataRefreshAction } from './logic';
import Loader from '../../../common/components/Loader';
import TextComponent from '../../../common/components/TextComponent';
import { getExploreSearchSummaryAction } from '../../../searchAndExplore/container/ExploreList/logic';
import TimeLine from '../../../common/container/TimeLine';

function CardDetail({ data, setDrawerWidth, from }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const deepdivepage = searchParams.get('deepdivepage');

  const [showDeepdive, setShowDeepdive] = useState(false);
  const [previewCardData, setPreviewCardData] = useState({});
  const [trialListData, setTrialListData] = useState({});
  const [readMore, setReadMore] = useState(false);
  const [readMorePOS, setReadMorePOS] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');

  const currentTab = useSelector((state) => state.currentTab.currentTab);
  const summaryData = useSelector((state) => state.summaryData);
  const getExploreSearchSummary = useSelector((state) => state.getExploreSearchSummary);

  const isCT = location.pathname.includes('/clinicalTrials');

  useEffect(() => {
    if (data?.doc_id) {
      dispatch(getSummaryDataRefreshAction());
      dispatch(getSummaryDataAction({
        apiUrl: `/clinical-trials/v0/search/clinical_trials/resource/${data.doc_id}`,
        headers: queryString.stringify({
          terminal_name: 'clinical_trials',
          id: data.doc_id,
          view: 'preView',
        }),
      }));

      if (isCT) {
        const url = `/explore/v0/search/clinical_trials/resource/${data.doc_id}`;
        dispatch(getExploreSearchSummaryAction({
          params: {
            url,
            headers: queryString.stringify({
              asset_class: 'clinical_trials',
              view: 'preView',
            }),
          },
        }));
      }
    }
    Mixpanel.track('preview_record_event', {
      action: 'Preview record',
      page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
      terminal_name: 'Clinical Trials',
      id: data?.trial_id,
      asset_class: 'clinical_trials',
    });
  }, [data.doc_id]);

  useEffect(() => {
    setRedirectTo(currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (deepdivepage) {
      setPreviewCardData(JSON.parse(deepdivepage));
      setShowDeepdive(true);
    }
  }, [deepdivepage]);

  useEffect(() => {
    if (data && !deepdivepage) {
      setPreviewCardData(data);
      setTrialListData(data);
      setReadMore(false);
    }
  }, [data]);

  const redirectToDeepdive = (option) => {
    dispatch(setBackButtonStatusAction(true));
    dispatch(setDocIdAction({
      docId: data.doc_id,
      trialId: data.trial_id,
    }));
    setRedirectTo(option);
    setPreviewCardData(data);
    setShowDeepdive(true);
    Mixpanel.track('expanded_view_icon_event', {
      action: 'summary expanded view click',
      terminal_name: getTerminalName(location),
      page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
      preview_segment: option,
    });
    Mixpanel.track('view_details_event', {
      action: 'view details click',
      terminal_name: getTerminalName(location),
      page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
      misc: option,
    });
  };

  const checkPOS = summaryData.flag && !summaryData.error;

  let POSmetric = '';
  let POSduration = '';
  let KeyConcepts = '';

  if (checkPOS) {
    POSmetric = summaryData.data?.primary_observations[0]?.metric || '';
    POSduration = summaryData.data?.primary_observations[0]?.duration.length !== 0 ? `(${summaryData.data?.primary_observations[0]?.duration})` : '';
  }

  const POSText = checkPOS ? `${POSmetric} ${POSduration}` : null;

  if (getExploreSearchSummary.flag) {
    let tempPOSMetric = '';
    const { biological_concepts } = getExploreSearchSummary.data.data;
    biological_concepts.forEach((item, index) => {
      if (index === biological_concepts.length - 1) {
        tempPOSMetric += `${item}.`;
      } else {
        tempPOSMetric += `${item}, `;
      }
    });

    KeyConcepts = tempPOSMetric;
  }

  const handlePOSText = () => {
    if (checkPOS) {
      if (readMorePOS) {
        return POSText;
      }
      return wordLimit(POSText, 100, true);
    }
    return null;
  };

  const handleReadMore = () => {
    setReadMore(!readMore);
    Mixpanel.track('summary_read_event', {
      action: !readMore ? 'Read More' : 'Read Less',
      terminal_name: 'Clinical Trials',
      page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
    });
  };

  const deepDiveIconClickTimeline = () => {
    redirectToDeepdive('timeline');
    Mixpanel.track('expanded_view_icon_event', {
      action: 'deep dive icon click CT Timeline',
      terminal_name: getTerminalName(location),
      section_name: 'Clinical Development Timeline',
      page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
    });
  };

  const handleReadMorePOS = () => {
    setReadMorePOS(!readMorePOS);
    Mixpanel.track('summary_read_event', {
      action: !readMorePOS ? 'Read More' : 'Read Less',
      terminal_name: getTerminalName(location),
      page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
    });
  };

  const loadingCondition = (summaryData.loading && !summaryData.flag) || (getExploreSearchSummary.loading && !getExploreSearchSummary.flag);
  const nodataCondition = summaryData.data?.summary === '' || getExploreSearchSummary.data?.data?.biological_concepts;

  const renderInfoMessage = () => {
    Mixpanel.track('hover_event', {
      action: 'hover info icon CT Timeline',
      terminal_name: getTerminalName(location),
      page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
      section_name: 'Clinical Development Timeline',
      view_type: 'Collapsible View',
      Label: 'Info icon hover',
    });
    return (
      <div className="text-black bg-white w-96 timeline-info-text">
        Visual representation of the clinical trial journey, highlighting interconnected trials and key research milestones.
      </div>
    );
  };

  return (
    <div className="right-side-info-container">
      <Loader loading={loadingCondition} error={summaryData.error} height={420} noDate={nodataCondition}>
        <div className="side-bar-info">
          <div className="summary-container">
            <div className="title-component">
              <span className="title-name flex-row">
                Summary
                <div className="trials-text">
                  Trials ID:
                  {' '}
                  {summaryData.data.trial_id}
                </div>
                <div className="partex-ai-icon ct-align-power-ai" />
              </span>
              <div
                role="presentation"
                className="deep-dive-view-icon"
                onClick={() => redirectToDeepdive('about')}
              >
                <SvgLoader width={32} height={32} svgName="deep-dive-view" />
              </div>
            </div>
            <div className="summry-wrapper">
              <div className="summary-text">
                {readMore ? summaryData.data?.summary : <TextComponent text={wordLimit(summaryData.data?.summary, 200, true)} />}
                <span className="read-more-text" role="presentation" onClick={() => handleReadMore()}>{summaryData.data?.summary?.length > 200 ? readMore ? '  read less' : 'read more' : null}</span>
              </div>
              {(summaryData.data?.primary_observations?.length && summaryData.data.primary_observations[0].metric !== '')
                ? (
                  <div>
                    <div>
                      {
                        POSduration.length !== 0
                          ? (
                            <div>
                              <span className="POS-pom">Primary Outcome Measure:</span>
                              <span className="POS-pom-text">
                                {' '}
                                {checkPOS ? <span>{handlePOSText()}</span> : null}
                                <span className="read-more-text" role="presentation" onClick={() => handleReadMorePOS()}>{checkPOS ? POSText?.length > 100 ? readMorePOS ? '  read less' : '  read more' : null : null}</span>
                              </span>
                            </div>
                          )
                          : null
                      }
                    </div>
                    {KeyConcepts?.length ? (
                      <div className="POS-key-concepts">
                        <span className="POS-key-concepts-text">Key Concepts:</span>
                        {' '}
                        <span className="POS-pom-text">{KeyConcepts}</span>
                      </div>
                    ) : null}

                  </div>
                ) : null}
            </div>
          </div>
          <div className="dash-contanier">
            <div className="dash" />
          </div>
          <div className="summary-container timeline-height-preview">
            <div className="title-component">
              <span className="title-name flex">
                Clinical Development Timeline
                <div className="ml-2">
                  <Popover content={renderInfoMessage} arrow={false}>
                    <SvgLoader width={22} height={22} svgName="info-icon" hoverIconName="info-icon-hover" pointer />
                  </Popover>
                </div>
              </span>
              <div
                role="presentation"
                className="deep-dive-view-icon flex items-center"
                onClick={() => deepDiveIconClickTimeline()}
              >
                <SvgLoader width={32} height={32} svgName="deep-dive-view" />
              </div>
            </div>
            <div>
              {data.trial_id && <TimeLine trialId={data.trial_id} setTimeLine={() => redirectToDeepdive('timeline')} />}
            </div>
          </div>
          <div className="dash-contanier">
            <div className="dash" />
          </div>
          <div className="summary-container">
            <div className="title-component">
              <span className="title-name">Associated Documents</span>
              <div
                role="presentation"
                className="deep-dive-view-icon"
                onClick={() => redirectToDeepdive('associated_documents')}
              >
                <SvgLoader width={32} height={32} svgName="deep-dive-view" />
              </div>
            </div>
            <div>
              <PreviewSectionCard
                trialData={trialListData}
                setShowDeepdive={setShowDeepdive}
                showDataFor={setPreviewCardData}
                showDeepdive={showDeepdive}
                from={from}
              />
            </div>
          </div>
        </div>
        <div className="buttons-deep-dive">
          <a
            className="visit-source-button"
            target="_blank"
            href={data?.data_source_link}
            rel="noreferrer noopener"
            onClick={() => {
              Mixpanel.track('visit_source_event', {
                terminal_name: 'Clinical Trials',
                page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
                misc: 'Preview Section',
              });
            }}
          >

            Visit Source
            <SvgLoader width={22} height={22} svgName="visit-source" />
          </a>
          <Button
            className="read-more-btn"
            onClick={() => {
              setDrawerWidth(2000);
              setPreviewCardData(data);
              setShowDeepdive(true);
              setRedirectTo('');
              dispatch(setBackButtonStatusAction(true));
              dispatch(setDocIdAction({
                docId: data.doc_id,
                trialId: data.trial_id,
              }));
              Mixpanel.track('view_details_event', {
                action: 'view details click',
                terminal_name: getTerminalName(location),
                page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
                misc: 'View details',
              });
              Mixpanel.track('expanded_view_icon_event', {
                action: 'view details click',
                terminal_name: getTerminalName(location),
                page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
                preview_segment: 'View details',
              });
            }}
          >
            View Details
          </Button>
        </div>
      </Loader>
      {showDeepdive ? <CTDeepDivePage setShowDeeopdive={setShowDeepdive} showDataFor={previewCardData} setDrawerWidth={setDrawerWidth} setPreviewCardData={setPreviewCardData} from={from} redirectTo={redirectTo} /> : null}
    </div>
  );
}

CardDetail.propTypes = {
  data: PropTypes.PropTypes.shape({
    laymen_title: PropTypes.string.isRequired,
  }).isRequired,
}.isRequired;

export default CardDetail;
