import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QueryString from 'qs';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../../common/components/Loader';
import SvgLoader from '../../../common/components/SvgLoader';
import { windowHrefToNavigate, wordLimit } from '../../../utils';
import { getPatentsDataAction } from './logic';
import Mixpanel from '../../../utils/mixpanel';

function PatentsList({
  params, setExpanded, selected, currentExpanded,
}) {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const dispatch = useDispatch();
  const patentsData = useSelector((state) => state.patentsData);
  const [selectedTopic, setSelectedTopic] = useState('indication');
  const [isExpanded, setIsExpanded] = useState(false);

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
    Mixpanel.track('patentsTabChange', {
      action: 'Patents topic change resulting in table data change',
      view: 'Intervention Analytics page with search query',
      query: JSON.parse(query),
      selectedTopic: topic,
    });
  };

  useEffect(() => {
    const request = {
      ...params,
      headers: QueryString.stringify({
        size: isExpanded ? 15 : 5,
        association_field: selectedTopic,
      }),
    };
    dispatch(getPatentsDataAction(request));
  }, [isExpanded, currentExpanded, selectedTopic]);

  const handleRowClick = (rowId, rowName) => {
    const q = JSON.parse(query);

    const patentsQuery = [
      {
        ...q[0],
        assetClass: 'patents',
      },
      {
        name: 'all',
        label: 'all',
        field: 'all',
        value: 'all',
        currentFilter: [rowName],
        currentKeys: [rowId],
        assetClass: 'patents',
      },
      {
        name: 'asset_classes',
        label: 'asset_classes',
        field: 'asset_classes',
        value: 'asset_classes',
        type: 'autosuggest-multi',
        currentFilter: ['patents'],
        currentKeys: ['patents'],
        assetClass: 'patents',
      },
    ];

    Mixpanel.track('patentsRowClick', {
      action: 'Patents row click to patents deep dive view',
      view: 'Intervention Analytics page with search query',
      query: JSON.parse(query),
      rowId,
      rowName,
    });

    Mixpanel.track('widget_deepdive_event', {
      terminal_name: 'Intervention',
      page_name: 'Analytics Page',
      widget_name: 'Patents List',
      view_type: isExpanded ? 'Expanded view' : 'Widget view',
      entity_class: selectedTopic,
      id: rowId,
      action: 'Patents row click to patents deep dive view',
    });

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Intervention',
    });

    windowHrefToNavigate(
      `/intervention/results?query=${encodeURIComponent(query)}${`&patentsQuery=${JSON.stringify(
        patentsQuery,
      )}`}${'&from=\'Patents List\''}`,
    );
  };

  const handleExpandClick = () => {
    setIsExpanded(true);
    setExpanded(selected.label);
    window.scrollTo(0, 0);
    Mixpanel.track('patentsExpandClick', {
      action: 'Expand view of patents table',
      view: 'Intervention Analytics page with search query',
      query: JSON.parse(query),
    });
  };

  const handleShrinkClick = () => {
    setIsExpanded(false);
    setExpanded('');
    Mixpanel.track('patentsShrinkClick', {
      action: 'Collapsed view of patents table',
      view: 'Intervention Analytics page with search query',
      query: JSON.parse(query),
    });
  };

  const getTopicColumnHeading = () => {
    switch (selectedTopic) {
      case 'indication':
        return 'Indications';
      case 'target':
        return 'Targets';
      case 'drug':
        return 'Drugs';
      default:
        return '';
    }
  };

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  const renderExpandedView = () => (
    <>
      <div className="subtopics">
        <button
          type="button"
          className={selectedTopic === 'indication' ? 'selected' : ''}
          onClick={() => handleTopicClick('indication')}
        >
          Indication
        </button>
        <button
          type="button"
          className={selectedTopic === 'target' ? 'selected' : ''}
          onClick={() => handleTopicClick('target')}
        >
          Targets
        </button>
        <button
          type="button"
          className={selectedTopic === 'drug' ? 'selected' : ''}
          onClick={() => handleTopicClick('drug')}
        >
          Drugs
        </button>
      </div>
      <div className="line" />
      <Loader
        error={patentsData.error}
        loading={patentsData.loading}
        noData={patentsData.data?.length === 0 && patentsData.flag}
        height="336px"
        className="white-loader"
        message="No records found"
      >
        <div className="patents-data-container">
          <table>
            <thead>
              <tr>
                <th>{getTopicColumnHeading()}</th>
                <th>Patents Count</th>
                <th>Assignees for recent patents</th>
              </tr>
            </thead>
            <tbody>
              {patentsData.data.map((row) => (
                <tr
                  key={row.key}
                  onClick={() => handleRowClick(row.key, row.name)}
                  className="clickable-row"
                >
                  <td title={isExpanded ? '' : row.name}>
                    {isExpanded ? row.name : wordLimit(row.name, 15)}
                  </td>
                  <td>{row.count}</td>
                  <td title={isExpanded ? '' : row.top_assignees.join(',   ')}>
                    {isExpanded
                      ? row.top_assignees.join(',   ')
                      : wordLimit(row.top_assignees.join(',   '), 25)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Loader>

    </>
  );

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }
  return (
    <div className={isExpanded ? 'expanded-widget' : ''}>
      <div className={isExpanded ? 'Widget expanded-endpoints' : ''}>
        <div className={isExpanded ? 'widget-expanded-patents' : 'Widget'}>
          <div className="widget-header">
            <div className="widget-sub-header">
              <SvgLoader width={17} height={34} svgName="widget-header-icon" />
              <div className="header-text">Patents List</div>
            </div>
            <div className="icons">
              {isExpanded ? (
                <SvgLoader
                  className="patent-expand-icon"
                  onClick={() => handleShrinkClick()}
                  width={22}
                  height={22}
                  svgName="contract"
                  hoverIconName="contract-hover"
                />
              ) : (
                <SvgLoader
                  className="patent-expand-icon"
                  onClick={() => handleExpandClick()}
                  width={22}
                  height={22}
                  svgName="expand"
                  hoverIconName="expand-hover"
                />
              )}
            </div>
          </div>

          {renderExpandedView()}
        </div>
      </div>
    </div>
  );
}
PatentsList.propTypes = {
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  setExpanded: PropTypes.func.isRequired,
  currentExpanded: PropTypes.string.isRequired,
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default PatentsList;
