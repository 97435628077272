import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_PROJECTS = 'GET_PROJECTS';
const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';
const GET_PROJECTS_REFRESH = 'GET_PROJECTS_REFRESH';

export const getProjectsAction = createAction(GET_PROJECTS);

const getProjectsSuccess = createAction(GET_PROJECTS_SUCCESS);

const getProjectsFaliure = createAction(GET_PROJECTS_FAILURE);
export const getProjectsRefreshAction = createAction(GET_PROJECTS_REFRESH);

export const getProjectsEpic = (actions$) => actions$.pipe(
  ofType(GET_PROJECTS),
  // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/getProject', 'GET', action.payload.params)).pipe(
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/project/getProject`, 'GET', action.payload.params)).pipe(

    map((res) => getProjectsSuccess(res)),
    catchError((err) => of(getProjectsFaliure(err))),
  )),
);

const ongetProjectsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetProjectsSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetProjectsFaliure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});
const onGetProjectsRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const getProjectsReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getProjectsAction, (state) => ongetProjectsAction(state))
      .addCase(getProjectsSuccess, (state, action) => ongetProjectsSuccess(state, action.payload))
      .addCase(getProjectsFaliure, (state) => ongetProjectsFaliure(state))
      .addCase(getProjectsRefreshAction, () => onGetProjectsRefresh())
      .addDefaultCase((state) => state);
  },
);

const GET_BOOKMARK_PROJECTS = 'GET_BOOKMARK_PROJECTS';
const GET_BOOKMARK_PROJECTS_SUCCESS = 'GET_BOOKMARK_PROJECTS_SUCCESS';
const GET_BOOKMARK_PROJECTS_FAILURE = 'GET_BOOKMARK_PROJECTS_FAILURE';
const GET_BOOKMARK_PROJECTS_REFRESH = 'GET_BOOKMARK_PROJECTS_REFRESH';

export const getBookmarkProjectsAction = createAction(GET_BOOKMARK_PROJECTS);

const getBookmarkProjectsSuccess = createAction(GET_BOOKMARK_PROJECTS_SUCCESS);

const getBookmarkProjectsFaliure = createAction(GET_BOOKMARK_PROJECTS_FAILURE);
export const getBookmarkProjectsRefreshAction = createAction(GET_BOOKMARK_PROJECTS_REFRESH);

export const getBookmarkProjectsEpic = (actions$) => actions$.pipe(
  ofType(GET_BOOKMARK_PROJECTS),
  // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/getProject', 'GET', action.payload.params)).pipe(
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/project/getProject`, 'GET', action.payload.params)).pipe(

    map((res) => getBookmarkProjectsSuccess(res)),
    catchError((err) => of(getBookmarkProjectsFaliure(err))),
  )),
);

const ongetBookmarkProjectsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetBookmarkProjectsSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetBookmarkProjectsFaliure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});
const onGetBookmarkProjectsRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const getBookmarkProjectsReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
    message: '',
  },
  (builder) => {
    builder.addCase(getBookmarkProjectsAction, (state) => ongetBookmarkProjectsAction(state))
      .addCase(getBookmarkProjectsSuccess, (state, action) => ongetBookmarkProjectsSuccess(state, action.payload))
      .addCase(getBookmarkProjectsFaliure, (state) => ongetBookmarkProjectsFaliure(state))
      .addCase(getBookmarkProjectsRefreshAction, () => onGetBookmarkProjectsRefresh())
      .addDefaultCase((state) => state);
  },
);

const GET_SAVED_SEARCH_PROJECTS = 'GET_SAVED_SEARCH_PROJECTS';
const GET_SAVED_SEARCH_PROJECTS_SUCCESS = 'GET_SAVED_SEARCH_PROJECTS_SUCCESS';
const GET_SAVED_SEARCH_PROJECTS_FAILURE = 'GET_SAVED_SEARCH_PROJECTS_FAILURE';
const GET_SAVED_SEARCH_PROJECTS_REFRESH = 'GET_SAVED_SEARCH_PROJECTS_REFRESH';

export const getSavedSearchProjectsAction = createAction(GET_SAVED_SEARCH_PROJECTS);

const getSavedSearchProjectsSuccess = createAction(GET_SAVED_SEARCH_PROJECTS_SUCCESS);

const getSavedSearchProjectsFaliure = createAction(GET_SAVED_SEARCH_PROJECTS_FAILURE);
export const getSavedSearchProjectsRefreshAction = createAction(GET_SAVED_SEARCH_PROJECTS_REFRESH);

export const getSavedSearchProjectsEpic = (actions$) => actions$.pipe(
  ofType(GET_SAVED_SEARCH_PROJECTS),
  // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/getProject', 'GET', action.payload.params)).pipe(
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/project/getProject`, 'GET', action.payload.params)).pipe(

    map((res) => getSavedSearchProjectsSuccess(res)),
    catchError((err) => of(getSavedSearchProjectsFaliure(err))),
  )),
);

const ongetSavedSearchProjectsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetSavedSearchProjectsSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetSavedSearchProjectsFaliure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});
const ongetSavedSearchProjectsRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const getSavedSearchProjectsReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getSavedSearchProjectsAction, (state) => ongetSavedSearchProjectsAction(state))
      .addCase(getSavedSearchProjectsSuccess, (state, action) => ongetSavedSearchProjectsSuccess(state, action.payload))
      .addCase(getSavedSearchProjectsFaliure, (state) => ongetSavedSearchProjectsFaliure(state))
      .addCase(getSavedSearchProjectsRefreshAction, () => ongetSavedSearchProjectsRefresh())
      .addDefaultCase((state) => state);
  },
);

const CREATE_PROJECT = 'CREATE_PROJECT';
const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';
const CREATE_PROJECTS_REFRESH = 'CREATE_PROJECTS_REFRESH';

export const createProjectAction = createAction(CREATE_PROJECT);

const createProjectSuccess = createAction(CREATE_PROJECT_SUCCESS);

const createProjectFaliure = createAction(CREATE_PROJECT_FAILURE);

export const createProjectRefreshAction = createAction(CREATE_PROJECTS_REFRESH);

export const createProjectEpic = (actions$) => actions$.pipe(
  ofType(CREATE_PROJECT),
  // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/createProject', 'POST', action.payload.body)).pipe(
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/project/createProject`, 'POST', action.payload.body)).pipe(

    map((res) => createProjectSuccess(res)),
    catchError((err) => of(createProjectFaliure(err))),
  )),
);

const onCreateProjectAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onCreateProjectSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  // showNotification: true,
  // message: 'hii',
  flag: true,
});

const onCreateProjectFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  // showNotification: true,
  // message: response.message,
  error: true,
  data: { ...response },
});

const onCreateProjectRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const createProjectReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(createProjectAction, (state) => onCreateProjectAction(state))
      .addCase(createProjectSuccess, (state, action) => onCreateProjectSuccess(state, action.payload))
      .addCase(createProjectFaliure, (state, action) => onCreateProjectFaliure(state, action.payload))
      .addCase(createProjectRefreshAction, () => onCreateProjectRefresh())
      .addDefaultCase((state) => state);
  },
);

const UPDATE_PROJECT = 'UPDATE_PROJECT';
const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';
const UPDATE_PROJECTS_REFRESH = 'UPDATE_PROJECTS_REFRESH';

export const updateProjectAction = createAction(UPDATE_PROJECT);

const updateProjectSuccess = createAction(UPDATE_PROJECT_SUCCESS);

const updateProjectFaliure = createAction(UPDATE_PROJECT_FAILURE);

export const updateProjectRefreshAction = createAction(UPDATE_PROJECTS_REFRESH);

export const updateProjectEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_PROJECT),
  // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/updateProject', 'PATCH', action.payload.params)).pipe(
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/project/updateProject`, 'PATCH', action.payload.params)).pipe(

    map((res) => updateProjectSuccess(res)),
    catchError((err) => of(updateProjectFaliure(err))),
  )),
);

const onUpdateProjectAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onUpdateProjectSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  showNotification: true,
  flag: true,
});

const onUpdateProjectFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onUpdateProjectRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const updateProjectReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(updateProjectAction, (state) => onUpdateProjectAction(state))
      .addCase(updateProjectSuccess, (state, action) => onUpdateProjectSuccess(state, action.payload))
      .addCase(updateProjectFaliure, (state, action) => onUpdateProjectFaliure(state, action.payload))
      .addCase(updateProjectRefreshAction, () => onUpdateProjectRefresh())
      .addDefaultCase((state) => state);
  },
);

const SHARE_PROJECT = 'SHARE_PROJECT';
const SHARE_PROJECT_SUCCESS = 'SHARE_PROJECT_SUCCESS';
const SHARE_PROJECT_FAILURE = 'SHARE_PROJECT_FAILURE';
const SHARE_PROJECTS_REFRESH = 'SHARE_PROJECTS_REFRESH';

export const shareProjectAction = createAction(SHARE_PROJECT);

const shareProjectSuccess = createAction(SHARE_PROJECT_SUCCESS);

const shareProjectFaliure = createAction(SHARE_PROJECT_FAILURE);

export const shareProjectRefreshAction = createAction(SHARE_PROJECTS_REFRESH);

export const shareProjectEpic = (actions$) => actions$.pipe(
  ofType(SHARE_PROJECT),
  // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/shareProject', 'POST', action.payload.body)).pipe(
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/project/shareProject`, 'POST', action.payload.body)).pipe(

    map((res) => shareProjectSuccess(res)),
    catchError((err) => of(shareProjectFaliure(err))),
  )),
);

const onshareProjectAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onshareProjectSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  showNotification: true,
  flag: true,
});

const onshareProjectFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onshareProjectRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const shareProjectReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(shareProjectAction, (state) => onshareProjectAction(state))
      .addCase(shareProjectSuccess, (state, action) => onshareProjectSuccess(state, action.payload))
      .addCase(shareProjectFaliure, (state, action) => onshareProjectFaliure(state, action.payload))
      .addCase(shareProjectRefreshAction, () => onshareProjectRefresh())
      .addDefaultCase((state) => state);
  },
);

export {
  getProjectsReducer, updateProjectReducer, createProjectReducer, shareProjectReducer, getBookmarkProjectsReducer, getSavedSearchProjectsReducer,
};
