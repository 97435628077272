import { createAction, createReducer, current } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const CREATE_BOOKMARK = 'CREATE_BOOKMARK';
const CREATE_BOOKMARK_SUCCESS = 'CREATE_BOOKMARK_SUCCESS';
const CREATE_BOOKMARK_FAILURE = 'CREATE_BOOKMARK_FAILURE';
const CREATE_BOOKMARKS_REFRESH = 'CREATE_BOOKMARKS_REFRESH';

export const createBookmarkAction = createAction(CREATE_BOOKMARK);

const createBookmarkSuccess = createAction(CREATE_BOOKMARK_SUCCESS);

const createBookmarkFaliure = createAction(CREATE_BOOKMARK_FAILURE);

export const createBookmarkRefreshAction = createAction(CREATE_BOOKMARKS_REFRESH);

export const createBookmarkEpic = (actions$) => actions$.pipe(
  ofType(CREATE_BOOKMARK),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/bookmark/createBookmark`, 'POST', action.payload.body)).pipe(
    map((res) => createBookmarkSuccess(res)),
    catchError((err) => of(createBookmarkFaliure(err))),
  )),
);

const onCreateBookmarkAction = (state) => ({
  ...state,
  loading: true,
  error: false,
  flag: false,
});

const onCreateBookmarkSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onCreateBookmarkFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onCreateBookmarkRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const createBookmarkReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(createBookmarkAction, (state) => onCreateBookmarkAction(state))
      .addCase(createBookmarkSuccess, (state, action) => onCreateBookmarkSuccess(state, action.payload))
      .addCase(createBookmarkFaliure, (state, action) => onCreateBookmarkFaliure(state, action.payload))
      .addCase(createBookmarkRefreshAction, () => onCreateBookmarkRefresh())
      .addDefaultCase((state) => state);
  },
);

const GET_BOOKMARK = 'GET_BOOKMARK';
const GET_BOOKMARK_SUCCESS = 'GET_BOOKMARK_SUCCESS';
const GET_BOOKMARK_FAILURE = 'GET_BOOKMARK_FAILURE';
const GET_BOOKMARKS_REFRESH = 'GET_BOOKMARKS_REFRESH';
const GET_MORE_BOOKMARKS = 'GET_MORE_BOOKMARKS';
const GET_MORE_BOOKMARKS_SUCCESS = 'GET_MORE_BOOKMARKS_SUCCESS';

export const getBookmarkAction = createAction(GET_BOOKMARK);
const getBookmarkSuccess = createAction(GET_BOOKMARK_SUCCESS);
const getBookmarkFaliure = createAction(GET_BOOKMARK_FAILURE);
export const getMoreBookmarkAction = createAction(GET_MORE_BOOKMARKS);
const getMoreBookmarkSuccess = createAction(GET_MORE_BOOKMARKS_SUCCESS);
export const getBookmarkRefreshAction = createAction(GET_BOOKMARKS_REFRESH);

export const getBookmarkEpic = (actions$) => actions$.pipe(
  ofType(GET_BOOKMARK),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/bookmark/getBookmark?${action.payload.params}`, 'POST', action.payload.body)).pipe(

    map((res) => getBookmarkSuccess(res)),
    catchError((err) => of(getBookmarkFaliure(err))),
  )),
);

export const getMoreBookmarkEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_BOOKMARKS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/bookmark/getBookmark?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => getMoreBookmarkSuccess(res)),
    catchError((err) => of(getBookmarkFaliure(err))),
  )),
);

const onGetBookmarkAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetBookmarkSuccess = (state, { response }) => ({
  ...state,
  data: { data: [...response.data] },
  total: response.total,
  loading: false,
  showNotification: true,
  flag: true,
});

const onGetBookmarkFaliure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onGetBookmarkRefresh = () => ({
  data: {},
  loading: false,
  error: false,
});

const onGetMoreBookmarkAction = (state) => ({
  ...state,
  loading: false,
  error: false,
});

const onGetMoreBookmarkSuccess = (state, { response }) => (
  {
    data: {
      total: response?.total,
      data: [
        ...current(state).data.data,
        ...response.data,
      ],
    },
    loading: false,
    flag: true,
  }
);

const getBookmarkReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getBookmarkAction, (state) => onGetBookmarkAction(state))
      .addCase(getBookmarkSuccess, (state, action) => onGetBookmarkSuccess(state, action.payload))
      .addCase(getBookmarkFaliure, (state) => onGetBookmarkFaliure(state))
      .addCase(getMoreBookmarkAction, (state) => onGetMoreBookmarkAction(state))
      .addCase(getMoreBookmarkSuccess, (state, action) => onGetMoreBookmarkSuccess(state, action.payload))
      .addCase(getBookmarkRefreshAction, () => onGetBookmarkRefresh())
      .addDefaultCase((state) => state);
  },
);

const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS';
const DELETE_BOOKMARK_FAILURE = 'DELETE_BOOKMARK_FAILURE';
const DELETE_BOOKMARKS_REFRESH = 'DELETE_BOOKMARKS_REFRESH';

export const deleteBookmarkAction = createAction(DELETE_BOOKMARK);

const deleteBookmarkSuccess = createAction(DELETE_BOOKMARK_SUCCESS);

const deleteBookmarkFaliure = createAction(DELETE_BOOKMARK_FAILURE);

export const deleteBookmarkRefreshAction = createAction(DELETE_BOOKMARKS_REFRESH);

export const deleteBookmarkEpic = (actions$) => actions$.pipe(
  ofType(DELETE_BOOKMARK),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/bookmark/deleteBookmark`, 'DELETE', action.payload.body)).pipe(
    map((res) => deleteBookmarkSuccess(res)),
    catchError((err) => of(deleteBookmarkFaliure(err))),
  )),
);

const ondeleteBookmarkAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ondeleteBookmarkSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  showNotification: true,
  flag: true,
});

const ondeleteBookmarkFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const ondeleteBookmarkRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const deleteBookmarkReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(deleteBookmarkAction, (state) => ondeleteBookmarkAction(state))
      .addCase(deleteBookmarkSuccess, (state, action) => ondeleteBookmarkSuccess(state, action.payload))
      .addCase(deleteBookmarkFaliure, (state, action) => ondeleteBookmarkFaliure(state, action.payload))
      .addCase(deleteBookmarkRefreshAction, () => ondeleteBookmarkRefresh())
      .addDefaultCase((state) => state);
  },
);

const SHARE_BOOKMARK = 'SHARE_BOOKMARK';
const SHARE_BOOKMARK_SUCCESS = 'SHARE_BOOKMARK_SUCCESS';
const SHARE_BOOKMARK_FAILURE = 'SHARE_BOOKMARK_FAILURE';
const SHARE_BOOKMARKS_REFRESH = 'SHARE_BOOKMARKS_REFRESH';

export const shareBookmarkAction = createAction(SHARE_BOOKMARK);

const shareBookmarkSuccess = createAction(SHARE_BOOKMARK_SUCCESS);

const shareBookmarkFaliure = createAction(SHARE_BOOKMARK_FAILURE);

export const shareBookmarkRefreshAction = createAction(SHARE_BOOKMARKS_REFRESH);

export const shareBookmarkEpic = (actions$) => actions$.pipe(
  ofType(SHARE_BOOKMARK),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/bookmark/shareBookmark`, 'POST', action.payload.body)).pipe(
    map((res) => shareBookmarkSuccess(res)),
    catchError((err) => of(shareBookmarkFaliure(err))),
  )),
);

const onShareBookmarkAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onShareBookmarkSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  showNotification: true,
  flag: true,
});

const onShareBookmarkFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onShareBookmarkRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const shareBookmarkReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(shareBookmarkAction, (state) => onShareBookmarkAction(state))
      .addCase(shareBookmarkSuccess, (state, action) => onShareBookmarkSuccess(state, action.payload))
      .addCase(shareBookmarkFaliure, (state, action) => onShareBookmarkFaliure(state, action.payload))
      .addCase(shareBookmarkRefreshAction, () => onShareBookmarkRefresh())
      .addDefaultCase((state) => state);
  },
);

export {
  getBookmarkReducer, createBookmarkReducer, deleteBookmarkReducer, shareBookmarkReducer,
};
