import React, { useState, useEffect } from 'react';
import {
  Popover, Modal, Input, Button,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import {
  updateProjectAction,
  getProjectsAction,
  shareProjectAction,
  updateProjectRefreshAction,
} from '../WorkSpace/logic';
import { archiveProjectAction } from './logic';
import { getBookmarkAction } from '../Bookmark/logic';
import { getCookie, setCookie } from '../../../utils';
import { sendNotification } from '../../components/Notification/logic';

function ProjectDropdown({
  updatedProjectDates,
  sharedDocumentId,
  projectsData,
  openExpandedProject,
  renderProjects,
  // eslint-disable-next-line no-use-before-define
  setProjectId = { setProjectId },
  projectId,
  bookmarkFilters,
  setCurrentPage,
  currentPage,
}) {
  const dispatch = useDispatch();
  const updateProject = useSelector((state) => state.updateProject);
  const archiveProject = useSelector((state) => state.archiveProject);
  const shareProject = useSelector((state) => state.shareProject);
  const [openProjectOptions, setOpenProjectOptions] = useState(-1);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openRenameProject, setOpenRenameProject] = useState(false);
  const [inputText, setInputText] = useState('');
  const [emailTags, setEmailTags] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [inputError, setInputError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [dummyData, setDummyData] = useState({});

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  function convertToLowerCaseWithUnderscores(filterData) {
    return filterData.map((filter) => filter.toLowerCase().replace(/ /g, '_'));
  }

  const apiCall = () => {
    dispatch(
      getProjectsAction({
        params: {
          doc_id: '',
        },
      }),
    );
  };
  const showShareModal = () => {
    setOpenShareModal(!openShareModal);
  };

  const handleRenameClick = (inputVal, data) => {
    setOpenRenameProject(!openRenameProject);
    dispatch(updateProjectRefreshAction());
    dispatch(
      updateProjectAction({
        params: {
          project_name: inputText,
          project_id: data?.projectId,
        },
      }),
    );
    if (data?.projectId === getCookie('projectId')) {
      setCookie(inputText, 'projectName');
    }
    setOpenProjectOptions(-1);
  };
  useEffect(() => {
    if (updateProject.flag || archiveProject.flag) {
      apiCall();
    }
  }, [JSON.stringify(updateProject), JSON.stringify(archiveProject)]);

  useEffect(() => {
    if (updateProject.flag) {
      // dispatch(updateProjectRefreshAction());
      dispatch(
        sendNotification({
          type: 'UPDATE_PROJECT_1_SUCCESS',
          notificationType: 'success',
          message: 'Project renamed successfully',
          showNotification: true,
        }),
      );
    }
  }, [updateProject.flag]);

  useEffect(() => {
    if (archiveProject.flag) {
      dispatch(
        sendNotification({
          type: 'PROJECT_ARCHIVE_SUCCESS',
          notificationType: 'success',
          message: 'Project archived successfully',
          showNotification: true,
        }),
      );
    }
  }, [JSON.stringify(archiveProject)]);
  const handleChange = (value) => {
    setInputText(value);

    if (value.length > 3) {
      setInputError('');
    } else {
      setInputError('Please enter more than 3 characters');
    }
  };
  const renderRenameProject = (data) => (
    <div className="create-project-sec">
      <div className="project-header">
        <div className="project-title"> Rename project</div>
        <div
          className="close-icon"
          role="presentation"
          onClick={() => {
            setOpenRenameProject(false);
          }}
        />
      </div>
      <div className="project-content">
        <Input
          onChange={(e) => handleChange(e.target.value)}
          value={inputText}
        />
        {inputError && <div style={{ color: 'red' }}>{inputError}</div>}
      </div>
      <div className="btn-section">
        <Button
          type="primary"
          className="cancel-btn"
          onClick={() => {
            setOpenRenameProject(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          role="presentation"
          onClick={() => handleRenameClick(inputText, data)}
          disabled={inputText.length <= 3}
        >
          Rename
        </Button>
      </div>
    </div>
  );

  const handleArchiveClick = (data) => {
    dispatch(
      archiveProjectAction({
        params: {
          project_id: data?.projectId,
        },
      }),
    );
    setOpenProjectOptions(-1);
  };

  const handleClick = (data, idx, objIdx) => {
    setOpenProjectOptions((prev) => {
      if (prev === objIdx * 10000 + idx) {
        return -1;
      }
      return objIdx * 10000 + idx;
    });
    setDummyData(data);
  };

  const handleInviteClick = () => {
    if (emailTags.length > 0 && !emailError) {
      dispatch(
        shareProjectAction({
          body: {
            project_id: dummyData.projectId,
            shared_ids: emailTags,
          },
        }),
      );
      if (!dummyData?.sharedusers || dummyData.sharedusers.length === 0) {
        setOpenShareModal(!openShareModal);
        setOpenProjectOptions(-1);
      }
    }
    setOpenProjectOptions(-1);
  };

  useEffect(() => {
    if (shareProject.flag && Object.keys(shareProject?.data).length) {
      if (shareProject.data.message.includes('not')) {
        dispatch(
          sendNotification({
            type: 'PROJECT_SHARED_FAILURE',
            notificationType: 'error',
            message: shareProject.data.message,
            showNotification: true,
          }),
        );
      } else {
        dispatch(
          sendNotification({
            type: 'PROJECT_SHARED_SUCCESS',
            notificationType: 'success',
            message: shareProject.data.message,
            showNotification: true,
          }),
        );
      }

      apiCall();
      setCurrentEmail('');
      setEmailTags([]);
      setOpenShareModal(false);
    }
  }, [JSON.stringify(shareProject)]);

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (currentEmail.trim() === '') return;
    if (e.key === 'Enter') {
      const emails = currentEmail.split(/[, ]+/);
      const validEmails = [];
      emails.forEach((email) => {
        if (emailRegex.test(email)) {
          validEmails.push(email);
        } else {
          setEmailError('Invalid email format');
        }
      });
      if (validEmails.length > 0) {
        setEmailTags([...emailTags, ...validEmails]);
        setCurrentEmail('');
        setEmailError('');
      }
    }
  };

  const handleBlur = () => {
    if (currentEmail.trim() === '') return;
    const emails = currentEmail.split(/[, ]+/);
    const validEmails = [];
    emails.forEach((email) => {
      if (emailRegex.test(email)) {
        validEmails.push(email);
      } else {
        setEmailError('Invalid email format');
      }
    });
    if (validEmails.length > 0) {
      setEmailTags([...emailTags, ...validEmails]);
      setCurrentEmail('');
      setEmailError('');
    }
  };

  const handleTagRemove = (emailToRemove) => {
    setEmailTags(emailTags.filter((email) => email !== emailToRemove));
  };

  useEffect(() => {
    if (shareProject.flag) {
      apiCall();
      setCurrentEmail('');
      setEmailTags([]);
    }
  }, [JSON.stringify(shareProject)]);
  const renderProjectOptions = (data) => (
    <div className="project-options-section">
      <div
        className="project-options"
        role="presentation"
        onClick={showShareModal}
      >
        <div className="share-btn" />
        <div className="share">Share</div>
      </div>

      {data?.projectName !== 'Default'
      && data?.projectName !== 'Shared Documents' ? (
        <Popover
          overlayClassName="create-proj-popover"
          placement="bottomLeft"
          open={openRenameProject}
          content={renderRenameProject(data)}
        >
          <div
            className="project-options"
            role="presentation"
            onClick={() => {
              setOpenRenameProject(!openRenameProject);
            }}
          >
            <div className="rename-btn" />
            <div className="rename">Rename</div>
          </div>
        </Popover>
        ) : null}

      <div
        className="project-options"
        role="presentation"
        onClick={() => handleArchiveClick(data)}
      >
        <div className="archive-btn" />
        <div className="archive">Archive</div>
      </div>
    </div>
  );

  return (
    <div className="analytics-container-content-sidebar-link-main">
      {Object.keys(updatedProjectDates)
        .filter(
          (key) => Array.isArray(updatedProjectDates[key])
            && updatedProjectDates[key].length > 0,
        )
        .map((key, index) => (
          <>
            <div key={updatedProjectDates}>
              <div
                className="analytics-container-content-sidebar-link"
                role="presentation"
                onClick={() => renderProjects(key)}
              >
                <div className="analytics-container-content-sidebar-link-title">
                  {key}
                </div>
                <span
                  className={
                    openExpandedProject.includes(key)
                      ? 'drop-icon'
                      : 'dropdown-arrow'
                  }
                  role="presentation"
                />
              </div>
            </div>
            <div />
            {openExpandedProject.includes(key) && (
              <Loader loading={projectsData.loading}>
                <div className="analytics-container-content-sidebar-link--child">
                  {updatedProjectDates[key]?.map((i, contentIndex) => (
                    <div
                      key={i.id}
                      className={
                        projectId === i?.projectId
                          ? 'analytics-container-content-sidebar-link active'
                          : 'analytics-container-content-sidebar-link'
                      }
                      role="presentation"
                      onClick={() => { setProjectId(i?.projectId); setCurrentPage(1); }}
                    >
                      <div className="folder-icon" />
                      <div className="analytics-container-content-sidebar-link-title">
                        <div className="analytics-container-content-sidebar-link-title-main">
                          {i?.projectName}
                        </div>
                        <div className="analytics-container-content-sidebar-link-share-option">
                          {i?.sharedByUser ? (
                            <div className="shared-with" key="shared-with">
                              <div className="shared-with-content">
                                shared by:
                              </div>

                              <div className="log-menu-content">
                                <div className="log-menu">
                                  <div className="log-menu-profile">
                                    <div
                                      aria-hidden
                                      className="user-name-initials"
                                    >
                                      <span
                                        className="user"
                                        title={i?.sharedByUser}
                                      >
                                        {i?.sharedByUser
                                          .charAt(0)
                                          .toUpperCase()}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : i?.sharedusers?.length > 0 ? (
                            <div className="shared-with" key="shared-with">
                              <div className="shared-with-content">
                                shared with:
                              </div>
                              {i.sharedusers.map((user) => (
                                <div className="log-menu-content">
                                  <div key={user} className="log-menu">
                                    <div className="log-menu-profile">
                                      <div
                                        aria-hidden
                                        className="user-name-initials"
                                      >
                                        <span className="user" title={user}>
                                          {user.charAt(0).toUpperCase()}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="shared-with-content">private</div>
                          )}
                        </div>
                      </div>

                      <div>
                        <div
                          className="three-dots-icon"
                          role="presentation"
                          onClick={() => {
                            handleClick(i, index + 1, contentIndex + 1);
                            setInputText(i?.projectName);
                          }}
                        >
                          {/* <div className="add-project" />
                                <div className="project-name">Create New Project</div> */}
                        </div>
                        <Popover
                          overlayClassName="proj-options-popover"
                          placement="bottom"
                          open={
                            openProjectOptions
                            === (contentIndex + 1) * 10000 + (index + 1)
                          }
                          onOpenChange={(open) => setOpenProjectOptions(open)}
                          trigger="click"
                          content={renderProjectOptions(i)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </Loader>
            )}
          </>
        ))}
      <div
        role="presentation"
        className=""
        onClick={() => {
          setProjectId(sharedDocumentId);
          renderProjects(-1);
          dispatch(
            getBookmarkAction({
              body: {
                project_id: projectId,
                asset_class: convertToLowerCaseWithUnderscores(bookmarkFilters),
                user_ids: [],
                export: false,
                file_type: '',
                sort: {},
                page: currentPage,
                size: 10,
              },
            }),
          );
        }}
      />
      {openShareModal && (
        <Modal
          className="share-with-modal"
          open={openShareModal}
          closable
          footer={false}
          onCancel={() => setOpenShareModal(false)}
        >
          <div className="share-modal-content">
            <div className="share-modal-content-input">
              <div className="share-with-title">Share with</div>
              <div
                className="email-tags-container"
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  marginBottom: '10px',
                }}
              >
                <div className="email-tags">
                  {emailTags.map((email, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="email-tag">
                      {email}
                      <span
                        role="presentation"
                        className="remove-tag"
                        onClick={() => handleTagRemove(email)}
                      />
                    </div>
                  ))}
                  <Input
                    value={currentEmail}
                    onChange={handleEmailInputChange}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onBlur={handleBlur}
                    placeholder="Email ID"
                  />
                  {emailError && (
                    <div style={{ color: 'red' }}>{emailError}</div>
                  )}
                </div>
              </div>
              <div className="share-with-message-input">
                <Input placeholder="Message (Optional)" />
              </div>
              <div className="invite-btn">
                <Button
                  type="primary"
                  onClick={() => handleInviteClick()}
                >
                  Share
                </Button>
              </div>
            </div>
            {dummyData?.sharedusers?.length ? (
              <div className="shared-with-title">Already shared with</div>
            ) : null}
            {dummyData?.sharedusers?.map((user) => (
              <div className="shared-with-content" key={user}>
                <div className="shared-with-persons-data">
                  <div className="shared-with-persons-list">
                    <div key={user} className="log-menu">
                      <div className="log-menu-profile">
                        <div aria-hidden className="user-name-initials">
                          <span className="user" title={user}>
                            {user.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="person-1-details person-details">
                      <div className="person-1-name person-name">{user}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ProjectDropdown;
