import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import Dropdown from '../../components/Dropdown';
import InputTypeHandler from '../../components/InputTypeHandler';
import SvgLoader from '../../components/SvgLoader';
import { removeFilterAction, updateFilterAction } from '../PowerSearch/logic';
import { getTypesValuesRefreshAction } from './logic';
import Mixpanel from '../../../utils/mixpanel';
import { getTerminalName } from '../../../utils';

function PowerSearchBox({
  options, currentValue, id, setShow, removedToBeAddedInOption, apiUrl, removedValues,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selected, setSelected] = useState(currentValue);
  const [svgName, setSvgName] = useState('minus');

  const removeFilter = () => {
    removedToBeAddedInOption(currentValue);
    dispatch(removeFilterAction({
      id,
    }));
    Mixpanel.track('button_click_event', {
      action: 'user clicked minus button to remove field from power search',
      terminal_name: getTerminalName(location),
      page: 'Search page',
      filterData: { ...currentValue },
    });
  };

  const handleSelect = (value) => {
    setSelected(value);
    dispatch(updateFilterAction({
      filterOption: { ...value },
      id,
    }));
    dispatch(getTypesValuesRefreshAction());
    Mixpanel.track('select_filter_add_search_query_event', {
      action: 'user selected field for power search',
      terminal_name: getTerminalName(location),
      filter_name: { ...value },
      page: 'Search page',
    });
  };

  const handleUpdate = (params) => {
    dispatch(updateFilterAction({
      filterOption: { ...selected, ...params },
      id,
    }));
  };

  return (
    <div className="search-container">
      <motion.div initial={{ y: 10 }} onMouseLeave={() => setSvgName('minus')} onMouseEnter={() => setSvgName('cross-icon')} whileHover={{ rotateZ: 180 }} transition={{ duration: 0.3 }}>
        <SvgLoader onClick={() => removeFilter()} width={30} height={30} svgName={svgName} className="minus-icon" />
      </motion.div>
      <Dropdown
        setShow={setShow}
        options={options}
        value={selected}
        onChange={handleSelect}
        id={id}
        className={['tags-display', 'tags-display-panel', 'tags-option']}
      />
      <InputTypeHandler setShow={setShow} handleUpdate={handleUpdate} type={selected.type} selectedValue={selected.value} currentFilter={selected} id={id} apiUrl={apiUrl} removedValues={removedValues} />
    </div>
  );
}

PowerSearchBox.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  currentValue: PropTypes.PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number.isRequired,
  setShow: PropTypes.func.isRequired,
  removedToBeAddedInOption: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  removedValues: PropTypes.instanceOf(Array).isRequired,
};

export default PowerSearchBox;
