/* eslint-disable array-callback-return */
import { React, useEffect, useState } from 'react';
import { Checkbox, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { getSavedSearchesAction } from '../SavedSearches/logic';

function SavedSearchFilter({
  projectId,
  setOpenFilterPopover,
  setBookmarkFilterUsers,
  bookmarkFilterUsers,
  projectsData,
}) {
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();

  function capitalizeFirstLetter(str) {
    const words = str.split(' ');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  const handleBookmarkFilters = () => {
    if (projectId !== '') {
      dispatch(
        getSavedSearchesAction({
          body: {
            project_id: projectId,
            user_ids: bookmarkFilterUsers,
          },
        }),
      );
    }

    setOpenFilterPopover(false);
  };
  const handleCheckboxChange = (checkedValue) => {
    setBookmarkFilterUsers((prevFilters) => {
      if (prevFilters.includes(checkedValue)) {
        return prevFilters.filter((filter) => filter !== checkedValue);
      }
      return [...prevFilters, checkedValue];
    });
  };

  const selectAll = () => {
    setBookmarkFilterUsers(userData);
  };
  const deselectAll = () => {
    setBookmarkFilterUsers([]);
  };

  useEffect(() => {
    let tempUserData = [];
    projectsData?.data?.map((itm) => {
      if (projectId === itm?.projectId) {
        tempUserData.push(itm?.user_details);
      }
    });
    if (tempUserData.length) {
      tempUserData = tempUserData[0].map((itm) => itm?.user_id);
    } else {
      tempUserData = [];
    }
    setUserData(tempUserData);
  }, []);

  return (
    <>
      <div className="subtopics">

        <Button
          type="button"
          className="filter-by-user"
        >
          <span className="filter-text">
            {' '}
            Filter by user
          </span>
        </Button>
      </div>
      <div className="line" />
      <div className="workspace-bookmark-filter-selection-section">
        <div
          className="workspace-bookmark-filter-select-all"
          role="presentation"
          onClick={selectAll}
        >
          Select All
        </div>
        <div
          className="workspace-bookmark-filter-deselect-all"
          role="presentation"
          onClick={deselectAll}
        >
          Deselect All
        </div>
      </div>
      <div className="workspace-bookmark-filter">

        <div className="analytics-container-content-sidebar-link--child">
          {projectsData?.data?.map((project) => {
            if (project.projectId === projectId) {
              return (
                <div>
                  {project.user_details.map((user) => (
                    <div
                      role="presentation"
                      key={user.user_id}
                      className="analytics-container-content-sidebar-link"
                      onClick={() => handleCheckboxChange(user.user_id)}

                    >
                      <div className="project-content-user">
                        <div className="analytics-container-content-sidebar-link-title">
                          <div className="analytics-container-content-sidebar-link-title-main">
                            <div className="log-menu-content">
                              <div className="log-menu">
                                <div className="log-menu-profile">
                                  <div
                                    aria-hidden
                                    className="user-name-initials"
                                  >
                                    <span
                                      className="user"
                                      title={user.user_name}
                                    >
                                      {capitalizeFirstLetter(user.user_name
                                        .charAt(0))}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {capitalizeFirstLetter(user.user_name)}
                          </div>
                          <Checkbox
                            checked={bookmarkFilterUsers.includes(
                              user.user_id,
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            }
            return null;
          })}
        </div>

        <div className="analytics-container-content-sidebar-link-footer">
          <Button
            type="primary"
            className="ok-btn"
            onClick={() => handleBookmarkFilters()}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
}

export default SavedSearchFilter;
