const CTFilterOption = [
  {
    sectionName: 'Date Range',
    fields: [
      {
        name: null,
        type: 'date-range',
        key: 'study_start_date',
        id: 0,
      },
    ],
  },
  {
    sectionName: 'Phase',
    fields: [
      {
        name: null,
        type: 'multiselect',
        key: 'phase',
        id: 1,
      },
    ],
  },
  {
    sectionName: 'Status',
    fields: [
      {
        name: null,
        type: 'multiselect',
        key: 'status',
        id: 2,
      },
    ],
  },
  {
    sectionName: 'Country/Region',
    fields: [
      {
        name: null,
        type: 'autosuggest-multi',
        key: 'country',
        id: 3,
      },
    ],
  },
  {
    sectionName: 'Registry',
    fields: [
      {
        name: null,
        type: 'multiselect',
        key: 'registry',
        id: 4,
      },
    ],
  },
  {
    sectionName: 'Indication',
    fields: [
      {
        name: null,
        type: 'autosuggest-multi',
        key: 'indication',
        id: 5,
      },
    ],
  },
  {
    sectionName: 'Intervention',
    fields: [
      {
        name: null,
        type: 'autosuggest-multi',
        key: 'intervention',
        id: 6,
      },
    ],
  },
  {
    sectionName: 'Sponsor',
    fields: [
      {
        name: null,
        type: 'autosuggest',
        key: 'sponsor',
        id: 7,
      },
    ],
  },
  {
    sectionName: 'Enrollment Range',
    fields: [
      {
        name: 'Actual Enrollments',
        type: 'number-range',
        key: 'enrollment_actual',
        id: 8,
      },
      {
        name: 'Anticipated Enrollments',
        type: 'number-range',
        key: 'enrollment_anticipated',
        id: 9,
      },
    ],
  },
  {
    sectionName: 'Endpoints',
    fields: [
      {
        name: null,
        type: 'autosuggest',
        key: 'endpoints',
        id: 10,
      },
    ],
  },
  {
    sectionName: 'Eligibility Criteria',
    fields: [
      {
        name: 'Age',
        type: 'number-range',
        key: 'age',
        id: 11,
      },
      {
        name: 'Gender',
        type: 'dropdown',
        key: 'gender',
        id: 12,
      },
    ],
  },
];

export const CTsearchFilters = [
  {
    name: 'study_start_date', label: 'Date Range', type: 'date-range',
  },
  {
    name: 'phase', label: 'Phase', type: 'multiselect',
  },
  {
    name: 'status', label: 'Status', type: 'multiselect',
  },
  {
    name: 'country', label: 'Country/Region', type: 'autosuggest-multi',
  },
  {
    name: 'registry', label: 'Registry', type: 'dropdown',
  },
  {
    name: 'indication', label: 'Indication', type: 'autosuggest-multi',
  },
  {
    name: 'intervention', label: 'Intervention', type: 'autosuggest-multi',
  },
  {
    name: 'sponsor', label: 'Sponsor', type: 'autosuggest',
  },
  {
    name: 'enrollment_actual', label: 'Actual Enrollments', type: 'number-range',
  },
  {
    name: 'enrollment_anticipated', label: 'Anticipated Enrollments', type: 'number-range',
  },
  {
    name: 'endpoints', label: 'Endpoints', type: 'autosuggest',
  },
  {
    name: 'age', label: 'Age', type: 'number', subNames: ['min_age', 'max_age'],
  },
  {
    name: 'gender', label: 'Gender', type: 'dropdown',
  },
];

export const SEfilterOptions = [
  {
    AssetType: null,
    children: [
      {
        sectionName: 'Asset Classes',
        fields: [
          {
            name: null,
            type: 'multiselect',
            key: 'asset_classes',
            assetClass: 'ot_search_assetclasses',
            id: 0,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Date Range',
        fields: [
          {
            name: null,
            type: 'date-range',
            key: 'study_start_date',
            assetClass: 'ot_search_assetclasses',
            id: 1,
            spa: false,
          },
        ],
      },
    ],
  },
  {
    AssetType: 'Clinical Trials',
    key: 'clinical_trials',
    children: [
      {
        sectionName: 'Phase',
        fields: [
          {
            name: null,
            type: 'multiselect',
            key: 'phase',
            assetClass: 'clinical_trials',
            id: 2,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Status',
        fields: [
          {
            name: null,
            type: 'multiselect',
            key: 'status',
            assetClass: 'clinical_trials',
            id: 3,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Country/Region',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'country',
            assetClass: 'clinical_trials',
            id: 4,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Registry',
        fields: [
          {
            name: null,
            type: 'multiselect',
            key: 'registry',
            assetClass: 'clinical_trials',
            id: 5,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Indication',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'indication',
            assetClass: 'clinical_trials',
            id: 6,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Intervention',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'intervention',
            assetClass: 'clinical_trials',
            id: 7,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Sponsor',
        fields: [
          {
            name: null,
            type: 'autosuggest',
            key: 'sponsor',
            assetClass: 'clinical_trials',
            id: 8,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Enrollment Range',
        fields: [
          {
            name: 'Actual Enrollments',
            type: 'number-range',
            key: 'enrollment_actual',
            assetClass: 'clinical_trials',
            id: 9,
            spa: false,
          },
          {
            name: 'Anticipated Enrollments',
            type: 'number-range',
            key: 'enrollment_anticipated',
            assetClass: 'clinical_trials',
            id: 10,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Endpoints',
        fields: [
          {
            name: null,
            type: 'autosuggest',
            key: 'endpoints',
            assetClass: 'clinical_trials',
            id: 11,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Eligibility Criteria',
        fields: [
          {
            name: 'Age',
            type: 'number-range',
            key: 'age',
            assetClass: 'clinical_trials',
            id: 12,
            spa: false,
          },
          {
            name: 'Gender',
            type: 'dropdown',
            key: 'gender',
            assetClass: 'clinical_trials',
            id: 13,
            spa: false,
          },
        ],
      },
    ],
  },
  {
    AssetType: 'Publications',
    key: 'publications',
    children: [
      {
        sectionName: 'Author',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'author',
            assetClass: 'publications',
            id: 14,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Citation',
        fields: [
          {
            name: null,
            type: 'dropdown',
            key: 'citation',
            assetClass: 'publications',
            id: 15,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Country/Region',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'country',
            assetClass: 'publications',
            id: 16,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Indication',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'indication',
            assetClass: 'publications',
            id: 17,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Intervention',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'intervention',
            assetClass: 'publications',
            id: 18,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Key Biological Concepts',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'key_biological_concepts',
            assetClass: 'publications',
            id: 19,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Publication Type',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'publication_type',
            assetClass: 'publications',
            id: 20,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Review Status',
        fields: [
          {
            name: null,
            type: 'dropdown',
            key: 'review_status',
            assetClass: 'publications',
            id: 21,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Source',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'source',
            assetClass: 'publications',
            id: 22,
            spa: true,
          },
        ],
      },
    ],
  },
  {
    AssetType: 'Congresses',
    key: 'congresses',
    children: [
      {
        sectionName: 'Author',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'author',
            assetClass: 'congresses',
            id: 23,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Country/Region',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'country',
            assetClass: 'congresses',
            id: 24,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Indication',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'indication',
            assetClass: 'congresses',
            id: 25,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Key Biological Concepts',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'key_biological_concepts',
            assetClass: 'congresses',
            id: 26,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Source',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'source',
            assetClass: 'congresses',
            id: 27,
            spa: true,
          },
        ],
      },
    ],
  },
  {
    AssetType: 'Patents',
    key: 'patents',
    children: [
      {
        sectionName: 'Inventor',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'inventor',
            assetClass: 'patents',
            id: 28,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Key Biological Concepts',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'key_biological_concepts',
            assetClass: 'patents',
            id: 29,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Source',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'source',
            assetClass: 'patents',
            id: 30,
            spa: true,
          },
        ],
      },
    ],
  },
  {
    AssetType: 'Press Releases',
    key: 'press_releases',
    children: [
      {
        sectionName: 'Key Biological Concepts',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'key_biological_concepts',
            assetClass: 'press_releases',
            id: 31,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Source',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'source',
            assetClass: 'press_releases',
            id: 32,
            spa: true,
          },
        ],
      },
    ],
  },
  {
    AssetType: 'Guidelines',
    key: 'guidelines',
    children: [
      {
        sectionName: 'Author',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'author',
            assetClass: 'guidelines',
            id: 33,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Indication',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'indication',
            assetClass: 'guidelines',
            id: 34,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Key Biological Concepts',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'key_biological_concepts',
            assetClass: 'guidelines',
            id: 35,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Source',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'source',
            assetClass: 'guidelines',
            id: 36,
            spa: true,
          },
        ],
      },
    ],
  },
  {
    AssetType: 'Grants',
    key: 'grants',
    children: [
      {
        sectionName: 'Approved Amount',
        fields: [
          {
            name: null,
            type: 'number-range',
            key: 'approved_amount',
            assetClass: 'grants',
            id: 37,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Disbursed Amount',
        fields: [
          {
            name: null,
            type: 'number-range',
            key: 'disbursed_amount',
            assetClass: 'grants',
            id: 38,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Funder',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'funder',
            assetClass: 'grants',
            id: 39,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Key Biological Concepts',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'key_biological_concepts',
            assetClass: 'grants',
            id: 40,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Recipient',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'recipient',
            assetClass: 'grants',
            id: 41,
            spa: true,
          },
        ],
      },
      {
        sectionName: 'Source',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'source',
            assetClass: 'grants',
            id: 42,
            spa: true,
          },
        ],
      },
    ],
  },
  {
    AssetType: 'Regulatory Data',
    key: 'regulatory_data',
    children: [
      {
        subAssetType: null,
        children: [
          {
            sectionName: 'Document Type',
            fields: [
              {
                name: null,
                type: 'multiselect',
                key: 'sub_asset_classes',
                assetClass: 'regulatory_data',
                subAssetClass: null,
                id: 43,
                spa: false,
              },
            ],
          },
        ],
      },
      {
        subAssetType: 'Drug Labels',
        key: 'drug_labels',
        children: [
          {
            sectionName: 'Brand Name',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'brand_name',
                assetClass: 'regulatory_data',
                subAssetClass: 'drug_labels',
                id: 44,
                spa: true,
                spsa: true,
              },
            ],
          },
          {
            sectionName: 'Active Ingredient',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'active_ingredient',
                assetClass: 'regulatory_data',
                subAssetClass: 'drug_labels',
                id: 45,
                spa: true,
                spsa: true,
              },
            ],
          },
          {
            sectionName: 'Regulatory Body Name',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'regulatory_body',
                assetClass: 'regulatory_data',
                subAssetClass: 'drug_labels',
                id: 46,
                spa: true,
                spsa: true,
              },
            ],
          },

        ],
      },
      {
        subAssetType: 'Regulatory Decisions',
        key: 'regulatory_decisions',
        children: [
          {
            sectionName: 'Brand Name',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'brand_name',
                assetClass: 'regulatory_data',
                subAssetClass: 'regulatory_decisions',
                id: 47,
                spa: true,
                spsa: true,

              },
            ],
          },
          {
            sectionName: 'Active Ingredient',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'active_ingredient',
                assetClass: 'regulatory_data',
                subAssetClass: 'regulatory_decisions',
                id: 48,
                spa: true,
                spsa: true,
              },
            ],
          },
          {
            sectionName: 'Regulatory Body Name',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'regulatory_body',
                assetClass: 'regulatory_data',
                subAssetClass: 'regulatory_decisions',
                id: 49,
                spa: true,
                spsa: true,
              },
            ],
          },

        ],
      },
      {
        subAssetType: 'Regulatory Reviews',
        key: 'regulatory_reviews',
        children: [
          {
            sectionName: 'Brand Name',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'brand_name',
                assetClass: 'regulatory_data',
                subAssetClass: 'regulatory_reviews',
                id: 50,
                spa: true,
                spsa: true,
              },
            ],
          },
          {
            sectionName: 'Active Ingredient',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'active_ingredient',
                assetClass: 'regulatory_data',
                subAssetClass: 'regulatory_reviews',
                id: 51,
                spa: true,
                spsa: true,
              },
            ],
          },
          {
            sectionName: 'Regulatory Body Name',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'regulatory_body',
                assetClass: 'regulatory_data',
                subAssetClass: 'regulatory_reviews',
                id: 52,
                spa: true,
                spsa: true,
              },
            ],
          },

        ],
      },
      {
        subAssetType: 'Advisory Committee',
        key: 'advisory_committee',
        children: [

          {
            sectionName: 'Regulatory Body Name',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'regulatory_body',
                assetClass: 'regulatory_data',
                subAssetClass: 'advisory_committee',
                id: 53,
                spa: true,
                spsa: true,
              },
            ],
          },

        ],
      },
      {
        subAssetType: 'Drug Specific Guidances',
        key: 'drug_specific_guidances',
        children: [
          {
            sectionName: 'Dosage Form',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'dosage_form',
                assetClass: 'regulatory_data',
                subAssetClass: 'drug_specific_guidances',
                id: 54,
                spa: true,
                spsa: true,
              },
            ],
          },
          {
            sectionName: 'Active Ingredient',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'active_ingredient',
                assetClass: 'regulatory_data',
                subAssetClass: 'drug_specific_guidances',
                id: 55,
                spa: true,
                spsa: true,
              },
            ],
          },
          {
            sectionName: 'Route of Administration',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'route_of_administration',
                assetClass: 'regulatory_data',
                subAssetClass: 'drug_specific_guidances',
                id: 56,
                spa: true,
                spsa: true,
              },
            ],
          },
          {
            sectionName: 'Regulatory Body Name',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'regulatory_body',
                assetClass: 'regulatory_data',
                subAssetClass: 'drug_specific_guidances',
                id: 57,
                spa: true,
                spsa: true,
              },

            ],
          },

        ],
      },
      {
        subAssetType: 'General Guidances',
        key: 'general_guidances',
        children: [

          {
            sectionName: 'Regulatory Body Name',
            fields: [
              {
                name: null,
                type: 'autosuggest-multi',
                key: 'regulatory_body',
                assetClass: 'regulatory_data',
                subAssetClass: 'general_guidances',
                id: 58,
                spa: true,
                spsa: true,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    AssetType: 'HTA Recommendations',
    key: 'hta_recommendations',
    children: [
      {
        sectionName: 'Indication',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'indication',
            assetClass: 'hta_recommendations',
            id: 59,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Active Ingredient',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'active_ingredient',
            assetClass: 'hta_recommendations',
            id: 60,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Decision Type',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'decision_type',
            assetClass: 'hta_recommendations',
            id: 61,
            spa: false,
          },
        ],
      },
      {
        sectionName: 'Issuing Body Name',
        fields: [
          {
            name: null,
            type: 'autosuggest-multi',
            key: 'hta_body',
            assetClass: 'hta_recommendations',
            id: 62,
            spa: false,
          },
        ],
      },
    ],
  },
];

export const SEsearchFilters = [
  {
    name: 'asset_classes',
    label: 'Asset Classes',
    type: 'multiselect',
    assetClass: 'ot_search_assetclasses',
  },
  {
    name: 'study_start_date',
    label: 'Date Range',
    type: 'date-range',
    assetClass: 'ot_search_assetclasses',
  },
  {
    name: 'phase',
    label: 'Phase',
    type: 'multiselect',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'multiselect',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'country',
    label: 'Country/Region',
    type: 'autosuggest-multi',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'registry',
    label: 'Registry',
    type: 'dropdown',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'indication',
    label: 'Indication',
    type: 'autosuggest-multi',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'intervention',
    label: 'Intervention',
    type: 'autosuggest-multi',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'sponsor',
    label: 'Sponsor',
    type: 'autosuggest',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'enrollment_actual',
    label: 'Actual Enrollments',
    type: 'number-range',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'enrollment_anticipated',
    label: 'Anticipated Enrollments',
    type: 'number-range',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'endpoints',
    label: 'Endpoints',
    type: 'autosuggest',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'age',
    label: 'Age',
    type: 'number',
    subNames: ['min_age', 'max_age'],
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'gender',
    label: 'Gender',
    type: 'dropdown',
    assetClass: 'clinical_trials',
    key: 'Clinical Trials',
  },
  {
    name: 'author',
    label: 'Author',
    type: 'autosuggest-multi',
    assetClass: 'publications',
    key: 'Publications',
  },
  {
    name: 'citation',
    label: 'Citation',
    type: 'autosuggest-multi',
    assetClass: 'publications',
    key: 'Publications',
  },
  {
    name: 'country',
    label: 'Country/Region',
    type: 'autosuggest-multi',
    assetClass: 'publications',
    key: 'Publications',
  },
  {
    name: 'indication',
    label: 'Indication',
    type: 'autosuggest-multi',
    assetClass: 'publications',
    key: 'Publications',
  },
  {
    name: 'intervention',
    label: 'Intervention',
    type: 'autosuggest-multi',
    assetClass: 'publications',
    key: 'Publications',
  },
  {
    name: 'key_biological_concepts',
    label: 'Key Biological Concepts',
    type: 'autosuggest-multi',
    assetClass: 'publications',
    key: 'Publications',
  },
  {
    name: 'publication_type',
    label: 'Publication Type',
    type: 'autosuggest-multi',
    assetClass: 'publications',
    key: 'Publications',
  },
  {
    name: 'review_status',
    label: 'Review Status',
    type: 'multiselect',
    assetClass: 'publications',
    key: 'Publications',
  },
  {
    name: 'source',
    label: 'Source',
    type: 'autosuggest-multi',
    assetClass: 'publications',
    key: 'Publications',
  },
  {
    name: 'author',
    label: 'Author',
    type: 'autosuggest-multi',
    assetClass: 'congresses',
    key: 'Congresses',
  },
  {
    name: 'country',
    label: 'Country/Region',
    type: 'autosuggest-multi',
    assetClass: 'congresses',
    key: 'Congresses',
  },
  {
    name: 'indication',
    label: 'Indication',
    type: 'autosuggest-multi',
    assetClass: 'congresses',
    key: 'Congresses',
  },
  {
    name: 'key_biological_concepts',
    label: 'Key Biological Concepts',
    type: 'autosuggest-multi',
    assetClass: 'congresses',
    key: 'Congresses',
  },
  {
    name: 'source',
    label: 'Source',
    type: 'autosuggest-multi',
    assetClass: 'congresses',
    key: 'Congresses',
  },
  {
    name: 'inventor',
    label: 'Inventor',
    type: 'autosuggest-multi',
    assetClass: 'patents',
    key: 'Patents',
  },
  {
    name: 'key_biological_concepts',
    label: 'Key Biological Concepts',
    type: 'autosuggest-multi',
    assetClass: 'patents',
    key: 'Patents',
  },
  {
    name: 'source',
    label: 'Source',
    type: 'autosuggest-multi',
    assetClass: 'patents',
    key: 'Patents',
  },
  {
    name: 'key_biological_concepts',
    label: 'Key Biological Concepts',
    type: 'autosuggest-multi',
    assetClass: 'press_releases',
    key: 'Press Releases',
  },
  {
    name: 'source',
    label: 'Source',
    type: 'autosuggest-multi',
    assetClass: 'press_releases',
    key: 'Press Releases',
  },
  {
    name: 'author',
    label: 'Author',
    type: 'autosuggest-multi',
    assetClass: 'guidelines',
    key: 'Guidelines',
  },
  {
    name: 'indication',
    label: 'Indication',
    type: 'autosuggest-multi',
    assetClass: 'guidelines',
    key: 'Guidelines',
  },
  {
    name: 'key_biological_concepts',
    label: 'Key Biological Concepts',
    type: 'autosuggest-multi',
    assetClass: 'guidelines',
    key: 'Guidelines',
  },
  {
    name: 'source',
    label: 'Source',
    type: 'autosuggest-multi',
    assetClass: 'guidelines',
    key: 'Guidelines',
  },
  {
    name: 'approved_amount',
    label: 'Approved Amount',
    type: 'number-range',
    assetClass: 'grants',
    key: 'Grants',
  },
  {
    name: 'disbursed_amount',
    label: 'Disbursed Amount',
    type: 'number-range',
    assetClass: 'grants',
    key: 'Grants',
  },
  {
    name: 'funder',
    label: 'Funder',
    type: 'autosuggest-multi',
    assetClass: 'grants',
    key: 'Grants',
  },
  {
    name: 'key_biological_concepts',
    label: 'Key Biological Concepts',
    type: 'autosuggest-multi',
    assetClass: 'grants',
    key: 'Grants',
  },
  {
    name: 'recipient',
    label: 'Recipient',
    type: 'autosuggest-multi',
    assetClass: 'grants',
    key: 'Grants',
  },
  {
    name: 'source',
    label: 'Source',
    type: 'autosuggest-multi',
    assetClass: 'grants',
    key: 'Grants',
  },
  {
    name: 'sub_asset_classes',
    label: 'Document Type',
    type: 'multiselect',
    assetClass: 'regulatory_data',
    subAssetClass: null,
    key: 'Regulatory Data',
    preferred_name: 'sub_asset_classes',
  },
  {
    name: 'brand_name',
    label: 'Brand Name',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'drug_labels',
    key: 'Regulatory Data',
  },
  {
    name: 'active_ingredient',
    label: 'Active Ingredient',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'drug_labels',
    key: 'Regulatory Data',
  },
  {
    name: 'regulatory_body',
    label: 'Regulatory Body Name',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'drug_labels',
    key: 'Regulatory Data',
  },
  {
    name: 'brand_name',
    label: 'Brand Name',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'regulatory_decisions',
    key: 'Regulatory Data',
  },
  {
    name: 'active_ingredient',
    label: 'Active Ingredient',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'regulatory_decisions',
    key: 'Regulatory Data',
  },
  {
    name: 'regulatory_body',
    label: 'Regulatory Body Name',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'regulatory_decisions',
    key: 'Regulatory Data',
  },
  {
    name: 'brand_name',
    label: 'Brand Name',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'regulatory_reviews',
    key: 'Regulatory Data',
  },
  {
    name: 'active_ingredient',
    label: 'Active Ingredient',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'regulatory_reviews',
    key: 'Regulatory Data',
  },
  {
    name: 'regulatory_body',
    label: 'Regulatory Body Name',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'regulatory_reviews',
    key: 'Regulatory Data',
  },
  {
    name: 'regulatory_body',
    label: 'Regulatory Body Name',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'advisory_committee',
    key: 'Regulatory Data',
  },
  {
    name: 'dosage_form',
    label: 'Dosage Form',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'drug_specific_guidances',
    key: 'Regulatory Data',
  },
  {
    name: 'active_ingredient',
    label: 'Active Ingredient',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'drug_specific_guidances',
    key: 'Regulatory Data',
  },
  {
    name: 'route_of_administration',
    label: 'Route of Administration',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'drug_specific_guidances',
    key: 'Regulatory Data',
  },
  {
    name: 'regulatory_body',
    label: 'Regulatory Body Name',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'drug_specific_guidances',
    key: 'Regulatory Data',
  },
  {
    name: 'regulatory_body',
    label: 'Regulatory Body Name',
    type: 'autosuggest-multi',
    assetClass: 'regulatory_data',
    subAssetClass: 'general_guidances',
    key: 'Regulatory Data',
  },
  {
    name: 'indication',
    label: 'Indication',
    type: 'autosuggest-multi',
    assetClass: 'hta_recommendations',
    key: 'HTA Recommendations',
  },
  {
    name: 'active_ingredient',
    label: 'Active Ingredient',
    type: 'autosuggest-multi',
    assetClass: 'hta_recommendations',
    key: 'HTA Recommendations',
  },
  {
    name: 'decision_type',
    label: 'Decision Type',
    type: 'autosuggest-multi',
    assetClass: 'hta_recommendations',
    key: 'HTA Recommendations',
  },
  {
    name: 'hta_body',
    label: 'Regulatory Body Name',
    type: 'autosuggest-multi',
    assetClass: 'hta_recommendations',
    key: 'HTA Recommendations',
  },

];

export default CTFilterOption;
