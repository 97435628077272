/*eslint-disable*/

import { React, useEffect, useState } from "react";
import { Button, Input, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { editCommentAction } from "./logic";
import { getBookmarkAction } from "../../Bookmark/logic";
import { getSavedSearchesAction } from "../../SavedSearches/logic";
import { wordLimit } from "../../../../utils";

const { TextArea } = Input;

function Comment({ from, data, projectId, setOpenDrawer,currentPage }) {
  const dispatch = useDispatch();
  const editCommentData = useSelector((state) => state.editComment);
  const [editComment, setEditComment] = useState(data?.comment);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isEditPopoverVisible, setIsEditPopoverVisible] = useState(false);
  const [isReadMorePopoverVisible, setIsReadMorePopoverVisible] =
    useState(false);

  function epochToDate(epochTimestamp) {
    const dateObject = new Date(epochTimestamp * 1000);
    return dateObject.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  function capitalizeFirstLetterOfEachWord(str) {
    if (!str) {
      return "";
    }
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const handleUpdate = () => {
    if (from === "bookmark") {
      dispatch(
        editCommentAction({
          params: {
            project_id: projectId,
            bookmark_id: data?.bookmarkId,
            comment: editComment,
            search_id: "",
          },
        })
      );
    } else if (from === "saved-searches") {
      dispatch(
        editCommentAction({
          params: {
            project_id: projectId,
            bookmark_id: "",
            comment: editComment,
            search_id: data?.searchId,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (from === "bookmark") {

      if (editCommentData?.flag && (isPopoverVisible || isEditPopoverVisible)) {
        setIsPopoverVisible(false);
        setIsEditPopoverVisible(false);
        dispatch(
          getBookmarkAction({
            body: {
              project_id: projectId,
              asset_class: [],
              user_ids: [],
              export: false,
              file_type: "",
              sort: {},
              page: currentPage,
              size: 10,

            },
          })
        );
      }
    } else if (from === "saved-searches") {

      if (editCommentData?.flag && (isPopoverVisible || isEditPopoverVisible)) {
        setIsPopoverVisible(false);
        setIsEditPopoverVisible(false);
        dispatch(
          getSavedSearchesAction({
            body: {
              project_id: projectId,

              user_ids: [],
            },
          })
        );
      }
    }
  }, [editCommentData]);

  const renderEditCommentSection = () => (
    <div className="comment-section">
      <div className="comment-section-header">
        <div className="comment-title">Comment</div>
      </div>
      <div className="comment-info">
        {/* <div className="profile-icon" /> */}
        <div className="comment-info-name-section">
          <div className="log-menu-content">
            <div className="log-menu">
              <div className="log-menu-profile">
                <div aria-hidden className="user-name-initials">
                  <span
                    className="user"
                    title={capitalizeFirstLetterOfEachWord(data?.commentor)}
                  >
                    {data?.commentor?.charAt(0).toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-name">
            {capitalizeFirstLetterOfEachWord(data?.commentor)}
          </div>
        </div>
        <div className="comment-date">{epochToDate(data?.commentDate)}</div>
      </div>
      <div className="input-section">
        <TextArea
          rows={4}
          value={editComment}
          onChange={(e) => setEditComment(e.target.value)}
        />
      </div>
      <div className="comment-footer">
        <Button
          className="cancel-btn"
          onClick={() => {
            setIsPopoverVisible(false);
            setIsEditPopoverVisible(false);
          }}
        >
          {" "}
          Cancel
        </Button>
        <Button className="Update-btn" onClick={handleUpdate}>
          Update
        </Button>
      </div>
    </div>
  );

  const renderReadMoreSection = () => (
    <>
      <div className="comment-section">
        <div className="comment-section-header">
          <div className="comment-title">Comment</div>
          {/* <Popover
            trigger="click"
            content={renderEditCommentSection}
            placement="bottomLeft"
            open={isEditPopoverVisible}
          > */}
          <div
            className="edit-comment-icon"
            role="presentation"
            onClick={() => {
              setIsEditPopoverVisible(!isPopoverVisible);
              setIsReadMorePopoverVisible(false);
            }}
          />

          {/* </Popover> */}
          {/* <div className="edit-comment-icon" role="presentation" onClick={() => setIsPopoverVisible(true)} />Edit</div> */}
          <div
            className="close-icon"
            role="presentation"
            onClick={() => setIsReadMorePopoverVisible(false)}
          />
        </div>
        <div className="comment-info">
          {/* <div className="profile-icon" /> */}
          <div className="log-menu-content">
            <div className="log-menu">
              <div className="log-menu-profile">
                <div aria-hidden className="user-name-initials">
                  <span
                    className="user"
                    title={capitalizeFirstLetterOfEachWord(data?.commentor)}
                  >
                    {data?.commentor?.charAt(0).toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-name">
            {" "}
            {capitalizeFirstLetterOfEachWord(data?.commentor)}
          </div>
          <div className="comment-date">{epochToDate(data?.commentDate)}</div>
        </div>
        <div className="input-section">
          <div className="comment-text">{data?.comment}</div>
        </div>
      </div>
    </>
  );

  return (
    <div className="comment-section-container">
      <div className="comment-section-header">
        {/* <div className="profile-icon" /> */}
          <div className="log-menu-content">
            <div className="log-menu">
              <div className="log-menu-profile">
                <div aria-hidden className="user-name-initials">
                  <span
                    className="user"
                    title={capitalizeFirstLetterOfEachWord(data?.commentor)}
                  >
                    {data?.commentor
                      ? data.commentor.charAt(0).toUpperCase()
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-name">
            {" "}
            {capitalizeFirstLetterOfEachWord(data?.commentor)}
          </div>
          <div className="comment-date">{epochToDate(data?.commentDate)}</div>
        <Popover
          trigger="click"
          content={renderEditCommentSection}
          placement="bottomLeft"
          open={isEditPopoverVisible}
          onOpenChange={() => setIsEditPopoverVisible(!isEditPopoverVisible)}
          overlayClassName="edit-section-popover"
        >
          <div
            className="edit-comment-icon"
            role="presentation"
            onClick={() => {
              setIsPopoverVisible(!isPopoverVisible);
              setOpenDrawer(false);
            }}
          />
        </Popover>
      </div>
      <div className="comment-section-body">
        {data?.comment?.length === 0 ? (
          <div className="comment-text">No Comments added yet</div>
        ) : (
          <div className="comment-text"> {wordLimit(data?.comment, 30)}</div>
        )}
        <Popover
          open={isReadMorePopoverVisible}
          trigger="click"
          content={renderReadMoreSection}
          placement="bottomLeft"
          overlayClassName="edit-section-popover"
        >
          {data?.comment?.length && data?.comment.length > 30 ? (
            <span
              role="presentation"
              className="read-more"
              onClick={() => {
                setIsReadMorePopoverVisible(true);
              }}
            >
              Read More
            </span>
          ) : null}
        </Popover>
      </div>
    </div>
  );
}

export default Comment;
