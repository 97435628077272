import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { merge } from 'webpack-merge';
import Header from '../../../common/components/Header';
import SearchNavOptions from '../../../clinicalTrials/container/SearchNavOptions';
import CardDetail from '../../../clinicalTrials/container/CardDetail';
import { getCookie, modifyDataForGetResultsQuery } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';
import { hashGeneratorAction } from '../../../common/container/SavedSearches/logic';
import {
  getInterventionSearchResultsAction,
  getMoreInterventionSearchResultsAction,
  getInterventionSearchResultsRefreshAction,
  getInterventionSummaryAction,
} from '../../container/InterventionList/logic';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import Loader from '../../../common/components/Loader';
import ExploreList from '../../../searchAndExplore/container/ExploreList';
import ExploreSummaryCard from '../../../searchAndExplore/components/ExploreSummaryCard';

function InterventionResultPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const getInterventionSearchResults = useSelector(
    (state) => state.getInterventionSearchResults,
  );
  const interventionSummary = useSelector((state) => state.interventionSummary);
  const query = searchParams.get('query');
  const q = JSON.parse(query);
  const from = searchParams.get('from');
  const requestData = modifyDataForGetResultsQuery(q, true);
  const filters = searchParams.get('filters');
  const f = JSON.parse(filters);
  const qUrl = location.pathname + location.search;
  const workspaceQuery = searchParams.get('workspaceQuery');
  const workspaceFilters = searchParams.get('workspaceFilters');
  const wq = JSON.parse(workspaceQuery);
  const wf = JSON.parse(workspaceFilters);
  const deepdivepage = searchParams.get('deepdivepage');
  const patentFilters = searchParams.get('patentsQuery');
  const filtersData = modifyDataForGetResultsQuery(JSON.parse(filters), true);
  const patentFiltersData = modifyDataForGetResultsQuery(JSON.parse(patentFilters), true);
  const apiUrl = '/intervention/v0/search/getSearchResults';
  const autoSuggestUrl = '/explore/v0/search/getFieldValues';
  const sort = searchParams.get('sort');
  const projId = getCookie('projectId');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResultData, setSearchResultData] = useState([]);
  const [totalResultCount, setTotalResultCount] = useState('');
  const [activeProject, setActiveProject] = useState(projId);
  // eslint-disable-next-line no-unused-vars
  const [drawerWidth, setDrawerWidth] = useState(0);

  const [activeList, setActiveList] = useState({});
  const terminalName = 'Intervention';

  let out;

  let view = '';

  let workspaceQ = {};
  if (wq || wf) {
    workspaceQ = merge(wq || {}, wf || {}, filtersData || {});
  }
  if (!location.search.includes('semantic_search')) {
    view = 'Power Search';
  } else {
    view = 'Semantic Search';
  }

  if (patentFilters?.length) {
    out = merge(patentFiltersData, filtersData);
  } else {
    out = merge(requestData, filtersData);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const newProjectId = getCookie('projectId');
      if (newProjectId !== activeProject) {
        setActiveProject(newProjectId);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [getCookie('projectId')]);

  useEffect(() => {
    if (query?.length) {
      const params = {
        apiUrl,
        headers: queryString.stringify({
          from: currentPage,
          size: 10,
          project_id: projId,
        }),
        body: { filters: out.body },
      };
      if (sort && sort !== 'null' && sort !== '{}') {
        params.body = { ...params.body, sort: [JSON.parse(sort)] };
      }
      dispatch(getInterventionSearchResultsAction(params));
    } else if (JSON.stringify(workspaceQ) !== '{}') {
      const params = {
        apiUrl,
        headers: queryString.stringify({
          from: currentPage,
          size: 10,
          project_id: projId,
        }),
        body: { filters: workspaceQ.body },
      };
      if (sort && sort !== 'null' && sort !== '{}') {
        params.body = { ...params.body, sort: [JSON.parse(sort)] };
      }
      dispatch(getInterventionSearchResultsAction(params));
    }

    return () => {
      dispatch(getInterventionSearchResultsRefreshAction({}));
      setTotalResultCount('');
      setActiveList({});
    };
  }, []);

  useEffect(() => {
    if (query?.length) {
      const params = {
        apiUrl,
        headers: queryString.stringify({
          from: currentPage,
          size: 10,
          project_id: projId,
        }),
        body: { filters: out.body },
      };
      if (sort && sort !== 'null' && sort !== '{}') {
        params.body = { ...params.body, sort: [JSON.parse(sort)] };
      }
      dispatch(getInterventionSearchResultsAction(params));
    } else if (JSON.stringify(workspaceQ) !== '{}') {
      const params = {
        apiUrl,
        headers: queryString.stringify({
          from: currentPage,
          size: 10,
          project_id: projId,
        }),
        body: { filters: workspaceQ.body },
      };
      if (sort && sort !== 'null' && sort !== '{}') {
        params.body = { ...params.body, sort: [JSON.parse(sort)] };
      }
      dispatch(getInterventionSearchResultsAction(params));
    }

    return () => {
      dispatch(getInterventionSearchResultsRefreshAction({}));
      setTotalResultCount('');
      setActiveList({});
    };
  }, [activeProject]);

  useEffect(() => {
    if (getInterventionSearchResults?.flag) {
      setTotalResultCount(getInterventionSearchResults.data.total);
      setSearchResultData(getInterventionSearchResults.data.data);
      if (activeList && !Object.keys(activeList)?.length) {
        setActiveList(getInterventionSearchResults?.data?.data[0]);
      }
    }
  }, [JSON.stringify(getInterventionSearchResults)]);

  const hashApiCall = () => {
    dispatch(hashGeneratorAction(
      {
        body: {
          query: out.body,
          project_id: projId,
        },
      },
    ));
  };

  useEffect(() => {
    hashApiCall();
  }, []);

  useEffect(() => {
    if (activeList && Object?.keys(activeList).length) {
      const url = `/intervention/v0/search/${activeList.type}/resource/${activeList.doc_id}`;
      dispatch(
        getInterventionSummaryAction({
          params: {
            url,
            headers: queryString.stringify({
              asset_class: activeList.type,
              view: 'preView',
              sub_asset_class: activeList?.type === 'regulatory_data' ? activeList?.sub_type : undefined,
            }),
          },
        }),
      );
    }
    Mixpanel.track('preview_record_event', {
      action: 'Preview record',
      page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
      terminal_name: 'Intervention',
      widget_name: from,
      id: activeList?.publication_id || activeList?.congress_id || activeList?.patent_id || activeList?.press_id || activeList?.guideline_id || activeList?.grant_id || undefined,
      asset_class: activeList?.type || undefined,
    });
  }, [activeList]);

  useEffect(() => {
    Mixpanel.track('page_entry_event', {
      action: 'User has entered Intervention List View page',
      terminal_name: 'Intervention',
      page_name: 'List View Page',
    });
  }, []);

  const loadMoreListData = () => {
    if (query?.length) {
      const params = {
        apiUrl,
        headers: queryString.stringify({
          from: currentPage + 1,
          size: 10,
        }),
        body: {
          filters: out.body,
          sort: (sort && sort !== 'null') ? [JSON.parse(sort)] : undefined,
        },
      };
      dispatch(getMoreInterventionSearchResultsAction(params));
    }
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className="main-page">
      <Header className="results-header-light" isResultsPage type="light" reqBody={requestData} filtersData={filtersData} terminalName={terminalName} view={view} totalCount={totalResultCount} url={qUrl} q={q} f={f} hashApiCall={hashApiCall} />
      <div className="se-results-page explore-results">
        <div className="explore-results-nav">
          {totalResultCount && !getInterventionSearchResults.loading ? (
            <div className="explore-results-text">
              {`${totalResultCount} Results found`}
            </div>
          ) : (
            <div className="explore-results-text" />
          )}
          <SearchNavOptions isExplorePage terminalType="intervention" autoSuggestUrl={autoSuggestUrl} />
        </div>
        <div className="search-and-explore-results-container">
          <div className="search-and-explore-results-container-list">
            <Loader
              loading={!deepdivepage && getInterventionSearchResults?.loading}
              error={!deepdivepage && getInterventionSearchResults?.error}
              noData={!deepdivepage && !searchResultData?.length}
            >
              <LazyLoadDiv
                className="explore-list-results"
                id="document-list"
                total={getInterventionSearchResults?.data?.total}
                currentTotal={(searchResultData || []).length}
                height="calc(100vh - 200px)"
                loadMoreRows={loadMoreListData}
              >
                {searchResultData
                  && searchResultData?.map((list) => (
                    <ExploreList
                      data={list}
                      activeList={activeList}
                      setActiveList={setActiveList}
                    />
                  ))}
              </LazyLoadDiv>
            </Loader>
          </div>
          <div className="search-and-explore-results-container-details-container">
            <Loader
              loading={!deepdivepage && (getInterventionSearchResults?.loading || interventionSummary?.loading)}
              error={!deepdivepage && (getInterventionSearchResults.error || interventionSummary?.error)}
              noData={!deepdivepage && !searchResultData?.length}
            >
              {(activeList?.type === 'clinical_trials' || deepdivepage)
                ? <CardDetail data={activeList} setDrawerWidth={setDrawerWidth} />
                : (
                  <ExploreSummaryCard activeList={interventionSummary?.data?.data} />
                ) }
            </Loader>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InterventionResultPage;
