import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatEpochTime } from '../../../../../utils';
import { openChatbotAction } from '../../../Chatbot/logic';
import Loader from '../../../../components/Loader';

function ChatHistorySidebar({ setSelectedSessionId, chatData, selectedSessionId }) {
  const dispatch = useDispatch();
  const chatHistory = useSelector((state) => state.chatHistory);

  return (
    <div className="chat-history-sidebar-container">
      <Loader
        loading={chatHistory.loading}
        error={chatHistory.error}
        noData={chatHistory?.data?.length === 0}
        message="No chat found"
      >
        {chatData?.map((item) => {
          if (item.session && item.session.length > 0) {
            return (
              <div
                key={item.session_id}
                className={selectedSessionId === item.session_id ? 'chat-history-sidebar-item active' : 'chat-history-sidebar-item'}
                onClick={() => setSelectedSessionId(item.session_id)}
                role="presentation"
              >
                <div className="chat-history-sidebar-title" title={item.session[0].searched_query}>
                  {item.session[0].searched_query}
                </div>
                <div className="chat-history-sidebar-subheader">
                  <div className="log-menu-content">
                    <div className="log-menu">
                      <div className="log-menu-profile">
                        <div
                          aria-hidden
                          className="user-name-initials"
                        >
                          <span
                            className="user"
                            title={item?.user_name}
                          >
                            {item?.user_name
                              .charAt(0)
                              .toUpperCase()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chat-history-sidebar-time">
                    {formatEpochTime(item.session[0].updatedAt)}
                  </div>
                </div>

              </div>
            );
          }
          return null;
        })}
      </Loader>
      <div className="start-new-chat-option-container" role="presentation" onClick={() => dispatch(openChatbotAction())}>
        <div className="start-new-chat-option-container-btn">
          <div className="add-icon" />
          <div className="start-new-chat-title" role="presentation">
            Start new chat
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatHistorySidebar;
