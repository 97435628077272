import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Popover, Modal, Button, Input,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SvgLoader from '../../../../components/SvgLoader';
import Comment from '../../Comment';
import SavedSearchWorkspace from '../../../SavedSearchWorkspace';
import {
  shareSaveSearchAction,
  shareSaveSearchRefreshAction,
  getEmailPreferenceAction,
} from '../../../SavedSearches/logic';
import { sendNotification } from '../../../../components/Notification/logic';
import SavePreferences from '../../../SavePreferences';

function SavedSearchesCard({ data, projectId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shareSaveSearch = useSelector((state) => state.shareSaveSearch);
  const getEmailPreference = useSelector((state) => state.getEmailPreference);
  // eslint-disable-next-line no-unused-vars
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showSavedSearches, setShowSavedSearches] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emailTags, setEmailTags] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [message, setMessage] = useState('');
  const [showSavePreferences, setShowSavePreferences] = useState(false);
  const [userPreference, setUserPreference] = useState('');
  const [preferenceData, setPreferenceData] = useState({});

  function formatString(input) {
    const replacedString = input.replace(/_/g, ' ');
    const capitalizedString = replacedString
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return capitalizedString;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // eslint-disable-next-line no-shadow
  const handleSearchClick = (data) => {
    navigate(data?.queryUrl?.url);
  };

  const showShareModal = () => {
    setOpenShareModal(!openShareModal);
  };

  const handleBlur = () => {
    if (currentEmail.trim() === '') return;
    const emails = currentEmail.split(/[, ]+/);
    const validEmails = [];
    emails.forEach((email) => {
      if (emailRegex.test(email)) {
        validEmails.push(email);
      } else {
        setEmailError('Invalid email format');
      }
    });
    if (validEmails.length > 0) {
      setEmailTags([...emailTags, ...validEmails]);
      setCurrentEmail('');
      setEmailError('');
    }
  };

  const handleInviteClick = () => {
    if (emailTags.length > 0 && !emailError) {
      dispatch(
        shareSaveSearchAction({
          body: {
            email_ids: emailTags,
            terminal_name: data?.terminalName,
            view: data?.view,
            query: data?.query,
            filters: data?.filters,
            count: data?.count,
            comment: message || data?.comment,
            query_url: data?.queryUrl,
            hash: data?.hash,
          },
        }),
      );
      setOpenShareModal(!openShareModal);
      setEmailTags([]);
    }
  };

  useEffect(() => {
    if (shareSaveSearch?.flag && Object.keys(shareSaveSearch?.data).length) {
      if (shareSaveSearch.data.message.includes('not')) {
        dispatch(
          sendNotification({
            type: 'SAVE_SEARCH_SHARED_FAILURE',
            notificationType: 'error',
            message: shareSaveSearch.data.message,
            showNotification: true,
          }),
        );
      } else {
        dispatch(
          sendNotification({
            type: 'SAVE_SEARCH_SHARED_SUCCESS',
            notificationType: 'success',
            message: shareSaveSearch.data.message,
            showNotification: true,
          }),
        );
      }
      dispatch(shareSaveSearchRefreshAction());
    }
  }, [JSON.stringify(shareSaveSearch)]);
  const handleKeyDown = (e) => {
    if (currentEmail.trim() === '') return;
    if (e.key === 'Enter') {
      const emails = currentEmail.split(/[, ]+/);
      const validEmails = [];
      emails.forEach((email) => {
        if (emailRegex.test(email)) {
          validEmails.push(email);
        } else {
          setEmailError('Invalid email format');
        }
      });
      if (validEmails.length > 0) {
        setEmailTags([...emailTags, ...validEmails]);
        setCurrentEmail('');
        setEmailError('');
      }
    }
  };

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
  };

  const handleTagRemove = (emailToRemove) => {
    setEmailTags(emailTags.filter((email) => email !== emailToRemove));
  };

  const handleEmailPreferenceClick = () => {
    setShowSavePreferences(true);
    dispatch(getEmailPreferenceAction(
      {
        body: {
          hash: data?.hash,
        },
      },
    ));
  };

  useEffect(() => {
    if (data) {
      setUserPreference(data?.userPreference);
    }
  }, [data]);

  useEffect(() => {
    if (getEmailPreference?.flag) {
      setPreferenceData(getEmailPreference?.data?.data);
    }
  }, [getEmailPreference]);

  return (
    <div
      role="presentation"
      className="search-and-explore-results-container-list-content-main explore "
    >
      <div className="list-content-left">
        <div className="list-content-header">
          <div className="list-content-section">
            <div className="list-content-terminal-name">
              <span className="list-content-terminal-key">Terminal: </span>
              <span className="list-content-terminal-value">
                {formatString(data?.terminalName)}
              </span>
            </div>
            <SvgLoader
              className="divider"
              width={6}
              height={6}
              svgName="seperator-icon"
            />

            <div className="list-content-view">
              <div className="view-value">{data?.view}</div>
            </div>
            <SvgLoader
              className="divider"
              width={6}
              height={6}
              svgName="seperator-icon"
            />

            <div className="list-content-total-count">
              <span className="list-content-total-count-key">Result: </span>
              <span className="list-content-total-count-value">
                {data?.count}
              </span>
            </div>
          </div>
          <div className="list-icon-section" />
        </div>
        {data?.view !== 'Semantic Search' ? (
          <div className="list-content-title">
            {(data?.queryUrl?.query || data?.queryUrl?.q)?.map((itm) => itm?.currentFilter?.map((item) => (
              <div className="tags">
                <div className="list-content-title-key" key={item}>
                  {item}
                </div>
              </div>
            )))}
          </div>
        ) : (
          <div className="list-content-title">
            <div className="tags">
              <div className="list-content-title-key">
                {data?.query?.body?.body}
              </div>
            </div>
          </div>
        )}

        <div className="list-content-data">
          <div className="list-content-data-key">Filter: </div>
          <div className="list-content-data-value">
            {(data?.queryUrl?.filter || data?.queryUrl?.f)?.map((itm) => itm?.currentFilter?.map((item) => (
              <div className="list-content-title-key" key={item}>
                {item}
                <span>,</span>
              </div>
            )))}
          </div>
        </div>
      </div>
      <div className="list-content-right">
        <div className="list-icon-section">
          <div className="comment-section">
            <Comment
              from="saved-searches"
              data={data}
              projectId={projectId}
              setOpenDrawer={setOpenDrawer}
            />
          </div>
          <div
            className="search-icon-saved-searches"
            role="presentation"
            onClick={() => handleSearchClick(data)}
          />

          <Popover
            overlayClassName="save-preferences-popover"
            trigger="click"
            placement="bottom"
            open={showSavePreferences}
            onOpenChange={(open) => setShowSavePreferences(open)}
            content={(
              <SavePreferences setShowSavePreferences={setShowSavePreferences} hash={data?.hash} setUserPreference={setUserPreference} userPreference={userPreference} preferenceData={preferenceData} setPreferenceData={setPreferenceData} getEmailPreference={getEmailPreference} />

            )}
          >
            <div
              className={userPreference === 'Weekly' ? 'weekly-email-preference' : userPreference === 'Daily' ? 'daily-email-preference' : 'email-notification'}
              onClick={() => {
                handleEmailPreferenceClick();
              }}
              role="presentation"
            />
          </Popover>
          <Popover
            overlayClassName="bookmark-popover"
            trigger="click"
            placement="bottom"
            open={showSavedSearches}
            onOpenChange={(open) => setShowSavedSearches(open)}
            content={(
              <SavedSearchWorkspace
                projIds={projectId}
                data={data}
                setShowSavedSearches={setShowSavedSearches}
                reqBody={data?.query}
                onClose={() => setShowSavedSearches(!showSavedSearches)}
                filtersData={data?.filters}
                terminalName={data?.terminalName}
                view={data?.view}
                totalCount={data?.count}
                semanticQueryRequest={data?.query}
                queryUrl={data?.queryUrl}
              />
            )}
          >
            <div
              className="saved-search-active"
              onClick={() => {
                setShowSavedSearches(true);
              }}
              role="presentation"
            />
          </Popover>

          <div
            className="share-icon"
            role="presentation"
            onClick={() => showShareModal()}
          />

          {openShareModal && (
            <Modal
              className="share-with-modal"
              open={openShareModal}
              closable
              footer={false}
              onCancel={() => setOpenShareModal(false)}
            >
              <div className="share-modal-content">
                <div className="share-modal-content-input">
                  <div className="share-with-title">Share with</div>
                  <div
                    className="email-tags-container"
                    style={{
                      maxHeight: '200px',
                      overflowY: 'auto',
                      marginBottom: '10px',
                    }}
                  >
                    <div className="email-tags">
                      {emailTags.map((email, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} className="email-tag">
                          {email}
                          <span
                            role="presentation"
                            className="remove-tag"
                            onClick={() => handleTagRemove(email)}
                          />
                        </div>
                      ))}
                      <Input
                        value={currentEmail}
                        onChange={handleEmailInputChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => handleKeyDown(e)}
                        placeholder="Email ID"
                      />
                      {emailError && (
                        <div style={{ color: 'red' }}>{emailError}</div>
                      )}
                    </div>
                  </div>
                  <div className="share-with-message-input">
                    <Input
                      placeholder="Message (Optional)"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div className="invite-btn">
                    <Button
                      type="primary"
                      onClick={() => handleInviteClick()}
                    >
                      Invite
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}

export default SavedSearchesCard;
