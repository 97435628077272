import { React, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QueryString from 'qs';
import { merge } from 'webpack-merge';
import { Tooltip } from 'antd';
import SvgLoader from '../../../common/components/SvgLoader';
import { getQuestionsDataAction } from './logic';
import Loader from '../../../common/components/Loader';
import Mixpanel from '../../../utils/mixpanel';
import { wordLimit } from '../../../utils';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

const modifyDataForGetResultsQuery = (data, isSE, type) => {
  const request = {
    body: {},
  };
  data?.forEach((item) => {
    if (item?.currentFilter?.length > 0) {
      if (item.type !== 'number' && !request.body[item.name]) {
        request.body[item.name] = [];
      }
      if (item.type === 'date-range' || item.type === 'number-range') {
        item?.currentFilter?.forEach((filter, index) => {
          if (index === 0 && filter !== '') {
            request.body[item.name].push({
              value: `${filter}`,
              operator: 'GTE',
            });
          }
          if (index === 1 && filter !== '') {
            request.body[item.name].push({
              value: `${filter}`,
              operator: 'LTE',
            });
          }
          if (isSE && filter !== '') {
            if (request.body[item.name].length > 1) {
              request.body[item.name][index].asset_class = 'ot_search_assetclasses';
            } else {
              request.body[item.name][0].asset_class = 'ot_search_assetclasses';
            }
          }
          if (item.assetClass && request.body[item.name][index]) {
            request.body[item.name][index].asset_class = item.assetClass;
          }
          if (type === 'ctfilters' && filter !== '' && request.body[item.name][index]) {
            request.body[item.name][index].filterFrom = 'filter_search';
          }
        });
      } else if (item.name === 'gender') {
        if (item.currentFilter[0]?.length) {
          item.currentFilter[0].forEach((filter, i) => {
            request.body[item.name].push({
              value: filter,
              operator: 'OR',
            });
            // eslint-disable-next-line dot-notation
            if (isSE) {
              request.body[item.name][i].asset_class = 'ot_search_assetclasses';
            }
            if (item?.assetClass) {
              request.body[item.name][i].asset_class = item.assetClass;
            }
            if (type === 'ctfilters') {
              request.body[item.name][i].filterFrom = 'filter_search';
            }
          });
        }
      } else if (item.subAssetClass) {
        item?.currentFilter?.forEach((filter) => {
          request.body[item.name].push({
            value: filter,
            operator: 'OR',
            asset_class: 'regulatory_data',
            sub_asset_class: item.subAssetClass,
          });
        });
      } else if (item.type === 'free_text') {
        if (item.currentFilter[0].length > 0) {
          item?.currentFilter?.forEach((filter, i) => {
            request.body[item.name].push({
              value: filter,
              operator: 'OR',
            });
            if (isSE) {
              request.body[item.name][i].asset_class = 'ot_search_assetclasses';
            }
            if (item?.assetClass) {
              request.body[item.name][i].asset_class = item.assetClass;
            }
            if (type === 'ctfilters') {
              request.body[item.name][i].filterFrom = 'filter_search';
            }
          });
        }
      } else if (item.type === 'number') {
        if (item.currentFilter.length > 0) {
          item?.currentFilter?.forEach((filter, i) => {
            if (filter !== '') {
              request.body[item.subNames[i]] = [];
              request.body[item.subNames[i]].push({
                value: filter,
                operator: i === 0 ? 'GTE' : 'LTE',
              });
            }
            if (isSE) {
              request.body[item.subNames[i]][0].asset_class = 'ot_search_assetclasses';
            }
            if (item?.assetClass) {
              request.body[item.subNames[i]][0].asset_class = item.assetClass;
            }
          });
        }
      } else if (item.name === 'asset_classes') {
        item?.currentFilter?.forEach((filter) => {
          request.body[item.name].push({
            value: filter,
            operator: 'OR',
            asset_class: filter,
          });
        });
      } else if (item.name === 'primary_sponsor') {
        item?.currentFilter?.forEach((filter, i) => {
          request.body[item.name].push({
            value: filter,
            operator: item.op === 'industrial' ? 'OR' : 'NOT',
          });
          if (isSE) {
            request.body[item.name][i].asset_class = 'ot_search_assetclasses';
          }
          if (type === 'ctfilters') {
            request.body[item.name][i].filterFrom = 'filter_search';
          }
          if (item?.assetClass) {
            request.body[item.name][i].asset_class = item.assetClass;
          }
        });
      } else {
        item?.currentFilter?.forEach((filter, i) => {
          request.body[item.name].push({
            value: filter,
            operator: 'OR',
          });
          if (isSE) {
            request.body[item.name][i].asset_class = 'ot_search_assetclasses';
          }
          if (type === 'ctfilters') {
            request.body[item.name][i].filterFrom = 'filter_search';
          }
          if (item?.assetClass) {
            request.body[item.name][i].asset_class = item.assetClass;
          }
        });
      }
    }
  });
  return request;
};

function RecommendedQuestions({ currentExpanded, selected, params }) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const query = searchParams.get('query');
  const q = JSON.parse(query);
  const requestData = modifyDataForGetResultsQuery(q);

  const semanticQuery = searchParams.get('semanticquery');
  const filters = searchParams.get('filters');
  const filtersData = modifyDataForGetResultsQuery(JSON.parse(filters), true);
  const out = merge(requestData, filtersData);

  const questionsData = useSelector((state) => state.questionsData);

  const handleRowClick = (row) => {
    const tempQuery = [{
      field_name: 'semantic_search',
      currentFilter: [encodeURIComponent(row)],
      type: 'sematic',
    }];
    navigate(`/searchAndExplore/analytics?semanticquery=${JSON.stringify(tempQuery)}`);
    navigate(0);
    Mixpanel.track('optimize_search_event', {
      action: 'Search',
      page: 'Analytics Page',
      widget_name: 'Recommended Next Searches',
      terminal_name: 'Search and Explore',
      value: row,
    });
  };

  useEffect(() => {
    const tempParams = {
      headers: params.headers,
      body: { filters: out.body },
    };

    const request = {
      body: {
        filters: {
          ...tempParams.body.filters,
        },
      },
      headers: QueryString.stringify({
        semantic_search: semanticQuery?.length ? JSON.parse(semanticQuery)[0]?.currentFilter[0] : undefined,
      }),
    };

    debounceCall(() => {
      dispatch(getQuestionsDataAction(request));
    }, 60);
  }, []);

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  return (
    <div className="Widget">
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="questions-header-text">
            {selected?.label}
            <div className="partex-ai-icon" />
          </div>
        </div>
      </div>
      <div className="questions-data-container">
        <Loader
          loading={questionsData?.loading}
          error={questionsData?.error}
          noData={!questionsData?.data?.length}
          height="250px"
          className="white-loader"
          message="No records found"

        >
          <table>
            <tbody>
              {questionsData?.data?.slice(0, 5).map((row) => (
                <tr key={row} className="clickable-row">
                  <Tooltip title={row} color="white" placement="bottomLeft" key="white">
                    <td>
                      <div className="questions-bar" role="presentation" onClick={() => handleRowClick(row)}>
                        <div className="search-questions" role="presentation" />
                        <div className="questions-title">{wordLimit(row, 70)}</div>
                        <div className="search-arrow" />

                      </div>

                    </td>
                  </Tooltip>

                </tr>
              ))}
            </tbody>
          </table>
          {/* <div id="topics-bar" /> */}
        </Loader>

      </div>
    </div>
  );
}

RecommendedQuestions.propTypes = {
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    graphData: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
};

export default RecommendedQuestions;
