import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import SvgLoader from '../../../common/components/SvgLoader';
import webAuth from '../../../utils/webAuth';
import { sendNotification } from '../../../common/components/Notification/logic';
import {
  checkUserAction,
  checkUserRefreshAction,
  registerUserAction,
  registerUserRefreshAction,
} from './logics';
import { getCookie, setCookie } from '../../../utils';
import Mixpanel, { encrypt } from '../../../utils/mixpanel';

function TryOntosightModal({
  open,
  setOpen,
  setOpenDeclarations,
  setOpenLoginModal,
}) {
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const [inputEmail, setInputEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [validOrgName, setVaildOrgName] = useState(true);
  const [otpSent, setOTPSent] = useState(false);
  const [loginBtnDissabled, setLoginBtnDissabled] = useState(true);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [validName, setValidName] = useState(true);
  const [type, setType] = useState('organization');
  const [loginIndBtnDissabled, setloginIndBtnDissabled] = useState(true);
  const [showNotOrgEmail, setShowNotOrgEmail] = useState(false);
  const [userInfo, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    purpose: '',
    orgName: '',
  });

  const checkUser = useSelector((state) => state.checkUser);
  const registerUserStatus = useSelector((state) => state.registerUserStatus);

  const setParamsToDefault = () => {
    setUserDetails({
      ...{
        firstName: '',
        lastName: '',
        email: '',
        purpose: '',
        orgName: '',
      },
    });
    setVaildOrgName(true);
    setValidName(true);
    setValidEmail(true);
    setVaildOrgName(true);
    setInputEmail('');
    setOTPSent(false);
    setOtp([...['', '', '', '', '', '']]);
    setloginIndBtnDissabled(true);
    setLoginBtnDissabled(true);
    setShowNotOrgEmail(false);
    dispatch(checkUserRefreshAction());
    dispatch(registerUserRefreshAction());
  };

  const processEmail = (email) => {
    const plusIndex = email.indexOf('+');
    if (plusIndex !== -1) {
      return (
        email.substring(0, plusIndex) + email.substring(email.indexOf('@'))
      );
    }
    return email;
  };

  useEffect(() => {
    if (registerUserStatus.flag) {
      if (registerUserStatus.data.message === 'User registered successfully') {
        dispatch(
          sendNotification({
            type: 'REGISTRATION_SUCCESS',
            notificationType: 'success',
            message: 'User registered successfully',
            showNotification: true,
          }),
        );
        setParamsToDefault();
        setOpen(false);
        setOpenDeclarations(true);
      }
    }

    if (registerUserStatus.error) {
      if (registerUserStatus.data.statusCode === 500) {
        dispatch(
          sendNotification({
            type: 'REGISTRATION_FAILURE',
            notificationType: 'error',
            message: 'User already registered',
            showNotification: true,
          }),
        );
        setParamsToDefault();
        setOpen(false);
      }
    }
  }, [JSON.stringify(registerUserStatus)]);

  useEffect(() => {
    if (checkUser.flag && !checkUser.data.userExists) {
      setCookie(checkUser.data.isPersonaBuild, 'personaBuild');
      setCookie(processEmail(inputEmail || userInfo.email), 'userEmail');
      if (type === 'organization' && !checkUser.data.userExistsByOrgEmail) {
        setShowNotOrgEmail(true);
      }

      if (type === 'organization' && checkUser.data.userExistsByOrgEmail) {
        setParamsToDefault();
        setCookie('org', 'freshUser');
        Mixpanel.track('persona_event', {
          action: 'user persona building process has began',
          data: getCookie('userEmail'),
        });
        navigate('/persona');
      } else if (
        checkUser.data.registrationStatus === 'NA'
        && type === 'individual'
      ) {
        setCookie(userInfo.firstName, 'firstNameTrialUser');
        dispatch(
          registerUserAction({
            body: {
              first_name: userInfo.firstName,
              last_name: userInfo.lastName,
              email: userInfo.email.toLowerCase(),
              organization_name: userInfo.orgName,
              purpose: userInfo.purpose || '-',
            },
          }),
        );
      }

      if (type === 'individual' && checkUser.data.registrationStatus !== 'NA') {
        dispatch(
          sendNotification({
            type: 'CHECK_USER_FAILURE ',
            notificationType: 'error',
            message: 'User already registered',
            showNotification: true,
          }),
        );
        setParamsToDefault();
      }
    }

    if (
      checkUser.flag
      && type === 'organization'
      && checkUser.data.userExists
    ) {
      setCookie(checkUser.data.isPersonaBuild, 'personaBuild');
      dispatch(
        sendNotification({
          type: 'CHECK_USER_FAILURE ',
          notificationType: 'error',
          message: 'You are already registered! Please log in to continue',
          showNotification: true,
        }),
      );
      setParamsToDefault();
      setOpenLoginModal(true);
      setOpen(false);
    }

    if (checkUser.flag && type === 'individual' && checkUser.data.userExists) {
      dispatch(
        sendNotification({
          type: 'CHECK_USER_FAILURE ',
          notificationType: 'error',
          message: 'You are already registered! Please log in to continue',
          showNotification: true,
        }),
      );
      setParamsToDefault();
      setOpenLoginModal(true);
      setOpen(false);
    }
  }, [JSON.stringify(checkUser)]);

  useEffect(() => {
    setParamsToDefault();
  }, [type]);

  const baseWidth = (window.innerWidth * 55) / 100;
  const baseHeight = baseWidth * 0.8;

  const imageSize = {
    width: baseWidth / 2,
  };

  useEffect(() => {
    const emptyCheck = inputEmail === '';
    setLoginBtnDissabled(!validEmail || emptyCheck);
  }, [inputEmail, validEmail]);

  useEffect(() => {
    const emptyCheck = userInfo.email === '' || userInfo.orgName === '';
    setloginIndBtnDissabled(
      !validEmail || !validName || !validOrgName || emptyCheck,
    );
  }, [JSON.stringify(userInfo), validEmail, validName, validOrgName]);

  const handleEmailIdValidation = () => {
    const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidEmail(check.test(userInfo.email));
  };

  const handleEmailIdValidationOrg = () => {
    const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidEmail(check.test(inputEmail));
  };

  const handleLoginClick = () => {
    if (!loginBtnDissabled) {
      dispatch(checkUserRefreshAction());
      dispatch(
        checkUserAction({
          headers: queryString.stringify({
            email: inputEmail.toLowerCase(),
          }),
        }),
      );
      Mixpanel.track('get_instant_access_btn_click_event', {
        action:
          'get instant access button click from Try Ontosight for free Org modal',
        pseudonymised_email_id: encrypt(inputEmail),
        page: 'Landing Page',
      });
    }
  };

  const handleSubmitDetails = () => {
    if (!loginIndBtnDissabled) {
      dispatch(checkUserRefreshAction());
      dispatch(
        checkUserAction({
          headers: queryString.stringify({
            email: userInfo.email.toLowerCase(),
          }),
        }),
      );
      Mixpanel.track('button_click_event', {
        action:
          'submit details button click from Try Ontosight for free Org modal',
        pseudonymised_email_id: encrypt(userInfo.email),
        page: 'Landing Page',
      });
    }
  };

  const handleInputChange = (index, value) => {
    if (value.length === 6) {
      setOtp([...value.split('')]);
    } else {
      const newOTP = [...otp];
      newOTP[index] = value.slice(0, 1);
      setOtp(newOTP);
    }
  };

  const verifyOTP = () => {
    webAuth.passwordlessLogin(
      {
        client_id: process.env.auth0.clientId,
        credential_type: 'passwordless_otp',
        username: inputEmail,
        otp: otp.join(''),
        realm: 'email',
      },
      (err) => {
        if (err) {
          dispatch(
            sendNotification({
              type: 'OTP_VERIFICATION_FAILED_3',
              notificationType: 'error',
              message:
                err?.message || 'Oops something went wrong, please try again',
              showNotification: true,
            }),
          );
        }
      },
    );
  };

  const hanldeChange = (input) => {
    setUserDetails({ ...userInfo, ...input });
  };

  const handleNameValidation = () => {
    // const check = /^[A-Za-z\s]+$/;
    const check = /^[A-Za-z\s]*$/;
    setValidName(
      check.test(userInfo.firstName) && check.test(userInfo.lastName),
    );
  };

  const handleOrgNameValidation = () => {
    setVaildOrgName(userInfo.orgName !== '');
  };

  const handleTypeChagne = (value) => {
    setType(value);
    setParamsToDefault();
    if (value === 'organization') {
      Mixpanel.track('organization_button click_event', {
        action: 'Organization buttion click in Try Ontosight for free modal',
        page: 'Landing Page',
      });
    } else {
      Mixpanel.track('individual_button click_event', {
        action: 'individual buttion click in Try Ontosight for free modal',
        page: 'Landing Page',
      });
    }
  };

  const handleCloseBtnClic = () => {
    setOpen(false);
    setParamsToDefault();
  };

  const handleOrgEmailIdUpdate = (email) => {
    setInputEmail(email);
    Mixpanel.track('login_email_input_event', {
      action: 'User entering email on login modal',
      pseudonymised_email_id: encrypt(email),
      page: 'Landing Page',
    });
  };

  const renderOTPinput = () => (
    <div className="otp-container">
      {otp.map((digit, index) => (
        <input
          key={`${digit}-${Math.random()}`}
          type="text"
          value={digit}
          onChange={(e) => handleInputChange(index, e.target.value)}
          ref={(ref) => {
            inputRefs.current[index] = ref;
          }}
        />
      ))}
    </div>
  );
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !loginBtnDissabled) {
      handleLoginClick();
    }

    Mixpanel.track('field_tap_event', {
      action: 'Enter',
      value: 'Email',
      page_name: 'Login Page pop up',
      user_type: 'Organization',
    });
  };

  const handleIndividualKeyDown = (event) => {
    if (event.key === 'Enter' && !loginIndBtnDissabled) {
      handleSubmitDetails();
    }
    Mixpanel.track('field_tap_event', {
      action: 'Enter',
      value: 'Email',
      page_name: 'Login Page pop up',
      user_type: 'Individual',
    });
  };

  const handleDivClick = (value, userType) => {
    Mixpanel.track('field_tap_event', {
      action: 'user tapped on input box to fill email',
      value,
      page_name: 'Login Page pop up',
      user_type: userType,
    });
  };

  const renderOrganizationRequest = () => (
    <div>
      {otpSent ? (
        <div className="otp-verfification-text">OTP Verification</div>
      ) : (
        <div className="email-text">Email</div>
      )}
      {otpSent ? (
        <div>
          {renderOTPinput()}
          <div className="otp-resend-text">
            Didn’t you receive the OTP?
            <span
              aria-hidden
              className="otp-resend-text-blue pointer"
              onClick={() => handleLoginClick()}
            >
              &nbsp; Resend OTP
            </span>
          </div>
          <div className="otp-not-received-text">
            Having trouble finding your OTP? Try checking your spam or junk
            email folder
          </div>
        </div>
      ) : (
        <input
          onBlur={handleEmailIdValidationOrg}
          value={inputEmail}
          className="Login-email-input"
          type="text"
          placeholder="name@xyz.com"
          onChange={(e) => handleOrgEmailIdUpdate(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          onClick={() => handleDivClick('email', 'Organization')}
        />
      )}
      {!validEmail ? (
        <div className="not-valid">Please enter valid email</div>
      ) : null}
    </div>
  );

  const renderIndividualRequest = () => (
    <div className="individual-request">
      <div className="ind-sub-header">
        <span className="info-icon" />
        Individual accounts get approved within 2 business days. But you will
        have few hours of complimentary first time use.
      </div>
      <div className="input-name-ctr">
        <div className="input-name-labels">
          <div className="name-label">First Name</div>
          <div className="name-label">Last Name</div>
        </div>
        <div className="input-names">
          <input
            value={userInfo.firstName}
            onBlur={handleNameValidation}
            className="name-input"
            type="text"
            onChange={(e) => hanldeChange({ firstName: e.target.value })}
            onClick={() => handleDivClick('first name', 'Individual')}
          />
          <input
            value={userInfo.lastName}
            onBlur={handleNameValidation}
            className="name-input"
            type="text"
            onChange={(e) => hanldeChange({ lastName: e.target.value })}
            onClick={() => handleDivClick('last name', 'Individual')}
          />
        </div>
        <span className="not-valid-full-width">
          {`${
            validName ? '' : 'Please enter valid name'
          }`}
        </span>
      </div>
      <div>
        <div className="name-label">Email</div>
        <input
          value={userInfo.email}
          onBlur={handleEmailIdValidation}
          className="name-input-email"
          type="text"
          placeholder="name@xyz.com"
          onChange={(e) => hanldeChange({ email: e.target.value })}
          onKeyDown={(e) => handleIndividualKeyDown(e)}
          onClick={() => handleDivClick('email', 'Individual')}
        />
        {!validEmail ? (
          <div className="not-valid-full-width">Please enter valid email</div>
        ) : null}
      </div>
      <div>
        <div className="name-label">Organization Name</div>
        <input
          value={userInfo.orgName}
          onBlur={handleOrgNameValidation}
          className="name-input-email"
          type="text"
          onChange={(e) => hanldeChange({ orgName: e.target.value })}
          onKeyDown={(e) => handleIndividualKeyDown(e)}
          onClick={() => handleDivClick('organization', 'Individual')}
        />
        {!validOrgName ? (
          <div className="not-valid-full-width">
            Organization name cannot be left empty
          </div>
        ) : null}
      </div>
      <div>
        <div className="name-label">Purpose of Use</div>
        <textarea
          value={userInfo.purpose}
          className="name-input-email"
          type="text"
          placeholder="Your intended usage will allow us to improve the product for you and other similar users"
          onChange={(e) => hanldeChange({ purpose: e.target.value })}
          onClick={() => handleDivClick('purpose of use', 'Individual')}
        />
      </div>
    </div>
  );

  return (
    <Modal
      className="login-modal-ctr"
      onCancel={() => handleCloseBtnClic()}
      footer={false}
      open={open}
      closeIcon={false}
      width={baseWidth}
      height={baseHeight}
    >
      <div className="login-modal">
        <div className="side-image" style={imageSize} />
        {/* <Button className="switch-profile-btn" onClick={() => handleSwitchProfile()}>Switch Profile</Button> */}
        <SvgLoader
          width={24}
          height={24}
          svgName="cross-login"
          onClick={() => handleCloseBtnClic()}
          pointer
        />
        <div className="login-header">
          <div className="login-form">
            <div
              className={`welcome-text${
                type === 'individual' ? '-no-margin-top' : ''
              }`}
            >
              Welcome, start your
            </div>
            <div className="welcome-text-second">free trial</div>
            {showNotOrgEmail && (
              <div className="otp-inbox-message">
                <SvgLoader width={16} height={16} svgName="not-icon" />
                <span className="not-signed">
                  Organization domain not registered with us. Please apply for
                  Individual account
                </span>
              </div>
            )}
            {!otpSent ? (
              <div className="menu-user-type">
                <div
                  aria-hidden
                  onClick={() => handleTypeChagne('organization')}
                  className={`org-btn btn-user-type${
                    type === 'organization' ? '-active' : ''
                  } pointer`}
                >
                  Organization
                </div>
                <div
                  aria-hidden
                  onClick={() => handleTypeChagne('individual')}
                  className={`individual-btn btn-user-type${
                    type === 'individual' ? '-active' : ''
                  } pointer`}
                >
                  Individual
                </div>
              </div>
            ) : null}
            {type === 'organization' ? renderOrganizationRequest() : null}
            {type === 'individual' ? renderIndividualRequest() : null}
          </div>
          {type === 'organization' ? (
            otpSent ? (
              <div
                aria-hidden
                onClick={verifyOTP}
                className="login-button pointer"
              >
                Verify OTP
              </div>
            ) : (
              <div
                aria-hidden
                onClick={() => handleLoginClick()}
                className={`${
                  loginBtnDissabled
                    ? 'login-button-dissabled'
                    : 'login-button pointer'
                }`}
              >
                Get Instant access
              </div>
            )
          ) : (
            <div
              aria-hidden
              onClick={() => handleSubmitDetails()}
              className={`${
                loginIndBtnDissabled
                  ? 'login-button-dissabled'
                  : 'login-button pointer'
              }`}
            >
              Submit Details
            </div>
          )}
          <div className="login-here-text">
            Already registered?
            { }
            <span className="login-here-btn" onClick={() => { setOpenLoginModal(true); setOpen(false); }} role="presentation">Login here</span>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TryOntosightModal;
