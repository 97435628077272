import { getSearchResultsReducer } from '../../common/container/List/logic';
import { widgetsReducer } from './AnalyticsContainer/logic';
import { getPhaseGraphDataReducer } from './PhaseGraph/logic';
import getGraphDataReducer from './PieChart/logic';
import getInterventionMeterReducer from './InterventionMeter/logic';
import getSponsorDataReducer from './SpeedometerGraph/logic';
import getEnrolomentDataReducer from './EnrolementMeter/logic';
import { getEndpointsGraphDataReducer, getEndpointsStatusDataReducer } from './EndopointsGraph/logics';
import {
  getGeographicalDataReducer, getGeographicalStateDataReducer, dateRangeReducer, showBackButtonReducer,
} from './Geographical/logic';
import { getExploreDeepDiveDetailsReducer, getExploreDeepDiveMentionsDetailsReducer, getExploreDeepDiveHistoryDetailsReducer } from './DeepDiveContainer/logic';
import { getSponsorDeepDiveDataReducer } from '../components/SponsorDeepDive/logic';
import { getNewsLetterDataReducer, getNewsLetterDataExpandedReducer } from './NewsLetter/logic';
import { getSetBackButtonStatusReducer, getSetCurrentTabReducer } from './CTdeepDive/logic';
import getSummaryDataReducer from './CardDetail/logic';
import { addLicenseReducer } from '../../app/container/LoginModalV2/logic';
import { feedbackModalReducer, getFeedbackQuestionsReducer } from '../../app/container/FeedbackModal/logic';

const rootClinicalTrialsReducer = ({
  searchResult: getSearchResultsReducer,
  graphData: getGraphDataReducer,
  widgets: widgetsReducer,
  phaseGraphData: getPhaseGraphDataReducer,
  interventionMeterData: getInterventionMeterReducer,
  sponsorData: getSponsorDataReducer,
  enrolomentData: getEnrolomentDataReducer,
  endpointsData: getEndpointsGraphDataReducer,
  endpointStatusData: getEndpointsStatusDataReducer,
  geoGraphicalCountryData: getGeographicalDataReducer,
  geoGraphicalStateData: getGeographicalStateDataReducer,
  getDateRangeData: dateRangeReducer,
  getExploreDeepDiveDetails: getExploreDeepDiveDetailsReducer,
  getSponsorDeepDiveData: getSponsorDeepDiveDataReducer,
  getNewsLetterData: getNewsLetterDataReducer,
  getExploreDeepDiveMentionsData: getExploreDeepDiveMentionsDetailsReducer,
  getExploreDeepDiveHistoryData: getExploreDeepDiveHistoryDetailsReducer,
  getNewsLetterDataExpanded: getNewsLetterDataExpandedReducer,
  showBackButton: showBackButtonReducer,
  backButtonStatus: getSetBackButtonStatusReducer,
  currentTab: getSetCurrentTabReducer,
  summaryData: getSummaryDataReducer,
  LicenseStatus: addLicenseReducer,
  toggleFeedback: feedbackModalReducer,
  feedbackQuestions: getFeedbackQuestionsReducer,
});

export const clinicalTrialsReducerWhiteList = [];

export default rootClinicalTrialsReducer;
