import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const REFERRAL_MODAL = 'REFERRAL_MODAL';

export const setReferralOpenModalAction = createAction(REFERRAL_MODAL);

const onSetReferralModal = (state, payload) => ({
  ...state,
  toggleReferralOpen: payload,
});

export const referralModalOpenReducer = createReducer(
  {
    toggleReferralOpen: false,
  },
  (builder) => {
    builder.addCase(setReferralOpenModalAction, (state, action) => onSetReferralModal(state, action.payload))
      .addDefaultCase((state) => state);
  },
);

// get Partner details
const CHECK_USER_MULTI = 'CHECK_USER_MULTI';
const CHECK_USER_MULTI_SUCCESS = 'CHECK_USER_MULTI_SUCCESS';
const CHECK_USER_MULTI_FAILURE = 'CHECK_USER_MULTI_FAILURE';
const CHECK_USER_MULTI_REFRESH = 'CHECK_USER_MULTI_REFRESH';

export const checkUserMultiAction = createAction(CHECK_USER_MULTI);

const checkUserMultiSuccess = createAction(CHECK_USER_MULTI_SUCCESS);

const checkUserMultiFailure = createAction(CHECK_USER_MULTI_FAILURE);

export const checkUserMultiRefreshAction = createAction(CHECK_USER_MULTI_REFRESH);

export const checkUserMultiEpic = (actions$) => actions$.pipe(
  ofType(CHECK_USER_MULTI),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users/checkUser?${action.payload.headers}`, 'GET', action.payload.params)).pipe(
    map((res) => checkUserMultiSuccess(res)),
    catchError((err) => of(checkUserMultiFailure(err))),
  )),
);

const checkUserMultiReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(checkUserMultiAction, (state) => {
      state.loading = true;
    })
      .addCase(checkUserMultiSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(checkUserMultiFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(checkUserMultiRefreshAction, (state) => {
        state.data = [];
        state.loading = false;
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

export default checkUserMultiReducer;

const ADD_REFERRAL = 'ADD_REFERRAL';
const ADD_REFERRAL_SUCCESS = 'ADD_REFERRAL_SUCCESS';
const ADD_REFERRAL_FAILURE = 'ADD_REFERRAL_FAILURE';
const ADD_REFERRAL_REFRESH = 'ADD_REFERRAL_REFRESH';

export const addReferralAction = createAction(ADD_REFERRAL);

const addReferralSuccess = createAction(ADD_REFERRAL_SUCCESS);

const addReferralFaliure = createAction(ADD_REFERRAL_FAILURE);

export const addReferralRefreshAction = createAction(ADD_REFERRAL_REFRESH);

export const addReferralEpic = (actions$) => actions$.pipe(
  ofType(ADD_REFERRAL),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/addReferral?`, 'POST', action.payload.body)).pipe(
    map((res) => addReferralSuccess(res)),
    catchError((err) => of(addReferralFaliure(err))),
  )),
);

const onaddReferralAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onaddReferralSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onaddReferralFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onaddReferralRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const addReferralReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(addReferralAction, (state) => onaddReferralAction(state))
      .addCase(addReferralSuccess, (state, action) => onaddReferralSuccess(state, action.payload))
      .addCase(addReferralFaliure, (state) => onaddReferralFailure(state))
      .addCase(addReferralRefreshAction, () => onaddReferralRefreshAction())
      .addDefaultCase((state) => state);
  },
);

const FEEDBACK_AND_REFERRAL_MODAL = 'FEEDBACK_AND_REFERRAL_MODAL';

export const setFeedBackAndReferralModalOpenAction = createAction(FEEDBACK_AND_REFERRAL_MODAL);

const onFeedbackAndReferralOpen = (state, payload) => ({
  ...state,
  trigger: payload,
});

export const feedbackAndReferralModalOpenReducer = createReducer(
  {
    trigger: false,
  },
  (builder) => {
    builder.addCase(setFeedBackAndReferralModalOpenAction, (state, action) => onFeedbackAndReferralOpen(state, action.payload))
      .addDefaultCase((state) => state);
  },
);
