import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

const GET_CHAT_HISTORY = 'GET_CHAT_HISTORY';
const GET_CHAT_HISTORY_SUCCESS = 'GET_CHAT_HISTORY_SUCCESS';
const GET_CHAT_HISTORY_FAILURE = 'GET_CHAT_HISTORY_FAILURE';
const GET_CHAT_HISTORY_REFRESH = 'GET_CHAT_HISTORY_REFRESH';

export const getChatHistoryAction = createAction(GET_CHAT_HISTORY);

const getChatHistorySuccess = createAction(GET_CHAT_HISTORY_SUCCESS);

const getChatHistoryFaliure = createAction(GET_CHAT_HISTORY_FAILURE);

export const getChatHistoryRefreshAction = createAction(GET_CHAT_HISTORY_REFRESH);

export const getChatHistoryEpic = (actions$) => actions$.pipe(
  ofType(GET_CHAT_HISTORY),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/chatbot/history?${action.payload.params.headers}`, 'GET')).pipe(
    map((res) => getChatHistorySuccess(res)),
    catchError((err) => of(getChatHistoryFaliure(err))),
  )),
);

const ongetChatHistoryAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetChatHistorySuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetChatHistoryFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetChatHistoryRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const getChatHistoryReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getChatHistoryAction, (state) => ongetChatHistoryAction(state))
      .addCase(getChatHistorySuccess, (state, action) => ongetChatHistorySuccess(state, action.payload))
      .addCase(getChatHistoryFaliure, (state) => ongetChatHistoryFailure(state))
      .addCase(getChatHistoryRefreshAction, () => ongetChatHistoryRefreshAction())
      .addDefaultCase((state) => state);
  },
);
