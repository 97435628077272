/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useSearchParams, useLocation } from 'react-router-dom';
import Panel from '../Panel';
import SvgLoader from '../SvgLoader';
import { goBtnZIndexUpdateAction, updateFilterAction } from '../../container/PowerSearch/logic';
import { getTypesValuesAction, updateCurrentDropdownAction } from '../../container/PowerSearchBox/logic';
import { getTerminalName, modifyDataForDropdown, wordLimit } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';

function MultiSelect({
  className, selectedValue, id, setShow, apiUrl, removedValues,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const multiSelectRef = useRef(null);
  const [openDropdown, setOpendDropDown] = useState(false);
  const optionValues = useSelector((state) => state.optionValues);
  const [options, setOptions] = useState([]);
  const [miniView, setMiniView] = useState(false);
  const currentDropdown = useSelector((state) => state.currentDropdown);
  const [tags, setTags] = useState([]);
  const query = searchParams.get('query');

  useEffect(() => {
    if (query && !removedValues?.includes(selectedValue)) {
      const q = JSON.parse(query);
      q.forEach((item) => {
        if (item.name === selectedValue) {
          setTags([...item.currentFilter]);
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(goBtnZIndexUpdateAction(openDropdown ? -1 : 0));
  }, [openDropdown]);

  useEffect(() => {
    if (optionValues.flag && optionValues.fieldName === selectedValue) {
      const tempOptions = modifyDataForDropdown(optionValues.data, 'key', 'value');
      setOptions([...tempOptions]);
    }
  }, [JSON.stringify(optionValues)]);

  useEffect(() => {
    if (options.length && id === currentDropdown.id) {
      setOpendDropDown(true);
    } else {
      setOpendDropDown(false);
    }
  }, [JSON.stringify(options), currentDropdown]);

  useEffect(() => {
    const handler = (event) => {
      if (!multiSelectRef.current) {
        return;
      }

      if (!multiSelectRef.current.contains(event.target)) {
        setOpendDropDown(false);
        setMiniView(true);
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    dispatch(updateFilterAction({
      filterOption: { currentFilter: tags, currentKeys: tags },
      id,
    }));
  }, [tags]);

  useEffect(() => {
    let request = {};
    if (location.pathname === '/searchAndExplore') {
      request = {
        apiUrl,
        params: queryString.stringify({
          fielAd_name: selectedValue,
          asset_class: 'ot_search_assetclasses',
        }),
        headers: {
          Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
        },
      };
    } else {
      request = {
        apiUrl,
        params: queryString.stringify({
          field_name: selectedValue,
          terminal_name: 'clinical_trials',
        }),
        headers: {
          Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
        },
      };
    }
    dispatch(getTypesValuesAction(request));
  }, []);

  const handleOptionClick = (value) => {
    setOpendDropDown(false);
    if (tags.indexOf(value) !== -1 && tags.length >= 1) {
      const temp = tags.filter((item) => item !== value);
      setTags([...temp]);
    } else if (tags.indexOf(value) === -1) {
      setTags([...tags, value]);
    }
    Mixpanel.track('cross_event', {
      action: 'user clicked on cross button',
      page_name: 'Search Page',
      search_type: 'Power Search',
    });
  };

  const renderTags = () => tags.map((item) => (
    <div key={item} className="tags">
      <div className="tag-label" title={item}>{wordLimit(item, 20)}</div>
      <SvgLoader className="cross-icon" onClick={() => handleOptionClick(item)} width={28} height={28} svgName="cross" />
    </div>
  ));

  const renderDropdowns = () => options.map((item) => (
    <div aria-hidden key={item.label} onClick={() => handleOptionClick(item.label)} className={`options ${className[3]} ${tags.indexOf(item.label) > -1 ? 'check' : ''}`}>
      {item.label}
      {tags.indexOf(item.label) > -1 && <SvgLoader width={24} height={24} svgName="tick" />}
    </div>
  ));

  const handleShow = (e) => {
    e.stopPropagation();
    if (e.type === 'click') {
      setOpendDropDown(true);
    }
    Mixpanel.track('field_tap_event', {
      action: 'user taped on search box to fill data for power search',
      field_name: selectedValue,
      terminal_name: getTerminalName(location),
      page_name: 'Search Page',
    });
    dispatch(updateCurrentDropdownAction(id));
    setShow('power');
    setMiniView(false);
    if (options.length === 0) {
      let request = {};
      if (location.pathname === '/searchAndExplore') {
        request = {
          apiUrl,
          params: queryString.stringify({
            field_name: selectedValue,
            asset_class: 'ot_search_assetclasses',
          }),
          headers: {
            Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
          },
        };
      } else {
        request = {
          apiUrl,
          params: queryString.stringify({
            field_name: selectedValue,
            terminal_name: 'clinical_trials',
          }),
          headers: {
            Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
          },
        };
      }
      dispatch(getTypesValuesAction(request));
    }
  };

  const renderMiniView = () => (
    <div className="mini-view">
      <div className="tags">
        {wordLimit(tags[0], 20)}
        <SvgLoader className="cross-icon" onClick={() => handleOptionClick(tags[0])} width={28} height={28} svgName="cross" />
      </div>
      {tags.length - 1 && <div className="tags" style={{ width: 50 }}>{`+${tags.length - 1}`}</div>}
    </div>
  );

  return (
    <div aria-hidden ref={multiSelectRef} className="multi-select">
      <div className={`multi-select-div ${className[0]}`} role="presentation" onClick={(e) => handleShow(e)}>
        {(tags.length) ? (
          <div className="tags-data-multi-select">
            {!miniView && renderTags()}
            {(miniView)
              ? renderMiniView() : null}
          </div>
        ) : null}
      </div>
      <div className="input-div">
        {(openDropdown && options.length) ? <Panel className={`panel ${className[1]}`}>{renderDropdowns()}</Panel> : null}
      </div>
    </div>
  );
}

MultiSelect.propTypes = {
  className: PropTypes.instanceOf(Array).isRequired,
  selectedValue: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  setShow: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  removedValues: PropTypes.instanceOf(Array).isRequired,
};

export default MultiSelect;
