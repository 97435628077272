import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { editTerminalsAction, getSingleUserDetailsAction } from '../UserList/logic';
import SvgLoader from '../../../common/components/SvgLoader';
import {
  appRoles, availableApps, rolesToAppName,
} from '../../../utils';
import {
  addRolesAction, addRolesRefreshAction, removeRolesAction, removeRolesRefreshAction,
} from '../AssignRemoveRoles/logic';
import { sendNotification } from '../../../common/components/Notification/logic';
import Mixpanel from '../../../utils/mixpanel';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

export default function EditTErminalsAccess() {
  const dispatch = useDispatch();

  const [checkTerminals, setCheckedTerminals] = useState({});
  const [showAccess, setShowAccess] = useState(false);

  const editTerminal = useSelector((state) => state.editTerminal);
  const addRoleStatus = useSelector((state) => state.addRoleStatus);
  const removeRolesStatus = useSelector((state) => state.removeRolesStatus);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedTerminals((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleClose = () => {
    dispatch(editTerminalsAction({
      open: false,
      data: {},
    }));
    setShowAccess(false);
  };

  const defaultState = () => {
    debounceCall(() => {
      dispatch(getSingleUserDetailsAction({
        headers: queryString.stringify({
          user_name: editTerminal.data.email,
        }),
      }));
    }, 100);
    setTimeout(() => {
      setCheckedTerminals({ ...{} });
      handleClose();
    }, 100);
  };

  useEffect(() => {
    if (removeRolesStatus.flag || addRoleStatus.flag) {
      if (removeRolesStatus.data.message === 'Roles removed successfully' || addRoleStatus.data.message === 'Roles assigned successfully') {
        debounceCall(() => {
          dispatch(sendNotification({
            type: 'ROLE_ADDED_SUCCESSFULLY',
            notificationType: 'success',
            message: 'Roles updated successfully',
            showNotification: true,
          }));
          defaultState();
        }, 300);
      }
    }
  }, [JSON.stringify(removeRolesStatus), JSON.stringify(addRoleStatus)]);

  useEffect(() => {
    if (editTerminal.open) {
      const newCheckTerminals = {};
      editTerminal.data.terminals.forEach((item) => {
        newCheckTerminals[rolesToAppName[item]] = true;
      });
      setCheckedTerminals(newCheckTerminals);
    }
  }, [JSON.stringify(editTerminal)]);

  const handleSubmit = () => {
    const allowedApps = [];
    const notAllowedApps = [];
    availableApps.forEach((app) => {
      if (checkTerminals[app]) {
        allowedApps.push(appRoles[app]);
      } else {
        notAllowedApps.push(appRoles[app]);
      }
    });

    const request = {
      body: {
        email: editTerminal.data.email,
        onboarding_type: editTerminal.data.userOnboardingType,
        roles: allowedApps,
      },
    };

    const requestRemove = {
      body: {
        email: editTerminal.data.email,
        onboarding_type: editTerminal.data.userOnboardingType,
        roles: notAllowedApps,
      },
    };

    if (allowedApps.length) {
      dispatch(addRolesRefreshAction());
      dispatch(addRolesAction(request));
    }

    if (notAllowedApps.length) {
      dispatch(removeRolesRefreshAction());
      dispatch(removeRolesAction(requestRemove));
    }

    Mixpanel.track('button_click_event', {
      action: 'submit click',
      page: 'terminal access pop up',
      terminal_name: 'Admin Panel',
      filter_value: allowedApps,
      misc: 'edit terminal access',
    });
  };

  const renderTerminals = () => availableApps.map((item) => (
    <div key={item} className="terminal-checkox">
      <label htmlFor={item}>
        <input
          type="checkbox"
          name={item}
          checked={!!checkTerminals[item]}
          onChange={handleCheckboxChange}
        />
        <span className="terminal-name">{item}</span>
      </label>
    </div>
  ));

  return (
    <Modal className="filter-modal-container" closeIcon={false} footer={false} open={editTerminal.open} width={770}>
      <div>
        {!showAccess ? (
          <>
            <div className="filter-header">
              <div>Edit Terminal Access</div>
              <SvgLoader onClick={() => handleClose()} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
            </div>
            <div className="terminals-checklist-container">
              <div className="main-text">Terminal Access</div>
              <div className="terminals-checklist">{renderTerminals()}</div>
            </div>
            <div className="filter-buttons">
              <div aria-hidden onClick={() => handleClose()} className="reset-all-btn pointer">Cancel</div>
              <div aria-hidden onClick={() => setShowAccess(true)} className="filter-btn pointer">Submit</div>
            </div>
          </>
        ) : (
          <div>
            <div className="filter-header">
              <div>{' '}</div>
              <SvgLoader onClick={() => setShowAccess(false)} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
            </div>
            <div className="access-update-text min-h-32">
              <div>Are you sure you want to update</div>
              <div>the user terminals access?</div>
            </div>
            <div className="filter-buttons">
              <div aria-hidden onClick={() => setShowAccess(false)} className="reset-all-btn pointer">Cancel</div>
              <div aria-hidden onClick={() => handleSubmit()} className="filter-btn pointer">Submit</div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
