import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { getFieldValuesAction } from './logic';
import Loader from '../../../common/components/Loader';
import useAppSelector from '../../../utils/redux-hook';
import SvgLoader from '../../../common/components/SvgLoader';
import Mixpanel from '../../../utils/mixpanel';
import { getTerimalPage, getTerminalName } from '../../../utils';

const { Search } = Input;

function InterventionSearchBox({ goBtnDissable, setGoBtnDissable, goClick }) {
  const location = useLocation();

  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [changeHandler, setChangeHandler] = useState([]);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');

  const fieldValuesResponse = useAppSelector(
    (reduxState) => reduxState.fieldValuesResponse || {},
  );

  useEffect(() => {
    const tempQuery = [
      {
        currentFilter: [changeHandler[0]],
        currentKeys: [changeHandler[1]],
        field_name: 'intervention',
        label: 'intervention',
        value: 'intervention',
        name: 'intervention',
      },
    ];
    if (!goBtnDissable && location.pathname === '/intervention' && goClick) {
      Mixpanel.track('page_exit_event', {
        action: 'page leave event',
        page: 'Search Page',
        terminal_name: 'Intervention',
      });
      Mixpanel.track('search_type_event', {
        action: 'go click power search',
        page_name: 'Search Page',
        terminal_name: 'Intervention',
        search_type: 'power search',
      });

      navigate(`/intervention/analytics?query=${JSON.stringify(tempQuery)}`);
    }
  }, [goClick, !goBtnDissable]);

  useEffect(() => {
    if (query?.length) {
      const q = JSON.parse(query)[0];

      setSearchText(q.currentFilter[0]);
      setChangeHandler([q.currentFilter[0], q.currentKeys[0]]);
    }
  }, [query]);

  useEffect(() => {
    if (searchText !== '') {
      setGoBtnDissable(false);
    }
  }, [searchText]);

  useEffect(() => {
    if (fieldValuesResponse.flag) {
      setAutosuggestOptions(fieldValuesResponse.data);
    }
  }, [fieldValuesResponse]);

  const onChangeInput = (e) => {
    if (e.target.value !== '') {
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      setGoBtnDissable(false);
      const request = {
        headers: {
          Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
        },
        params: queryString.stringify({
          asset_class: 'ot_search_assetclasses',
          field_name: 'intervention',
          field_query: e.target.value,
        }),
      };
      dispatch(getFieldValuesAction(request));
    } else {
      setSearchText('');
      setDisplayDropdown(false);
      setGoBtnDissable(true);
    }
    Mixpanel.track('onChangeSearchBox', {
      action: 'Response changes on search box',
      view: 'Intervention page',
      searchText: e.target.value,
    });
  };

  const renderAutosuggestOptions = () => {
    const options = [];

    /* eslint-disable */
    for (let i = 0; i < autosuggestOptions.length; i++) {
      const itm = autosuggestOptions[i];
      options.push(
        <div
          className="options autosuggest-options"
          key={Math.random()}
          onClick={() => {
            const newChangeHandler = [itm?.synonyms, itm?.value];

            setChangeHandler(newChangeHandler);
            setSearchText(itm?.synonyms);
            setDisplayDropdown(false);
            Mixpanel.track("onSelectingOption", {
              action: "Dropdown gets disappeared on selecting option",
              view: "Intervention page",
              searchText: itm?.key,
              displayDropdown: false,
              selectedOption: newChangeHandler,
            });
          }}
        >
          <div className="main-options">
            <div className="main-options-title">{itm?.synonyms}</div>

            <div className="sub-options">{`Pref Name: ${itm?.key}`}</div>
          </div>
        </div>
      );
    }
    return options;
  };

  const handleDivClick = () => {
    Mixpanel.track('field_tap_event', {
      action: 'user tapped on search box ',
      terminal_name: getTerminalName(location),
      page_name: 'Search Page',
    });
    Mixpanel.track('search_box_tap_event', {
      action: 'user tapped on search box ',
      terminal_name: getTerminalName(location),
      page_name: 'Search Page',
      search_type: 'Power Search',
    });
  };

  return (
    <div className="searchbar-dashboard-content-search intervention-search joyride-searchBar">
      <div className="intervention-search-content" onClick={()=>handleDivClick()}>
        <Search
          placeholder="Enter Intervention"
          value={searchText}
          allowClear
          size="large"
          maxLength="256"
          onChange={onChangeInput}
        />
        {searchText?.length ? (
          <SvgLoader
            className="cancel-search-icon"
            width={24}
            height={24}
            svgName="cancel-search-icon"
            onClick={() => {
              setSearchText("");
              setGoBtnDissable(true);
              Mixpanel.track("cross_event", {
                action: "user clicked on cross button",
                page_name: getTerimalPage(location),
                searchText: searchText,
              });
            }}
          />
        ) : (
          <SvgLoader
            className="search-icon"
            width={24}
            height={24}
            svgName="search-icon"
          />
        )}
      </div>
      <div className="input-div">
        {displayDropdown && searchText?.length ? (
          <div className="panel-section ">
            <div className="search-dropdown-result-list">
              <Loader
                loading={fieldValuesResponse.loading}
                style={{ height: "100px" }}
                noData={fieldValuesResponse.data?.length === 0}
                error={fieldValuesResponse.error}
              >
                {renderAutosuggestOptions()}
              </Loader>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
InterventionSearchBox.propTypes = {
  setGoBtnDissable: PropTypes.func.isRequired,
  goBtnDissable: PropTypes.bool.isRequired,
  goClick: PropTypes.bool.isRequired,
};
export default InterventionSearchBox;
