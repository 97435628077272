import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { getSavedSearchesAction } from '../../SavedSearches/logic';
import SavedSearchesCard from './SavedSearchesCard';

function SavedSearchesList({ projectId, setBookmarkFilterUsers }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savedSearchesData = useSelector((state) => state.savedSearchesData);

  useEffect(
    () => {
      setBookmarkFilterUsers([]);

      if (projectId !== '') {
        dispatch(
          getSavedSearchesAction({
            body: {
              project_id: projectId,
              user_ids: [],
            },
          }),
        );
      }
    },
    [projectId],
  );

  const renderNoSaveSearchData = () => (
    <div className="no-bookmark-container">
      <div className="no-bookmark-icon" />
      <div className="no-bookmark-title">No saved search found</div>
      <div className="no-bookmark-content">
        It seems that you have not saved any search yet in your current project.
        We recommend utilising the save the search feature to re-execute your search and quickly find documents of your interest.
      </div>
      <Button className="home-btn" onClick={() => navigate('/home')}>Return to home</Button>
    </div>
  );
  const renderSavedSearchList = () => {
    if (projectId === '' || savedSearchesData?.data?.length === 0) {
      return <div className="no-bookmark-data">{renderNoSaveSearchData()}</div>;
    }
    return savedSearchesData?.data?.map((item) => (
      <SavedSearchesCard
        data={item}
        projectId={projectId}

      />
    ));
  };
  return (
    <div className="list-container">

      <Loader
        loading={savedSearchesData.loading}
        error={false}
      >
        {renderSavedSearchList()}

      </Loader>

    </div>
  );
}

export default SavedSearchesList;
