/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as d3 from 'd3';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import queryString from 'query-string';
import {
  ResponsiveContainer, Cell, PieChart, Pie,
} from 'recharts';
import SvgLoader from '../../../common/components/SvgLoader';
import { getSponsorDataAction, getSponsorDataRefreshAction } from './logic';
import Loader from '../../../common/components/Loader';
import SponsorDeepDive from '../../components/SponsorDeepDive';
import Mixpanel from '../../../utils/mixpanel';
import { Mapfields } from './data';
import { getTerminalName } from '../../../utils';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function SpeedometerGraph({
  params,
  selected,
  currentExpanded,
  setIsGraphClicked,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');

  const [showDeepdive, setShowDeepdive] = useState(false);
  const [sponsorKey, setSponsorKey] = useState('');
  const [sponsorValue, setSponsorValue] = useState();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tootipId, setTooltipId] = useState('');

  const sponsorData = useSelector((state) => state.sponsorData);
  const searchResult = useSelector((state) => state.searchResult);

  useEffect(() => {
    d3.select('#speedometerid').remove();
  }, []);

  useEffect(() => {
    if ((currentExpanded === '' && searchResult.flag) || currentExpanded === selected.label) {
      dispatch(getSponsorDataRefreshAction());
      const request = {
        ...params,
        apiUrl: '/clinical-trials/v0/search/getSponsorSummary',
        headers: queryString.stringify({
          ...params.headers,
          field_name: 'sponsor',
        }),
      };
      debounceCall(() => {
        dispatch(getSponsorDataAction(request));
      }, 80);
    }
  }, [currentExpanded, JSON.stringify(searchResult)]);

  if (currentExpanded !== '' && selected.label !== currentExpanded) {
    return null;
  }

  const colors2 = d3.quantize(
    d3.interpolateRgb(d3.rgb(220, 220, 255), d3.rgb(82, 82, 250)),
    sponsorData.data.length,
  );

  const colorScale = d3
    .scaleOrdinal()
    .domain(sponsorData.data.map((item) => item.name))
    .range(colors2);

  const renderLegends = (dataset) => dataset.map((item) => (
    item.value !== 0
      ? (
        <div key={item.name} className="legend align-legends">
          <div className="circle" style={{ backgroundColor: colorScale(item.name) }} />
          <span className="legend-name">{item?.name || item?.displayName}</span>
        </div>
      ) : null
  ));

  const handleMouseenter = (slicekey, sliceValue) => {
    setTooltipVisible(true);
    setSponsorKey(slicekey);
    setSponsorValue(sliceValue);
  };

  const handleArrowClick = (dataKey) => {
    Mixpanel.track('widget_deepdive_event', {
      action: 'Sponsor Insights Tooltip arrow click for sponsor widget deepdive',
      search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      query: JSON.parse(semanticQuery) || JSON.parse(query),
      widget_name: 'Sponsor Insights',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
      view_type: 'Widget_view',
      label: dataKey,
      misc: 'Level 0',
    });
    Mixpanel.track('page_entry_event', {
      action: 'Sponsor Insights Tooltip click for sponsor listing',
      terminal_name: 'Clinical Trials',
      page_name: 'Sponsor Listing',
    });
    setShowDeepdive(true);
    setIsGraphClicked(true);
    setTooltipVisible(false);
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percentage, value, index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percentage > 5) {
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" id={`slice-${index}`}>
          {`${value}`}
        </text>
      );
    }
    return null;
  };

  const slices = [];
  let totalValue = 0;
  sponsorData.data.forEach((item) => {
    totalValue += item.value;
    if (item.value !== 0) {
      slices.push({
        name: item.name,
        value: item.value,
      });
    }
  });

  slices.forEach((item) => {
    item.percentage = (item.value / totalValue) * 100;
  });

  const renderGraph = () => {
    const width = 250;
    const height = 160;

    const pieProps = {
      startAngle: 0,
      endAngle: 180,
      cx: width / 2,
      cy: width / 2,
      isAnimationActive: false,
    };

    return (
      <div style={{ width, height: 160 }}>
        <ResponsiveContainer>
          <div className="pie-chart">
            <PieChart id="speedometerid" width={width} height={height} layout="centric">
              <Pie
                dataKey="value"
                stroke="none"
                data={slices}
                innerRadius={60}
                outerRadius={120}
                fill="56"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...pieProps}
                label={renderCustomizedLabel}
              >
                {slices.map((each, i) => (
                  <Cell
                    cursor="pointer"
                    key={Math.random()}
                    fill={colorScale(slices[i].name)}
                    stroke="none"
                    onMouseEnter={() => {
                      handleMouseenter(slices[i].name, slices[i].value);
                      setTooltipId(`slice-${i}`);
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            <div
              className="pie-chart-indicators"
              style={{
                display: 'flex',
                width: '100px',
                justifyContent: 'space-between',
              }}
            />
          </div>
          <div className="overall-score-label" />
        </ResponsiveContainer>
      </div>
    );
  };

  const renderTooltip = () => {
    Mixpanel.track('hover_popup_event', {
      action: 'user hovered on status snapshot tooltip',
      label: sponsorKey,
      terminal_name: getTerminalName(location),
      page_name: 'Clinical Trials Analytics page',
      widget_name: 'Sponsor Insights',
      search_type: location.pathname.includes('semantic') ? 'Semantic Search' : 'Power Search',
      view_type: 'Widget_view',
    });
    return (
      <Tooltip
        place="bottom"
        id="tooltip-speedometer"
        anchorSelect={`#${tootipId}`}
        isOpen={tooltipVisible}
      >
        <div>
          <div className="tooltip tooltip-graph">
            <div className="tooltip-content tooltip-content-graph">
              <div className="tooltip-content-graph-content">
                <div className="tooltip-value">{sponsorValue}</div>
                <div className="endpoint-type">{sponsorKey}</div>
                <div className="tooltip-btn">
                  <button className="arrow-button" type="button" onClick={() => handleArrowClick(sponsorKey)}>
                    <span className="right-arrow-black pointer" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    );
  };

  const primaryWidget = (
    <div className="Widget" onMouseLeave={() => setTooltipVisible(false)}>
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">
            {selected.label}
            {location.pathname === '/searchAndExplore/analytics' ? (
              <sup className="partex-ai-icon">* Powered by AI</sup>
            ) : null}
          </div>
        </div>
        <div className="icons" />
      </div>
      <Loader
        error={sponsorData.error}
        loading={sponsorData.loading || searchResult.loading}
        noData={
          slices.length === 0
          && sponsorData.flag
        }
        height="250px"
        className="white-loader"
        message="No records found"
      >
        <div className="speedometer">
          {sponsorData?.data?.length ? (
            renderGraph()
          ) : (
            <div className="unavilable-data">Data unavailable</div>
          )}
        </div>
        <div className="legends-wrap-speedometer">
          <div className="legends speedometer">
            {sponsorData?.data?.length ? renderLegends([...sponsorData.data] || []) : null}
          </div>
        </div>
      </Loader>
      {renderTooltip()}
      <div className="drag-expaned-icon" />
    </div>
  );

  return (
    <>
      {showDeepdive ? (
        <SponsorDeepDive
          setShowDeepdive={setShowDeepdive}
          params={params}
          sponsorKey={Mapfields[sponsorKey]}
          setIsGraphClicked={setIsGraphClicked}
        />

      ) : null}
      {!showDeepdive ? primaryWidget : null}
    </>
  );
}

SpeedometerGraph.propTypes = {
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    graphData: PropTypes.PropTypes.shape([
      {
        value: PropTypes.number.isRequired,
      },
    ]),
  }).isRequired,
  currentExpanded: PropTypes.string.isRequired,
  setIsGraphClicked: PropTypes.func.isRequired,
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default SpeedometerGraph;
