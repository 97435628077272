import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tooltip } from 'antd';
import { createStyles } from 'antd-style';
import { getOrganizationValuesAction, getOrganizationValuesRefreshAction } from '../Admin/logic';
import UpdateOrg from '../UpdateOrg';
import DeleteOrg from '../DeleteOrg';
import { setCookie } from '../../../utils';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: unset;
          }
        }
      }
    `,
  };
});

export default function OrgTable({ search, update }) {
  const dispatch = useDispatch();
  const { styles } = useStyle();

  const [openCreateOrg, setOpenCreateOrg] = useState(false);
  const [orgEditData, setOrgEditData] = useState({});
  const [orgDataDeleteID, setOrgDataDeleteiD] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const organizationValues = useSelector((state) => state.organizationValues);

  const baseWidth = window.innerWidth - 150;

  const getWidth = (percent) => (percent * baseWidth) / 100;

  const loadList = (page) => {
    const request = {
      from: page,
      size: 100,
      search,
    };
    debounceCall(() => dispatch(getOrganizationValuesAction(queryString.stringify(request))), 100);
    setCurrentPage((current) => current + 1);
  };

  useEffect(() => {
    dispatch(getOrganizationValuesRefreshAction());
    setCurrentPage(1);
    loadList(1);
  }, [update, search]);

  const handleOnEditClick = (data) => {
    setOrgEditData({ ...data });
  };

  useEffect(() => {
    if (openCreateOrg) {
      setCookie('edit', 'orgUpdateType');
    }
  }, [openCreateOrg]);

  const handleSetOpenCreateOrg = (value) => {
    setOpenCreateOrg(value);
    if (!value) {
      setOrgEditData({ ...{} });
      debounceCall(() => dispatch(getOrganizationValuesAction(queryString.stringify({
        search,
        size: 100,
        from: 1,
      }))), 100);
    }
  };

  const handleOrgDataUpdate = (data) => {
    setOrgDataDeleteiD(data.organization_id);
  };

  const renderTooltip = (data) => (
    <div className="tooltip-org flex flex-col gap-2">
      <div aria-hidden onClick={() => handleOnEditClick(data)} className="flex gap-2 pointer container-edit-org">
        <div className="edit-icon" />
        <div>Edit</div>
      </div>
      <div aria-hidden onClick={() => handleOrgDataUpdate(data)} className="flex gap-2 pointer container-edit-org">
        <div className="delete-icon" />
        <div>Delete</div>
      </div>
    </div>
  );

  const columns = [
    {
      title: 'Organization ID',
      dataIndex: 'organization_id',
      key: 'organization_id',
      sorter: (a, b) => {
        const valueA = a.organization_id.toLowerCase();

        const valueB = b.organization_id.toLowerCase();

        return valueA.localeCompare(valueB);
      },
      width: getWidth(35),
    },
    {
      title: 'Organization name',
      dataIndex: 'organization_name',
      key: 'organization_name',
      sorter: (a, b) => {
        const valueA = a.organization_name.toLowerCase();
        const valueB = b.organization_name.toLowerCase();

        return valueA.localeCompare(valueB);
      },
      width: getWidth(20),
    },
    {
      title: 'Organization type',
      dataIndex: 'organization_type',
      key: 'organization_type',
      sorter: (a, b) => {
        const valueA = a.organization_type.toLowerCase();
        const valueB = b.organization_type.toLowerCase();

        return valueA.localeCompare(valueB);
      },
      width: getWidth(20),
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      width: getWidth(5),
    },
    {
      width: getWidth(5),
      render: (a) => (
        <div>
          <Tooltip
            placement="left"
            overlayClassName="prediction-tooltip position-zero"
            title={() => renderTooltip(a)}
            arrow={false}
          >
            <div className="three-dots-org pointer" />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    let sortQuery = {};
    if (sorter.order && sorter.columnKey) {
      sortQuery = {
        search,
        sort: sorter.columnKey,
        order: sorter.order === 'ascend' ? 'asc' : 'desc',
        from: 1,
        size: 100,
      };
      debounceCall(() => dispatch(getOrganizationValuesAction(queryString.stringify({ ...sortQuery }))), 100);
    }
  };

  return (
    <div>
      <LazyLoadDiv
        className="explore-list-results"
        id="org-list-3"
        total={organizationValues?.total}
        currentTotal={(organizationValues.data || []).length}
        height="calc(100vh - 200px)"
        loadMoreRows={() => loadList(currentPage)}
      >
        <Table
          borderec
          className={styles.customTable}
          columns={columns}
          dataSource={organizationValues?.data.filter((item) => !item.is_deleted)}
          scroll={{
            x: 'max-content',
          }}
          sticky
          pagination={false}
          showSorterTooltip={false}
          onChange={handleChange}
        />
      </LazyLoadDiv>
      <UpdateOrg orgData={orgEditData} open={openCreateOrg} setOpen={(value) => handleSetOpenCreateOrg(value)} type="edit" />
      <DeleteOrg orgDataID={orgDataDeleteID} setOrgDataIDEmpty={() => setOrgDataDeleteiD('')} />
    </div>
  );
}
