/*eslint-disable */
import { React, useEffect, useState } from "react";
import { Checkbox, Button } from "antd";
import { dispatch } from "d3";
import { useDispatch } from "react-redux";
import { getBookmarkAction } from "../Bookmark/logic";

function BookmarkFilter({
  sortByOptions,
  projectId,
  setOpenFilterPopover,
  setBookmarkFilters,
  setBookmarkFilterUsers,
  bookmarkFilters,
  bookmarkFilterUsers,
  filterData,
  projectsData,
}) {
  const [selectedTopic, setSelectedTopic] = useState("asset_type");
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();

  function convertToLowerCaseWithUnderscores(filterData) {
    return filterData.map((filter) => filter.toLowerCase().replace(/ /g, "_"));
  }

  function capitalizeFirstLetter(str) {
    const words = str.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  const handleBookmarkFilters = () => {
    if (projectId !== "") {
      dispatch(
        getBookmarkAction({
          body: {
            project_id: projectId,
            asset_class: convertToLowerCaseWithUnderscores(bookmarkFilters),
            user_ids: bookmarkFilterUsers,
            sort: sortByOptions,
            export: false,
            file_type: "",
          },
        })
      );
    }

    setOpenFilterPopover(false);
  };
  const handleCheckboxChange = (checkedValue) => {
    if (selectedTopic === "asset_type") {
      setBookmarkFilters((prevFilters) => {
        if (prevFilters.includes(checkedValue)) {
          return prevFilters.filter((filter) => filter !== checkedValue);
        } else {
          return [...prevFilters, checkedValue];
        }
      });
    }
    if (selectedTopic === "user") {
      setBookmarkFilterUsers((prevFilters) => {
        if (prevFilters.includes(checkedValue)) {
          return prevFilters.filter((filter) => filter !== checkedValue);
        } else {
          return [...prevFilters, checkedValue];
        }
      });
    }
  };
  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  const selectAll = () => {
    if (selectedTopic === "asset_type") {
      setBookmarkFilters(filterData);
    }
    if (selectedTopic === "user") {
      setBookmarkFilterUsers(userData);
    }
  };
  const deselectAll = () => {
    setBookmarkFilters([]);
    setBookmarkFilterUsers([]);
  };

  useEffect(() => {
    var tempUserData = [];
    projectsData?.data?.map((itm) => {
      if (projectId === itm?.projectId) {
        tempUserData.push(itm?.user_details);
      }
    });
    if (tempUserData.length) {
      tempUserData = tempUserData[0].map((itm) => itm?.user_id);
    } else {
      tempUserData = [];
    }
    setUserData(tempUserData);
  }, []);

  return (
    <>
      <div className="subtopics">
        <Button
          type="button"
          className={selectedTopic === "asset_type" ? "selected" : ""}
          onClick={() => handleTopicClick("asset_type")}
        >
          Filter by asset type
        </Button>
        <Button
          type="button"
          className={selectedTopic === "user" ? "selected" : ""}
          onClick={() => handleTopicClick("user")}
        >
          Filter by user
        </Button>
      </div>
      <div className="line" />
      <div className="workspace-bookmark-filter-selection-section">
        <div
          className="workspace-bookmark-filter-select-all"
          role="presentation"
          onClick={selectAll}
        >
          Select All
        </div>
        <div
          className="workspace-bookmark-filter-deselect-all"
          role="presentation"
          onClick={deselectAll}
        >
          Deselect All
        </div>
      </div>
      <div className="workspace-bookmark-filter">
        {selectedTopic === "asset_type" && (
          <div className="analytics-container-content-sidebar-link--child">
            {filterData?.map((i) => (
              <div
                className="analytics-container-content-sidebar-link"
                onClick={() => handleCheckboxChange(i)}
              >
                <div className="project-content">
                  <Checkbox checked={bookmarkFilters.includes(i)} />
                  <div className="analytics-container-content-sidebar-link-title">
                    <div className="analytics-container-content-sidebar-link-title-main">
                      {i}
                      
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {selectedTopic === "user" && (
          <div className="analytics-container-content-sidebar-link--child">
            {projectsData?.data?.map((project) => {
              if (project.projectId === projectId) {
                return (
                  <div>
                    {project.user_details.map((user) => (
                      <div
                        key={user.user_id}
                        className="analytics-container-content-sidebar-link"
                        onClick={() => handleCheckboxChange(user.user_id)}
                      >
                        <div className="project-content-user">
                          <div className="analytics-container-content-sidebar-link-title">
                            <div className="analytics-container-content-sidebar-link-title-main">
                              <div className="log-menu-content">
                                <div className="log-menu">
                                  <div className="log-menu-profile">
                                    <div
                                      aria-hidden
                                      className="user-name-initials"
                                    >
                                      <span
                                        className="user"
                                        title={user.user_name}
                                      >
                                        {capitalizeFirstLetter(
                                          user.user_name.charAt(0)
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {capitalizeFirstLetter(user.user_name)}
                            </div>
                            <Checkbox
                              checked={bookmarkFilterUsers.includes(
                                user.user_id
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
        <div className="analytics-container-content-sidebar-link-footer">
          <Button
            type="primary"
            className="ok-btn"
            onClick={() => handleBookmarkFilters()}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
}

export default BookmarkFilter;
