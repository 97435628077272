import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useSearchParams, useLocation } from 'react-router-dom';
import PowerSearchBox from '../PowerSearchBox';
import SvgLoader from '../../components/SvgLoader';
import {
  addFilterAction, getPowerSearchTypesAction, refreshFilterAction, updateFilterFromUrlAction,
} from './logic';
import { getTypesValuesRefreshAction } from '../PowerSearchBox/logic';
import { debounceCall, getTerminalName, windowHrefToNavigate } from '../../../utils';
import { persistor } from '../../../store';
import Mixpanel from '../../../utils/mixpanel';

const ModifyData = (data) => {
  const options = [];
  for (let i = 0; i < Object.keys(data).length; i += 1) {
    const temp = Object.keys(data[i])[0];
    options.push({
      ...data[i][temp],
      label: data[i][temp].display_name,
      value: data[i][temp].name,
      type: data[i][temp].type,
    });
  }
  return options;
};

function PowerSearch({
  apiUrl, autoSuggestUrl, setShow, goClick, setGoBtnDissable, goBtnDissable, isSemanticSearch,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const searchFilters = useSelector((state) => state.filters);
  const types = useSelector((state) => state.types);
  const tempOptions = ModifyData(types.data);
  const [options, setOptions] = useState([...tempOptions]);
  const query = searchParams.get('query');
  const [removedValues, setRemovedValues] = useState([]);

  useEffect(() => {
    if (query?.length) {
      const q = JSON.parse(query);
      const temp = q.filter((itm) => itm?.currentFilter?.length);
      dispatch(updateFilterFromUrlAction(temp));
      setShow('power');
    }
  }, []);

  useEffect(() => {
    if (tempOptions.length) {
      setOptions([...tempOptions]);
    }
  }, [JSON.stringify(tempOptions)]);

  useEffect(() => {
    let flag = false;
    searchFilters.filters.forEach((item) => {
      if (item.currentFilter?.length > 0) {
        flag = true;
      }
    });
    setGoBtnDissable(!flag);
  }, [JSON.stringify(searchFilters)]);

  useEffect(() => {
    if (goClick && !isSemanticSearch) {
      if (!goBtnDissable && location.pathname === '/searchAndExplore') {
        Mixpanel.track('search_type_event', {
          action: 'go click power search',
          page_name: 'Search Page',
          terminal_name: 'Search and Explore',
          search_type: 'power search',
          query: searchFilters.filters,
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'Search Page',
          terminal_name: 'Search and Explore',
        });

        dispatch(refreshFilterAction());
        windowHrefToNavigate(`/searchAndExplore/analytics?query=${JSON.stringify(searchFilters.filters)}`);
        persistor.purge();
      } else if (!goBtnDissable) {
        Mixpanel.track('search_type_event', {
          action: 'go click power search',
          page_name: 'Search Page',
          query: searchFilters.filters,
          terminal_name: 'Clinical Trials',
          search_type: 'power search',
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'Search Page',
          terminal_name: 'Clinical Trials',
        });

        dispatch(refreshFilterAction());
        windowHrefToNavigate(`/clinicalTrials/analyticsResults?query=${JSON.stringify(searchFilters.filters)}`);
        persistor.purge();
      }
    }
  }, [goClick]);

  useEffect(() => {
    let request = {};
    if (location.pathname === '/searchAndExplore') {
      request = {
        apiUrl,
        params: queryString.stringify({
          asset_class: 'ot_search_assetclasses',
          view_name: 'power_search',
        }),
        headers: {
          Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
        },
      };
    } else {
      request = {
        apiUrl,
        params: queryString.stringify({
          terminal_name: 'clinical_trials',
          view_name: 'power_search',
        }),
        headers: {
          Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
        },
      };
    }
    debounceCall(() => dispatch(getPowerSearchTypesAction(request)), 50);
  }, []);

  const removedToBeAddedInOption = (value) => {
    setRemovedValues([...removedValues, value.value]);

    let flag = true;
    options.forEach((item) => {
      if (item.name === value.name) {
        flag = false;
      }
    });
    if (flag) {
      setOptions([value, ...options]);
    }
  };

  const addOption = () => {
    dispatch(getTypesValuesRefreshAction());
    const temp = [];
    searchFilters?.filters?.forEach((item) => {
      temp.push(item.name);
    });
    const temp1 = [];
    options.forEach((item) => {
      if (temp.indexOf(item.value) === -1) {
        temp1.push(item);
      }
    });

    if (temp1[0]) {
      dispatch(addFilterAction({
        newOption: temp1[0],
      }));
    }
    setOptions([...temp1]);
    Mixpanel.track('button_click_event', {
      action: 'plus button click power search',
      terminal_name: getTerminalName(location),
      page: 'Search Page',
    });
  };

  const renderSearchs = () => searchFilters.filters.map((item, index) => (
    <PowerSearchBox
      key={item.label}
      currentValue={item}
      options={options}
      id={index}
      removedToBeAddedInOption={(value) => removedToBeAddedInOption(value)}
      setShow={setShow}
      apiUrl={autoSuggestUrl}
      removedValues={removedValues}
    />
  ));

  return (
    <div>
      <div className="power-search">
        <div className="power-search-container">
          {renderSearchs()}
        </div>
        {' '}
        <SvgLoader onClick={addOption} width={30} height={30} svgName="add" className="add-icon" />
      </div>
    </div>
  );
}

PowerSearch.propTypes = {
  setShow: PropTypes.func.isRequired,
  goClick: PropTypes.bool.isRequired,
  setGoBtnDissable: PropTypes.func.isRequired,
  goBtnDissable: PropTypes.bool.isRequired,
  apiUrl: PropTypes.string.isRequired,
  autoSuggestUrl: PropTypes.string.isRequired,
  isSemanticSearch: PropTypes.bool.isRequired,
};

export default PowerSearch;
