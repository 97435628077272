import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import SvgLoader from '../../../common/components/SvgLoader';
import {
  addReferralAction, checkUserMultiAction, checkUserMultiRefreshAction, setFeedBackAndReferralModalOpenAction, setReferralOpenModalAction,
} from './logic';
import { removeSession } from '../../../common/container/Status/logic';
import { getCookie, setCookie, windowHrefToNavigate } from '../../../utils';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

const removeDuplicates = (arr) => {
  const uniqueSet = new Set(arr);
  return [...uniqueSet];
};

const hasDuplicates = (arr) => {
  const uniqueSet = new Set(arr);
  return arr.length !== uniqueSet.size;
};

const referralDefaultMessage = 'I am happily using this platform for my desk research. It is better than Google, PubMed, Clinicaltrials.gov and ChatGPT in accelerating my work. Referring you for a free 15-day free trial, expecting you to get similar results.';

export default function Referral() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [inputMessage, setInputMessage] = useState(referralDefaultMessage);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [atLeastOneAllowedUser, setAtLeastOneAllowedUser] = useState(true);

  const referralModalOpen = useSelector((state) => state.referralModalOpen);
  const checkUserMultiStatus = useSelector((state) => state.checkUserMultiStatus);
  const addRefferalStatus = useSelector((state) => state.addRefferalStatus);

  const processEmail = (emailProcessed) => {
    const plusIndex = emailProcessed.indexOf('+');
    if (plusIndex !== -1) {
      return emailProcessed.substring(0, plusIndex) + emailProcessed.substring(emailProcessed.indexOf('@'));
    }
    return emailProcessed;
  };

  useEffect(() => {
    if (addRefferalStatus.flag) {
      if (addRefferalStatus.data.message === 'User referred successfully') {
        setSuccessModalOpen(true);
        dispatch(setReferralOpenModalAction(false));
      }
    }
  }, [JSON.stringify(addRefferalStatus)]);

  useEffect(() => {
    if (hasDuplicates(emailList)) {
      const uniqueList = removeDuplicates(emailList).filter((item) => item !== '');
      setEmailList([...uniqueList.map((item) => processEmail(item))]);
    }
  }, [JSON.stringify(emailList)]);

  useEffect(() => {
    if (checkUserMultiStatus.flag) {
      setShowLoader(false);
      const tempList = emailList.filter((item) => !checkUserMultiStatus.data.includes(item));
      invalidEmails.forEach((item) => {
        if (tempList.includes(item)) {
          const index = tempList.indexOf(item);
          tempList.splice(index, 1);
        }
      });
      setAllowedUsers([...tempList]);
    }
  }, [JSON.stringify(checkUserMultiStatus)]);

  const defaultState = () => {
    setEmail('');
    setAllowedUsers([...[]]);
    setShowLoader(false);
    setEmailList([...[]]);
    setInvalidEmails([...[]]);
    setAtLeastOneAllowedUser(true);
    dispatch(checkUserMultiRefreshAction());
  };

  const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const extractEmail = (emailInput) => {
    const emailPattern = /(?:<([^>]+)>|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2.}))/;
    const match = emailInput.match(emailPattern);

    if (match) {
      return match[1].trim().toLowerCase() || match[2].trim().toLowerCase();
    }
    if (check.test(emailInput)) {
      return emailInput;
    }
    return null;
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    const tempInvalidEmails = invalidEmails.filter((item) => !emailList.includes(item));
    setEmailList([...emailList, ...tempInvalidEmails.map((item) => processEmail(item))]);
  }, [JSON.stringify(invalidEmails)]);

  const processB = (emails) => {
    let j = 0;
    let i = 0;
    const tempEmails = [];
    const tempInvalidEmails = [];
    while (i < emails.length) {
      const ch = emails[i];
      if (ch === ' ') {
        const tempEmail = extractEmail(emails.slice(j, i));
        if (tempEmail) {
          tempEmails.push(tempEmail);
          j = i + 1;
        }
      } else if (i === emails.length - 1) {
        const tempEmail = extractEmail(emails.slice(j, i + 1));
        if (tempEmail) {
          tempEmails.push(tempEmail);
          break;
        } else if (emails.slice(j, i + 1).includes(' ')) {
          const tempEmail1 = emails.slice(j, i + 1);
          while (j < emails.length) {
            if (emails[j] === ' ') {
              j += 1;
              i -= 1;
              tempInvalidEmails.push(tempEmail1.split(' ')[0]);
              break;
            }
            j += 1;
          }
        } else {
          const tempEmail1 = emails.slice(j, i + 1);
          tempInvalidEmails.push(tempEmail1.split(' ')[0]);
        }
      }
      i += 1;
    }
    if (tempInvalidEmails.length) {
      setInvalidEmails([...invalidEmails, ...tempInvalidEmails]);
    }
    return tempEmails;
  };

  const processA = (emails) => {
    const allEmails = emails.split(',');
    const tempEmails = [];
    allEmails.forEach((item) => {
      if (processB(item) && processB(item).length) {
        tempEmails.push(...processB(item));
      }
    });
    return JSON.parse(JSON.stringify(tempEmails));
  };

  const ctrAllProcess = () => {
    if (email.includes(',')) {
      let tempEmails = [];

      const emailFromProcessA = processA(email);
      tempEmails = emailFromProcessA.map((item) => processEmail(item));
      if (tempEmails.length >= 1) {
        setEmailList([...emailList, ...tempEmails]);
        dispatch(checkUserMultiRefreshAction());
        dispatch(checkUserMultiAction({
          headers: queryString.stringify({
            email: [...emailList, ...tempEmails].join(','),
            checkExisting: true,
          }),
        }));
        setShowLoader(true);
        setEmail('');
      }
    } else if (email.includes(' ')) {
      let tempEmails = [];

      const emailsFromProcessB = processB(email);
      tempEmails = emailsFromProcessB.map((item) => processEmail(item));

      if (tempEmails.length >= 1) {
        setEmailList([...emailList, ...tempEmails]);
        dispatch(checkUserMultiRefreshAction());
        dispatch(checkUserMultiAction({
          headers: queryString.stringify({
            email: [...emailList, ...tempEmails].join(','),
            checkExisting: true,
          }),
        }));
        setShowLoader(true);
        setEmail('');
      }
    } else if (extractEmail(email)) {
      setEmailList([...emailList, processEmail(extractEmail(email))]);
      dispatch(checkUserMultiRefreshAction());
      dispatch(checkUserMultiAction({
        headers: queryString.stringify({
          email: [...emailList, processEmail(extractEmail(email).trim().toLowerCase())].join(','),
          checkExisting: true,
        }),
      }));
      setShowLoader(true);
      setEmail('');
    }
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      ctrAllProcess();
    }
  };

  const handleEmailsValidationAndEnite = () => {
    if (allowedUsers.length !== 0) {
      setAtLeastOneAllowedUser(true);
      ctrAllProcess();
      const request = {
        body: {
          referral_user: allowedUsers.join(','),
          message: inputMessage,
        },
      };
      debounceCall(() => dispatch(addReferralAction(request)), 50);
    } else {
      ctrAllProcess();
      if (allowedUsers.length === 0) {
        setAtLeastOneAllowedUser(false);
      }
    }
  };

  const handleCrossModalIconClick = () => {
    if (getCookie('feedbackReferModal') === 'true') {
      defaultState();
      setInputMessage(referralDefaultMessage);
      dispatch(setFeedBackAndReferralModalOpenAction(true));
      dispatch(setReferralOpenModalAction(false));
    } else {
      defaultState();
      setInputMessage(referralDefaultMessage);
      setCookie('', 'previousTimeStamp');
      dispatch(setReferralOpenModalAction(false));
    }
  };

  const handleLogoutClick = () => {
    if (getCookie('feedbackReferModal') === 'true') {
      dispatch(removeSession());
      windowHrefToNavigate('/');
    } else {
      defaultState();
      setSuccessModalOpen(false);
      dispatch(setReferralOpenModalAction(false));
    }
  };

  const handleCrossIconClick = (user) => {
    const filterList = emailList.filter((value) => value !== user);
    const filterInvalidList = invalidEmails.filter((value) => value !== user);
    setInvalidEmails([...filterInvalidList]);
    setEmailList([...filterList]);
    dispatch(checkUserMultiRefreshAction());
    dispatch(checkUserMultiAction({
      headers: queryString.stringify({
        email: filterList.join(','),
        checkExisting: true,
      }),
    }));
    setShowLoader(true);
  };

  const renderUsersTag = () => emailList.map((user) => (
    <div className={`py-1.5 px-2.5 ${!checkUserMultiStatus.data.includes(user) && check.test(user) ? 'user-tag' : 'user-tag-red'} flex`} key={user}>
      <span>{user}</span>
      <div aria-hidden onClick={() => handleCrossIconClick(user)} className={!checkUserMultiStatus.data.includes(user) && check.test(user) ? 'croos-btn-tag-user' : 'cross-red'} />
    </div>
  ));

  const successModal = () => (
    <Modal
      open={successModalOpen}
      onCancel={handleLogoutClick}
      okButtonProps={{ style: { display: 'none' } }}
      className="admin-invite-user-success-modal"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className="invite-success-icon">
        <SvgLoader svgName="success-signup" width={120} height={120} style={{ display: 'flex' }} />

      </div>
      <div className="invite-success-msg flex flex-col justify-center items-center">
        <span>You have referred your colleague(s).</span>
      </div>
      <div className="invite-sub-msg flex flex-col items-center justify-center">
        <span>
          Once the referred colleague(s) successfully logs onto Ontosight
          <sup>@</sup>
          &nbsp;
          Terminal,
        </span>
        <span>
          then your licence period will be extend by 15 days
        </span>
      </div>
    </Modal>
  );

  const referralModal = () => (
    <Modal className="feedback-modal" footer={false} open={referralModalOpen.toggleReferralOpen} closeIcon={false} width={600}>
      <div className="referral-container px-11 py-6">
        <div className="referral-header flex items-center justify-between">
          <div className="referral-header-text">Invite your colleague</div>
          <SvgLoader onClick={handleCrossModalIconClick} width={22} height={22} pointer svgName="cancel-search-icon" />
        </div>
        <div className="referral-message mt-2 mb-4">
          <div>
            We will extend your license by
            &nbsp;
            <strong>15 days</strong>
            {' '}
            for each referred colleague once they sign up.
          </div>
        </div>
        <div className="email-input-container" onMouseLeave={() => ctrAllProcess()}>
          <div className="flex flex-wrap">
            {showLoader
              ? (
                <div className="relative left-4 top-2.5 h-6">
                  <div className="snippet" data-title="dot-flashing">
                    <div className="stage">
                      <div className="dot-flashing" />
                    </div>
                  </div>
                </div>
              ) : renderUsersTag()}
            {!showLoader ? <input className="input-email m-1 px-2.5 w-full" placeholder="Email ID" type="text" value={email} onChange={(e) => handleInputChange(e)} onKeyDown={handleEnterKey} /> : null}
          </div>
        </div>
        {
          invalidEmails.length !== 0 && checkUserMultiStatus.data.length === 0 && !showLoader ? (
            <div className="not-valid">
              <span>
                Highlighted email IDs are invalid. Please enter correct email ID.
              </span>
            </div>
          ) : null
        }
        {
          invalidEmails.length !== 0 && checkUserMultiStatus.data.length !== 0 && !showLoader ? (
            <div className="not-valid">
              <span>
                Highlighted email IDs are either invalid or belong to exiting user(s), who can not be referred.
              </span>
            </div>
          ) : null
        }
        {
        checkUserMultiStatus.data.length !== 0 && invalidEmails.length === 0 ? (
          <div className="not-valid">
            <span>
              Highlighted users are already active in the Ontosight
              <sup>@</sup>
              {' '}
              Terminal and will not be re-invited.
            </span>
          </div>
        ) : null
      }
        {((allowedUsers.length === 0 && emailList.length !== 0) || !atLeastOneAllowedUser) && !showLoader ? <div className="not-valid">Please enter at least one new user to proceed.</div> : null}
        <div className="mt-3.5">
          <textarea
            className="message-input-container input-email m-1 w-full h-full px-2.5 min-h-36"
            value={inputMessage}
            placeholder="Message (Optional)"
            onChange={(e) => setInputMessage(e.target.value)}
          />
        </div>
        <div className="questions-container mt-1">
          <div className="flex justify-end">
            <div
              aria-hidden
              onClick={() => {
                setInputMessage('');
                defaultState();
              }}
              className="py-3.5 px-6 mr-6 pointer btn-hollow-grey"
            >
              Clear
            </div>
            <div aria-hidden onClick={() => handleEmailsValidationAndEnite()} className="py-3.5 px-6 pointer btn-blue-fill">Submit</div>
          </div>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      {referralModal()}
      {successModal()}
    </>
  );
}
