import React, { useEffect } from 'react';
import { checkPermission, windowHrefToNavigate } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';
import Header from '../../components/Header';

function AccessPage() {
  useEffect(() => {
    Mixpanel.track('accessPageEvnet', {
      action: 'You do not have access page view',
      page: 'Access',
    });

    if (checkPermission('ot-access')) {
      windowHrefToNavigate('/', { replace: true });
    }
  }, []);

  return (
    <div className="access-page">
      <Header className="header-home" accesspage />
      <div className="landing-page access-text">
        <div>You do not have access</div>
        <div>
          Contact us at support@ontosight.ai
        </div>
      </div>
    </div>
  );
}

export default AccessPage;
