import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UpdateOrg from '../../container/UpdateOrg';
import { setCookie } from '../../../utils';

export default function OrgSubHeader({ searchValue, setSearchVAlue, handleSearchClick }) {
  const [openCreateOrg, setOpenCreateOrg] = useState(false);
  const [input, setInput] = useState(searchValue);

  const organizationValues = useSelector((state) => state.organizationValues);

  const handleSearchIconClick = () => {
    setSearchVAlue(input);
    handleSearchClick();
  };

  const handleUpdateOrg = (event) => {
    setInput(event.target.value);
    setSearchVAlue(event.target.value);
    handleSearchClick();
    event.preventDefault();
  };

  useEffect(() => {
    if (openCreateOrg) {
      setCookie('create', 'orgUpdateType');
    }
  }, [openCreateOrg]);

  const handleCreateorg = () => setOpenCreateOrg((current) => !current);

  return (
    <div>
      <div className="sub-header-admin">
        <div className="users-count-admin">
          List of Organizations :
          {' '}
          {organizationValues?.total || ''}
        </div>
        <div className="flex righ-container-org">
          <input placeholder="Enter organization" className="p-4 org-input" type="text" name="org-search" value={input} onChange={handleUpdateOrg} />
          <div onClick={handleSearchIconClick} aria-hidden className="search-org-icon" />
          <div aria-hidden onClick={handleCreateorg} className="invite-enterprise-admin pointer">Create Organization</div>
        </div>
      </div>
      <UpdateOrg open={openCreateOrg} setOpen={(value) => setOpenCreateOrg(value)} type="create" />
    </div>
  );
}
