import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const ASSOCIATED_USERS = 'ASSOCIATED_USERS';
const ASSOCIATED_USERS_SUCCESS = 'ASSOCIATED_USERS_SUCCESS';
const ASSOCIATED_USERS_FAILURE = 'ASSOCIATED_USERS_FAILURE';
const ASSOCIATED_USERS_REFRESH = 'ASSOCIATED_USERS_REFRESH';

export const getAssociatedUsersAction = createAction(ASSOCIATED_USERS);

const getAssociatedUsersSuccess = createAction(ASSOCIATED_USERS_SUCCESS);

const getAssociatedUsersFaliure = createAction(ASSOCIATED_USERS_FAILURE);

export const getAssociatedUsersRefresh = createAction(ASSOCIATED_USERS_REFRESH);

export const getAssociatedUsersEpic = (actions$) => actions$.pipe(
  ofType(ASSOCIATED_USERS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/getAssociatedUsers?${action.payload.headers}`, 'GET')).pipe(
    map((res) => getAssociatedUsersSuccess(res)),
    catchError((err) => of(getAssociatedUsersFaliure(err))),
  )),
);

const ongetAssociatedUsersAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetAssociatedUsersSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
  error: false,
});

const ongetAssociatedUsersFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetAssociatedUsersRefresh = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const getAssociatedUsersReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getAssociatedUsersAction, (state) => ongetAssociatedUsersAction(state))
      .addCase(getAssociatedUsersSuccess, (state, action) => ongetAssociatedUsersSuccess(state, action.payload))
      .addCase(getAssociatedUsersFaliure, (state) => ongetAssociatedUsersFailure(state))
      .addCase(getAssociatedUsersRefresh, () => ongetAssociatedUsersRefresh())
      .addDefaultCase((state) => state);
  },
);

const DELETE_ORG = 'DELETE_ORG';
const DELETE_ORG_SUCCESS = 'DELETE_ORG_SUCCESS';
const DELETE_ORG_FAILURE = 'DELETE_ORG_FAILURE';
const DELETE_ORG_REFRESH = 'DELETE_ORG_REFRESH';

export const deleteOrgAction = createAction(DELETE_ORG);

const deleteOrgSuccess = createAction(DELETE_ORG_SUCCESS);

const deleteOrgFaliure = createAction(DELETE_ORG_FAILURE);

export const deleteOrgRefresh = createAction(DELETE_ORG_REFRESH);

export const deleteOrgEpic = (actions$) => actions$.pipe(
  ofType(DELETE_ORG),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/organizations?${action.payload.headers}`, 'DELETE')).pipe(
    map((res) => deleteOrgSuccess(res)),
    catchError((err) => of(deleteOrgFaliure(err))),
  )),
);

const ondeleteOrgAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ondeleteOrgSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
  error: false,
});

const ondeleteOrgFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ondeleteOrgRefresh = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const deleteOrgReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(deleteOrgAction, (state) => ondeleteOrgAction(state))
      .addCase(deleteOrgSuccess, (state, action) => ondeleteOrgSuccess(state, action.payload))
      .addCase(deleteOrgFaliure, (state) => ondeleteOrgFailure(state))
      .addCase(deleteOrgRefresh, () => ondeleteOrgRefresh())
      .addDefaultCase((state) => state);
  },
);
