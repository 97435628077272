import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import SvgLoader from '../../../common/components/SvgLoader';
import Panel from '../../../common/components/Panel';
import sortByOptions, {
  viewByOptions,
  searchAndExploresortOptions,
} from './data';
import {
  getTerimalPage, getTerminalName, trackOutSideEvents, windowHrefToNavigate,
} from '../../../utils';
import FilterModal from '../../../common/container/FilterModal';
import Mixpanel from '../../../utils/mixpanel';

function SearchNavOptions({ isExplorePage, terminalType, autoSuggestUrl }) {
  const [searchParams] = useSearchParams();
  const workspaceQuery = searchParams.get('workspaceQuery');
  const workspaceFilters = searchParams.get('workspaceFilters');
  const workspaceSemanticQuery = searchParams.get('workspaceSemanticQuery');
  const query = searchParams.get('query');
  const from = searchParams.get('from');
  const semanticQuery = searchParams.get('semanticquery');

  const location = useLocation();
  const sortByRef = useRef(null);
  const viewByRef = useRef(null);
  const sortParams = searchParams.get('sort');
  const filters = searchParams.get('filters');

  const [showSortByFilters, setShowByFilters] = useState(false);
  const [showViewByFilters, setShowViewByFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);
  const [toggleMouseOver, setToggleMouseOver] = useState(false);

  useEffect(() => {
    if (!filters || JSON.parse(filters)?.length === 0) {
      setIsDefaultFilter(true);
    }
  }, []);

  useEffect(() => {
    trackOutSideEvents(sortByRef, () => setShowByFilters(false));
    trackOutSideEvents(viewByRef, () => setShowViewByFilters(false));
  }, []);

  const updateUrl = (type, option) => {
    const patentsQuery = searchParams.get('patentsQuery');
    const sponsorQuery = searchParams.get('sponsorQuery');
    const statusQuery = searchParams.get('statusQuery');
    const deepdivequery = searchParams.get('deepdivequery');

    if (location.pathname === '/searchAndExplore/results') {
      if (semanticQuery?.length) {
        Mixpanel.track('sort_event', {
          action: `Sort value - ${!option?.value || Object.keys(option.value).length === 0 ? 'Relevance' : option.value.order} click`,
          page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
          terminal_name: 'Search and Explore',
          search_type: 'Semantic Search',
        });
        windowHrefToNavigate(`/searchAndExplore/results?semanticquery=${semanticQuery}&${type}=${JSON.stringify(option.value)}${filters ? `&filters=${encodeURIComponent(filters)}` : ''}`);
      } else {
        Mixpanel.track('sort_event', {
          action: `Sort value - ${!option?.value || Object.keys(option.value).length === 0 ? 'Relevance' : option.value.order} click`,
          page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
          terminal_name: 'Search and Explore',
          search_type: 'Power Search',
        });
        windowHrefToNavigate(`/searchAndExplore/results?query=${encodeURIComponent(query)}&${type}=${JSON.stringify(option.value)}${filters ? `&filters=${encodeURIComponent(filters)}` : ''}`);
      }
    } else if (location.pathname === '/clinicalTrials/searchResults') {
      if (semanticQuery?.length) {
        Mixpanel.track('sort_event', {
          action: `Sort value - ${!option?.value || Object.keys(option.value).length === 0 ? 'Relevance' : option.value.order} click`,
          page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
          terminal_name: 'Clinical Trials',
          search_type: 'Semantic Search',

        });
        windowHrefToNavigate(
          `/clinicalTrials/searchResults?semanticquery=${semanticQuery}&${type}=${JSON.stringify(
            option.value,
          )}${filters ? `&filters=${encodeURIComponent(filters)}` : ''}${
            deepdivequery
              ? `&deepdivequery=${encodeURIComponent(deepdivequery)}`
              : ''
          }`,
        );
      } else {
        Mixpanel.track('sort_event', {
          action: `Sort value - ${!option?.value || Object.keys(option.value).length === 0 ? 'Relevance' : option.value.order} click`,
          page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
          terminal_name: 'Clinical Trials',
          search_type: 'Power Search',
        });
        if (location.search.includes('workspaceQuery')) {
          windowHrefToNavigate(
            `/clinicalTrials/searchResults?workspaceQuery=${workspaceQuery}&${type}=${JSON.stringify(
              option.value,
            )}${
              workspaceFilters?.length > 0
                ? `&workspaceFilters=${encodeURIComponent(workspaceFilters)}`
                : ''
            }${sponsorQuery ? `&sponsorQuery=${sponsorQuery}` : ''}${
              statusQuery ? `&statusQuery=${statusQuery}` : ''
            }${filters ? `&filters=${encodeURIComponent(filters)}` : ''}${
              deepdivequery
                ? `&deepdivequery=${encodeURIComponent(deepdivequery)}`
                : ''
            }`,
          );
        } else if (workspaceSemanticQuery?.length) {
          windowHrefToNavigate(
            `/clinicalTrials/searchResults?workspaceSemanticQuery=${workspaceSemanticQuery}&${type}=${JSON.stringify(
              option.value,
            )}${
              workspaceFilters?.length > 0
                ? `&workspaceFilters=${encodeURIComponent(workspaceFilters)}`
                : ''
            }${sponsorQuery ? `&sponsorQuery=${sponsorQuery}` : ''}${
              statusQuery ? `&statusQuery=${statusQuery}` : ''
            }${filters ? `&filters=${encodeURIComponent(filters)}` : ''}${
              deepdivequery
                ? `&deepdivequery=${encodeURIComponent(deepdivequery)}`
                : ''
            }`,
          );
        } else {
          windowHrefToNavigate(
            `/clinicalTrials/searchResults?query=${encodeURIComponent(query)}&${type}=${JSON.stringify(
              option.value,
            )}${sponsorQuery ? `&sponsorQuery=${sponsorQuery}` : ''}${
              statusQuery ? `&statusQuery=${statusQuery}` : ''
            }${filters ? `&filters=${encodeURIComponent(filters)}` : ''}${
              deepdivequery
                ? `&deepdivequery=${encodeURIComponent(deepdivequery)}`
                : ''
            }`,
          );
        }
      }
    } else if (location.pathname === '/intervention/results') {
      if (query?.length) {
        Mixpanel.track('sort_event', {
          action: `Sort value - ${option.value.order} click`,
          page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
          terminal_name: 'Intervention',
        });
        windowHrefToNavigate(
          `/intervention/results?query=${query}&${type}=${JSON.stringify(
            option.value,
          )}${patentsQuery ? `&patentsQuery=${patentsQuery}` : ''}${
            filters ? `&filters=${filters}` : ''
          }`,
        );
      } else if (location.search.includes('workspaceQuery')) {
        windowHrefToNavigate(
          `/intervention/results?workspaceQuery=${workspaceQuery}&${type}=${JSON.stringify(
            option.value,
          )}${patentsQuery ? `&patentsQuery=${patentsQuery}` : ''}${
            workspaceFilters ? `&workspaceFilters=${workspaceFilters}` : ''
          }`,
        );
      }
    }
  };

  const handleClick = () => {
    const sort = searchParams.get('sort');
    if (location.pathname === '/searchAndExplore/results') {
      if (semanticQuery?.length) {
        Mixpanel.track('analytics_view_icon_event', {
          action: 'Analytics View click in nav bar',
          page: 'List View Page',
          terminal_name: 'Search and Explore',
          search_type: 'Semantic Search',
          query: JSON.parse(semanticQuery),
          filters: JSON.parse(filters),
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'List View Page',
          terminal_name: 'Search and Explore',
        });
        windowHrefToNavigate(
          `/searchAndExplore/analytics?semanticquery=${encodeURIComponent(semanticQuery)}${filters ? `&filters=${encodeURIComponent(filters)}` : ''}${query ? `&query=${encodeURIComponent(query)}` : ''}`,
        );
      } else {
        Mixpanel.track('analytics_view_icon_event', {
          action: 'Analytics View click in nav bar',
          page: 'List View Page',
          terminal_name: 'Search and Explore',
          search_type: 'Power Search',
          query: JSON.parse(query),
          filters: JSON.parse(filters),
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'List View Page',
          terminal_name: 'Search and Explore',
        });

        windowHrefToNavigate(`/searchAndExplore/analytics?query=${encodeURIComponent(query)}&sort=${sort}${filters ? `&filters=${encodeURIComponent(filters)}` : ''}`);
      }
    }

    if (location.pathname.indexOf('clinicalTrials') > -1) {
      if (semanticQuery) {
        Mixpanel.track('analytics_view_icon_event', {
          action: 'Analytics View click in nav bar',
          page: 'List View Page',
          terminal_name: 'Clinical Trials',
          search_type: 'Semantic Search',
          query: JSON.parse(semanticQuery),
          filters: JSON.parse(filters),
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'List View Page',
          terminal_name: 'Clinical Trials',
        });

        windowHrefToNavigate(
          `/clinicalTrials/analyticsResults?semanticquery=${encodeURIComponent(
            semanticQuery,
          )}${filters ? `&filters=${encodeURIComponent(filters)}` : ''}`,
        );
      } else {
        Mixpanel.track('analytics_view_icon_event', {
          action: 'Analytics View click in nav bar',
          page: 'List View Page',
          terminal_name: 'Clinical Trials',
          search_type: 'Power Search',
          query: JSON.parse(query),
          filters: JSON.parse(filters),
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'List View Page',
          terminal_name: 'Clinical Trials',
        });
        if (location.search.includes('workspaceQuery')) {
          windowHrefToNavigate(
            `/clinicalTrials/analyticsResults?workspaceQuery=${workspaceQuery}${
              workspaceFilters?.length > 0
                ? `&workspaceFilters=${encodeURIComponent(workspaceFilters)}`
                : ''
            }`,
          );
        } else if (workspaceSemanticQuery?.length) {
          windowHrefToNavigate(
            `/clinicalTrials/analyticsResults?workspaceSemanticQuery=${workspaceSemanticQuery}${
              workspaceFilters?.length > 0
                ? `&workspaceFilters=${encodeURIComponent(workspaceFilters)}`
                : ''
            }`,
          );
        } else {
          windowHrefToNavigate(
            `/clinicalTrials/analyticsResults?query=${encodeURIComponent(
              query,
            )}&sort=${sort}${
              filters ? `&filters=${encodeURIComponent(filters)}` : ''
            }`,
          );
        }
      }
    }

    if (location.pathname === '/intervention/results') {
      if (query?.length) {
        Mixpanel.track('analytics_view_icon_event', {
          action: 'Analytics View click in nav bar',
          page: 'List View Page',
          terminal_name: 'Intervention',
          query: JSON.parse(query),
          filters: JSON.parse(filters),
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'List View Page',
          terminal_name: 'Intervention',
        });

        windowHrefToNavigate(
          `/intervention/analytics?query=${query}&sort=${sort}${
            filters ? `&filters=${encodeURIComponent(filters)}` : ''
          }`,
        );
      } else if (location.search.includes('workspaceQuery')) {
        windowHrefToNavigate(
          `/intervention/analytics?workspaceQuery=${workspaceQuery}&sort=${sort}${
            workspaceFilters ? `&workspaceFilters=${encodeURIComponent(workspaceFilters)}` : ''
          }`,
        );
      }
    }
  };

  const showFiltersTrack = () => {
    setShowFilters(true);
    Mixpanel.track('filter_icon_event', {
      action: 'show filter modal',
      terminal_name: getTerminalName(location),
      page: 'List View Page',
      search_type: `${
        semanticQuery
          ? 'Semantic Search'
          : getTerminalName(location) === 'Intervention'
            ? 'Intervention Search'
            : 'Power Search'
      }`,
      query: JSON.parse(semanticQuery || query),
    });
  };

  const renderViewOptions = () => viewByOptions.map((option) => (
    <div className="options filters-options" aria-hidden key={option.value}>
      {option.label}
    </div>
  ));

  const renderViewByOptions = () => (
    <Panel className="panel view-by-panel">
      <div className="filter-header">View by</div>
      {renderViewOptions()}
    </Panel>
  );

  const renderedOptions = () => sortByOptions.map((option) => (
    <div
      className={`options filters-options ${
        JSON.parse(sortParams)?.field === option?.value?.field
          && JSON.parse(sortParams)?.order === option?.value?.order
          ? 'active'
          : ''
      }`}
      aria-hidden
      key={option.label}
      onClick={() => updateUrl('sort', option)}
    >
      {option.label}
    </div>
  ));

  const renderedSESortOptions = () => searchAndExploresortOptions.map((option) => (
    <div
      className={`options filters-options ${
        JSON.parse(sortParams)?.field === option?.value?.field
          && JSON.parse(sortParams)?.order === option?.value?.order
          ? 'active'
          : ''
      }`}
      aria-hidden
      key={option.label}
      onClick={() => updateUrl('sort', option)}
    >
      {option.label}
    </div>
  ));

  const renderSortByOptions = () => (
    <Panel
      className={`panel filters-panel ${isExplorePage ? 'explore-page' : ''}`}
    >
      <div className="filter-header">Sort by</div>
      {terminalType === 'explore' || terminalType === 'intervention'
        ? renderedSESortOptions()
        : renderedOptions()}
    </Panel>
  );

  const handleClose = () => {
    setShowFilters(false);
    Mixpanel.track('cross_event', {
      action: 'user clicked on cross button',
      page_name: getTerimalPage(location),
    });
  };

  return (
    <div className="filter-bar-container">
      <AnimatePresence>
        <motion.div className="filter-center-container">
          {!location.search.includes('semanticquery') ? (
            <div
              aria-hidden
              onClick={() => setShowByFilters(!showSortByFilters)}
              className="sort-by"
              ref={sortByRef}
            >
              <SvgLoader
                className="option"
                width="20px"
                height="20px"
                svgName={`${sortParams ? 'sort-checked-icon' : 'sort-filter'}`}
              />
              {showSortByFilters && renderSortByOptions()}
              <span className="filter-text-name">Sort</span>
            </div>
          ) : null}

          <div
            aria-hidden
            className="ct-filters"
            onClick={() => showFiltersTrack()}
          >
            <SvgLoader
              className="option"
              width={20}
              height={20}
              svgName={`${
                isDefaultFilter ? 'filter-icon' : 'filter-checked-icon'
              }`}
            />
            <span className="filter-text-name">Filter</span>
          </div>
        </motion.div>
      </AnimatePresence>
      <div className="card-view-options">
        {showFilters ? (
          <FilterModal
            autoSuggestUrl={autoSuggestUrl}
            isDefault={isDefaultFilter}
            setdefault={setIsDefaultFilter}
            open={showFilters}
            handleClose={() => handleClose()}
          />
        ) : null}
        <div className="view-by" ref={viewByRef}>
          {/* <SvgLoader onClick={() => setShowViewByFilters(!showViewByFilters)} className="option" width={22} height={22} svgName="view-by" /> */}
          {showViewByFilters && renderViewByOptions()}
        </div>
        <div className={`toggle-result ${toggleMouseOver ? 'purple-background ' : ''}`}>
          <div aria-hidden className="flex-row" onFocus={() => setToggleMouseOver(true)} onMouseLeave={() => setToggleMouseOver(false)} onMouseEnter={() => setToggleMouseOver(true)} onClick={() => handleClick()}>
            <SvgLoader
              width={20}
              height={20}
              svgName="anaytics-inactive-icon"
            />
            <span className="filter-text-name">Analytics View</span>
          </div>
          <div className="flex-row current-view" onMouseEnter={((event) => event.stopPropagation())}>
            <SvgLoader width={20} height={20} svgName="list-view-active" />
            <span className="filter-text-name">List View</span>
          </div>
        </div>
      </div>
    </div>
  );
}

SearchNavOptions.propTypes = {
  isExplorePage: PropTypes.bool,
  autoSuggestUrl: PropTypes.string.isRequired,
  terminalType: PropTypes.string,
};

SearchNavOptions.defaultProps = {
  isExplorePage: false,
  terminalType: '',
};

export default SearchNavOptions;
