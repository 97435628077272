import React, { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';
import { Input } from 'antd';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import { getChatbotAnswerAction, closeChatbotAction } from './logic';
import Loader from '../../components/Loader';
import TextComponent from '../../components/TextComponent';
import Mixpanel from '../../../utils/mixpanel';
import {
  getCookie, getTerimalPage, getTerminalName, setCookie,
} from '../../../utils';

function ChatBot() {
  const dispatch = useDispatch();
  const location = useLocation();
  const chatbotAnswers = useSelector((state) => state.chatbotAnswers);
  const [messages, setMessages] = useState([{ type: 'bot', text: 'Hi there! How can I help?', time: new Date() }]);
  const [inputValue, setInputValue] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCookieSet, setIsCookieSet] = useState(false);
  const lastBotMessageRef = useRef('');
  const chatBodyRef = useRef(null);
  const intervalRef = useRef(null);

  const projId = getCookie('projectId');

  useEffect(() => {
    if (getCookie('chatSession') === '') {
      setMessages([{ type: 'bot', text: 'Hi there! How can I help?', time: new Date() }]);
      localStorage.removeItem('chatMessages');
      localStorage.removeItem('chatCollapsedState');
      setCookie('', 'chatSession');
      setIsCookieSet(false);
    }
  }, [getCookie('chatSession')]);

  const getRelativeTime = (time) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInHours > 0) {
      return diffInHours === 1 ? '1 h ago' : `${diffInHours} h ago`;
    }

    if (diffInMinutes > 0) {
      return diffInMinutes === 1 ? '1 m ago' : `${diffInMinutes} m ago`;
    }

    return diffInSeconds === 0 ? 'Just now' : 'few secs ago';
  };

  const randomString = uuidv4();
  const hash = CryptoJS.SHA256(randomString).toString(CryptoJS.enc.Hex);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const savedMessages = JSON.parse(localStorage.getItem('chatMessages'));
    if (savedMessages) {
      setMessages(savedMessages);
    }

    const savedCollapsedState = JSON.parse(localStorage.getItem('chatCollapsedState'));
    if (savedCollapsedState !== null) {
      setIsCollapsed(savedCollapsedState);
    }
  }, []);

  useEffect(() => {
    if (chatbotAnswers.flag) {
      const newBotMessage = chatbotAnswers.data.text;

      if (newBotMessage !== lastBotMessageRef.current) {
        const updatedMessages = [
          ...messages.slice(0, -1),
          { type: 'bot', text: newBotMessage, time: new Date() },
        ];

        setMessages(updatedMessages);
        localStorage.setItem('chatMessages', JSON.stringify(updatedMessages));
        // setCookie(hash, 'chatSession');
        lastBotMessageRef.current = newBotMessage;
      }
    }
  }, [chatbotAnswers]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setMessages((prevMessages) => prevMessages.map((message) => ({
        ...message,
        relativeTime: getRelativeTime(message.time),
      })));
    }, 60000);
    return () => clearInterval(intervalRef.current);
  }, []);

  const handleSend = () => {
    if (inputValue.trim() === '') return;

    const userMessage = { type: 'user', text: inputValue, time: new Date() };
    const updatedMessages = [...messages, userMessage];

    localStorage.setItem('chatMessages', JSON.stringify(updatedMessages));
    if (!isCookieSet && !getCookie('chatSession')) {
      setCookie(hash, 'chatSession');
      setIsCookieSet(true);
    } else if (getCookie('chatSession') === '') {
      setCookie(hash, 'chatSession');
      setIsCookieSet(true);
    }

    setMessages(updatedMessages);

    const botMessagePlaceholder = {
      type: 'bot',
      text: '',
      time: new Date(),
      loading: true,
    };
    setMessages((prevMessages) => [...prevMessages, botMessagePlaceholder]);
    setInputValue('');

    dispatch(getChatbotAnswerAction({
      params: {
        headers: queryString.stringify({
          searched_query: inputValue,
          project_id: projId,
          session_id: getCookie('chatSession') !== '' ? getCookie('chatSession') : hash,
        }),
      },
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const handleCross = () => {
    setMessages([{ type: 'bot', text: 'Hi there! How can I help?', time: new Date() }]);
    dispatch(closeChatbotAction());
    localStorage.removeItem('chatMessages');
    localStorage.removeItem('chatCollapsedState');
    setCookie('', 'chatSession');
    setIsCookieSet(false);

    Mixpanel.track('cross_event', {
      action: 'user clicked on cross button',
      page_name: getTerimalPage(location),
    });
  };

  const handleCollapseToggle = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    localStorage.setItem('chatCollapsedState', JSON.stringify(newCollapsedState));
    Mixpanel.track('switch_widget_view', {
      action: !isCollapsed ? 'Collapsed view' : 'Expanded view',
      feature: 'Chatbot',
      terminal_name: getTerminalName(location),
      page: getTerimalPage(location),
    });
  };

  return (
    <div className="chatbot-content">
      <div className="chat-header">
        <div className="onto-icon" />
        <div className="heading">Chat with BioBot</div>
        <div className={isCollapsed ? 'expanded-view' : 'collapsed-view'} role="presentation" onClick={handleCollapseToggle} />
        <div className="cross-icon" role="presentation" onClick={handleCross} />
      </div>
      {!isCollapsed && (
        <div className="chat-section">
          <div
            className="chat-body"
            ref={chatBodyRef}
            style={{ overflowY: 'auto', flexGrow: 1 }}
          >
            {messages.map((message, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={message.type === 'bot' ? 'receiver-text text-chat' : 'sender-text text-chat'}>
                {message.type === 'bot' ? (
                  <div className="chat-content">
                    {message.loading ? (
                      <Loader
                        error={chatbotAnswers.error}
                        loading
                        height="auto"
                        className="white-loader"
                        message="Loading..."
                        style={{ justifyContent: 'center', paddingTop: '10px' }}
                      />
                    ) : (
                      <div className="chat-content-value">
                        <TextComponent text={message.text} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="chat-content">
                    <div className="chat-content-time-stamp">{getRelativeTime(message.time)}</div>
                    <div className="chat-content-value">{message.text}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="input-text-section">
            <div className="question-bar">
              <Input
                placeholder="Type a question..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <div className="send-btn" role="presentation" onClick={handleSend} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatBot;
