import { React, useState, useEffect } from 'react';
import {
  Input, Button, Radio, Popover,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getProjectsAction, createProjectAction, createProjectRefreshAction } from '../WorkSpace/logic';
import { sendNotification } from '../../components/Notification/logic';
import Loader from '../../components/Loader';
import Mixpanel from '../../../utils/mixpanel';

function HeaderProjectDropdown({
  projectId,
  projectName,
  setSelectedProjectName,
  onProjectChange,
  setOpenProjectDropdown,
  from,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const createProject = useSelector((state) => state.createProject);
  const projectsData = useSelector((state) => state.projectsData);
  const [inputError, setInputError] = useState('');
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [inputText, setInputText] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  useEffect(() => {
    if (projectId) {
      setSelectedProjectName(projectName);
      setSelectedProjectId(projectId);
    }
  }, []);

  const handleChange = (value) => {
    setInputText(value);
    if (value.length > 3) {
      setInputError('');
    } else {
      setInputError('Please enter more than 3 characters');
    }
  };

  const handleClick = () => {
    dispatch(
      createProjectAction({
        body: {
          project_name: inputText,
        },
      }),
    );
    setOpenCreateProject(!openCreateProject);
    setInputText('');
    dispatch(createProjectRefreshAction());
  };

  const apiCall = () => {
    dispatch(
      getProjectsAction({
        params: {
          doc_id: '',
        },
      }),
    );
  };

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    if (createProject.flag) {
      apiCall();
    }
  }, [JSON.stringify(createProject)]);

  useEffect(() => {
    if (createProject.flag) {
      dispatch(
        sendNotification({
          type: 'PROJECT_CREATED_SUCCESS',
          notificationType: 'success',
          message: 'Project created successfully',
          showNotification: true,
        }),
      );
      apiCall();
    } else if (createProject.error) {
      if (createProject.data?.statusCode === 409) {
        dispatch(
          sendNotification({
            type: 'CREATE_PROJECT_ERROR',
            notificationType: 'error',
            message: createProject.data?.message,
            showNotification: true,
          }),
        );
      }
    }
  }, [JSON.stringify(createProject)]);

  const handleDivClick = (projId, projName) => {
    const selectedProject = projectsData.data.find(
      (project) => project.projectId === projId,
    );
    setSelectedProjectId(projId);
    setSelectedProjectName(selectedProject.projectName);
    onProjectChange(projId, selectedProject.projectName);
    setOpenProjectDropdown(false);

    Mixpanel.track('switch_project_event', {
      action: 'switch project click',
      project_name: projName,
      page_name: from ? `Document Listing Page-${from}` : location.pathname.includes('/home') ? 'home-page' : location.pathname.includes('/analytics') ? 'analytics page' : 'Document Listing Page - List View',
    });
  };

  const handleRadioChange = (e) => {
    const selectedProject = projectsData.data.find(
      (project) => project.projectId === e.target.value,
    );
    setSelectedProjectId(e.target.value);
    setSelectedProjectName(selectedProject.projectName);
    onProjectChange(e.target.value, selectedProject.projectName);
    setOpenProjectDropdown(false);
  };

  const handleCancel = () => {
    setOpenCreateProject(false);
    setInputText('');
  };

  const renderCreateProject = () => (
    <div className="create-project-sec">
      <div className="project-header">
        <div className="project-title"> New Project</div>
        <div
          className="close-icon"
          role="presentation"
          onClick={() => handleCancel()}
        />
      </div>
      <div className="project-content">
        <Input
          value={inputText}
          onChange={(e) => handleChange(e.target.value)}
        />
        {inputError && <div style={{ color: 'red' }}>{inputError}</div>}
      </div>
      <div className="btn-section">
        <Button
          type="primary"
          className="cancel-btn"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          role="presentation"
          onClick={handleClick}
          disabled={inputText.length <= 3}
        >
          Create
        </Button>
      </div>
    </div>
  );
  return (
    <div className="bookmark">
      <div className="bookmark-container">
        <div className="bookmark-title">Switch Project</div>
        <div
          className="cross-btn"
          role="presentation"
          onClick={() => setOpenProjectDropdown(false)}
        />
      </div>
      <Loader loading={projectsData.loading}>
        <div className="analytics-container-content-sidebar-link--child">
          <Radio.Group value={selectedProjectId} onChange={handleRadioChange}>
            {projectsData?.data?.map(
              (i) => i.projectName !== 'Shared Documents' && (
              <div
                role="presentation"
                onClick={() => handleDivClick(i?.projectId, i?.projectName)}
                key={i.id}
                className={
                        selectedProjectId === i.projectId
                          ? 'analytics-container-content-sidebar-link analytics-container-content-sidebar-link-selected'
                          : 'analytics-container-content-sidebar-link'
                      }
              >
                <Radio value={i.projectId} />
                <div className="project-content">
                  <div className="analytics-container-content-sidebar-link-title">
                    <div className="analytics-container-content-sidebar-link-title-main">
                      {i?.projectName}
                    </div>
                  </div>
                </div>
              </div>
              ),
            )}
          </Radio.Group>
        </div>
        <Popover
          overlayClassName="create-proj-popover"
          placement="top"
          open={openCreateProject}
          content={renderCreateProject()}
        >
          <div
            className="create-project"
            role="presentation"
            onClick={() => setOpenCreateProject(!openCreateProject)}
          >
            <div className="add-project" />
            <div className="project-name">Create New Project</div>
          </div>
        </Popover>
      </Loader>
    </div>
  );
}

export default HeaderProjectDropdown;
