import { createAction, createReducer, current } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_USER_LIST = 'GET_USER_LIST';
const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';
const GET_USER_LIST_REFRESH = 'GET_USER_LIST_REFRESH';
const UPDATE_USER_LIST = 'UPDATE_USER_LIST';

export const getUserListForAdminAction = createAction(GET_USER_LIST);

const getUserListForAdminSuccess = createAction(GET_USER_LIST_SUCCESS);

const getUserListForAdminFaliure = createAction(GET_USER_LIST_FAILURE);

export const getUserListForAdminRefreshAction = createAction(GET_USER_LIST_REFRESH);

export const updateUserListAction = createAction(UPDATE_USER_LIST);

export const getUserListForAdminEpic = (actions$) => actions$.pipe(
  ofType(GET_USER_LIST),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users?${action.payload.headers}`, 'GET')).pipe(
    map((res) => getUserListForAdminSuccess(res)),
    catchError((err) => of(getUserListForAdminFaliure(err))),
  )),
);

const ongetUserListForAdminAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetUserListForAdminSuccess = (state, { response }) => ({
  ...state,
  data: [...state.data, ...response.data],
  total: response.total,
  loading: false,
  flag: true,
});

const ongetUserListForAdminFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetUserListForAdminRefreshAction = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const onUpdateUserList = (state, payload) => {
  const newList = [];
  current(state).data.forEach((user) => {
    let tempUser = {};
    payload.data.forEach((data) => {
      const { email, userOnboardingType } = data;
      if (user.email === email && user.userOnboardingType === userOnboardingType) {
        tempUser = data;
      }
    });
    if (JSON.stringify(tempUser) !== '{}') {
      newList.push(tempUser);
    } else {
      newList.push(user);
    }
  });
  state.data = [...newList];
  return state;
};

const getUserListForAdminReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getUserListForAdminAction, (state) => ongetUserListForAdminAction(state))
      .addCase(getUserListForAdminSuccess, (state, action) => ongetUserListForAdminSuccess(state, action.payload))
      .addCase(getUserListForAdminFaliure, (state) => ongetUserListForAdminFailure(state))
      .addCase(getUserListForAdminRefreshAction, () => ongetUserListForAdminRefreshAction())
      .addCase(updateUserListAction, (state, action) => onUpdateUserList(state, action.payload))
      .addDefaultCase((state) => state);
  },
);

export default getUserListForAdminReducer;

const TERMINAL_ACCESS_MODAL = 'TERMINAL_ACCESS_MODAL';

export const editTerminalsAction = createAction(TERMINAL_ACCESS_MODAL);

const onEditTerminals = (state, payload) => {
  state.open = payload.open;
  state.data = { ...payload.data };
  return state;
};

export const editTerminalsReducer = createReducer(
  {
    open: false,
    data: {},
  },
  (builder) => {
    builder.addCase(editTerminalsAction, (state, action) => onEditTerminals(state, action.payload))
      .addDefaultCase((state) => state);
  },
);

const GET_SINGLE_USER_ITEM = 'GET_SINGLE_USER_ITEM';
const GET_SINGLE_USER_ITEM_SUCCESS = 'GET_SINGLE_USER_ITEM_SUCCESS';
const GET_SINGLE_USER_ITEM_FAILURE = 'GET_SINGLE_USER_ITEM_FAILURE';
const GET_SINGLE_USER_ITEM_REFRESH = 'GET_SINGLE_USER_ITEM_REFRESH';

export const getSingleUserDetailsAction = createAction(GET_SINGLE_USER_ITEM);

const getSingleUserDetailsSuccess = createAction(GET_SINGLE_USER_ITEM_SUCCESS);

const getSingleUserDetailsFaliure = createAction(GET_SINGLE_USER_ITEM_FAILURE);

export const getSingleUserDetailsRefreshAction = createAction(GET_SINGLE_USER_ITEM_REFRESH);

export const getSingleUserDetailsEpic = (actions$) => actions$.pipe(
  ofType(GET_SINGLE_USER_ITEM),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users?${action.payload.headers}`, 'GET')).pipe(
    map((res) => getSingleUserDetailsSuccess(res)),
    catchError((err) => of(getSingleUserDetailsFaliure(err))),
  )),
);

const ongetSingleUserDetailsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetSingleUserDetailsSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  total: response.total,
  loading: false,
  flag: true,
});

const ongetSingleUserDetailsFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetSingleUserDetailsRefreshAction = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getSingleUserDetailsReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getSingleUserDetailsAction, (state) => ongetSingleUserDetailsAction(state))
      .addCase(getSingleUserDetailsSuccess, (state, action) => ongetSingleUserDetailsSuccess(state, action.payload))
      .addCase(getSingleUserDetailsFaliure, (state) => ongetSingleUserDetailsFailure(state))
      .addCase(getSingleUserDetailsRefreshAction, () => ongetSingleUserDetailsRefreshAction())
      .addDefaultCase((state) => state);
  },
);

const CURRENT_DATE_ACCESS_MODAL = 'CURRENT_DATE_ACCESS_MODAL';

export const currentDataModalAction = createAction(CURRENT_DATE_ACCESS_MODAL);

const oncurrentDataModal = (state, payload) => {
  state.open = payload.open;
  state.data = { ...payload.data };
  return state;
};

export const currentDataModalReducer = createReducer(
  {
    open: false,
    data: {},
  },
  (builder) => {
    builder.addCase(currentDataModalAction, (state, action) => oncurrentDataModal(state, action.payload))
      .addDefaultCase((state) => state);
  },
);

const UPDATE_USER_TYPE = 'UPDATE_USER_TYPE';
const UPDATE_USER_TYPE_SUCCESS = 'UPDATE_USER_TYPE_SUCCESS';
const UPDATE_USER_TYPE_FAILURE = 'UPDATE_USER_TYPE_FAILURE';
const UPDATE_USER_TYPE_REFRESH = 'UPDATE_USER_TYPE_REFRESH';

export const updateUserTypeAction = createAction(UPDATE_USER_TYPE);

const updateUserTypeSuccess = createAction(UPDATE_USER_TYPE_SUCCESS);

const updateUserTypeFaliure = createAction(UPDATE_USER_TYPE_FAILURE);

export const updateUserTypeRefreshAction = createAction(UPDATE_USER_TYPE_REFRESH);

export const updateUserTypeEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_USER_TYPE),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users/updateUserType?${action.payload.headers}`, 'PUT')).pipe(
    map((res) => updateUserTypeSuccess(res)),
    catchError((err) => of(updateUserTypeFaliure(err))),
  )),
);

const onupdateUserTypeAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onupdateUserTypeSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onupdateUserTypeFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onupdateUserTypeRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const updateUserTypeReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(updateUserTypeAction, (state) => onupdateUserTypeAction(state))
      .addCase(updateUserTypeSuccess, (state, action) => onupdateUserTypeSuccess(state, action.payload))
      .addCase(updateUserTypeFaliure, (state) => onupdateUserTypeFailure(state))
      .addCase(updateUserTypeRefreshAction, () => onupdateUserTypeRefreshAction())
      .addDefaultCase((state) => state);
  },
);

const UPDATE_USER_ORGANIZATION = 'UPDATE_USER_ORGANIZATION';
const UPDATE_USER_ORGANIZATION_SUCCESS = 'UPDATE_USER_ORGANIZATION_SUCCESS';
const UPDATE_USER_ORGANIZATION_FAILURE = 'UPDATE_USER_ORGANIZATION_FAILURE';
const UPDATE_USER_ORGANIZATION_REFRESH = 'UPDATE_USER_ORGANIZATION_REFRESH';

export const updateUserOrganizationNameAction = createAction(UPDATE_USER_ORGANIZATION);

const updateUserOrganizationNameSuccess = createAction(UPDATE_USER_ORGANIZATION_SUCCESS);

const updateUserOrganizationNameFaliure = createAction(UPDATE_USER_ORGANIZATION_FAILURE);

export const updateUserOrganizationNameRefreshAction = createAction(UPDATE_USER_ORGANIZATION_REFRESH);

export const updateUserOrganizationNameEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_USER_ORGANIZATION),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users/updateUserOrganization?${action.payload.headers}`, 'PUT')).pipe(
    map((res) => updateUserOrganizationNameSuccess(res)),
    catchError((err) => of(updateUserOrganizationNameFaliure(err))),
  )),
);

const onupdateUserOrganizationNameAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onupdateUserOrganizationNameSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onupdateUserOrganizationNameFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onupdateUserOrganizationNameRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const updateUserOrganizationNameReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(updateUserOrganizationNameAction, (state) => onupdateUserOrganizationNameAction(state))
      .addCase(updateUserOrganizationNameSuccess, (state, action) => onupdateUserOrganizationNameSuccess(state, action.payload))
      .addCase(updateUserOrganizationNameFaliure, (state) => onupdateUserOrganizationNameFailure(state))
      .addCase(updateUserOrganizationNameRefreshAction, () => onupdateUserOrganizationNameRefreshAction())
      .addDefaultCase((state) => state);
  },
);
