import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import ErrorPage from './utils/ErrorPage';
import '../public/styles/main.scss';
import Main from './app/container/Main';
import './utils/app.css';
import CTLandingPage from './clinicalTrials/pages/CTLandingPage';
import HomePage from './app/pages/HomePage';
import AccessPage from './common/pages/AccessPage';
import CTsearchResultPage from './clinicalTrials/pages/CTsearchResultPage';
import CTAnalyticsPage from './clinicalTrials/pages/CTAnalyticsPage';
import SELandingPage from './searchAndExplore/pages/SELandingPage';
import SEResultPage from './searchAndExplore/pages/SEResultPage';
import SEAnalyticsPage from './searchAndExplore/pages/SEAnalyticsPage';
import InterventionLandingPage from './intervention/pages/InterventionLandingPage';
import InterventionAnalyticsPage from './intervention/pages/InterventionAnalyticsPage';
import InterventionResultPage from './intervention/pages/InterventionResultPage';
import ContactUs from './common/pages/ContactUsPage';
import PrivacyPolicy from './common/pages/PrivacyPolicy';
import UserPolicy from './common/pages/UserPolicyPage';
import LandingPageV2 from './app/pages/LandingPageV2';
import Persona from './app/container/Persona';
import WorkSpace from './common/container/WorkSpace';
import AdminPage from './app/pages/AdminPage';
import HomePageV2 from './app/pages/HomePageV2';
import RedirectPage from './common/pages/RedirectPage';
import Callback from './common/container/Callback';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

mixpanel.init(process.env.mixpanelToken, { debug: true });

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <LandingPageV2 />,
      },
      {
        path: 'home',
        element: <HomePageV2 />,
      },
      {
        path: 'homeV2',
        element: <HomePage />,
      },
      {
        path: '/access',
        element: <AccessPage />,
      },
      {
        path: '/contact',
        element: <ContactUs />,
      },
      {
        path: '/privacyPolicy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/userPolicy',
        element: <UserPolicy />,
      },
      {
        path: '/persona',
        element: <Persona />,
      },
      {
        path: '/callback',
        element: <Callback />,
      },
      {
        path: '/redirect',
        element: <RedirectPage />,
      },
      {
        path: '/admin',
        element: <AdminPage />,
      },
    ],
  },
  {
    path: '/clinicalTrials',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <CTLandingPage />,
      },
      {
        path: 'searchResults',
        element: <CTsearchResultPage />,
      },
      {
        path: 'analyticsResults',
        element: <CTAnalyticsPage />,
      },
    ],
  },
  {
    path: '/searchAndExplore',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <SELandingPage />,
      },
      {
        path: 'results',
        element: <SEResultPage />,
      },
      {
        path: 'analytics',
        element: <SEAnalyticsPage />,
      },
    ],
  },
  {
    path: '/intervention',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <InterventionLandingPage />,
      },
      {
        path: 'results',
        element: <InterventionResultPage />,
      },
      {
        path: 'analytics',
        element: <InterventionAnalyticsPage />,
      },
    ],
  },
  {
    path: '/workspace',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <WorkSpace />,
      },
    ],

  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
