/*eslint-disable */
import { React, useState, useEffect } from "react";
import { Popover, Input, Button, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createProjectAction,
  createProjectRefreshAction,
  getProjectsAction,
} from "../WorkSpace/logic";
import { hashGeneratorAction, hashGeneratorRefresh } from "./logic";
import { createSavedSearchAction, createSavedSearchRefresh } from "./logic";

import Loader from "../../components/Loader";
import { sendNotification } from "../../components/Notification/logic";
import { getCookie } from "../../../utils";

const { TextArea } = Input;

function SavedSearches({
  reqBody,
  onClose,
  bookmarkId,
  setBookmarkid,
  filtersData,
  terminalName,
  view,
  totalCount,
  semanticQueryRequest,
  url,
  q,
  f,
  hashApiCall,
}) {
  const dispatch = useDispatch();
  const createProject = useSelector((state) => state.createProject);
  const projectsData = useSelector((state) => state.projectsData);
  const createSavedSearch = useSelector((state) => state.createSavedSearch);
  const hashGenerator = useSelector((state) => state.hashGenerator);
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [inputText, setInputText] = useState("");
  const [inputError, setInputError] = useState("");
  const [comment, setComment] = useState("");
  const [bookmarkProjects, setBookmarkProjects] = useState({
    originalBookmarks: [],
    currentBookmarks: [],
    addedBookmarks: [],
    removedBookmarks: [],
  });

  const projId = getCookie("projectId");
  const queryUrl = {
    url: url,
    query: q,
    filter: f,
  };

  const isEmpty = (obj) => Object.keys(obj).length === 0;
  const nonEmptyRequestBody = !isEmpty(reqBody.body)
    ? reqBody
    : semanticQueryRequest;

  const handleChange = (value) => {
    setInputText(value);
    if (value.length > 3) {
      setInputError("");
    } else {
      setInputError("Please enter more than 3 characters");
    }
  };
  const handleClick = () => {
    dispatch(
      createProjectAction({
        body: {
          project_name: inputText,
        },
      })
    );
    setOpenCreateProject(!openCreateProject);
  };

  const apiCall = () => {
    dispatch(
      getProjectsAction({
        params: {
          doc_id: "",
          hash: hashGenerator?.data?.hash,
        },
      })
    );
  };
  useEffect(() => {
    if (createProject.flag) {
      dispatch(
        sendNotification({
          type: "PROJECT_CREATED_SUCCESS",
          notificationType: "success",
          message: "Project created successfully",
          showNotification: true,
        })
      );
      dispatch(createProjectRefreshAction());
      apiCall();
    }
  }, [JSON.stringify(createProject)]);

  useEffect(() => {
    if (projectsData.flag) {
      const projectIds = [];
      for (let i = 0; i < projectsData.data.length; i += 1) {
        if (projectsData.data[i]?.bookmarkFlag === true) {
          projectIds.push(projectsData.data[i]?.projectId);
        }
      }

      setBookmarkProjects({
        ...bookmarkProjects,
        originalBookmarks: projectIds,
        currentBookmarks: projectIds,
        addedBookmarks: [],
        removedBookmarks: [],
      });
    }
  }, [JSON.stringify(projectsData)]);

  useEffect(() => {
    apiCall();
    dispatch(createSavedSearchRefresh());
  }, []);

  useEffect(() => {
    if (createSavedSearch.flag) {
      dispatch(
        sendNotification({
          type: "SAVED_SEARCH_CREATED_SUCCESS",
          notificationType: "success",
          message: createSavedSearch.data.message,
          showNotification: true,
        })
      );
      dispatch(createSavedSearchRefresh({}));
      hashApiCall();

    }
  }, [createSavedSearch.flag]);

  const renderCreateProject = () => (
    <div className="create-project-sec">
      <div className="project-header">
        <div className="project-title"> New project</div>
        <div
          className="close-icon"
          role="presentation"
          onClick={() => setOpenCreateProject(!openCreateProject)}
        />
      </div>
      <div className="project-content">
        <Input onChange={(e) => handleChange(e.target.value)} />
        {inputError && <div style={{ color: "red" }}>{inputError}</div>}
      </div>
      <div className="btn-section">
        <Button
          type="primary"
          className="cancel-btn"
          onClick={() => setOpenCreateProject(false)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          role="presentation"
          onClick={handleClick}
          disabled={inputText.length <= 3}
        >
          Create
        </Button>
      </div>
    </div>
  );

  const handleCheckboxChange = (projectId) => {
    let current = [...bookmarkProjects.currentBookmarks];
    let added = [...bookmarkProjects.addedBookmarks];
    let removed = [...bookmarkProjects.removedBookmarks];
    let status = "";

    if (current.includes(projectId)) {
      status = "remove";
      current = current.filter((id) => id !== projectId);
    } else {
      status = "add";
      current = [...current, projectId];
    }

    if (status === "add") {
      if (!bookmarkProjects.originalBookmarks.includes(projectId)) {
        if (!added.includes(projectId)) {
          added = [...added, projectId];
        }
      }
      if (removed.includes(projectId)) {
        removed = removed.filter((id) => id !== projectId);
      }
    }
    if (status === "remove") {
      if (bookmarkProjects.originalBookmarks.includes(projectId)) {
        if (!removed.includes(projectId)) {
          removed = [...removed, projectId];
        }
      }
      if (added.includes(projectId)) {
        added = added.filter((id) => id !== projectId);
      }
    }

    setBookmarkProjects({
      ...bookmarkProjects,
      currentBookmarks: current,
      addedBookmarks: added,
      removedBookmarks: removed,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleOk = (selectedProjects) => {
    // if (selectedProjects.includes(projId)) {
    //   setBookmarkid([...bookmarkId, docId]);
    // }
    dispatch(
      createSavedSearchAction({
        body:
          //  {
          //   addProject_ids: bookmarkProjects.addedBookmarks,
          //   deleteProject_ids: bookmarkProjects.removedBookmarks,
          //   doc_id: docId,
          //   asset_class: type,
          //   comment,
          // },
          {
            addProject_ids: bookmarkProjects.addedBookmarks,
            deleteProject_ids: bookmarkProjects.removedBookmarks,
            terminal_name: terminalName,
            view: view,
            query: nonEmptyRequestBody,
            comment: comment,
            filters: filtersData,
            count: totalCount,
            query_url: queryUrl,
            hash: hashGenerator?.data?.hash,
          },
      })
    );
    onClose();
  };
  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="bookmark">
      <div className="bookmark-container">
        <div className="bookmark-title">Save Search</div>
        <div className="comment-section">
          <TextArea rows={3} onChange={(e) => setComment(e.target.value)} />
        </div>
        <Popover
          overlayClassName="create-proj-popover"
          placement="bottomRight"
          open={openCreateProject}
          content={renderCreateProject()}
        >
          <div
            className="create-project"
            role="presentation"
            onClick={() => setOpenCreateProject(!openCreateProject)}
          >
            <div className="add-project" />
            <div className="project-name">Create New Project</div>
          </div>
        </Popover>
      </div>

      <Loader loading={projectsData.loading}>
        <div className="analytics-container-content-sidebar-link--child">
          {projectsData?.data
            ?.filter((project) => project.projectName !== "Shared Documents")
            .map((i) => (
              <div
                role="presentation"
                key={i.id}
                className="analytics-container-content-sidebar-link"
                onClick={() => handleCheckboxChange(i?.projectId)}
              >
                <div className="folder-icon" />
                <div className="project-content">
                  <div className="analytics-container-content-sidebar-link-title">
                    <div className="analytics-container-content-sidebar-link-title-main">
                      {i?.projectName}
                    </div>
                  </div>
                  <Checkbox
                    checked={bookmarkProjects.currentBookmarks.includes(
                      i?.projectId
                    )}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="analytics-container-content-sidebar-link-footer">
          <Button
            type="primary"
            className="cancel-btn"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="ok-btn"
            onClick={() => handleOk(bookmarkProjects.currentBookmarks)}
          >
            Ok
          </Button>
        </div>
      </Loader>
    </div>
  );
}

export default SavedSearches;
