import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAdminFiltersAction } from '../FilterAdmin/logics';

export default function AdmintextInput({ filter, reset }) {
  const dispatch = useDispatch();

  const [input, setInput] = useState('');

  const adminUserFilter = useSelector((state) => state.adminUserFilter.currentAdminFilter);
  const filtersUpdate = useSelector((state) => state.filtersUpdate.upate);

  useEffect(() => {
    setInput(adminUserFilter[filter.key]);
  }, [filtersUpdate]);

  useEffect(() => {
    if (reset) {
      setInput('');
    }
  }, [reset]);

  const handleInputUpdate = (e) => {
    setInput(e.target.value);
    dispatch(updateAdminFiltersAction({
      key: filter.key,
      options: [e.target.value],
    }));
    e.stopPropagation();
  };

  return (
    <div className="date-time-admin-input">
      <input className="input-padding-update" onChange={(e) => handleInputUpdate(e)} type="text" placeholder="Enter first, last name or email" value={input} />
    </div>
  );
}
