import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { merge } from 'webpack-merge';
import Header from '../../../common/components/Header';
import AnalyticsNavOptions from '../../../common/container/AnalyticsNavOptions';
import { debounceCall, modifyDataForGetResultsQuery, getCookie } from '../../../utils';
import { hashGeneratorAction } from '../../../common/container/SavedSearches/logic';
import AnalyticsContainer from '../../container/AnalyticsContainer';
import { getSearchResultsAction } from '../../../common/container/List/logic';
import { showBackButtonAction } from '../../container/Geographical/logic';
import Mixpanel from '../../../utils/mixpanel';

function CTAnalyticsPage() {
  const [searchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname + location.search;
  const searchResult = useSelector((state) => state.searchResult);
  const showBackButton = useSelector((state) => state.showBackButton);
  const query = searchParams.get('query');
  const workspaceQuery = searchParams.get('workspaceQuery');
  const workspaceSemanticQuery = searchParams.get('workspaceSemanticQuery');
  const workspaceFilters = searchParams.get('workspaceFilters');

  const wq = JSON.parse(workspaceQuery);
  const wf = JSON.parse(workspaceFilters);

  const sort = searchParams.get('sort');
  const semanticQuery = searchParams.get('semanticquery');
  const wsq = JSON.parse(workspaceSemanticQuery);
  const filters = searchParams.get('filters');
  const f = JSON.parse(filters);
  const filtersData = modifyDataForGetResultsQuery(JSON.parse(filters), false, 'ctfilters');
  const autoSuggestUrl = '/clinical-trials/v0/search/getFieldValues';
  const terminalName = 'clinical_trials';
  const q = JSON.parse(query);
  const requestData = modifyDataForGetResultsQuery(q);

  const currentFilter = semanticQuery !== null ? (JSON.parse(semanticQuery)[0]?.currentFilter[0]) : (null);
  const semanticQueryRequest = {
    body: {
      body: currentFilter,
    },
  };
  const out = merge(requestData, filtersData);
  const semanticOut = merge(semanticQueryRequest, filtersData);

  const nonEmptyRequestBody = semanticQuery ? semanticOut.body : out.body;
  let workspaceQ = {};
  if (wq || wf) {
    workspaceQ = merge(wq || {}, wf || {}, filtersData || {});
  }

  const projId = getCookie('projectId');

  const params = {
    headers: {
      terminal_name: terminalName,
      project_id: projId,
    },
    body: { filters: out.body },
  };

  if (JSON.stringify(workspaceQ) !== '{}') {
    params.body.filters = { ...workspaceQ.body };
  }

  if (wsq && wf) {
    params.body.filters = { ...wf.body };
  }

  if (sort) {
    params.headers.sort = sort;
  }

  let view = '';

  if (!location.search.includes('semantic_search')) {
    view = 'Power Search';
  } else {
    view = 'Semantic Search';
  }

  useEffect(() => {
    if (searchResult?.flag) {
      setTotalCount(searchResult?.data?.total);
    }
  }, [JSON.stringify(searchResult)]);

  useEffect(() => {
    Mixpanel.track('page_entry_event', {
      action: 'User has entered Clinck Analytics page',
      terminal_name: 'clinical_trials',
      page_name: 'Analytics Page',
    });
  }, []);

  if (semanticQuery) {
    const nq = JSON.parse(semanticQuery)[0].currentFilter[0];
    params.headers.natural_query = nq;
  }

  if (workspaceSemanticQuery) {
    const nq = JSON.parse(workspaceSemanticQuery).body.body;
    params.headers.natural_query = nq;
  }

  const hashApiCall = () => {
    dispatch(hashGeneratorAction(
      {
        body: {
          query: nonEmptyRequestBody,
          project_id: projId,
        },
      },
    ));
  };

  useEffect(() => {
    if (getCookie('projectId')) {
      hashApiCall();
    }
  }, [getCookie('projectId')]);

  const apiCall = () => {
    const request = {
      apiUrl: '/clinical-trials/v0/search/getSearchResults',
      ...params,
      headers: queryString.stringify({
        ...params.headers,
        page: 1,
        size: 1,
        project_id: projId,
      }),
    };
    debounceCall(() => dispatch(getSearchResultsAction(request)), 50);
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className="main-page">
      <Header className="results-header-light" isResultsPage type="light" reqBody={requestData} filtersData={filtersData} terminalName={terminalName} view={view} totalCount={totalCount} semanticQueryRequest={semanticQueryRequest} url={url} q={q} f={f} hashApiCall={hashApiCall} />
      <div className="ct-results-page">
        <div className="card-view-nav">
          <div className="ct-results-total-count-header">
            {(showBackButton.isExpanded)
              ? (
                <div
                  className="back-btn-container"
                  role="presentation"
                  onClick={() => {
                    dispatch(showBackButtonAction(false));
                  }}
                >
                  <span className="back-arrow" />
                  <span>Back</span>
                </div>
              )
              : null}
            <div className="cl-results-info-text">{`${searchResult?.data?.total ? searchResult?.data?.total : 0} Results`}</div>
          </div>
          <AnalyticsNavOptions autoSuggestUrl={autoSuggestUrl} />
        </div>
        <div className="analytics-container">
          <AnalyticsContainer params={params} />
        </div>
      </div>
    </div>
  );
}

export default CTAnalyticsPage;
