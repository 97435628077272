import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { getRefreshTokenAction, removeSession } from './logic';
import {
  allowedPaths, getCookie, setCookie, windowHrefToNavigate,
} from '../../../utils';
import { sendNotification } from '../../components/Notification/logic';
import Mixpanel from '../../../utils/mixpanel';
import { openLoginModalAction } from '../../../app/container/LoginModalV2/logic';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function Status() {
  const dispatch = useDispatch();
  const location = useLocation();

  const payload = useSelector((state) => state.notification);
  const { type, status, message } = payload;
  const getAccessTokenFromRefreshToken = useSelector((state) => state.getAccessTokenFromRefreshToken);

  const allowedPathEmail = ['/', '/contact', '/userPolicy', '/privacyPolicy'];

  const isTimestampLessThanToday = (timestampString) => {
    const inputTimestamp = moment(Number(timestampString));

    const startOfToday = moment().startOf('day');
    return inputTimestamp.isBefore(startOfToday);
  };

  useEffect(() => {
    if (location.search.includes('alreadyAuthFailed=true')) {
      if (getCookie('refreshToken') && !isTimestampLessThanToday(getCookie('counterExp'))) {
        setCookie(`${location.pathname}${location.search}`, 'loggedUrl');
        debounceCall(() => {
          dispatch(getRefreshTokenAction({
            headers: queryString.stringify({
              refresh_token: getCookie('refreshToken'),
            }),
          }));
        }, 1000);
      } else {
        dispatch(removeSession());
        windowHrefToNavigate('/');
      }
    }

    if (location.search.includes('alreadyLoggedOut=true') && !location.search.includes('alreadyAuthFailed=true')) {
      dispatch(removeSession());
      windowHrefToNavigate('/');
    }

    if (location.search.includes('AppLoggedIn=influence')) {
      setCookie(true, 'influenceLoggedIn');
    }
  }, [location]);

  const logout = () => {
    dispatch(sendNotification({
      type: 'LOGIN_AUTHORIZED_FAILURE',
      notificationType: 'error',
      message: 'user unauthorized',
      showNotification: true,
    }));
    setTimeout(() => {
      dispatch(removeSession());
      windowHrefToNavigate('/');
    }, 3000);
  };

  useEffect(() => {
    if (getAccessTokenFromRefreshToken.flag) {
      setCookie(getAccessTokenFromRefreshToken.data.response.refresh_token, 'refreshToken');
      windowHrefToNavigate(`/callback?access_token=${getAccessTokenFromRefreshToken.data.response.access_token}`);
      Mixpanel.track('success_refresh_token_event', {
        refresh_token: getCookie('refreshToken'),
        time: moment().format('HH:mm:ss'),
      });
    } else if (getAccessTokenFromRefreshToken.error) {
      logout();
      Mixpanel.track('failed_refresh_token_event', {
        refresh_token: getCookie('refreshToken'),
        time: moment().format('HH:mm:ss'),
      });
    }
  }, [JSON.stringify(getAccessTokenFromRefreshToken)]);

  const activityFlow = () => {
    if (type === undefined) {
      return null;
    }
    if (type.includes('_FAILURE')) {
      if (status === 401 && message === 'jwt expired' && type !== 'LOGIN_FAILURE' && getCookie('accessToken')) {
        if (getCookie('refreshToken') && getCookie('expiresIN') && !isTimestampLessThanToday(getCookie('expiresIN')) && getCookie('counterExp') && !isTimestampLessThanToday(getCookie('counterExp'))) {
          setCookie(`${location.pathname}${location.search}`, 'loggedUrl');
          debounceCall(() => {
            Mixpanel.track('api_call_refresh_token', {
              refresh_token: getCookie('refreshToken'),
              time: moment().format('HH:mm:ss'),
            });
            dispatch(getRefreshTokenAction({
              headers: queryString.stringify({
                refresh_token: getCookie('refreshToken'),
              }),
            }));
          }, 1000);
        } else {
          logout();
        }
      } else if (status === 401 && message === 'User deactivated' && getCookie('accessToken')) {
        if (getCookie('refreshToken') && getCookie('expiresIN') && !isTimestampLessThanToday(getCookie('expiresIN')) && getCookie('counterExp') && !isTimestampLessThanToday(getCookie('counterExp'))) {
          debounceCall(() => {
            dispatch(sendNotification({
              type: 'PERMISSION_UPDATE_SUCCUSE',
              notificationType: 'success',
              message: 'Your Licence period has been updated',
              showNotification: true,
            }));
          }, 50);

          setCookie(`${location.pathname}${location.search}`, 'loggedUrl');
          debounceCall(() => {
            dispatch(getRefreshTokenAction({
              headers: queryString.stringify({
                refresh_token: getCookie('refreshToken'),
              }),
            }));
          }, 1000);
        } else {
          logout();
        }
      } else if (status === 401 && message === 'User permissions updated' && type !== 'LOGIN_FAILURE' && getCookie('accessToken')) {
        if (getCookie('refreshToken') && getCookie('expiresIN') && !isTimestampLessThanToday(getCookie('expiresIN')) && getCookie('counterExp') && !isTimestampLessThanToday(getCookie('counterExp'))) {
          debounceCall(() => {
            dispatch(sendNotification({
              type: 'PERMISSION_UPDATE_SUCCUSE',
              notificationType: 'success',
              message: 'Your permission has been update',
              showNotification: true,
            }));
          }, 50);

          setCookie(`${location.pathname}${location.search}`, 'loggedUrl');
          debounceCall(() => {
            dispatch(getRefreshTokenAction({
              headers: queryString.stringify({
                refresh_token: getCookie('refreshToken'),
              }),
            }));
          }, 1000);
        } else {
          logout();
        }
      } else if (status === 401 && getCookie('accessToken')) {
        Mixpanel.track('user_logout_401_event', {
          refresh_token: getCookie('refreshToken'),
          time: moment().format('HH:mm:ss'),
        });
        logout();
      }
    }
    return false;
  };

  useEffect(() => {
    if (
      window.location.pathname === '/'
      && getCookie('userEmail')
      && getCookie('personaBuild') !== 'false'
    ) {
      windowHrefToNavigate('/home');
    }

    if (
      !allowedPathEmail.includes(location.pathname)
      && !getCookie('userEmail')
    ) {
      windowHrefToNavigate('/');
    }

    if (
      !allowedPaths.includes(location.pathname)
      && !getCookie('accessToken')
    ) {
      debounceCall(() => dispatch(openLoginModalAction()), 200);
    }
  });

  useEffect(() => {
    if (type !== 'cancel') {
      activityFlow();
    }
  }, [type]);
  return null;
}

export default Status;
