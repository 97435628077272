import { React } from 'react';
import queryString from 'query-string';
import Axios from 'axios';
import bookmarkExportOptionsData from './data';
import { sendNotification } from '../../components/Notification/logic';
import { getCookie } from '../../../utils';

function BookmarkExportOptions({
  bookmarkFilters, bookmarkFilterUsers, projectId, setOpenExportPopover, sortByOptions,
}) {
  function convertToLowerCaseWithUnderscores(filters) {
    return filters.map((filter) => filter.toLowerCase().replace(/ /g, '_'));
  }

  const handleExportClick = (sortOption) => {
    setOpenExportPopover(false);
    const csvFile = 'bookmark-list-records.csv';
    const jsonFile = 'bookmark-list-records.json';
    const amaFile = 'bookmark-list-records.docx';
    const params = {
      Accept: sortOption.value === 'json' ? 'application/json' : (sortOption.value === 'ama' ? 'application/msword' : 'text/csv'),
    };

    const body = {
      project_id: projectId,
      asset_class: convertToLowerCaseWithUnderscores(bookmarkFilters),
      sort: sortByOptions,
      export: true,
      file_type: sortOption.value,
      user_ids: bookmarkFilterUsers,
    };

    Axios({
      url: `${process.env.apiUrl}/common/v0/workspace/bookmark/getBookmark?${queryString.stringify(params)}`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
      data: body,
    })
      .then((response) => {
        let url;
        let filename;

        if (sortOption.value === 'csv') {
          url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
          filename = csvFile;
        } else if (sortOption.value === 'json') {
          url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/json' }));
          filename = jsonFile;
        } else if (sortOption.value === 'ama') {
          url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/msword' }));
          filename = amaFile;
        }

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return null;
      })
      .catch((e) => {
        console.error('Error during export:', e);
        sendNotification('Error exporting bookmarks', 'error');
        return e;
      });
  };

  return (
    <div className="bookmark-export-container">
      <div className="bookmark-export-container-body">
        {bookmarkExportOptionsData.map((i) => (
          <div className="bookmark-export-container-body-item" role="presentation" onClick={() => handleExportClick(i)}>
            <div className="bookmark-export-container-body-item-title">
              {i.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BookmarkExportOptions;
