import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const ADD_ROLES = 'ADD_ROLES';
const ADD_ROLES_SUCCESS = 'ADD_ROLES_SUCCESS';
const ADD_ROLES_FAILURE = 'ADD_ROLES_FAILURE';
const ADD_ROLES_REFRESH = 'ADD_ROLES_REFRESH';

export const addRolesAction = createAction(ADD_ROLES);

const addRolesSuccess = createAction(ADD_ROLES_SUCCESS);

const addRolesFaliure = createAction(ADD_ROLES_FAILURE);

export const addRolesRefreshAction = createAction(ADD_ROLES_REFRESH);

export const addRolesEpic = (actions$) => actions$.pipe(
  ofType(ADD_ROLES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/auth/assignRoles`, 'POST', action.payload.body)).pipe(
    map((res) => addRolesSuccess(res)),
    catchError((err) => of(addRolesFaliure(err))),
  )),
);

const onaddRolesAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onaddRolesSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onaddRolesFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onaddRolesRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

const addRolesReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(addRolesAction, (state) => onaddRolesAction(state))
      .addCase(addRolesSuccess, (state, action) => onaddRolesSuccess(state, action.payload))
      .addCase(addRolesFaliure, (state) => onaddRolesFailure(state))
      .addCase(addRolesRefreshAction, () => onaddRolesRefreshAction())
      .addDefaultCase((state) => state);
  },
);

const REMOVE_ROLES = 'REMOVE_ROLES';
const REMOVE_ROLES_SUCCESS = 'REMOVE_ROLES_SUCCESS';
const REMOVE_ROLES_FAILURE = 'REMOVE_ROLES_FAILURE';
const REMOVE_ROLES_REFRESH = 'REMOVE_ROLES_REFRESH';

export const removeRolesAction = createAction(REMOVE_ROLES);

const removeRolesSuccess = createAction(REMOVE_ROLES_SUCCESS);

const removeRolesFaliure = createAction(REMOVE_ROLES_FAILURE);

export const removeRolesRefreshAction = createAction(REMOVE_ROLES_REFRESH);

export const removeRolesEpic = (actions$) => actions$.pipe(
  ofType(REMOVE_ROLES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/auth/removeRoles`, 'POST', action.payload.body)).pipe(
    map((res) => removeRolesSuccess(res)),
    catchError((err) => of(removeRolesFaliure(err))),
  )),
);

const onremoveRolesAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onremoveRolesSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onremoveRolesFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onremoveRolesRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const removeRolesReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(removeRolesAction, (state) => onremoveRolesAction(state))
      .addCase(removeRolesSuccess, (state, action) => onremoveRolesSuccess(state, action.payload))
      .addCase(removeRolesFaliure, (state) => onremoveRolesFailure(state))
      .addCase(removeRolesRefreshAction, () => onremoveRolesRefreshAction())
      .addDefaultCase((state) => state);
  },
);

export default addRolesReducer;
