import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { merge } from 'webpack-merge';
import Header from '../../../common/components/Header';
import SearchNavOptions from '../../../clinicalTrials/container/SearchNavOptions';
import CardDetail from '../../../clinicalTrials/container/CardDetail';

import {
  getExploreSearchResultsAction,
  getMoreExploreSearchResultsAction,
  getExploreSearchSummaryAction,
  getExploreSearchResultsRefreshAction,
} from '../../container/ExploreList/logic';
import { modifyDataForGetResultsQuery, getCookie } from '../../../utils';
import { hashGeneratorAction } from '../../../common/container/SavedSearches/logic';
import ExploreList from '../../container/ExploreList';
import ExploreSummaryCard from '../../components/ExploreSummaryCard';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import Loader from '../../../common/components/Loader';
import Mixpanel from '../../../utils/mixpanel';

function SEResultPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const getExploreSearchResults = useSelector((state) => state.getExploreSearchResults);
  const getExploreSearch = useSelector((state) => state.getExploreSearchSummary);
  const [previewCardData, setPreviewCardData] = useState({});
  const query = searchParams.get('query');
  const from = searchParams.get('from');
  const terminalName = 'Search & Explore';
  const q = JSON.parse(query);

  const workspaceQuery = searchParams.get('workspaceQuery');
  const workspaceFilters = searchParams.get('workspaceFilters');
  const wq = JSON.parse(workspaceQuery);
  const wf = JSON.parse(workspaceFilters);
  const semanticQuery = searchParams.get('semanticquery');
  const deepdivequery = searchParams.get('deepdivequery');
  const apiUrl = '/explore/v0/search/getSearchResults';
  const autoSuggestUrl = '/explore/v0/search/getFieldValues';
  const sort = searchParams.get('sort');
  const requestData = modifyDataForGetResultsQuery(q, true);
  const filters = searchParams.get('filters');
  const f = JSON.parse(filters);
  const qUrl = location.pathname + location.search;
  const projId = getCookie('projectId');
  const deepdivepage = searchParams.get('deepdivepage');
  const filtersData = modifyDataForGetResultsQuery(JSON.parse(filters), true);
  const deepdiveQueryData = modifyDataForGetResultsQuery(JSON.parse(deepdivequery), true);
  const currentFilter = semanticQuery !== null ? (JSON.parse(semanticQuery)[0]?.currentFilter[0]) : (null);
  const semanticQueryRequest = {
    body: {
      body: currentFilter,
    },
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResultData, setSearchResultData] = useState([]);
  const [totalResultCount, setTotalResultCount] = useState('');
  const [activeList, setActiveList] = useState({});
  const [activeProject, setActiveProject] = useState(projId);
  // eslint-disable-next-line no-unused-vars
  const [drawerWidth, setDrawerWidth] = useState(0);

  const out = merge(requestData, filtersData, deepdiveQueryData);
  const semanticOut = merge(semanticQueryRequest, filtersData);
  const nonEmptyRequestBody = semanticQuery ? semanticOut.body : out.body;

  let view = '';

  useEffect(() => {
    const interval = setInterval(() => {
      const newProjectId = getCookie('projectId');
      if (newProjectId !== activeProject) {
        setActiveProject(newProjectId);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [getCookie('projectId')]);

  let workspaceQ = {};
  if (wq || wf) {
    workspaceQ = merge(wq || {}, wf || {}, filtersData || {});
  }

  if (!location.search.includes('semantic_search')) {
    view = 'Power Search';
  } else {
    view = 'Semantic Search';
  }

  useEffect(() => {
    if (!deepdivepage) {
      if (semanticQuery?.length) {
        const params = {
          apiUrl,
          headers: queryString.stringify({
            project_id: projId,
            from: currentPage,
            size: 10,
            semantic_search: JSON.parse(semanticQuery)[0]?.currentFilter,
          }),
          body: { filters: query ? out.body : (semanticQuery?.length && out?.body) ? out.body : {} },
        };
        if (sort && sort !== 'null' && sort !== '{}') {
          params.body = { ...params.body, sort: [JSON.parse(sort)] };
        }
        dispatch(getExploreSearchResultsAction(params));
      } else {
        const params = {
          apiUrl,
          headers: queryString.stringify({
            from: currentPage,
            size: 10,
            project_id: projId,

          }),
          body: { filters: out.body },

        };
        if (JSON.stringify(workspaceQ) !== '{}') {
          params.body.filters = { ...workspaceQ.body };
        }
        if (sort && sort !== 'null' && sort !== '{}') {
          params.body = { ...params.body, sort: [JSON.parse(sort)] };
        }
        dispatch(getExploreSearchResultsAction(params));
      }
    }

    return () => {
      dispatch(getExploreSearchResultsRefreshAction({}));
      setTotalResultCount('');
      setActiveList({});
    };
  }, []);

  useEffect(() => {
    if (!deepdivepage) {
      if (semanticQuery?.length) {
        const params = {
          apiUrl,
          headers: queryString.stringify({
            project_id: projId,
            from: currentPage,
            size: 10,
            semantic_search: JSON.parse(semanticQuery)[0]?.currentFilter,
          }),
          body: { filters: query ? out.body : (semanticQuery?.length && out?.body) ? out.body : {} },
        };
        if (sort && sort !== 'null' && sort !== '{}') {
          params.body = { ...params.body, sort: [JSON.parse(sort)] };
        }
        dispatch(getExploreSearchResultsAction(params));
      } else {
        const params = {
          apiUrl,
          headers: queryString.stringify({
            from: currentPage,
            size: 10,
            project_id: projId,

          }),
          body: { filters: out.body },

        };
        if (JSON.stringify(workspaceQ) !== '{}') {
          params.body.filters = { ...workspaceQ.body };
        }
        if (sort && sort !== 'null' && sort !== '{}') {
          params.body = { ...params.body, sort: [JSON.parse(sort)] };
        }
        dispatch(getExploreSearchResultsAction(params));
      }
    }

    return () => {
      dispatch(getExploreSearchResultsRefreshAction({}));
      setTotalResultCount('');
      setActiveList({});
    };
  }, [activeProject]);

  useEffect(() => {
    Mixpanel.track('page_entry_event', {
      action: 'User has entered Search & Explore List View page',
      terminal_name: 'Search and Explore',
      page_name: 'List View Page',
    });
  }, []);

  useEffect(() => {
    if (getExploreSearchResults?.flag) {
      setTotalResultCount(getExploreSearchResults.data.total);
      setSearchResultData(getExploreSearchResults.data.data);
      if (activeList && !Object.keys(activeList)?.length) {
        setActiveList(getExploreSearchResults?.data?.data[0]);
      }
    }
  }, [JSON.stringify(getExploreSearchResults)]);

  useEffect(() => {
    if (getExploreSearch?.flag) {
      setPreviewCardData(getExploreSearch?.data?.data);
      Mixpanel.track('preview_record_event', {
        action: 'Preview record',
        page_name: from ? `Document Listing Page-${from}` : 'Document Listing Page - List View',
        terminal_name: 'Search and Explore',
        id: activeList?.publication_id || activeList?.congress_id || activeList?.patent_id || activeList?.press_id || activeList?.guideline_id || activeList?.grant_id || undefined,
        asset_class: activeList?.type || undefined,
      });
    }
  }, [JSON.stringify(getExploreSearch)]);

  useEffect(() => {
    if (activeList && Object?.keys(activeList).length) {
      const url = `/explore/v0/search/${activeList.type}/resource/${activeList.doc_id}`;
      dispatch(getExploreSearchSummaryAction({
        params: {
          url,
          headers: queryString.stringify({
            asset_class: activeList.type,
            view: 'preView',
            sub_asset_class: activeList?.type === 'regulatory_data' ? activeList?.sub_type : undefined,
          }),
        },
      }));
    }
  }, [activeList]);

  const hashApiCall = () => {
    dispatch(hashGeneratorAction(
      {
        body: {
          query: nonEmptyRequestBody,
          project_id: projId,
        },
      },
    ));
  };

  useEffect(() => {
    hashApiCall();
  }, []);
  const loadMoreListData = () => {
    if (semanticQuery?.length) {
      const params = {
        apiUrl,
        headers: queryString.stringify({
          from: currentPage + 1,
          size: 10,
          semantic_search: JSON.parse(semanticQuery)[0]?.currentFilter,
        }),
        body: {
          filters: query ? out.body : (semanticQuery?.length && out?.body) ? out.body : {},
          sort: (sort && sort !== 'null') ? [JSON.parse(sort)] : undefined,
        },
      };
      dispatch(getMoreExploreSearchResultsAction(params));
    } else {
      const params = {
        apiUrl,
        headers: queryString.stringify({
          from: currentPage + 1,
          size: 10,
        }),
        body: {
          filters: out.body,
          sort: (sort && sort !== 'null') ? [JSON.parse(sort)] : undefined,
        },
      };
      dispatch(getMoreExploreSearchResultsAction(params));
    }
    setCurrentPage(currentPage + 1);
  };

  const renderSummaryCard = () => (
    <ExploreSummaryCard activeList={previewCardData} from={from} />
  );

  return (
    <div className="main-page">
      <Header className="results-header-light" isResultsPage type="light" reqBody={requestData} filtersData={filtersData} terminalName={terminalName} view={view} totalCount={totalResultCount} semanticQueryRequest={semanticQueryRequest} url={qUrl} q={q} f={f} hashApiCall={hashApiCall} />
      <div className="se-results-page explore-results">
        <div className="explore-results-nav">
          {(totalResultCount && !getExploreSearchResults.loading) ? (
            <div className="explore-page-header-container">
              {(location.search).includes('deepdivequery')
                ? (
                  <div
                    className="back-btn-container"
                    role="presentation"
                    onClick={() => {
                      Mixpanel.track('page_exit_event', {
                        action: 'page leave event',
                        page: 'Search Page',
                        terminal_name: 'Search and Explore',
                      });
                      navigate(`/searchAndExplore/analytics${location.search}`);
                      navigate(0);
                    }}
                  >
                    <span className="back-arrow" />
                    <span>Back</span>
                  </div>
                )
                : null}
              <div className="explore-results-text">
                {`${totalResultCount} Results found`}
              </div>
            </div>
          ) : <div className="explore-results-text" />}
          <SearchNavOptions isExplorePage terminalType="explore" autoSuggestUrl={autoSuggestUrl} />
        </div>
        <div className="search-and-explore-results-container">
          <div className="search-and-explore-results-container-list">
            <Loader
              loading={!deepdivepage && getExploreSearchResults?.loading}
              error={!deepdivepage && getExploreSearchResults?.error}
              noData={!deepdivepage && !searchResultData?.length}
            >
              <LazyLoadDiv
                className="explore-list-results"
                id="document-list"
                total={getExploreSearchResults?.data?.total}
                currentTotal={(searchResultData || []).length}
                height="calc(100vh - 200px)"
                loadMoreRows={loadMoreListData}
              >
                {searchResultData && searchResultData?.map((list) => (<ExploreList key={list?.doc_id} data={list} activeList={activeList} setActiveList={setActiveList} from={from} />))}
              </LazyLoadDiv>
            </Loader>
          </div>
          <div className="search-and-explore-results-container-details-container">
            <Loader
              loading={!deepdivepage && (getExploreSearchResults?.loading || getExploreSearch?.loading)}
              error={!deepdivepage && (getExploreSearchResults.error || getExploreSearch?.error)}
              noData={!deepdivepage && !searchResultData?.length}
            >
              {previewCardData?.type === 'clinical_trials'
                ? <CardDetail data={previewCardData} setDrawerWidth={setDrawerWidth} from={from} />
                : renderSummaryCard()}
            </Loader>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SEResultPage;
