import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import SvgLoader from '../../../common/components/SvgLoader';
import { debounceCall, trackOutSideEvents, getTerminalName } from '../../../utils';
import {
  getEndpointsGraphDataAction, getEndpointsGraphDataRefreshAction, getEndpointsStatusDataAction, getEndpointsStatusDataRefreshAction,
} from './logics';
import Loader from '../../../common/components/Loader';
import Mixpanel from '../../../utils/mixpanel';

function EndpointsGraph({
  selected, currentExpanded, setExpanded, params,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');
  const navigate = useNavigate();

  const endpointsData = useSelector((state) => state.endpointsData);
  const endpointStatusData = useSelector((state) => state.endpointStatusData);
  const searchResult = useSelector((state) => state.searchResult);
  const [isExpanded, setIsExpanded] = useState(false);
  const [endpointStatusView, showEndpointStatusView] = useState(false);
  const [maxCircleRadius, setMaxCircleRadius] = useState(200);
  const [maxRadius, setMaxRadius] = useState(isExpanded ? 130 : 70);
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentTooltipId, setCurrentTooltipId] = useState(null);
  const [currentEndpointNameClicked, setCurrentEndpointNameClicked] = useState('');
  const [tooltipData, setTooltipData] = useState({
    enpointName: '',
    primary: 0,
    secondary: 0,
    other: 0,
  });
  const [showBigCircle, setShowBigCircle] = useState(true);

  const statusCircle = useRef(null);
  const toolTipRef = useRef(null);

  const baseWidth = window.innerWidth - 150;
  const minRadius = 20;
  const maxTried = 800;
  const maxFontSize = 25;

  const values = endpointsData?.data.map((element) => element.primary_percentage);

  values.sort((a, b) => b - a);

  const colorScale = d3.scaleLinear()
    .domain(d3.extent(values))
    .interpolate(d3.interpolateHcl)
    .range(['#DCDCFE', '#5252FA']);

  const getRadius = d3.scaleLinear()
    .domain([0, d3.max(endpointsData?.data?.map((d) => d.trials_count))])
    .range([minRadius, maxRadius]);

  const intersects = (first, second) => {
    const dx = first.x - second.x;
    const dy = first.y - second.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const sumOfRadii = first.radius + second.radius;
    return distance < sumOfRadii;
  };

  const renderClassName = (value) => {
    const valArr = value.split(' ');
    let temp = '';
    valArr.forEach((item) => {
      if (item !== '/') {
        temp += item;
      }
    });
    return temp;
  };

  const handleCircleClick = (endpointName) => {
    if (currentExpanded === '' || currentExpanded === selected.label) {
      const request = {
        ...params,
        headers: queryString.stringify({
          ...params.headers,
          field_name: 'endpoints',
          endpoint_name: endpointName,
        }),
      };
      if (isExpanded) {
        dispatch(getEndpointsStatusDataRefreshAction());
        dispatch(getEndpointsStatusDataAction(request));
      }
      showEndpointStatusView(!!isExpanded);
    }
  };

  const handleClick = (type, endpointNamve) => {
    const endpointQuery = {
      filters: {},
      queryValue: type === 'status' ? currentEndpointNameClicked : tooltipData.enpointName,
    };

    if (type === 'all') {
      endpointQuery.filters = {
        endpoints: [{
          value: tooltipData.enpointName,
          operator: 'OR',
        }],
      };
    }

    if (type === 'primary') {
      endpointQuery.filters = {
        primary_endpoints: [{
          value: tooltipData.enpointName,
          operator: 'OR',
        }],
      };
      Mixpanel.track('widget_deepdive_event', {
        action: 'Endpoint Widget Tooltip arrow click for List View Search',
        page: 'Analytics Page',
        widget_name: 'Endpoint Analytics',
        terminal_name: 'Clinical Trials',
        search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
        query: JSON.parse(semanticQuery || query),
        endpoint_name: tooltipData.enpointName,
      });
      Mixpanel.track('page_entry_event', {
        action: 'Endpoint Widget Tooltip arrow click for List View Search',
        terminal_name: 'Clinical Trials',
        page_name: 'Document Listing Page - Endpoint Analytics',
      });
    }

    if (type === 'secondary') {
      endpointQuery.filters = {
        secondary_endpoints: [{
          value: tooltipData.enpointName,
          operator: 'OR',
        }],
      };
      Mixpanel.track('widget_deepdive_event', {
        action: 'Endpoint Widget Tooltip arrow click for List View Search',
        page: 'Analytics Page',
        widget_name: 'Endpoint Analytics',
        terminal_name: 'Clinical Trials',
        search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
        query: JSON.parse(semanticQuery || query),
        endpoint_name: tooltipData.enpointName,
      });
      Mixpanel.track('page_entry_event', {
        action: 'Endpoint Widget Tooltip arrow click for List View Search',
        terminal_name: 'Clinical Trials',
        page_name: 'Document Listing Page - Endpoint Analytics',
      });
    }

    if (type === 'other') {
      endpointQuery.filters = {
        other_endpoints: [{
          value: tooltipData.enpointName,
          operator: 'OR',
        }],
      };
      Mixpanel.track('widget_deepdive_event', {
        action: 'Endpoint Widget Tooltip arrow click for List View search',
        page: 'Analytics Page',
        widget_name: 'Endpoint Analytics',
        terminal_name: 'Clinical Trials',
        search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
        query: JSON.parse(semanticQuery || query),
        endpoint_name: tooltipData.enpointName,
      });
      Mixpanel.track('page_entry_event', {
        action: 'Endpoint Widget Tooltip arrow click for List View Search',
        terminal_name: 'Clinical Trials',
        page_name: 'Document Listing Page - Endpoint Analytics',
      });
    }

    if (type === 'status') {
      endpointQuery.filters = {
        status: [{
          value: endpointNamve,
          operator: 'OR',
        }],
        endpoints: [{
          value: currentEndpointNameClicked,
          operator: 'OR',
        }],
      };
    }

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
    });

    if (semanticQuery) {
      navigate(`/clinicalTrials/searchResults?semanticquery=${semanticQuery}&endpointQuery=${JSON.stringify(endpointQuery)}${'&from=\'Endpoint Analytics\''}`);
      navigate(0);
    } else {
      navigate(`/clinicalTrials/searchResults?query=${query}&endpointQuery=${JSON.stringify(endpointQuery)}${'&from=\'Endpoint Analytics\''}`);
      navigate(0);
    }
  };

  const drawStatus = (dataSet) => {
    const dimensions = {
      width: 600,
      height: 600,
    };

    const minCircleRadisu = 20;

    const colorsStatus = d3.quantize(d3.interpolateRgb(d3.rgb(82, 82, 250), d3.rgb(220, 220, 254)), dataSet?.data.length);

    const valuesStatus = dataSet?.data.map((element) => element.primaryPercentage);

    valuesStatus.sort((a, b) => b - a);

    const colorScaleStatus = d3.scaleOrdinal()
      .domain(valuesStatus)
      .range(colorsStatus);

    const getStatusRadius = d3.scaleLinear()
      .domain([0, d3.max(dataSet?.data?.map((d) => d.counts))])
      .range([minCircleRadisu, maxCircleRadius]);

    const svg = d3.select('#status')
      .append('svg')
      .attr('id', 'status-graph')
      .attr('width', dimensions.width)
      .attr('height', dimensions.height);

    const ctr = svg.append('g');

    ctr.append('circle')
      .attr('cx', 300)
      .attr('cy', 300)
      .attr('r', 300)
      .attr('opacity', 1)
      .attr('stroke', '#5252FA')
      .attr('stroke-width', 3)
      .attr('fill', '#E9E9FF')
      .on('mouseleave', () => setShowBigCircle(true));

    const dots = [];

    const checkCirclIntersect = (circle) => {
      const dx = 300 - circle.x;
      const dy = 300 - circle.y;
      const distanceBetweenTwoCircle = Math.sqrt(dx * dx + dy * dy);
      if (distanceBetweenTwoCircle + circle.radius <= 300) {
        return true;
      }
      return false;
    };

    const createDots = (d) => {
      const x = Math.floor(Math.random() * dimensions.width);
      const y = Math.floor(Math.random() * dimensions.height);

      const radius = getStatusRadius(d.counts);
      const dot = {
        radius, x, y, ...d,
      };

      let flag = false;
      dots.forEach((item) => {
        if (intersects(dot, item)) {
          flag = true;
        }
      });

      if (!flag && checkCirclIntersect(dot)) {
        return dot;
      }
      return null;
    };

    let i = 0;
    let tried = 0;
    let checkOutOfBound = false;
    while (i < dataSet.data.length) {
      const dot = createDots(dataSet.data[i]);
      if (dot) {
        dots.push(dot);
        i += 1;
      }
      tried += 1;
      if (tried > maxTried) {
        checkOutOfBound = true;
        setMaxCircleRadius((current) => current - 10);
        i += 20;
      }
    }

    if (checkOutOfBound) {
      return false;
    }

    const statusCircles = ctr.append('g');
    const dotsLength = dots.length;
    statusCircles.selectAll('circle')
      .data(dots)
      .join('circle')
      .attr('r', (d) => d.radius)
      .attr('fill', (d) => (dotsLength === 1 ? '#5252FA' : colorScaleStatus(d.primaryPercentage)))
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y);

    statusCircles.selectAll('text')
      .data(dots)
      .join('text')
      .attr('class', 'endpoints-label')
      .style('text-anchor', 'middle')
      .attr('fill', 'white')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y + 2)
      .style('font-size', (d) => {
        const len = d.name.substring(0, d.radius / 3).length;
        let size = d.radius / 3;
        size *= 10 / len;
        size -= 3;
        if (Math.round(size) > maxFontSize) {
          return `${maxFontSize}px`;
        }
        return `${Math.round(size)}px`;
      })
      .text((d) => {
        const text = d.name.substring(0, d.radius / 3);
        return text;
      });

    const labelCounts = ctr.append('g');

    labelCounts.selectAll('text')
      .data(dots)
      .join('text')
      .attr('class', 'endpoints-label pointer')
      .attr('fill', 'white')
      .style('text-anchor', 'middle')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y + d.radius / 3 + 5)
      .style('font-size', (d) => {
        const size = d.radius / 3;
        return `${Math.round(size)}px`;
      })
      .text((d) => {
        const count = String(d.counts).substring(0, d.radius / 3);
        return count;
      })
      .on('click', (event, datum) => {
        handleClick('status', datum.name);
        Mixpanel.track('widget_deepdive_event', {
          action: 'endpoint status click for list view search',
          endpoint_data: {
            endpoint_name: currentEndpointNameClicked,
            status: datum.name,
          },
          page: 'Analytics Page',
          widget_name: 'Endpoint Analytics',
          terminal_name: 'Clinical Trials',
          search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
          query: JSON.parse(semanticQuery || query),
        });
      });

    return null;
  };

  const makeCircles = (dataset, width, height, ctr, extraDots, j, current) => {
    if (!dataset) {
      return null;
    }
    const tempDots = [];

    extraDots.forEach((val) => {
      if (val.phases[0].name === current) {
        val.x += width / 2;
      } else {
        val.x -= width;
      }
      tempDots.push(val);
    });

    const dots = [];

    const checkInsideRect = (dot) => {
      const checkWidth = dot.radius < dot.x && dot.x < width - dot.radius;
      const checkHight = dot.radius < dot.y && dot.y < height - dot.radius;
      return checkWidth && checkHight;
    };

    const createDots = (d) => {
      const x = Math.floor(Math.random() * width);
      const y = Math.floor(Math.random() * height);

      const radius = getRadius(d.trials_count);
      const dot = {
        radius, x, y, ...d,
      };

      let flag = false;
      tempDots.forEach((item) => {
        if (intersects(dot, item)) {
          flag = true;
        }
      });

      if (!flag && checkInsideRect(dot)) {
        return dot;
      }
      return null;
    };

    let i = 0;
    let tried = 0;
    let checkOutOfBound = false;
    while (i < dataset.length) {
      const dot = createDots(dataset[i]);
      if (dot) {
        dots.push(dot);
        tempDots.push(dot);
        i += 1;
      }
      tried += 1;
      if (tried > maxTried) {
        checkOutOfBound = true;
        setMaxRadius((currentMax) => currentMax - 10);
        i += 20;
      }
    }

    if (checkOutOfBound) {
      return false;
    }

    const circlePlot = ctr.append('g');

    circlePlot.selectAll('circle')
      .data(dots)
      .join('circle')
      .attr('r', (d) => d.radius)
      .attr('fill', (d) => colorScale(d.primary_percentage))
      .attr('class', `${isExpanded ? 'circle-pointer' : 'pointer'}`)
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .on('click', (event, datum) => {
        if (isExpanded) {
          setCurrentEndpointNameClicked(datum.endpoint_name);
          handleCircleClick(datum.endpoint_name);
          Mixpanel.track('endpointCircleClickEvent', {
            action: 'Deep dive of all status endpoint',
            page: 'Analytics Page',
            widget_name: 'Endpoint Analytics',
            terminal_name: 'Clinical Trials',
            search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
            query: JSON.parse(semanticQuery || query),
            endpoint_data: {
              endpoint_name: datum.endpoint_name,
              endpoint_count: datum.trials_count,
            },
          });
        }
      })
      .on('mouseenter', (event, datum) => {
        setShowTooltip(true);
        const temp = {
          primary: 0,
          secondary: 0,
          other: 0,
        };
        datum.endpoint_types.forEach((endpoint) => {
          if (endpoint.name === 'Primary') {
            temp.primary = endpoint.count;
          }

          if (endpoint.name === 'Secondary') {
            temp.secondary = endpoint.count;
          }

          if (endpoint.name === 'Other') {
            temp.other = endpoint.count;
          }
        });
        setTooltipData({
          ...{
            enpointName: datum.endpoint_name,
            ...temp,
          },
        });
        setCurrentTooltipId(`endpoint-${renderClassName(datum.endpoint_name)}`);
        showEndpointStatusView(false);
      });

    const labelCounts = ctr.append('g');

    circlePlot.selectAll('text')
      .data(dots)
      .join('text')
      .attr('class', `${isExpanded ? 'circle-pointer endpoints-label' : 'endpoints-label pointer'}`)
      .attr('fill', 'white')
      .style('text-anchor', 'middle')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y + 2)
      .style('font-size', (d) => {
        const len = d.endpoint_name.substring(0, d.radius / 3).length;
        let size = d.radius / 3;
        size *= 10 / len;
        size -= 2;
        if (Math.round(size) > maxFontSize) {
          return `${maxFontSize}px`;
        }
        return `${Math.round(size)}px`;
      })
      .text((d) => {
        const text = d.endpoint_name.substring(0, d.radius / 3);
        return text;
      });

    labelCounts.selectAll('text')
      .data(dots)
      .join('text')
      .attr('class', 'endpoints-label')
      .attr('fill', 'white')
      .attr('class', 'dot-counts pointer')
      .attr('id', (d) => `endpoint-${renderClassName(d.endpoint_name)}`)
      .style('text-anchor', 'middle')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y + d.radius / 3 + 5)
      .style('font-size', (d) => {
        const size = d.radius / 3;
        return `${Math.round(size)}px`;
      })
      .text((d) => {
        const count = String(d.trials_count).substring(0, d.radius / 3);
        return count;
      });

    return false;
  };

  const makePartitionCircles = (dataset, width, height, ctr, parallax) => {
    if (!dataset) {
      return [];
    }

    const dots = [];
    const checkInsideRect = (dot) => {
      const checkWidth = dot.radius < dot.x && dot.x < width - dot.radius;
      const checkHight = dot.radius < dot.y && dot.y < height - dot.radius;
      return checkWidth && checkHight;
    };

    const createDots = (d) => {
      const radius = getRadius(d.trials_count);
      const x = width / 2 + parallax + radius - (2 * radius * d.phases[0].score);
      const y = Math.floor(Math.random() * height);

      const dot = {
        radius, x, y, ...d,
      };

      let flag = false;
      dots.forEach((item) => {
        if (intersects(dot, item)) {
          flag = true;
        }
      });

      if (!flag && checkInsideRect(dot)) {
        return dot;
      }
      return null;
    };

    let i = 0;
    let tried = 0;
    let checkOutOfBound = false;
    while (i < dataset.length) {
      const dot = createDots(dataset[i]);
      if (dot) {
        dots.push(dot);
        i += 1;
      }
      tried += 1;
      if (tried > maxTried) {
        checkOutOfBound = true;
        setMaxRadius((currentMax) => currentMax - 10);
        i += 20;
      }
    }

    if (checkOutOfBound) {
      return [];
    }

    const circlePlot = ctr.append('g');

    circlePlot.selectAll('circle')
      .data(dots)
      .join('circle')
      .attr('r', (d) => d.radius)
      .attr('fill', (d) => colorScale(d.primary_percentage))
      .attr('class', (d, k) => `endpoint-${d.endpoint_name}_${k} pointer`)
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .on('click', (event, datum) => {
        if (isExpanded) {
          setCurrentEndpointNameClicked(datum.endpoint_name);
          handleCircleClick(datum.endpoint_name);
          Mixpanel.track('endpointCircleClickEvent', {
            action: 'endpoint circle click to view deep dive all status circle',
            page: 'Analytics Page',
            widget_name: 'Endpoint Analytics',
            terminal_name: 'Clinical Trials',
            search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
            query: JSON.parse(semanticQuery || query),
            endpoint_data: {
              endpoint_name: datum.endpoint_name,
              endpoint_count: datum.trials_count,
            },
          });
        }
      })
      .on('mouseenter', (event, datum) => {
        setShowTooltip(true);
        const temp = {
          primary: 0,
          secondary: 0,
          other: 0,
        };
        datum.endpoint_types.forEach((endpoint) => {
          if (endpoint.name === 'Primary') {
            temp.primary = endpoint.count;
          }

          if (endpoint.name === 'Secondary') {
            temp.secondary = endpoint.count;
          }

          if (endpoint.name === 'Other') {
            temp.other = endpoint.count;
          }
        });
        setTooltipData({
          ...{
            enpointName: datum.endpoint_name,
            ...temp,
          },
        });
        showEndpointStatusView(false);
        setCurrentTooltipId(`endpoint-${renderClassName(datum.endpoint_name)}`);
      });

    circlePlot.selectAll('text')
      .data(dots)
      .join('text')
      .attr('class', `${isExpanded ? 'circle-pointer endpoints-label' : 'endpoints-label pointer'}`)
      .style('text-anchor', 'middle')
      .attr('fill', 'white')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y + 2)
      .style('font-size', (d) => {
        const len = d.endpoint_name.substring(0, d.radius / 3).length;
        let size = d.radius / 3;
        size *= 10 / len;
        size -= 2;
        if (Math.round(size) > maxFontSize) {
          return `${maxFontSize}px`;
        }
        return `${Math.round(size)}px`;
      })
      .text((d) => {
        const text = d.endpoint_name.substring(0, d.radius / 3);
        return text;
      });

    const labelCounts = ctr.append('g');

    labelCounts.selectAll('text')
      .data(dots)
      .join('text')
      .attr('class', 'endpoints-label')
      .attr('fill', 'white')
      .attr('class', 'dot-counts pointer')
      .attr('id', (d) => `endpoint-${renderClassName(d.endpoint_name)}`)
      .style('text-anchor', 'middle')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y + d.radius / 3 + 5)
      .style('font-size', (d) => {
        const size = d.radius / 3;
        return `${Math.round(size)}px`;
      })
      .text((d) => {
        const count = String(d.trials_count).substring(0, d.radius / 3);
        return count;
      });
    return dots;
  };

  const draw = (dataset) => {
    const dimensions = {
      width: baseWidth,
      height: isExpanded ? 650 : 180,
      margin: {
        left: 10,
        top: 10,
        bottom: 10,
        right: 10,
      },
    };

    dimensions.ctrWidth = dimensions.width - dimensions.margin.left - dimensions.margin.right;
    dimensions.ctrHeight = dimensions.height - dimensions.margin.top - dimensions.margin.bottom;

    const svg = d3.select(`${isExpanded ? '#endpoints-expanded' : '#endpoints'}`).append('svg')
      .attr('id', `${isExpanded ? 'endpoints-graph' : 'endpoints-graph-expanded'}`)
      .attr('width', dimensions.width)
      .attr('height', dimensions.height);

    const ctr = svg.append('g')
      .attr('transform', `translate(${0}, ${0})`)
      .attr('width', dimensions.ctrWidth)
      .attr('height', dimensions.ctrHeight);

    const phaseWiseSegeratedMap = {};
    const phaseInbetweenMap = {};
    const alreadyBeenIncluded = [];

    dataset.forEach((item) => {
      item.phases.forEach((val, i) => {
        if (val.score === 1) {
          phaseWiseSegeratedMap[val.name] = phaseWiseSegeratedMap[val.name] ? [...phaseWiseSegeratedMap[val.name], item] : [item];
        } else if (val.score === 0.5 && i === 0) {
          phaseWiseSegeratedMap[val.name] = phaseWiseSegeratedMap[val.name] ? [...phaseWiseSegeratedMap[val.name], item] : [item];
        } else if (phaseInbetweenMap[val.name] && phaseInbetweenMap[val.name].length < 1 && i === 0) {
          phaseInbetweenMap[val.name] = [...phaseInbetweenMap[val.name], item];
          alreadyBeenIncluded.push(item.endpoint_name);
        } else if (!phaseInbetweenMap[val.name] && i === 0) {
          phaseInbetweenMap[val.name] = [item];
          alreadyBeenIncluded.push(item.endpoint_name);
        } else if (val.score > 0.5 && !alreadyBeenIncluded.includes(item.endpoint_name)) {
          phaseWiseSegeratedMap[val.name] = phaseWiseSegeratedMap[val.name] ? [...phaseWiseSegeratedMap[val.name], item] : [item];
        }
      });
    });

    let prevAllPartialDots = [];

    endpointsData.labelPhases.forEach((item, i) => {
      const translateX = (baseWidth / endpointsData.labelPhases.length) * i;
      const width = dimensions.ctrWidth / endpointsData.labelPhases.length;
      const height = dimensions.ctrHeight;

      let allPartialDots = [];

      const phaseCtr = ctr.append('g')
        .attr('transform', `translate(${translateX}, 0)`);

      if (i !== endpointsData.labelPhases.length - 1) {
        phaseCtr.append('line')
          .attr('stroke', '#BEBEBE')
          .attr('stroke-width', 1)
          .attr('stroke-dasharray', ('3, 3'))
          .attr('x1', width)
          .attr('y1', 0)
          .attr('x2', width)
          .attr('y2', height + 20);
      }

      if (i !== endpointsData.labelPhases.length - 1) {
        const parallaxError = 5 / (endpointsData.labelPhases.length - 1);
        const widthForPartion = dimensions.ctrWidth / endpointsData.labelPhases.length;
        const translateXPartion = translateX + width / 2 + parallaxError;
        const partialCtr = ctr.append('g')
          .attr('transform', `translate(${translateXPartion}, 0)`);

        allPartialDots = makePartitionCircles(phaseInbetweenMap[item], widthForPartion, height, partialCtr, parallaxError);
      }

      makeCircles(phaseWiseSegeratedMap[item], width, height, phaseCtr, [...allPartialDots, ...prevAllPartialDots], i, item);

      prevAllPartialDots = allPartialDots;
    });
  };

  const handleExpandClick = () => {
    dispatch(getEndpointsGraphDataRefreshAction());
    setIsExpanded(true);
    setExpanded(selected.label);
    window.scrollTo(0, 0);
    setMaxRadius(130);
    Mixpanel.track('expandWidgetEvent', {
      action: 'expand Endpoint widget',
      page: 'Analytics Page',
      widget_name: 'Endpoint Analytics',
      terminal_name: 'Clinical Trials',
      search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      query: JSON.parse(semanticQuery || query),
    });
    Mixpanel.track('switch_widget_view', {
      action: 'expanded view',
      widget_name: 'Endpoint Analytics',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
    });
  };

  const handleShrinkClick = () => {
    dispatch(getEndpointsGraphDataRefreshAction());
    setIsExpanded(false);
    setExpanded('');
    setMaxRadius(70);
    Mixpanel.track('collapseWidgetEvent', {
      action: 'collapse Endpoint widget',
      page: 'Analytics Page',
      widget_name: 'Endpoint Analytics',
      terminal_name: 'Clinical Trials',
      search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      query: JSON.parse(semanticQuery || query),
    });
    Mixpanel.track('switch_widget_view', {
      action: 'widget view',
      widget_name: 'Endpoint Analytics',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
    });
  };

  useEffect(() => {
    trackOutSideEvents(toolTipRef, () => setShowTooltip(false));
    trackOutSideEvents(statusCircle, () => showEndpointStatusView(false));
  }, []);

  useEffect(() => {
    if (endpointStatusData.flag && endpointStatusView) {
      d3.select('#status-graph').remove();
      drawStatus(endpointStatusData);
    }
  }, [endpointStatusView, maxCircleRadius, JSON.stringify(endpointStatusData)]);

  useEffect(() => {
    if ((currentExpanded === '' && searchResult.flag) || currentExpanded === selected.label) {
      const request = {
        ...params,
        apiUrl: '/clinical-trials/v0/search/getInsightsByPhase',
        headers: queryString.stringify({
          ...params.headers,
          field_name: 'endpoints',
          isExpanded,
        }),
      };
      debounceCall(() => dispatch(getEndpointsGraphDataAction(request)), 100);
    }
  }, [isExpanded, currentExpanded, JSON.stringify(searchResult)]);

  useEffect(() => {
    if (endpointsData?.flag) {
      d3.select('#endpoints-graph-expanded').remove();
      d3.select('#endpoints-graph').remove();
      const tempDataList = [];
      endpointsData.data.forEach((item) => {
        const tempObj = {
          ...item,
          phases: item.phases.map((ph) => ({ ...ph, score: Number(ph.score.toFixed(1)) })),
        };
        tempDataList.push(tempObj);
      });
      draw(tempDataList);
    }
  }, [isExpanded, currentExpanded, maxRadius, JSON.stringify(endpointsData)]);

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  let widthOfPhaselabel = null;

  if (endpointsData.flag) {
    widthOfPhaselabel = baseWidth / endpointsData.labelPhases.length;
  }

  const renderLabelPhases = () => endpointsData.labelPhases.map((item) => (
    <div className="phases-child" key={item} style={{ width: widthOfPhaselabel }}>{item}</div>
  ));

  const renderStatus = () => (
    <div className="status-view">
      <div className="status-container">
        <Loader
          error={endpointStatusData.error}
          loading={endpointStatusData.loading}
          noData={endpointStatusData.data?.length === 0 && endpointStatusData.flag}
          height="800px"
          className="white-loader"
          message="No records found"
        >
          <div id="status" style={{ left: showBigCircle ? '300px' : 0 }} />
          {showBigCircle ? <div onMouseEnter={() => setShowBigCircle(false)} className="big-circle" ref={statusCircle} /> : null}
        </Loader>
      </div>
    </div>
  );

  function renderTooltip() {
    Mixpanel.track('hover_popup_event', {
      action: 'user hovered on endpoint graph',
      label: tooltipData?.enpointName,
      terminal_name: getTerminalName(location),
      page_name: 'Clinical Trials Analytics page',
      widget_name: 'Endpoint Analytics',
      search_type: location.search.includes('semantic') ? 'Semantic Search' : 'Power Search',
      view_type: isExpanded ? 'Expanded_view' : 'Widget_view',
    });
    return (
      <div>
        <Tooltip className="tooltip-container-status" anchorSelect={`#${currentTooltipId}`} clickable isOpen={showTooltip} noArrow>
          <div ref={toolTipRef} className="endpoints-detail-container">
            <div className="blue-vertical-line adjust-min-height" />
            <div className="endpoints-detail-sub-container">
              <div className="endpoint-detail">
                <span className="endpoint-count adjust-endpoint-name-width">{tooltipData.enpointName}</span>
              </div>
              <div className="endpoint-detail">
                <span className="endpoint-type">Primary</span>
                <span className="endpoint-type add-margin">{tooltipData.primary}</span>
                <SvgLoader className="pointer adjust-arrow-pos" onClick={() => handleClick('primary')} width={20} height={14} svgName="right-arrow-tooltip" hoverIconName="right-arrow-tooltip-hover" />
              </div>
              <div className="endpoint-detail">
                <span className="endpoint-type">Secondary</span>
                <span className="endpoint-type add-margin">{tooltipData.secondary}</span>
                <SvgLoader className="pointer adjust-arrow-pos" onClick={() => handleClick('secondary')} width={20} height={14} svgName="right-arrow-tooltip" hoverIconName="right-arrow-tooltip-hover" />
              </div>
              <div className="endpoint-detail">
                <span className="endpoint-type">Other</span>
                <span className="endpoint-type add-margin">{tooltipData.other}</span>
                <SvgLoader className="pointer adjust-arrow-pos" onClick={() => handleClick('other')} width={20} height={14} svgName="right-arrow-tooltip" hoverIconName="right-arrow-tooltip-hover" />
              </div>
              <div className="endpoint-detail">
                <span className="endpoint-type">All</span>
                <span className="endpoint-type add-margin">{tooltipData.primary + tooltipData.secondary + tooltipData.other}</span>
                <SvgLoader className="pointer adjust-arrow-pos" onClick={() => handleClick('all')} width={20} height={14} svgName="right-arrow-tooltip" hoverIconName="right-arrow-tooltip-hover" />
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
    );
  }

  const renderExpandedView = () => (
    <div>
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text flex-row">
            <div>{selected.label}</div>
            <div className="partex-ai-icon ct-align-power-ai" />
          </div>
        </div>
        <div className="icons">
          {/* <SvgLoader width={18.7} height={18.7} svgName="info-icon" hoverIconName="info-icon-hover" /> */}
          <SvgLoader onClick={() => handleShrinkClick()} width={22} height={22} svgName="contract" hoverIconName="contract-hover" />
        </div>
      </div>
      <div id="endpoints-expanded" />
      <div className="phases-label" style={{ width: baseWidth }}>
        {endpointsData?.data?.length ? renderLabelPhases() : null}
      </div>
      {endpointsData.flag && (
        <div className="color-meter">
          <div className="color-meter-header">Type of Endpoint</div>
          <div className="type-of-endpoints" />
          <div className="color-scale-text">
            <div>Primary</div>
            <div>Secondary</div>
            <div>Other</div>
          </div>
        </div>
      )}
      {(endpointStatusView) ? renderStatus() : null}
      <div className="endpoints-note">*Top Endpoints (upto 20)</div>
      <div className="drag-expaned-icon" />
      {renderTooltip()}
    </div>
  );

  if (isExpanded) {
    return (
      <div className="expanded-widget" onMouseLeave={() => setShowTooltip(false)}>
        <div className="Widget expanded-endpoints">
          <Loader
            error={endpointsData.error}
            loading={endpointsData.loading}
            noData={endpointsData.data?.length === 0 && endpointsData.flag}
            height="800px"
            className="white-loader"
            message="No records found"
          >
            {renderExpandedView()}
          </Loader>
        </div>
      </div>
    );
  }

  return (
    <div className="Widget large" onMouseLeave={() => setShowTooltip(false)}>
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text flex-row">
            <div>{selected.label}</div>
            <div className="partex-ai-icon ct-align-power-ai" />
          </div>
        </div>
        <div className="icons">
          {/* <SvgLoader width={18.7} height={18.7} svgName="info-icon" hoverIconName="info-icon-hover" /> */}
          <SvgLoader onClick={() => handleExpandClick()} width={18.7} height={18.7} svgName="expand" hoverIconName="expand-hover" />
        </div>
      </div>
      <Loader
        error={endpointsData.error}
        loading={endpointsData.loading || searchResult.loading}
        noData={endpointsData.flag && (endpointsData.data?.length === 0 || endpointsData.labelPhases?.length === 0)}
        height="250px"
        className="white-loader"
        message="No records found"
      >
        <div id="endpoints" />
        <div className="phases-label" style={{ width: baseWidth }}>
          {endpointsData?.data?.length ? renderLabelPhases() : null}
        </div>
        {endpointsData?.flag && (
          <div className="color-meter">
            <div className="color-meter-header">Type of Endpoint</div>
            <div className="type-of-endpoints" />
            <div className="color-scale-text">
              <div>Primary</div>
              <div>Secondary</div>
              <div>Other</div>
            </div>
          </div>
        )}
        <div className="endpoints-note">*Top Endpoints (upto 10)</div>
        <div className="drag-expaned-icon" />
        {renderTooltip()}
      </Loader>
    </div>
  );
}

EndpointsGraph.propTypes = {
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  setExpanded: PropTypes.func.isRequired,
  currentExpanded: PropTypes.string.isRequired,
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default EndpointsGraph;
