/* eslint-disable no-lone-blocks */
/* eslint-disable camelcase */
import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Popover, Tooltip, Button, Input,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SvgLoader from '../../../../components/SvgLoader';
import { ucFirst } from '../../../../../utils';
import {
  shareBookmarkAction,
  shareBookmarkRefreshAction,
} from '../../../Bookmark/logic';
import { sendNotification } from '../../../../components/Notification/logic';
import Comment from '../../Comment';
import { getBookmarkProjectsAction } from '../../logic';
import BookmarkWorkspace from '../../../BookmarkWorkspace';

function BookmarkCardTwo({
  data,
  setSelectedCard,
  projectId,
  setOpenDrawer,
  setIsBookmarkClicked,
}) {
  const dispatch = useDispatch();
  const shareBookmark = useSelector((state) => state.shareBookmark);
  // eslint-disable-next-line no-unused-vars
  const [isBookmarked, setIsBookmarked] = useState(true);
  const [activeBookmarkId, setActiveBookmarkId] = useState(null);
  const [bookmarkId, setBookmarkid] = useState([]);
  const [bookmarkCardSelected, setBookmarkCardSelected] = useState([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emailTags, setEmailTags] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isEnterPressed, setIsEnterPressed] = useState(false);

  const renderClass = (predictionScore) => {
    if (predictionScore > 65) {
      return 'pos-high';
    }
    if (predictionScore > 35 && predictionScore <= 65) {
      return 'pos-med';
    }
    return 'pos-low';
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleListClick = () => {
    setSelectedCard(data);
    setOpenDrawer(true);
  };

  const showShareModal = (item) => {
    const temp = [];
    temp.push(item?.doc_id);
    temp.push(item?.type);

    setBookmarkCardSelected(temp);
    setOpenShareModal(!openShareModal);
  };

  useEffect(() => {
    if (isBookmarked === false) {
      dispatch(
        sendNotification({
          type: ' BOOKMARK_DELETE_SUCCESS',
          notificationType: 'success',
          message: 'Bookmark deleted successfully',
          showNotification: true,
        }),
      );
    }
  }, [isBookmarked]);

  const renderTooltip = (predictionScore) => (
    <div className="prediction-tooltip-container">
      <div className="prediction-tooltip-data">
        <div
          className={
            predictionScore >= 65
              ? 'high'
              : predictionScore >= 35 && predictionScore < 65
                ? 'medium'
                : 'low'
          }
        />
        <div className="prediction-tooltip-sub-container">
          <div className="prediction-score">{`${predictionScore} %`}</div>
          <div className="predition-title">Probability of success</div>
        </div>
      </div>
    </div>
  );

  const getClassName = (str) => {
    const modifiedStr = str.replace(/ /g, '-').toLowerCase();
    return modifiedStr;
  };

  const renderTooltipValue = (dataset) => dataset?.map(
    (item) => `${item?.name || item?.kol_name || item?.author_name || item} `,
  );
  const renderField = (value, dataset, len = 0) => (
    <span className="filter">
      <span className="filter-value" title={renderTooltipValue(dataset)}>
        {' '}
        {value?.name ?? value}
      </span>
      <span>{`${len > 1 ? ` +${len - 1}` : ''}`}</span>
    </span>
  );

  const handleInviteClick = () => {
    if (emailTags.length > 0 && !emailError) {
      dispatch(
        shareBookmarkAction({
          body: {
            email_ids: emailTags,
            doc_id: bookmarkCardSelected[0],
            asset_class: bookmarkCardSelected[1],
          },
        }),
      );
      setOpenShareModal(!openShareModal);
      setEmailTags([]);
    }
  };

  const handleBlur = () => {
    if (currentEmail.trim() === '') return;
    const emails = currentEmail.split(/[, ]+/);
    const validEmails = [];
    emails.forEach((email) => {
      if (emailRegex.test(email)) {
        validEmails.push(email);
      } else {
        setEmailError('Invalid email format');
      }
    });
    if (validEmails.length > 0) {
      setEmailTags([...emailTags, ...validEmails]);
      setCurrentEmail('');
      setEmailError('');
    }
  };

  useEffect(() => {
    if (shareBookmark.flag && Object.keys(shareBookmark?.data).length) {
      if (shareBookmark.data.message.includes('not')) {
        dispatch(
          sendNotification({
            type: 'BOOKMARK_SHARED_FAILURE',
            notificationType: 'error',
            message: shareBookmark.data.message,
            showNotification: true,
          }),
        );
      } else {
        dispatch(
          sendNotification({
            type: 'BOOKMARK_SHARED_SUCCESS',
            notificationType: 'success',
            message: shareBookmark.data.message,
            showNotification: true,
          }),
        );
      }
      dispatch(shareBookmarkRefreshAction());
    }
  }, [JSON.stringify(shareBookmark)]);
  const handleKeyDown = (e) => {
    if (currentEmail.trim() === '') return;
    if (e.key === 'Enter') {
      const emails = currentEmail.split(/[, ]+/);
      const validEmails = [];
      emails.forEach((email) => {
        if (emailRegex.test(email)) {
          validEmails.push(email);
        } else {
          setEmailError('Invalid email format');
        }
      });
      if (validEmails.length > 0) {
        setEmailTags([...emailTags, ...validEmails]);
        setCurrentEmail('');
        setEmailError('');
      }
    }
  };

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
  };
  const handleTagRemove = (emailToRemove) => {
    setEmailTags(emailTags.filter((email) => email !== emailToRemove));
  };

  const renderSubDetailsDocument = ({
    route_of_administration,
    dosage_form,
    committee,
    sub_type,
    active_ingredient,
    sponsors,
    indications,
    interventions,
    start_date,
    authors,
  }) => (
    <div className="sub-details-container">
      {authors?.length ? (
        <div
          className={`sub-details ${
            sponsors?.length || indications?.length
              ? 'extra-fileds-present'
              : ''
          }`}
        >
          <SvgLoader width={16} height={16} svgName="authors-icon" />
          <span className="filter-value">
            {' '}
            {renderField(
              authors[0]?.kol_name || authors[0]?.author_name || authors[0],
              authors,
              authors.length,
            )}
          </span>
        </div>
      ) : null}
      {sponsors?.length ? (
        <div
          className={`sub-details ${
            indications?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <SvgLoader width={16} height={16} svgName="sponsor-icon" />
          <span className="filter-value">
            {' '}
            {sponsors}
          </span>
        </div>
      ) : null}
      {indications?.length ? (
        <div
          className={`sub-details ${
            indications?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <SvgLoader width={16} height={16} svgName="indication-icon" />
          {renderField(indications[0], indications, indications.length)}
        </div>
      ) : null}
      {interventions?.length && data?.type !== 'regulatory_data' ? (
        <div
          className={`sub-details ${
            interventions?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <SvgLoader width={16} height={16} svgName="intervention-icon" />
          {renderField(interventions[0], interventions, interventions.length)}
        </div>
      ) : null}
      {interventions?.length && data?.type === 'regulatory_data' ? (
        <div
          className={`sub-details ${
            start_date?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <SvgLoader width={16} height={16} svgName="intervention-icon" />
          {typeof interventions === 'string' ? (
            <span className="filter-value">
              {' '}
              {active_ingredient}
            </span>
          ) : (
            renderField(
              interventions[0]?.name,
              interventions,
              interventions.length,
            )
          )}
        </div>
      ) : null}
      {sub_type === 'advisory_committee' ? (
        <div
          className={`sub-details ${
            start_date?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <span className="filter-value" title={committee}>
            {' '}
            {committee}
          </span>
        </div>
      ) : null}
      {sub_type === 'drug_specific_guidances' ? (
        <div
          className={`sub-details ${
            start_date?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <span className="filter-value">
            {' '}
            {dosage_form}
          </span>
        </div>
      ) : null}
      {sub_type === 'drug_specific_guidances' ? (
        <div
          className={`sub-details ${
            start_date?.length ? 'extra-fileds-present' : ''
          }`}
        >
          <span className="filter-value">
            {' '}
            {route_of_administration}
          </span>
        </div>
      ) : null}
      {data?.type === 'hta_recommendations' ? (
        <div
          className={`sub-details ${
            start_date?.length ? 'extra-fileds-present' : ''
          }`}
        >
          {active_ingredient?.length ? (
            <SvgLoader width={16} height={16} svgName="indication-icon" />
          ) : null}
          <span className="filter-value">
            {' '}
            {renderField(
              active_ingredient[0]?.name,
              active_ingredient,
              active_ingredient.length,
            )}
          </span>
        </div>
      ) : null}
      {start_date ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="calender-icon" />
          <span className="filter-value">
            {' '}
            {start_date}
          </span>
        </div>
      ) : null}
    </div>
  );

  if (!isBookmarked) {
    return null;
  }

  return (
    <div
      className={
        activeBookmarkId === data?.doc_id
          ? 'search-and-explore-results-container-list-content-main explore-active'
          : 'search-and-explore-results-container-list-content-main'
      }
    >
      <div
        className="search-card-click-patch"
        role="presentation"
        onClick={() => handleListClick()}
      />
      <div className="list-content-left">
        <div className="list-content-header">
          <div className="list-content-section">
            {}
            <div className="list-content-asset-class-icon">
              <Tooltip
                placement="bottomLeft"
                color="#646464"
                overlayClassName="explore-list-doc-type"
                title={data?.display_type}
              >
                <SvgLoader
                  width={16}
                  height={16}
                  svgName={`${data?.type}-icon`}
                />
              </Tooltip>
            </div>
            <div className="list-content-asset-name">
              <span>{data?.data_source || data?.regulatory_body}</span>
              <span className="redirect-icon-wrapper">
                <a
                  target="_blank"
                  href={data?.data_source_link}
                  rel="noreferrer noopener"
                  label
                >
                  <SvgLoader width={16} height={16} svgName="redirect-icon" />
                </a>
              </span>
            </div>
            <SvgLoader
              className="divider"
              width={6}
              height={6}
              svgName="seperator-icon"
            />
            <div className="list-content-published-date">
              <div className="published-date-value">
                {data?.date || data?.published_date}
              </div>
            </div>
            {data?.guideline_type?.length ? (
              <>
                <SvgLoader
                  className="divider"
                  width={6}
                  height={6}
                  svgName="seperator-icon"
                />
                <div className="list-content-published-date">
                  <div className="published-date-value">
                    {renderField(
                      data.guideline_type[0],
                      data.indications,
                      data.indications.length,
                    )}
                  </div>
                </div>
              </>
            ) : null}
            {data?.type === 'patents' ? (
              <>
                <SvgLoader
                  className="divider"
                  width={6}
                  height={6}
                  svgName="seperator-icon"
                />
                <div className="list-content-published-date">
                  <div className="published-date-value">
                    {data?.expiry_date}
                  </div>
                </div>
              </>
            ) : null}
            {data?.type === 'patents' || data?.type === 'grants' ? (
              <>
                <SvgLoader
                  className="divider"
                  width={6}
                  height={6}
                  svgName="seperator-icon"
                />
                <div className="list-content-published-date">
                  <div className="published-date-value">{data?.status}</div>
                </div>
              </>
            ) : null}
            {data?.type === 'publications' && data?.full_text !== '' ? (
              <>
                <SvgLoader
                  className="divider"
                  width={6}
                  height={6}
                  svgName="seperator-icon"
                />
                <div className="list-content-full-text">
                  <div className="full-text-value" />
                </div>
              </>
            ) : null}
            {' '}
            {data?.decision ? (
              <>
                <SvgLoader
                  className="divider"
                  width={6}
                  height={6}
                  svgName="seperator-icon"
                />
                <div className="list-content-published-date">
                  <div className="published-date-value">{data?.decision}</div>
                </div>
              </>
            ) : null}
            {data?.prediction_score ? (
              <SvgLoader
                className="divider"
                width={6}
                height={6}
                svgName="seperator-icon"
              />
            ) : null}
            {data?.phase_short ? (
              <>
                <SvgLoader
                  className="divider"
                  width={6}
                  height={6}
                  svgName="seperator-icon"
                />
                <div className="list-content-published-date">
                  {data?.phase_short}
                </div>
              </>
            ) : null}
            {data?.study_status ? (
              <>
                <SvgLoader
                  className="divider"
                  width={6}
                  height={6}
                  svgName="seperator-icon"
                />
                <div className="status">
                  <div className={getClassName(data.study_status)}>
                    {data.study_status === 'Completed' ? (
                      <div className="completed-icon" />
                    ) : null}
                    {data.study_status}
                  </div>
                </div>
              </>
            ) : null}
            {data.prediction_score ? (
              <div className="pos-val-container">
                <Tooltip
                  placement="left"
                  overlayClassName="prediction-tooltip position-zero"
                  title={() => renderTooltip(data.prediction_score)}
                  arrow={false}
                >
                  <div
                    role="presentation"
                    className={renderClass(data.prediction_score)}
                    id={`score${data.trial_id}`}
                  >
                    <SvgLoader
                      width={11}
                      height={8}
                      svgName={renderClass(data.prediction_score)}
                    />
                    PoS
                  </div>
                </Tooltip>
              </div>
            ) : null}
            {data?.study_results?.length ? (
              <>
                <SvgLoader
                  className="divider"
                  width={6}
                  height={6}
                  svgName="seperator-icon"
                />
                <div className="list-content-published-date">
                  <div className="published-date-value">
                    {data?.full_text?.length
                      ? 'Free full text available'
                      : 'Results available'}
                  </div>
                </div>
              </>
            ) : null}
            {data?.sub_type?.length ? (
              <>
                <SvgLoader
                  className="divider"
                  width={6}
                  height={6}
                  svgName="seperator-icon"
                />
                <div className="list-content-published-date">
                  <div className="published-date-value">
                    {ucFirst(data?.sub_type)}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div
          className="list-content-title"
          title={data.title || data?.title_publication || data?.layman_title}
        >
          {data?.title || data?.title_publication || data?.layman_title}
        </div>
        <div className="list-content-data">
          {renderSubDetailsDocument(data)}
        </div>
      </div>
      <div className="list-content-right">
        <div className="list-icon-section">
          <div className="comment-section">
            <Comment
              from="bookmark"
              data={data}
              projectId={projectId}
              setOpenDrawer={setOpenDrawer}
            />
          </div>
          <Popover
            overlayClassName="bookmark-popover"
            placement="bottomRight"
            // trigger="click"
            open={activeBookmarkId === data?.trial_id}
            content={(
              <BookmarkWorkspace
                projectId={projectId}
                docId={data.doc_id}
                activeBookmarkId={activeBookmarkId}
                setActiveBookmarkId={setActiveBookmarkId}
                trialId={data.trial_id}
                onClose={() => setActiveBookmarkId(null)}
                type={data.type}
                bookmarkId={bookmarkId}
                setBookmarkid={setBookmarkid}
              />
            )}
          >
            <div
              className="bookmark-icon-active-list"
              role="presentation"
              onClick={() => {
                {
                  setIsBookmarkClicked(true);

                  const newBookmarkId = activeBookmarkId === data?.trial_id ? null : data.trial_id;
                  setActiveBookmarkId(newBookmarkId);
                  dispatch(
                    getBookmarkProjectsAction({
                      params: {
                        doc_id: data?.doc_id,
                      },
                    }),
                  );
                }
              }}
            />
          </Popover>

          <div
            className="share-icon"
            role="presentation"
            onClick={() => showShareModal(data)}
          />

          {openShareModal && (
            <Modal
              className="share-with-modal"
              open={openShareModal}
              closable
              footer={false}
              onCancel={() => setOpenShareModal(false)}
            >
              <div className="share-modal-content">
                <div className="share-modal-content-input">
                  <div className="share-with-title">Share with</div>
                  <div
                    className="email-tags-container"
                    style={{
                      maxHeight: '200px',
                      overflowY: 'auto',
                      marginBottom: '10px',
                    }}
                  >
                    <div className="email-tags">
                      {emailTags.map((email, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} className="email-tag">
                          {email}
                          <span
                            role="presentation"
                            className="remove-tag"
                            onClick={() => handleTagRemove(email)}
                          />
                        </div>
                      ))}
                      <Input
                        //  mode="tags"
                        value={currentEmail}
                        onBlur={handleBlur}
                        onChange={handleEmailInputChange}
                        onKeyDown={(e) => handleKeyDown(e)}
                        placeholder="Email ID"
                        //   style={{ flex: '0 0 auto', marginLeft: '5px' }}
                      />
                      {emailError && (
                        <div style={{ color: 'red' }}>{emailError}</div>
                      )}
                    </div>
                  </div>
                  <div className="share-with-message-input">
                    <Input placeholder="Message (Optional)" />
                  </div>
                  <div className="invite-btn">
                    <Button
                      type="primary"
                      onClick={() => handleInviteClick()}
                    >
                      Invite
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}

BookmarkCardTwo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default BookmarkCardTwo;
