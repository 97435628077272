import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../components/Dropdown';
import Mixpanel from '../../../utils/mixpanel';
import { postSubmitFormAction, postSubmitFormRefreshAction } from './logics';
import { sendNotification } from '../../components/Notification/logic';
import Loader from '../../components/Loader';
import { getTerminalName, windowHrefToNavigate } from '../../../utils';

const options = [
  { label: 'How did you hear about us?', value: 'how did you hear about us?' },
  { label: 'Advertisement', value: 'advertisement' },
  { label: 'Word of Mouth', value: 'word of mouth' },
  { label: 'Conferences', value: 'Conferences' },
  { label: 'Social Media', value: 'social media' },
  { label: 'News', value: 'news' },
  { label: 'Search', value: 'search' },
  { label: 'Other', value: 'other' },
];

function ContactUsForm() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [validName, setValidName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);
  const [submitDissabled, setSubmitDissebled] = useState(false);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    subscribeToNewsletter: false,
    howDidYouHearAboutUs: '',
  });

  const statusContactUsFormSubmit = useSelector((state) => state.statusContactUsFormSubmit);

  useEffect(() => {
    const emptyCheck = userInfo.firstName === '' || userInfo.lastName === '' || userInfo.email === '' || userInfo.phone === '';
    setSubmitDissebled(!validName || !validEmail || !validPhone || emptyCheck);
  }, [JSON.stringify(userInfo)]);

  const defaultState = () => {
    setUserDetails({
      ...userInfo,
      ...{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        subscribeToNewsletter: false,
        howDidYouHearAboutUs: '',
      },
    });
    setSelectedDropdownValue(null);
    setLoading(false);
  };

  useEffect(() => {
    if (statusContactUsFormSubmit.flag) {
      if (statusContactUsFormSubmit.data === 'Contact us form submitted successfully') {
        dispatch(sendNotification({
          type: 'CONTACT_US_SUCCESS',
          notificationType: 'success',
          message: 'Contact us form submitted successfully',
          showNotification: true,
        }));
      }
      defaultState();
    }

    if (statusContactUsFormSubmit.error) {
      defaultState();
    }
  }, [JSON.stringify(statusContactUsFormSubmit)]);

  const hanldeChange = (input) => {
    setUserDetails({ ...userInfo, ...input });
  };

  const handleNameValidation = () => {
    const check = /^[A-Za-z\s]+$/;
    setValidName(check.test(userInfo.firstName) && userInfo.firstName !== '' && check.test(userInfo.lastName) && userInfo.lastName !== '');
  };

  const handleEmailIdValidation = () => {
    const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidEmail(check.test(userInfo.email));
  };

  const handleValidPhoneNumber = () => {
    const check = /^\d+$/;
    setValidPhone(check.test(userInfo.phone));
  };

  const handlePolicyClick = () => {
    Mixpanel.track('privacy_policy_event', {
      action: 'privacy policy click in contact us form to view privacy policy',
      page: 'Contact Us Page',
    });
    windowHrefToNavigate('/privacyPolicy');
  };

  const handleDropDownChange = (value) => {
    setSelectedDropdownValue({ ...value });
    setUserDetails({
      ...userInfo,
      howDidYouHearAboutUs: value.label,
    });
  };

  const handleSubmit = () => {
    if (!submitDissabled) {
      setLoading(true);
      const userDetails = {
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        email: userInfo.email,
        phone: userInfo.phone,
        message: userInfo.message,
        is_subscribed_newsletter: userInfo.subscribeToNewsletter,
        how_did_you_hear: userInfo.howDidYouHearAboutUs,
      };
      Mixpanel.track('bottom_bar_event', {
        misc: 'Send message',
        action: 'Send message button click',
        page: 'home page',
        details: userDetails,
      });
      Mixpanel.track('user_support', {
        action: 'Send message click',
        page: 'contact page',
        terminal_name: getTerminalName(location),
        support_type: 'ChatBot',
        misc: 'Bottom bar',
      });
      dispatch(postSubmitFormRefreshAction());
      dispatch(postSubmitFormAction({
        body: {
          first_name: userInfo.firstName,
          last_name: userInfo.lastName,
          email: userInfo.email,
          phone: userInfo.phone,
          message: userInfo.message,
          is_subscribed_newsletter: userInfo.subscribeToNewsletter,
          how_did_you_hear: userInfo.howDidYouHearAboutUs,
        },
      }));
    }
  };

  return (
    <div>
      <div aria-hidden className="contact-us-form">
        <div className="input-ctr">
          <div className="type-1-ctr">
            <input value={userInfo.firstName} onBlur={handleNameValidation} className="contat-us-input-type-1" type="text" placeholder="First name*" onChange={(e) => hanldeChange({ firstName: e.target.value })} />
            <input value={userInfo.lastName} onBlur={handleNameValidation} className="contat-us-input-type-1" type="text" placeholder="Last name*" onChange={(e) => hanldeChange({ lastName: e.target.value })} />
          </div>
          <span className="not-valid">{`${validName ? '' : 'Please enter valid name'}`}</span>
        </div>
        <div className="input-ctr">
          <div className="type-1-ctr">
            <input value={userInfo.email} onBlur={handleEmailIdValidation} className="contat-us-input-type-1" type="text" placeholder="Email*" onChange={(e) => hanldeChange({ email: e.target.value })} />
            <input value={userInfo.phone} onBlur={handleValidPhoneNumber} className="contat-us-input-type-1" type="text" placeholder="Phone number*" onChange={(e) => hanldeChange({ phone: e.target.value })} />
          </div>
          <div className="flex-row">
            {
              !validEmail
                ? <div className="not-valid">Please enter valid email</div>
                : null
            }
            {
              !validPhone
                ? <div className="not-valid">Please enter valid phone number</div> : null
            }
          </div>
        </div>
        <div className="type-text-area">
          <TextArea value={userInfo.message} placeholder="Message" className="contat-us-input-textarea" onChange={(e) => hanldeChange({ message: e.target.value })} />
        </div>
        <div className="type-dropdown">
          <Dropdown defaultMessage="How did you hear about us?" options={options} value={selectedDropdownValue} onChange={handleDropDownChange} className={['contact-us-display', 'contact-us-panel', 'contact-us-option']} />
        </div>
        <div className="type-checkbox">
          <input checked={userInfo.subscribeToNewsletter} onChange={() => hanldeChange({ subscribeToNewsletter: !userInfo.subscribeToNewsletter })} className="checkbox pointer" type="checkbox" />
          <span className="checkbox-text">Subscribe to our newsletter.</span>
        </div>
        <div className="type-desciption">
          <div>
            Innoplexus needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our
            {' '}
            <span className="policy-text" aria-hidden onClick={() => handlePolicyClick()}>Privacy Policy.</span>
          </div>
        </div>
        <div>
          {
            !loading
              ? <button onClick={() => handleSubmit()} className={submitDissabled ? 'contact-us-submit-dissabled' : 'contact-us-submit'} type="submit">Send Message</button>
              : <Loader loading height="30px" width="130px" />
          }
        </div>
      </div>
    </div>
  );
}

export default ContactUsForm;
