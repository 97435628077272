import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Button } from 'antd';
import queryString from 'query-string';
import Loader from '../../../components/Loader';
import { getBookmarkAction, getMoreBookmarkAction } from '../../Bookmark/logic';
import BookmarkCardTwo from './BookmarkCardTwo';
import { getExploreSearchSummaryAction } from '../../../../searchAndExplore/container/ExploreList/logic';
import ExploreSummaryCard from '../../../../searchAndExplore/components/ExploreSummaryCard';
import CardDetail from '../../../../clinicalTrials/container/CardDetail';
import { windowHrefToNavigate } from '../../../../utils';
import LazyLoadDiv from '../../../components/LazyLoadDiv';

function BookmarkList({
  projectId,
  setBookmarkFilters,
  setSortByOptions,
  setBookmarkFilterUsers,
  currentPage,
  setCurrentPage,
}) {
  const dispatch = useDispatch();
  const getExploreSearch = useSelector(
    (state) => state.getExploreSearchSummary,
  );
  const bookmarkData = useSelector((state) => state.bookmarkData);
  const [selectedCard, setSelectedCard] = useState({});
  const [previewCardData, setPreviewCardData] = useState({});
  const [drawerWidth, setDrawerWidth] = useState(600);
  const [openDrawer, setOpenDrawer] = useState(false);
  const onDrawerClose = () => {
    setSelectedCard({});
  };

  useEffect(() => {
    if (getExploreSearch?.flag) {
      setPreviewCardData(getExploreSearch?.data?.data);
    }
  }, [JSON.stringify(getExploreSearch)]);

  useEffect(() => {
    if (selectedCard && Object?.keys(selectedCard).length) {
      const url = `/explore/v0/search/${selectedCard.type}/resource/${selectedCard.doc_id}`;
      dispatch(
        getExploreSearchSummaryAction({
          params: {
            url,
            headers: queryString.stringify({
              asset_class: selectedCard.type,
              view: 'preView',
              sub_asset_class:
                selectedCard?.type === 'regulatory_data'
                  ? selectedCard?.sub_type
                  : undefined,
            }),
          },
        }),
      );
    }
  }, [selectedCard]);

  useEffect(
    () => {
      setBookmarkFilters([]);
      setBookmarkFilterUsers([]);
      setSortByOptions({});

      if (projectId !== '') {
        dispatch(
          getBookmarkAction({
            body: {
              project_id: projectId,
              asset_class: [],
              user_ids: [],
              export: false,
              file_type: '',
              sort: {},
              page: currentPage,
              size: 10,
            },
          }),
        );
      }
    },
    [projectId],
  );

  const loadMoreListData = () => {
    if (projectId !== '') {
      const request = {
        body: {
          project_id: projectId,
          asset_class: [],
          user_ids: [],
          export: false,
          file_type: '',
          sort: {},
          page: currentPage + 1,
          size: 10,
        },
      };

      dispatch(
        getMoreBookmarkAction(request),
      );
      setCurrentPage(currentPage + 1);
    }
  };

  const renderSummaryCard = () => (
    <ExploreSummaryCard activeList={previewCardData} />
  );

  const renderNoBookmarkData = () => (
    <div className="no-bookmark-container">
      <div className="no-bookmark-icon" />
      <div className="no-bookmark-title">No bookmark found</div>
      <div className="no-bookmark-content">
        It seems that you have not bookmarked any document yet in your current
        project. We recommend utilising the bookmark feature to conveniently
        save and view your favourite documents at any time.
      </div>
      <Button className="home-btn" onClick={() => windowHrefToNavigate('/home')}>Return to home</Button>
    </div>
  );

  const renderBookmarkList = () => {
    if (projectId === '' || bookmarkData?.data?.data?.length === 0) {
      return <div className="no-bookmark-data">{renderNoBookmarkData()}</div>;
    }
    return bookmarkData?.data?.data?.map((item) => (
      <BookmarkCardTwo
        data={item}
        setSelectedCard={setSelectedCard}
        projectId={projectId}
        setOpenDrawer={setOpenDrawer}
        currentPage={currentPage}
      />
    ));
  };

  return (
    <div className="list-container">
      <Loader
        loading={bookmarkData?.loading || !bookmarkData?.flag}
        error={bookmarkData?.error}
      >
        <LazyLoadDiv
          className="bookmark-list-results"
          id="bookmark-list"
          total={bookmarkData?.total}
          currentTotal={(bookmarkData?.data?.data || []).length}
          height="calc(100vh - 200px)"
          loadMoreRows={() => loadMoreListData()}
        >
          {renderBookmarkList()}

        </LazyLoadDiv>
      </Loader>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onDrawerClose}
        height={100}
        width={drawerWidth}
        open={Object.keys(selectedCard).length > 0 && openDrawer}
        key="right"
      >
        {previewCardData?.type === 'clinical_trials' ? (
          <CardDetail data={previewCardData} setDrawerWidth={setDrawerWidth} />
        ) : (
          renderSummaryCard()
        )}
      </Drawer>
    </div>
  );
}

export default BookmarkList;
