import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const FEEDBACK_MODAL = 'FEEDBACK_MODAL';

export const setFeedBackOpenModalAction = createAction(FEEDBACK_MODAL);

const onSetFeedbackOpenModal = (state, payload) => ({
  ...state,
  toggleFeedback: payload,
});

export const feedbackModalReducer = createReducer(
  {
    toggleFeedback: false,
  },
  (builder) => {
    builder.addCase(setFeedBackOpenModalAction, (state, action) => onSetFeedbackOpenModal(state, action.payload))
      .addDefaultCase((state) => state);
  },
);

const GET_FEEDBACK_QUESTIONS = 'GET_FEEDBACK_QUESTIONS';
const GET_FEEDBACK_QUESTIONS_SUCCESS = 'GET_FEEDBACK_QUESTIONS_SUCCESS';
const GET_FEEDBACK_QUESTIONS_FAILURE = 'GET_FEEDBACK_QUESTIONS_FAILURE';
const GET_FEEDBACK_QUESTIONS_REFRESH = 'GET_FEEDBACK_QUESTIONS_REFRESH';

export const getFeedbackQuestionsAction = createAction(GET_FEEDBACK_QUESTIONS);

const getFeedbackQuestionsSuccess = createAction(GET_FEEDBACK_QUESTIONS_SUCCESS);

const getFeedbackQuestionFailure = createAction(GET_FEEDBACK_QUESTIONS_FAILURE);

export const getFeedbackQuestionsRefresh = createAction(GET_FEEDBACK_QUESTIONS_REFRESH);

export const getFeedbackQuestionsEpic = (actions$) => actions$.pipe(
  ofType(GET_FEEDBACK_QUESTIONS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/getFeedbackQuestions?`, 'GET')).pipe(
    map((res) => getFeedbackQuestionsSuccess(res)),
    catchError((err) => of(getFeedbackQuestionFailure(err))),
  )),
);

const ongetFeedbackQuestionsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetFeedbackQuestionsSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
  error: false,
});

const ongetFeedbackQuestionsFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetFeedbackQuestionsRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getFeedbackQuestionsReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getFeedbackQuestionsAction, (state) => ongetFeedbackQuestionsAction(state))
      .addCase(getFeedbackQuestionsSuccess, (state, action) => ongetFeedbackQuestionsSuccess(state, action.payload))
      .addCase(getFeedbackQuestionFailure, (state) => ongetFeedbackQuestionsFailure(state))
      .addCase(getFeedbackQuestionsRefresh, () => ongetFeedbackQuestionsRefresh())
      .addDefaultCase((state) => state);
  },
);

const ADD_FEEDBACK = 'ADD_FEEDBACK';
const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS';
const ADD_FEEDBACK_FAILURE = 'ADD_FEEDBACK_FAILURE';
const ADD_FEEDBACK_REFRESH = 'ADD_FEEDBACK_REFRESH';

export const addFeedBackAction = createAction(ADD_FEEDBACK);

const addFeedBackSuccess = createAction(ADD_FEEDBACK_SUCCESS);

const addFeedbackFailure = createAction(ADD_FEEDBACK_FAILURE);

export const addFeedBackRefresh = createAction(ADD_FEEDBACK_REFRESH);

export const addFeedBackEpic = (actions$) => actions$.pipe(
  ofType(ADD_FEEDBACK),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/addFeedback`, 'POST', action.payload.body)).pipe(
    map((res) => addFeedBackSuccess(res)),
    catchError((err) => of(addFeedbackFailure(err))),
  )),
);

const onAddFeedbackAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onAddFeedbackSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
  error: false,
});

const onAddFeedbackFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onAddFeedbackRefresh = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const addFeedBackReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(addFeedBackAction, (state) => onAddFeedbackAction(state))
      .addCase(addFeedBackSuccess, (state, action) => onAddFeedbackSuccess(state, action.payload))
      .addCase(addFeedbackFailure, (state) => onAddFeedbackFailure(state))
      .addCase(addFeedBackRefresh, () => onAddFeedbackRefresh())
      .addDefaultCase((state) => state);
  },
);
