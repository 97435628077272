/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation, useSearchParams } from 'react-router-dom';
import Panel from '../Panel';
import SvgLoader from '../SvgLoader';
import { getTypesValuesAction, updateCurrentDropdownAction, getTypesValuesRefreshAction } from '../../container/PowerSearchBox/logic';
import {
  debounceCall, getTerminalName, modifyDataForDropdown, wordLimit,
} from '../../../utils';
import Loader from '../Loader';
import { updateFilterAction, goBtnZIndexUpdateAction } from '../../container/PowerSearch/logic';
import Mixpanel from '../../../utils/mixpanel';

function AutoSuggest({
  className, selectedValue, id, setShow, apiUrl, removedValues,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const multiSelectRef = useRef(null);
  const [input, setInput] = useState('');
  const [openDropdown, setOpendDropDown] = useState(false);
  const [tags, setTags] = useState([]);
  const [checkFocus, setCheckFocus] = useState(false);
  const [miniView, setMiniView] = useState(false);
  const currentDropdown = useSelector((state) => state.currentDropdown);
  const query = searchParams.get('query');
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (query && !removedValues?.includes(selectedValue)) {
      const q = JSON.parse(query);
      q.forEach((item) => {
        if (item.name === selectedValue) {
          setTags([...item.currentFilter]);
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(goBtnZIndexUpdateAction(openDropdown ? -1 : 0));
  }, [openDropdown]);

  const optionValues = useSelector((state) => state.optionValues);

  const options = modifyDataForDropdown(optionValues.data, 'key', 'value');

  useEffect(() => {
    if (options.length && id === currentDropdown.id && optionValues.fieldName === selectedValue) {
      setOpendDropDown(true);
    }
  }, [JSON.stringify(options), currentDropdown]);

  useEffect(() => {
    const tempTags = tags.map((item) => encodeURIComponent(item));
    dispatch(updateFilterAction({
      filterOption: { currentFilter: tempTags, currentKeys: tempTags },
      id,
    }));
  }, [tags]);

  useEffect(() => {
    const handler = (event) => {
      if (!multiSelectRef.current) {
        return;
      }

      if (!multiSelectRef.current.contains(event.target)) {
        setMiniView(true);
        setOpendDropDown(false);
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    if (input.length >= 3) {
      setShowLoader(false);
      const request = {
        apiUrl,
        params: queryString.stringify({
          field_query: input,
          field_name: selectedValue,
          terminal_name: 'clinical_trials',
        }),
        headers: {
          Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
        },
      };
      debounceCall(() => dispatch(getTypesValuesAction(request)), 300);
    }
  }, [input]);

  const handleChange = (event) => {
    event.preventDefault();
    setShowLoader(true);
    setOpendDropDown(true);
    dispatch(getTypesValuesRefreshAction({}));
    setInput(event.target.value);
    dispatch(updateCurrentDropdownAction(id));
    debounceCall(() => updateCurrentDropdownAction(id), 300);
  };

  const handleOptionClick = (value) => {
    const temp = [value];
    if (tags.includes(value)) {
      setTags([]);
    } else {
      setTags([...temp]);
    }
    setInput('');
    setCheckFocus(true);

    Mixpanel.track('cross_event', {
      action: 'user clicked on cross button',
      page_name: 'Search Page',
      search_type: 'Power Search',
    });
  };

  const handleDivClick = () => {
    Mixpanel.track('field_tap_event', {
      action: 'user tapped on search box to fill data for power search',
      value: selectedValue,
      terminal_name: getTerminalName(location),
      page_name: 'Search Page',
      search_type: 'Power Search',
    });
    Mixpanel.track('search_box_tap_event', {
      action: 'user tapped on search box ',
      terminal_name: getTerminalName(location),
      page_name: 'Search Page',
      search_type: 'Power Search',
    });
    setMiniView(false);
    dispatch(updateCurrentDropdownAction(id));
  };

  const renderTags = () => tags.map((item) => (
    <div className="tags" key={item}>
      <div className="tag-label" title={item}>{wordLimit(item, 20)}</div>
      <SvgLoader className="cross-icon" onClick={() => handleOptionClick(item)} width={28} height={28} svgName="cross" />
    </div>
  ));

  const renderDropdowns = () => options.map((item) => (
    <div aria-hidden key={item.value} onClick={() => handleOptionClick(item.label)} className={`options ${className[2]} ${tags.indexOf(item.label) > -1 ? 'check' : ''}`}>
      {item.label}
      {tags.indexOf(item.label) > -1 && <SvgLoader width={24} height={24} svgName="tick" />}
    </div>
  ));

  const renderMiniView = () => (
    <div className="mini-view">
      <div className="tags" title={tags[0]}>
        {wordLimit(tags[0], 20)}
        <SvgLoader className="cross-icon" onClick={() => handleOptionClick(tags[0])} width={28} height={28} svgName="cross" />
      </div>
      {tags.length - 1 && <div className="tags" style={{ width: 50 }}>{`+${tags.length - 1}`}</div>}
    </div>
  );

  return (
    <div aria-hidden ref={multiSelectRef} className="multi-select" onClick={() => handleDivClick()}>
      <div className="multi-select-wrap">
        {(tags.length) ? (
          <div className={`multi-select-div ${className[0]}`}>
            <div className="tags-data">
              {!miniView && renderTags()}
              {(miniView)
                ? renderMiniView() : null}
            </div>
          </div>
        ) : null}
        {(!miniView || !tags.length)
      && <input autoFocus={checkFocus} onFocus={() => setShow('power')} className="multi-input" onChange={handleChange} value={input} />}
      </div>
      <div className="input-div">
        {(openDropdown && input?.length)
          ? (
            <Panel className={`panel ${className[1]}`}>
              {renderDropdowns()}
              <Loader
                error={optionValues.error}
                loading={(!optionValues.flag && optionValues.loading) || showLoader}
                noData={optionValues.data?.length === 0}
                height={50}
                className="white-loader"
              />
            </Panel>
          ) : null}
      </div>
    </div>
  );
}

AutoSuggest.propTypes = {
  className: PropTypes.instanceOf(Array).isRequired,
  selectedValue: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  setShow: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  removedValues: PropTypes.instanceOf(Array).isRequired,
};

export default AutoSuggest;
