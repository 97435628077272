import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SvgLoader from '../../../common/components/SvgLoader';
import { getEnrolomentDataAction } from './logic';
import Loader from '../../../common/components/Loader';
import Mixpanel from '../../../utils/mixpanel';

const modifeyNumber = (number, type) => {
  if (number >= 1000000000) {
    const result = ((Number(number / 1000000)) - Math.floor(Number(number / 1000000))) !== 0;
    if (type === 'fixval') {
      return `${(number / 1000000000).toFixed(0)}B`;
    }
    if (result) {
      return `${(number / 1000000000).toFixed(2)}B`;
    }
    return `${(number / 1000000000).toFixed(0)}B`;
  }

  if (number >= 1000000) {
    const result = ((Number((number / 1000000).toFixed(2))) - Math.floor(Number((number / 1000000).toFixed(2)))) !== 0;
    if (type === 'fixval') {
      return `${(number / 1000000).toFixed(0)}M`;
    }
    if (result) {
      return `${(number / 1000000).toFixed(2)}M`;
    }
    return `${(number / 1000000).toFixed(0)}M`;
  }

  if (number >= 1000) {
    const result = ((Number((number / 1000000).toFixed(2))) - Math.floor(Number((number / 1000000).toFixed(2)))) !== 0;
    if (type === 'fixval') {
      return `${(number / 1000).toFixed(0)}K`;
    }
    if (result) {
      return `${(number / 1000).toFixed(2)}K`;
    }
    return `${(number / 1000).toFixed(0)}K`;
  }
  return `${number}`;
};

const enrollementOption = [{
  field_name: 'Phase',
  label: 'Phase',
  display_name: 'Phase',
  value: 'phase',
  type: 'multiselect',
  name: 'phase',
  currentFilter: [],
}];

function EnrollmentMeter({ params, selected, currentExpanded }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');

  const enrolomentData = useSelector((state) => state.enrolomentData);
  const searchResult = useSelector((state) => state.searchResult);
  let actualEnrollment = 0;
  let estimatedEnrollment = 0;

  useEffect(() => {
    if (currentExpanded === '' && searchResult.flag && !enrolomentData.flag) {
      const request = {
        ...params,
        headers: queryString.stringify({
          ...params.headers,
          field_name: 'enrolment_count',
        }),
      };
      dispatch(getEnrolomentDataAction(request));
    }
  }, [currentExpanded, JSON.stringify(searchResult)], JSON.stringify(enrolomentData));

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  const handleEnrollementRowClick = (value) => {
    const q = JSON.parse(query);
    const newQuery = [];
    let flag = true;
    if (q) {
      newQuery.push({
        currentFilter: [value],
        currentKeys: [value],
        display_name: 'Phase',
        field_name: 'Phase',
        label: 'Phase',
        name: 'phase',
        type: 'multiselect',
        value: 'phase',
      });
      flag = false;
    }

    if (semanticQuery) {
      newQuery.push({
        currentFilter: [value],
        currentKeys: [value],
        display_name: 'Phase',
        field_name: 'Phase',
        label: 'Phase',
        name: 'phase',
        type: 'multiselect',
        value: 'phase',
      });
      flag = false;
    }

    if (flag) {
      enrollementOption[0].currentFilter = [value];
      enrollementOption[0].currentKeys = [value];
      newQuery.push(...enrollementOption);
    }

    Mixpanel.track('widget_deepdive_event', {
      action: 'User clicked on enrollement row for list view search',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Enrollment Stats',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
      label: value,
      view_type: 'Widget View',
      misc: 'Level 0',
    });

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
    });

    if (semanticQuery) {
      navigate(`/clinicalTrials/searchResults?semanticquery=${semanticQuery}&deepdivequery=${JSON.stringify(newQuery)}${'&from=\'Enrollment Stats\''}`);
      navigate(0);
    } else {
      navigate(`/clinicalTrials/searchResults?query=${query}&deepdivequery=${JSON.stringify(newQuery)}${'&from=\'Enrollment Stats\''}`);
      navigate(0);
    }
  };

  const showPercentage = (value) => {
    if (value.enrollment_anticipated === 0) {
      return 0;
    }
    const temp = (value.enrollment_actual / value.enrollment_anticipated) * 100;
    return temp.toFixed(2);
  };

  const showTotalActualEnrollment = () => {
    enrolomentData?.data?.forEach((itm) => {
      actualEnrollment += itm.enrollment_actual;
    });
    return `${modifeyNumber(actualEnrollment)}`;
  };

  const showTotalEstimatedEnrollment = () => {
    enrolomentData?.data?.forEach((itm) => {
      estimatedEnrollment += itm.enrollment_anticipated;
    });
    return `${modifeyNumber(estimatedEnrollment)}`;
  };

  const showTotalPercentage = () => {
    let temp = 0;
    if (estimatedEnrollment === 0) {
      return '0%';
    }
    temp = (actualEnrollment / estimatedEnrollment) * 100;
    return `${temp.toFixed(2)}%`;
  };

  const showTooltip = (itm) => (
    <div className="tooltip tooltip-graph">
      <div className="tooltip-content tooltip-content-graph">
        <div className="tooltip-content-graph-content">
          <div className="tooltip-value">{itm.enrollment_actual}</div>
          <div className="endpoint-type">Actual enrollment</div>
        </div>
        <div className="tooltip-content-graph-content">
          <div className="tooltip-value">{itm.enrollment_anticipated}</div>
          <div className="endpoint-type">Estimated enrollment</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="Widget">
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">
            <div>{selected.label}</div>
            <div className="partex-ai-icon ct-align-power-ai" />
          </div>
        </div>
        <div className="icons" />
      </div>
      <Loader
        error={enrolomentData.error}
        loading={enrolomentData.loading || searchResult.loading}
        noData={(!enrolomentData?.data?.length) && enrolomentData.flag}
        height="250px"
        className="white-loader"
        message="No records found"
      >
        <div className="enrolled-container">

          <div className="enrolled-header">
            <div className="enrollment-header-list">
              <span className="enrollment-header-list-label">Total estimated enrollment </span>
              <span className="enrollment-header-list-value">{showTotalEstimatedEnrollment()}</span>
            </div>
            <div className="enrollment-header-list">
              <span className="enrollment-header-list-label">Total actual enrollment </span>
              <span className="enrollment-header-list-value">{showTotalActualEnrollment()}</span>
            </div>
            <div className="enrollment-header-list">
              <span className="enrollment-header-list-label">% Subjects enrolled </span>
              <span className="enrollment-header-list-value">{showTotalPercentage()}</span>
            </div>
          </div>

          <div className="enrolled-data-container">
            <div className="enrolled-itm header">
              <div className="enrolled-itm-name-header">Phase</div>
              <div className="enrolled-itm-data-header">
                Actual/Estimated Enrollment
              </div>
            </div>

            {enrolomentData?.data?.map((itm, idx) => {
              if (idx < 5) {
                return (
                  <div key={itm.name} aria-hidden className="enrolled-itm pointer" onClick={() => handleEnrollementRowClick(itm.name)}>
                    <div className="enrolled-itm-name">{`${itm.name}`}</div>
                    <Tooltip placement="top" color="#fff" title={showTooltip(itm)} paddingSM={0} paddingXS={0}>
                      {
                        itm.enrollment_anticipated === 0
                          ? <div className="enrolled-itm-data">0%</div>
                          : <div className="enrolled-itm-data">{`${modifeyNumber(itm.enrollment_actual, 'fixval')} / ${modifeyNumber(itm.enrollment_anticipated, 'fixval')} (${showPercentage(itm)}%)`}</div>
                      }
                    </Tooltip>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
        <div className="drag-expaned-icon" />
      </Loader>
    </div>
  );
}

EnrollmentMeter.propTypes = {
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired,
  currentExpanded: PropTypes.string.isRequired,
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default EnrollmentMeter;
