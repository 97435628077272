import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import DateAndTimeRange from '../DateAndTimeRange';
import { currentDataModalAction, getSingleUserDetailsAction } from '../UserList/logic';
import { addLicenseAction } from '../LoginModalV2/logic';
import { sendNotification } from '../../../common/components/Notification/logic';
import SvgLoader from '../../../common/components/SvgLoader';
import Mixpanel from '../../../utils/mixpanel';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

const getCurrentDateTime = () => {
  const now = new Date(); // Get the current date and time

  // Format the date as "DD MMM, YYYY"
  const day = String(now.getDate()).padStart(2, '0'); // Get day and pad if needed
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[now.getMonth()]; // Get the month name
  const year = now.getFullYear(); // Get the full year
  const formattedDate = `${day} ${month}, ${year}`;

  // Format the time as "HH:MM AM/PM"
  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12; // Convert to 12-hour format
  hours = hours ? String(hours).padStart(2, '0') : '12'; // If hours is 0, set to 12

  const formattedTime = `${hours}:${minutes} ${ampm}`;

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

const convertToTimestamp = (dateString) => {
  // Replace AM/PM with lowercase to handle it correctly
  const normalizedString = dateString.replace(/(AM|PM)/i, (match) => match.toUpperCase());

  // Create a new Date object from the normalized date string
  const date = new Date(normalizedString);

  // Return the timestamp (milliseconds since Unix epoch)
  return date.getTime();
};

// eslint-disable-next-line no-unused-vars
const formatTimestamp = (timestamp) => {
  // Create a Date object from the timestamp (in seconds)
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert to milliseconds

  // Get day, month, and year
  const day = date.getDate();
  const monthIndex = date.getMonth(); // Month is 0-indexed
  const year = date.getFullYear();

  // Array of month names
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  // Construct and return the formatted date string
  return `${day} ${monthNames[monthIndex]}, ${year}`;
};

const getDateTimeFromTimeStamp = (timestamp) => {
  const date = new Date(timestamp);

  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
    'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec',
  ];

  const year = date.getUTCFullYear();
  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');

  let hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours ? String(hours).padStart(2, '0') : '12';

  const getDate = `${day} ${month}, ${year}`;
  const getTime = `${hours}:${minutes} ${ampm}`;
  return {
    date: getDate,
    time: getTime,
  };
};

const formatTime = (timestamp) => {
  if (timestamp) {
    const momentObj = moment(Number(timestamp));
    const formattedTime = momentObj.format('hh:mm A');
    return formattedTime;
  }
  return null;
};

export default function EditCurrentAccessDate() {
  const dispatch = useDispatch();

  const editCurrentAccesDate = useSelector((state) => state.editCurrentAccesDate);
  const LicenseStatus = useSelector((state) => state.LicenseStatus);

  const currentDate = getCurrentDateTime().date;
  const currentTime = getCurrentDateTime().time;

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [startTime, setStartTime] = useState(currentTime);
  const [endTime, setEndTime] = useState(currentTime);
  const [showAccess, setShowAccess] = useState(false);

  useEffect(() => {
    if (editCurrentAccesDate.open) {
      const startTimeStamp = editCurrentAccesDate.data.licenseStartDate;
      const endTimeStamp = editCurrentAccesDate.data.licenseEndDate;
      setStartTime(formatTime(startTimeStamp));
      setEndTime(formatTime(endTimeStamp));
    }
  }, [JSON.stringify(editCurrentAccesDate)]);

  const dateTimeModalTitle = 'Licence period';

  useEffect(() => {
    if (editCurrentAccesDate.open) {
      if (editCurrentAccesDate.data.licenseStartDate) {
        const { date } = getDateTimeFromTimeStamp(editCurrentAccesDate.data.licenseStartDate);
        setStartDate(date);
      }
      if (editCurrentAccesDate.data.licenseEndDate) {
        const { date } = getDateTimeFromTimeStamp(editCurrentAccesDate.data.licenseEndDate);
        setEndDate(date);
      }
    }
  }, [JSON.stringify(editCurrentAccesDate)]);

  const handleClose = () => {
    dispatch(currentDataModalAction({
      open: false,
      data: {},
    }));
    setShowAccess(false);
  };

  useEffect(() => {
    if (LicenseStatus.flag) {
      setTimeout(() => {
        dispatch(sendNotification({
          type: 'USER_Licence_SUCCESS',
          notificationType: 'success',
          message: 'Licence updated successfully',
          showNotification: true,
        }));
        handleClose();
        debounceCall(() => {
          dispatch(getSingleUserDetailsAction({
            headers: queryString.stringify({
              user_name: editCurrentAccesDate.data.email,
            }),
          }));
        }, 100);
      }, 100);
    }
  }, [JSON.stringify(LicenseStatus)]);

  const handleChanges = () => {
    const startTimeStamp = convertToTimestamp(`${startDate} ${startTime}`);
    const endTimeStamp = convertToTimestamp(`${endDate} ${endTime}`);
    dispatch(addLicenseAction({
      body: {
        email: editCurrentAccesDate.data.email,
        license_type: 'licensed',
        trial_start_date: 0,
        trial_end_date: 0,
        license_start_date: startTimeStamp,
        license_end_date: endTimeStamp,
        is_active: true,
        details: 'trial or org',
      },
    }));
    Mixpanel.track('button_click_event', {
      action: 'submit click',
      page: 'edit date and time pop up',
      terminal_name: 'Admin Panel',
      filter_value: {
        license_start_date: startDate,
        license_end_date: endDate,
        license_start_time: startTime,
        license_end_time: endTime,
      },
      misc: 'edit terminal access',
    });
  };

  const handleAccess = () => {
    setShowAccess(true);
  };

  const handleCancelClick = () => {
    setShowAccess(false);
  };

  return (
    <Modal className="filter-modal-container" closeIcon={false} footer={false} open={editCurrentAccesDate.open} width={850}>
      {!showAccess ? (
        <DateAndTimeRange
          open={editCurrentAccesDate.open}
          setOpen={handleClose}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          title={dateTimeModalTitle}
          handleSubmit={() => handleAccess()}
        />
      ) : (
        <div>
          <div className="filter-header">
            <div>{' '}</div>
            <SvgLoader onClick={() => setShowAccess(false)} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
          </div>
          <div className="access-update-text min-h-32">
            <div>Are you sure you want to update</div>
            <div>current access start and end date access?</div>
          </div>
          <div className="filter-buttons">
            <div aria-hidden onClick={() => handleCancelClick()} className="reset-all-btn pointer">Cancel</div>
            <div aria-hidden onClick={() => handleChanges()} className="filter-btn pointer">Submit</div>
          </div>
        </div>
      )}
    </Modal>
  );
}
