import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_TRENDING_TOPICS_DATA = 'GET_TRENDING_TOPICS_DATA';
const GET_TRENDING_TOPICS_DATA_SUCCESS = 'GET_TRENDING_TOPICS_DATA_SUCCESS';
const GET_TRENDING_TOPICS_DATA_FAILURE = 'GET_TRENDING_TOPICS_DATA_FAILURE';
const GET_TRENDING_TOPICS_DATA_REFRESH = 'GET_TRENDING_TOPICS_DATA_REFRESH';

export const getTrendingTopicsDataAction = createAction(GET_TRENDING_TOPICS_DATA);

const getTrendingTopicsDataSuccess = createAction(GET_TRENDING_TOPICS_DATA_SUCCESS);

const getTrendingTopicsDataFaliure = createAction(GET_TRENDING_TOPICS_DATA_FAILURE);

export const getTrendingTopicsDataRefresh = createAction(GET_TRENDING_TOPICS_DATA_REFRESH);

export const getTrendingTopicsDataEpic = (actions$) => actions$.pipe(
  ofType(GET_TRENDING_TOPICS_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/explore/v0/analytics/topics?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getTrendingTopicsDataSuccess(res)),
    catchError((err) => of(getTrendingTopicsDataFaliure(err))),
  )),
);

const ongetTrendingTopicsDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
  flag: false,
});

const ongetTrendingTopicsDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
  showNotification: true,
});

const ongetTrendingTopicsDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetTrendingTopicsDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getTrendingTopicsDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getTrendingTopicsDataAction, (state) => ongetTrendingTopicsDataAction(state))
      .addCase(getTrendingTopicsDataSuccess, (state, action) => ongetTrendingTopicsDataSuccess(state, action.payload))
      .addCase(getTrendingTopicsDataFaliure, (state) => ongetTrendingTopicsDataFailure(state))
      .addCase(getTrendingTopicsDataRefresh, () => ongetTrendingTopicsDataRefresh())
      .addDefaultCase((state) => state);
  },
);
