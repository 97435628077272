import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_TIMELINE_DATA = 'GET_TIMELINE_DATA';
const GET_TIMELINE_DATA_SUCCESS = 'GET_TIMELINE_DATA_SUCCESS';
const GET_TIMELINE_DATA_FAILURE = 'GET_TIMELINE_DATA_FAILURE';
const GET_TIMELINE_DATA_REFRESH = 'GET_TIMELINE_DATA_REFRESH';

export const getTimeLineDataAction = createAction(GET_TIMELINE_DATA);

const getTimeLineDataSuccess = createAction(GET_TIMELINE_DATA_SUCCESS);

const getTimeLineDataFaliure = createAction(GET_TIMELINE_DATA_FAILURE);

export const getTimeLineDataRefreshAction = createAction(GET_TIMELINE_DATA_REFRESH);

export const getTimeLineDataEpic = (actions$) => actions$.pipe(
  ofType(GET_TIMELINE_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/search/clinical_trials/timeline?${action.payload.params}`, 'get')).pipe(
    map((res) => getTimeLineDataSuccess(res)),
    catchError((err) => of(getTimeLineDataFaliure(err))),
  )),
);

const ongetTimeLineDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetTimeLineDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
  error: false,
});

const ongetTimeLineDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetTimeLineDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: true,
});

export const getTimeLineDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getTimeLineDataAction, (state) => ongetTimeLineDataAction(state))
      .addCase(getTimeLineDataSuccess, (state, action) => ongetTimeLineDataSuccess(state, action.payload))
      .addCase(getTimeLineDataFaliure, (state) => ongetTimeLineDataFailure(state))
      .addCase(getTimeLineDataRefreshAction, () => ongetTimeLineDataRefresh())
      .addDefaultCase((state) => state);
  },
);

const SET_TRIAL_ID = 'SET_TRIAL_ID';

export const setTrialIdAction = createAction(SET_TRIAL_ID);

const onSetTrial = (state, payload) => ({
  ...state,
  trialId: payload,
});

export const getTrialIdReducer = createReducer(
  {
    trialId: '',
  },
  (builder) => {
    builder.addCase(setTrialIdAction, (state, action) => onSetTrial(state, action.payload))
      .addDefaultCase((state) => state);
  },
);

const SET_DOC_ID = 'SET_DOC_ID';

export const setDocIdAction = createAction(SET_DOC_ID);

const onSetDocId = (state, payload) => ({
  ...state,
  docId: payload.docId,
  trialId: payload.trialId,
});

export const getDocIdReducer = createReducer(
  {
    docId: '',
  },
  (builder) => {
    builder.addCase(setDocIdAction, (state, action) => onSetDocId(state, action.payload))
      .addDefaultCase((state) => state);
  },
);
