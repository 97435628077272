import {
  Button, Form, Input, Modal,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import AdminDropdown from '../AdminDropdown';
import {
  createOrganizationAction, createOrganizationRefreshAction, editOrganizationAction, editOrganizationRefreshAction,
} from '../Admin/logic';
import SvgLoader from '../../../common/components/SvgLoader';
import { getCookie, setCookie } from '../../../utils';

export default function UpdateOrg({
  open, setOpen, orgData = {}, type = '',
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [formValue, setFormValue] = useState({
    organizationId: '',
    firstName: '',
    lastName: '',
    email: '',
    organization: '',
    organizationName: '',
    job: '',
    organizationType: 'external',
    domain: '',
  });

  const createOrganization = useSelector((state) => state.createOrganization);
  const editOrganization = useSelector((state) => state.editOrganization);

  const defaultSTate = () => {
    setOpen(false);
    setFormValue({
      ...{
        organizationId: '',
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        organizationName: '',
        job: '',
        organizationType: 'external',
        domain: '',
      },
    });
    form.resetFields();
  };

  useEffect(() => {
    if (editOrganization.flag && getCookie('orgUpdateType') === type) {
      dispatch(editOrganizationRefreshAction());
      setIsConfirmationModalOpen(true);
      defaultSTate();
    }

    if (editOrganization?.data?.error?.statusCode === 409 && getCookie('orgUpdateType') === type) {
      dispatch(editOrganizationRefreshAction());
      setIsFailureModalOpen(true);
    }
  }, [JSON.stringify(editOrganization)]);

  useEffect(() => {
    if (createOrganization.flag && getCookie('orgUpdateType') === type) {
      dispatch(createOrganizationRefreshAction());
      setIsConfirmationModalOpen(true);
      defaultSTate();
    } else if (createOrganization?.data?.statusCode === 409 && getCookie('orgUpdateType') === type) {
      dispatch(createOrganizationRefreshAction());
      setIsFailureModalOpen(true);
      defaultSTate();
    }
  }, [JSON.stringify(createOrganization)]);

  useEffect(() => {
    if (JSON.stringify(orgData) !== '{}') {
      setFormValue({
        ...formValue, organizationName: orgData.organization_name, organizationType: orgData.organization_type, domain: orgData.domain, organizationId: orgData.organization_id,
      });
      setOpen(true);
      setCookie('edit', 'orgUpdateType');
    }
  }, [JSON.stringify(orgData)]);

  const formValueChange = (e, keyName) => {
    if (keyName === 'firstName') {
      setFormValue({
        ...formValue,
        firstName: e.target.value,
      });
    }
    if (keyName === 'lastName') {
      setFormValue({
        ...formValue,
        lastName: e.target.value,
      });
    }
    if (keyName === 'email') {
      setFormValue({
        ...formValue,
        email: e.target.value,
      });
    }
    if (keyName === 'job') {
      setFormValue({
        ...formValue,
        job: e.target.value,
      });
    }
    if (keyName === 'organizationName') {
      setFormValue({
        ...formValue,
        organizationName: e.target.value,
      });
    }
    if (keyName === 'organizationType') {
      setFormValue({
        ...formValue,
        organizationType: e.target.value,
      });
    }
    if (keyName === 'domain') {
      setFormValue({
        ...formValue,
        domain: e.target.value,
      });
    }
  };

  const handleOrgTypeUpdate = (value) => {
    setFormValue({ ...formValue, organizationType: value });
  };

  const handlePrefilled = () => {
    if (formValue.organizationType === 'internal') {
      return { key: 'internal', label: 'Internal' };
    }
    return { key: 'external', label: 'External' };
  };

  const options = [
    { key: 'external', label: 'External' },
    { key: 'internal', label: 'Internal' },
  ];

  const handleUpdateClick = () => {
    defaultSTate();
    const request = {
      headers: queryString.stringify({
        organization_id: formValue.organizationId,
      }),
      body: {

        organization_name: formValue.organizationName,
        organization_type: formValue.organizationType,
        domain: formValue.domain,

      },
    };
    dispatch(editOrganizationAction(request));
  };

  const handleClick = () => {
    if (type === 'edit') {
      handleUpdateClick();
    } else {
      dispatch(createOrganizationAction({
        body: {
          organization_name: formValue.organizationName,
          organization_type: formValue.organizationType,
          domain: formValue.domain,
        },
      }));
      defaultSTate();
    }
  };

  const handleCancel = () => {
    setIsConfirmationModalOpen(false);
    setIsFailureModalOpen(false);
    setCookie('', 'orgUpdateType');
    defaultSTate();
  };

  if (getCookie('orgUpdateType') !== '' && getCookie('orgUpdateType') !== type) {
    return null;
  }

  const modalCancelClick = () => {
    setCookie('', 'orgUpdateType');
    defaultSTate();
  };

  const orgUpdateSuccessfull = () => (
    <Modal
      open={isConfirmationModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      className="admin-invite-user-success-modal"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className="invite-success-icon">
        <SvgLoader svgName="success-signup" width={120} height={120} style={{ display: 'flex' }} />

      </div>
      <div className="invite-success-msg">{getCookie('orgUpdateType') === 'edit' ? 'Organization updated successfully' : 'Organization created successfully'}</div>
    </Modal>
  );

  const renderOrgFailure = () => (
    <Modal
      open={isFailureModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      className="admin-invite-user-success-modal"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className="invite-success-icon">
        <SvgLoader svgName="failure" width={120} height={120} style={{ display: 'flex' }} />

      </div>
      <div className="invite-success-msg">Organization already exists</div>
    </Modal>
  );

  return (
    <div>
      <Modal
        title="Create organization"
        style={{
          height: '100vh',
        }}
        open={open}
        onCancel={() => modalCancelClick()}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        className="admin-invite-user-modal"
      >
        <div className="invite-user">
          <Form
            form={form}
            name="invite-user-form"
            layout="vertical"
          >
            <Form.Item
              label="Organization name"
              name="organizationName"
              rules={[
                {
                  required: true,
                  message: 'Please input the organization name!',
                },
              ]}
            >
              <h1 style={{ visibility: 'hidden', display: 'none' }}>{formValue.organization}</h1>
              <Input
                className="input-wrapper"
                placeholder="Organization name"
                value={formValue.organizationName}
                onChange={(e) => formValueChange(e, 'organizationName')}
              />
            </Form.Item>

            <Form.Item
              label="Organization Type"
              name="organizationType"
              rules={[
                {
                  required: true,
                  message: 'Please select the organization type!',
                },
              ]}
            >
              <AdminDropdown prefilled={() => handlePrefilled()} options={options} handleOrgTypeUpdate={handleOrgTypeUpdate} />
            </Form.Item>

            <Form.Item
              label="Domain"
              name="domain"
              rules={[
                {
                  required: true,
                  message: 'Please input domain name',
                },
              ]}
            >
              <h1 style={{ visibility: 'hidden', display: 'none' }}>{formValue.domain}</h1>
              <Input
                className="input-wrapper"
                placeholder="Domain"
                value={formValue.domain}
                onChange={(e) => formValueChange(e, 'domain')}
              />

            </Form.Item>

            <Form.Item>
              <Button
                type="default"
                htmlType="button"
                disabled={formValue.organizationName === '' || formValue.organizationType === ''}
                onClick={handleClick}
              >
                Submit
              </Button>

            </Form.Item>
          </Form>
        </div>
      </Modal>
      {isConfirmationModalOpen ? orgUpdateSuccessfull() : null}
      {isFailureModalOpen ? renderOrgFailure() : null}
    </div>
  );
}
