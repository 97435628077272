/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
const exploreWidgets = [
  {
    label: 'Literature Highlights',
    value: 'literature',
    type: 'summary',
    size: 'large',
  },
  {
    label: 'Literature Distribution',
    value: 'literature_meter',
    type: 'pie',
    size: 'medium',
  },
  // {
  //   label: 'Grant Meter',
  //   value: 'grant_meter',
  //   type: 'grant',
  //   size: 'small',
  // },
  {
    label: 'Recommended Next Searches',
    value: 'recommended_next_searches',
    type: 'recommended_next_searches',
    size: 'medium',
  },
  {
    label: 'Trending Topics',
    value: 'trending_topics',
    type: 'topics',
    size: 'medium',
  },
  {
    label: 'What\'s New?',
    value: 'newsletter',
    type: 'newsletter',
    size: 'medium',
  },
  {
    label: 'Word cloud of biological concepts',
    value: 'wordcloud',
    type: 'wordcloud',
    size: 'medium',
  },
];

export default exploreWidgets;

export const wdata = [
  {
    category: 'Indication',
    count: 624959,
    summary: [
      {
        name: 'lung cancer',
        count: 203301,
        class: 'disease',
        type: 'Indication',
        id: 'DISE_ID_10a0997aef8b80517d4993f04281477b9299289f',
      },
      {
        name: 'non small cell lung cancer',
        count: 71981,
        class: 'disease',
        type: 'Indication',
        id: 'DISE_ID_c35cd469bab9b80b80426a7b1cd49a863d9e432a',
      },
      {
        name: 'lung adenocarcinoma nos',
        count: 23110,
        class: 'disease',
        type: 'Indication',
        id: 'DISE_ID_aa7ea6ab2cb3645fec3b778de8464ec171434c74',
      },
      {
        name: 'cancer',
        count: 122062,
        class: 'disease',
        type: 'Indication',
        id: 'DISE_ID_30632c306ba7c5de34561bf6a06ff09e62d805cd',
      },
      {
        name: 'small cell lung cancer',
        count: 17316,
        class: 'disease',
        type: 'Indication',
        id: 'DISE_ID_a4ae3a0f8e45f0f3b38aa3f4d2e02c7cb890a383',
      },
      {
        name: 'Neoplastic Disease',
        count: 136162,
        class: 'disease',
        type: 'Indication',
        id: 'DISE_ID_aa3939a63ace95f5ffeab3d1798d49b5adbe9e27',
      },
      {
        name: 'lung metastasis',
        count: 16844,
        class: 'disease',
        type: 'Indication',
        id: 'DISE_ID_80f8a8252e7f6681dc03d1fc05f88bbfc01d93b8',
      },
      {
        name: 'adenocarcinoma',
        count: 21212,
        class: 'disease',
        type: 'Indication',
        id: 'DISE_ID_e57ce7eac6c15db54d92323b0c6207d18834be91',
      },
      {
        name: 'bronchiogenic carcinoma',
        count: 6614,
        class: 'disease',
        type: 'Indication',
        id: 'DISE_ID_4045f3f614462f6a65f19cc11a6a4c93460fee99',
      },
      {
        name: 'metastatic lung adenocarcinoma',
        count: 6357,
        class: 'disease',
        type: 'Indication',
        id: 'DISE_ID_30ce6d137a69d7c16241873973da0f4b78be01b7',
      },
    ],
  },
  {
    category: 'Intervention',
    count: 69151,
    summary: [
      {
        name: 'GEFITINIB',
        count: 5452,
        class: 'marketed_drug',
        type: 'Intervention - Marketed',
        id: 'DRUG_ID_713cdd3a3a8f284d0828ce9312ea7c90688f6261',
      },
      {
        name: 'Gefitinib biosimilar',
        count: 5355,
        class: 'marketed_drug',
        type: 'Intervention - Marketed',
        id: 'DRUG_ID_1dc4ee00e4c81a7dfccbdef4c1704afb1fb9fd68',
      },
      {
        name: 'cisplatin',
        count: 14099,
        class: 'marketed_drug',
        type: 'Intervention - Marketed',
        id: 'DRUG_ID_ca4ec58c97943d2cf24998c15dcabe45aff6c3e4',
      },
      {
        name: 'Cisplatin biosimilar',
        count: 14099,
        class: 'marketed_drug',
        type: 'Intervention - Marketed',
        id: 'DRUG_ID_96830283500d5c2ddd16edc20e9830e296e074d5',
      },
      {
        name: 'Erlotinib',
        count: 4890,
        class: 'marketed_drug',
        type: 'Intervention - Marketed',
        id: 'DRUG_ID_d392734181bada38b035675f3adc2fe305765555',
      },
      {
        name: 'Chromium picolinate',
        count: 14158,
        class: 'marketed_drug',
        type: 'Intervention - Marketed',
        id: 'DRUG_ID_511159a712d8dd423deb9764ce9bf922e2e6a817',
      },
      {
        name: 'Erlotinib biosimilar',
        count: 4271,
        class: 'marketed_drug',
        type: 'Intervention - Marketed',
        id: 'DRUG_ID_535aacaa5413b1869243ebae273c9855863b48a1',
      },
      {
        name: 'PEMETREXED SODIUM',
        count: 2613,
        class: 'marketed_drug',
        type: 'Intervention - Marketed',
        id: 'DRUG_ID_5132270f9848dbb3162b906b3412246f25598647',
      },
      {
        name: 'CRIZOTINIB',
        count: 2186,
        class: 'marketed_drug',
        type: 'Intervention - Marketed',
        id: 'DRUG_ID_8f08527773f83c756f605b1f62b6977cce2a920e',
      },
      {
        name: 'Osimertinib',
        count: 2028,
        class: 'marketed_drug',
        type: 'Intervention - Marketed',
        id: 'DRUG_ID_5b95aadf59e756509ed3e699ca3ceee894ce3ce4',
      },
    ],
  },
  {
    category: 'Gene',
    count: 48655,
    summary: [
      {
        name: 'epidermal growth factor receptor',
        count: 9430,
        class: 'gene',
        type: 'Gene',
        id: 'GENE_ID_9606_1e369288ede8a3f3f0a17967a030879a',
      },
      {
        name: 'ALK receptor tyrosine kinase',
        count: 3182,
        class: 'gene',
        type: 'Gene',
        id: 'GENE_ID_9606_60e448878753c39726a0684de197f3e6',
      },
      {
        name: 'KRAS proto-oncogene, GTPase',
        count: 6957,
        class: 'gene',
        type: 'Gene',
        id: 'GENE_ID_9606_b0d7408398ef2fa045d88b6724365316',
      },
      {
        name: 'CD274 molecule',
        count: 6937,
        class: 'gene',
        type: 'Gene',
        id: 'GENE_ID_9606_fd5c4bba63f3eb7b4d1a24e2018dc929',
      },
      {
        name: 'epidermal growth factor',
        count: 7460,
        class: 'gene',
        type: 'Gene',
        id: 'GENE_ID_9606_a956d53a4403d2bb542f4a8e9e4ba198',
      },
      {
        name: 'ROS proto-oncogene 1, receptor tyrosine kinase',
        count: 1419,
        class: 'gene',
        type: 'Gene',
        id: 'GENE_ID_9606_50722c31f9bea0f91f0e8f7aec47b6fb',
      },
      {
        name: 'programmed cell death 1',
        count: 4888,
        class: 'gene',
        type: 'Gene',
        id: 'GENE_ID_9606_423d55483d42b9e9c4ce86a2af533a59',
      },
      {
        name: 'RPL17-C18orf32 readthrough',
        count: 4189,
        class: 'gene',
        type: 'Gene',
        id: 'GENE_ID_9606_1d05f6c1d13d386f1a5892f1b5b74f24',
      },
      {
        name: 'ribosomal protein L17',
        count: 4193,
        class: 'gene',
        type: 'Gene',
        id: 'GENE_ID_9606_52f5611cd50723488c6aa628de05a388',
      },
    ],
  },
  {
    category: 'Protein',
    count: 52033,
    summary: [
      {
        name: 'Epidermal growth factor receptor',
        count: 14769,
        class: 'protein',
        type: 'Protein',
        id: 'PROT_ID_9606_f27fec4b5b054967545bd7249fbe88d53deea809',
      },
      {
        name: 'ALK tyrosine kinase receptor',
        count: 2988,
        class: 'protein',
        type: 'Protein',
        id: 'PROT_ID_9606_8d5af4d56620e92698d5c3df30e8f0e5d8c5a581',
      },
      {
        name: 'Programmed cell death 1 ligand 1',
        count: 7115,
        class: 'protein',
        type: 'Protein',
        id: 'PROT_ID_9606_c254561740b2ffe8dc57a726b69d9d05caa5e073',
      },
      {
        name: 'GTPase KRas',
        count: 5875,
        class: 'protein',
        type: 'Protein',
        id: 'PROT_ID_9606_ac163eb7524af5ef2d68f5f84cd19f696b235972',
      },
      {
        name: 'GTPase HRas',
        count: 6121,
        class: 'protein',
        type: 'Protein',
        id: 'PROT_ID_9606_d349441871718f9ef0c6d5d76b0e5f347945eca6',
      },
      {
        name: 'Pro-epidermal growth factor',
        count: 7528,
        class: 'protein',
        type: 'Protein',
        id: 'PROT_ID_9606_71149c2a769f58505cecc0fb2767e510d8da223e',
      },
      {
        name: 'Proto-oncogene tyrosine-protein kinase ROS',
        count: 1460,
        class: 'protein',
        type: 'Protein',
        id: 'PROT_ID_9606_0a3e51088d4ca876c0cf80c203b648e6f707b1bf',
      },
      {
        name: 'Homeobox protein Nkx-2.1',
        count: 1641,
        class: 'protein',
        type: 'Protein',
        id: 'PROT_ID_9606_d13a78c789eb26bb05f1f36b14ba0a36fb2bf971',
      },
      {
        name: 'Microtubule-associated tumor suppressor candidate 2',
        count: 2263,
        class: 'protein',
        type: 'Protein',
        id: 'PROT_ID_9606_fb4ead819a92eefd7163a25a6316c9cee7b567d8',
      },
      {
        name: 'Microtubule-associated tumor suppressor 1',
        count: 2273,
        class: 'protein',
        type: 'Protein',
        id: 'PROT_ID_9606_ae4ef442f0ae7cc7f6082a0a36eb6e084f695785',
      },
    ],
  },
];
