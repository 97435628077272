import React, { useEffect, useRef, useState } from 'react';

export default function TimeInput({ title, time, updateTime }) {
  const hrsRef = useRef(null);
  const minRef = useRef(null);
  const amPmRef = useRef(null);

  const [hrs, setHrs] = useState(12);
  const [min, setMin] = useState(0);
  const [amPm, setAmPm] = useState('AM');

  useEffect(() => {
    const tempTime = time.split(' ');
    setHrs(tempTime[0].split(':')[0]);
    setMin(tempTime[0].split(':')[1]);
    setAmPm(tempTime[1]);
  }, [time]);

  const handleTimeUpdate = (event, type) => {
    if (type === 'hrs') {
      if (event.key === 'Enter') {
        minRef.current.focus();
      }
      updateTime(`${event.target.value}:${event.target.value} ${amPm}`);
      setHrs(event.target.value);
    } else {
      if (event.key === 'Enter') {
        amPmRef.current.focus();
      }
      updateTime(`${hrs}:${event.target.value} ${amPm}`);
      setMin(event.target.value);
    }
  };

  const handleAmPmUpdate = (event) => {
    updateTime(`${hrs}:${min} ${event.target.value}`);
    setAmPm(event.target.value);
  };

  return (
    <div className="time-container">
      <div className="label">{title}</div>
      <div className="input-box">
        <input
          className="input-box-time"
          type="number"
          value={hrs}
          ref={hrsRef}
          onKeyDown={(e) => handleTimeUpdate(e, 'hrs')}
          onChange={(e) => handleTimeUpdate(e, 'hrs')}
          min="0"
          max="12"
        />
        <div className="dot-container">
          <div className="dot" />
          <div className="dot" />
        </div>
        <input
          className="input-box-time"
          type="number"
          value={min}
          ref={minRef}
          onKeyDown={(e) => handleTimeUpdate(e, 'min')}
          onChange={(e) => handleTimeUpdate(e, 'min')}
          min="0"
          max="60"
        />
        <div className="dot-container">
          <div className="dot" />
          <div className="dot" />
        </div>
        <select
          className="input-box-time"
          value={amPm}
          onChange={(e) => handleAmPmUpdate(e)}
          ref={amPmRef}
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div>
  );
}
