import { React } from 'react';
import { useDispatch } from 'react-redux';
import Logout from '../Logout';
import webAuth from '../../../utils/webAuth';
import { getCookie } from '../../../utils';
import { setReferralOpenModalAction } from '../../../app/container/Referral/logic';

function ProfileModal() {
  const dispatch = useDispatch();

  const onClickReferral = () => {
    dispatch(setReferralOpenModalAction(true));
  };

  return (
    <div className="pofile-sec">
      <div className="email-sec">{getCookie('userEmail')}</div>
      <div aria-hidden className="sign-out-sec pofile-sec-btn" onClick={() => onClickReferral()}>
        <div className="referral mr-2.5" />
        {' '}
        Refferal
      </div>
      <Logout className="sign-out-sec pofile-sec-btn" webAuth={webAuth} />
    </div>
  );
}

export default ProfileModal;
