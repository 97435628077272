import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import * as d3 from 'd3';
import { Tooltip } from 'react-tooltip';
import { getGraphDataAction } from './logic';
import SvgLoader from '../../../common/components/SvgLoader';
import Loader from '../../../common/components/Loader';
import Mixpanel from '../../../utils/mixpanel';
import { trackOutSideEvents, getTerminalName } from '../../../utils';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function PieChart({ params, selected, currentExpanded }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toolTipRef = useRef(null);
  const [searchParams] = useSearchParams();
  const semanticQuery = searchParams.get('semanticquery');
  const query = searchParams.get('query');

  const [showTooltip, setshowTooltip] = useState(false);
  const [currentTooltipId, setCurrentTooltipId] = useState('');
  const [toolTipData, setToolTipData] = useState({ key: '', value: '' });
  const nq = [];

  const searchResult = useSelector((state) => state.searchResult);
  const graphData = useSelector((state) => state.graphData);
  const colors = d3.quantize(
    d3.interpolateRgb(d3.rgb(82, 82, 250), d3.rgb(220, 220, 255)),
    graphData?.data?.length,
  );

  const colorScale = d3
    .scaleOrdinal()
    .domain(graphData?.data?.map((element) => element?.key || element?.name))
    .range(colors);

  const renderClassName = (value) => {
    const valArr = value?.split(' ');
    let temp = '';
    valArr?.forEach((item) => {
      if (item !== '/') {
        temp += item;
      }
    });
    return temp;
  };

  const handleClick = (dataKey) => {
    if (semanticQuery?.length) {
      nq.push({
        name: 'status',
        label: 'status',
        field: 'status',
        value: dataKey,
        type: 'semantic',
        currentFilter: [dataKey],
        currentKeys: [dataKey],
      });
    } else {
      nq.push({
        name: 'status',
        label: 'status',
        field: 'status',
        value: dataKey,
        type: 'query',
        currentFilter: [dataKey],
        currentKeys: [dataKey],
      });
    }
    if (location.pathname.includes('/clinicalTrials')) {
      if (semanticQuery) {
        Mixpanel.track('widget_deepdive_event', {
          action: 'status snapshot Tooltip click for List View search',
          search_Type: 'Semantic Search',
          terminal_name: 'Clinical Trials',
          widget_name: 'Status Snapshot',
          page: 'Analytics Page',
          status_data: {
            status_name: toolTipData.key,
            status_value: toolTipData.value,
          },
          query: JSON.parse(semanticQuery),
          view_type: 'Widget View',
          label: dataKey,
          misc: 'Level 0',
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'Analytics Page',
          terminal_name: 'Clinical Trials',
        });
        useEffect(() => {
          Mixpanel.track('page_entry_event', {
            action: 'Status snapshot Tooltip click for List View search',
            terminal_name: 'Clinical Trials',
            page_name: 'Document Listing Page -Status Snapshot',
          });
        }, []);

        navigate(`/clinicalTrials/searchResults?semanticquery=${encodeURIComponent(semanticQuery)}${`&statusQuery=${JSON.stringify(nq)}`}${'&from=\'Status Snapshot\''}`);
        navigate(0);
      } else {
        Mixpanel.track('widget_deepdive_event', {
          action: 'status snapshot Tooltip click for List View search',
          search_Type: 'Power Search',
          terminal_name: 'Clinical Trials',
          widget_name: 'Status Snapshot',
          page: 'Analytics Page',
          status_data: {
            status_name: toolTipData.key,
            status_value: toolTipData.value,
          },
          query: JSON.parse(query),
          view_type: 'Widget View',
          label: dataKey,
          misc: 'Level 0',
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: 'Analytics Page',
          terminal_name: 'Clinical Trials',
        });
        Mixpanel.track('page_entry_event', {
          action: 'Status snapshot Tooltip click for List View search',
          terminal_name: 'Clinical Trials',
          page_name: 'Document Listing Page -Status Snapshot',
        });

        navigate(`/clinicalTrials/searchResults?query=${encodeURIComponent(query)}${`&statusQuery=${JSON.stringify(nq)}`}${'&from=\'Status Snapshot\''}`);
        navigate(0);
      }
    }
  };
  const draw = (dataset) => {
    const dimensions = {
      width: 200,
      height: 200,
      margins: 0,
    };

    dimensions.ctrWidth = dimensions.width - dimensions.margins * 2;
    dimensions.ctrHeight = dimensions.height - dimensions.margins * 2;
    const radius = 90;

    const svg = d3
      .select('#pie-chart')
      .append('svg')
      .attr('id', 'pie')
      .attr('width', dimensions.width)
      .attr('height', dimensions.height);

    const ctr = svg
      .append('g')
      .attr(
        'transform',
        `translate(${dimensions.margins}, ${dimensions.margins})`,
      );

    const populationPie = d3.pie().value((d) => d?.value);

    const slices = populationPie(dataset);

    const arc = d3
      .arc()
      .outerRadius(radius)
      .cornerRadius(selected.value === 'literature_meter' ? 4 : 0)
      .padAngle(selected.value === 'literature_meter' ? 0.01 : 0)
      .innerRadius(40);

    const arcGroup = ctr
      .append('g')
      .attr(
        'transform',
        `translate(${dimensions.ctrWidth / 2}, ${dimensions.ctrHeight / 2})`,
      );

    const convert = (value) => {
      let temp = value;
      if (value >= 1000000) {
        temp = `${(value / 1000000).toFixed()}M`;
      } else if (value >= 1000) {
        temp = `${(value / 1000).toFixed()}k`;
      }
      return temp;
    };

    arcGroup
      .selectAll('path')
      .data(slices)
      .join('path')
      .attr('d', arc)
      .attr(
        'fill',
        dataset.length === 1
          ? '#5252FA'
          : (d) => colorScale(d?.data?.key || d.data.name),
      )
      .attr('id', (d) => `pie-${renderClassName(d.data.key)}`)
      .style('cursor', 'pointer')
      .on('mouseenter', (event, d) => {
        setshowTooltip(true);
        setCurrentTooltipId(`pie-${renderClassName(d.data.key)}`);
        setToolTipData({ key: d.data.key, value: d.data.value });
      });

    const showPercentage = (value) => (Math.round(value) >= 5 ? `${Math.round(value)}%` : '');

    arcGroup
      .selectAll('text')
      .data(slices)
      .join('text')
      .text(
        (d) => `${
          selected.value === 'literature_meter'
            ? d.data.percentage >= 5
              ? convert(d.data.value)
              : ''
            : showPercentage(d.value)
        }`,
      )
      .attr('fill', '#ffff')
      .attr('class', 'pie-chart-label')
      .attr('transform', (d) => `translate(${arc.centroid(d)})`)
      .style('text-anchor', 'middle')
      .style('cursor', 'pointer')
      .style('font-size', 17);
  };

  useEffect(() => {
    trackOutSideEvents(toolTipRef, () => setshowTooltip(false));
  }, []);
  function convertToInteger(value) {
    return Math.round(value);
  }
  function convertString(str) {
    const tempStringArray = str.split('_');
    const updateArray = tempStringArray.map((item) => item.slice(0, 1).toUpperCase() + item.slice(1, item.length));
    return updateArray.join(' ');
  }

  function renderTooltip() {
    Mixpanel.track('hover_popup_event', {
      action: location.pathname.includes('searchAndExplore') ? 'user hovered on literature distribution' : 'user hovered on status snapshot',
      label: toolTipData?.value,
      terminal_name: getTerminalName(location),
      page_name: location.pathname.includes('searchAndExplore') ? 'Search and explore Analytics page' : 'Clinical Trials Analytics page',
      widget_name: location.pathname.includes('searchAndExplore') ? 'Literature Distribution' : 'Status Snapshot',
      search_type: location.search.includes('semantic') ? 'Semantic Search' : 'Power Search',
      view_type: 'Widget_view',
    });
    return (
      <div role="presentation">
        <Tooltip
          className="status-graph-tooltip"
          place="top"
          anchorSelect={`#${currentTooltipId}`}
          clickable
          isOpen={showTooltip}
          offset={10}
        >
          <div ref={toolTipRef} className="tooltip-container">
            <div className="blue-vertical-line" />
            <div className="endpoints-detail-sub-container">
              <div className="endpoint-detail">
                <div className="endpoint-count">
                  {location.pathname === '/searchAndExplore/analytics'
                    ? toolTipData?.value
                    : convertToInteger(
                      (toolTipData.value / 100) * searchResult.data.total,
                    )}
                </div>

                <div className="endpoint-type">
                  {location.pathname === '/searchAndExplore/analytics'
                    ? convertString(toolTipData?.key)
                    : toolTipData?.key}
                </div>
                {location.pathname === '/searchAndExplore/analytics' ? (
                  ''
                ) : (
                  <div className="endpoint-arrow">
                    <SvgLoader
                      className="pointer"
                      onClick={() => handleClick(toolTipData?.key)}
                      width={20}
                      height={14}
                      svgName="right-arrow-tooltip"
                      hoverIconName="right-arrow-tooltip-hover"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
    );
  }

  useEffect(() => {
    d3.select('#pie').remove();
    if (graphData.flag && graphData.data.length) {
      draw(graphData?.data);
    }
  }, [JSON.stringify(graphData)]);

  useEffect(() => {
    if (currentExpanded === '') {
      let request = {};
      if (location.pathname === '/searchAndExplore/analytics') {
        request = {
          ...params,
          apiUrl: '/explore/v0/analytics/meter',
          headers: queryString.stringify({
            type: 'literature',
            semantic_search: semanticQuery?.length
              ? JSON.parse(semanticQuery)[0]?.currentFilter
              : undefined,
          }),
        };
        debounceCall(() => {
          dispatch(getGraphDataAction(request));
        }, 50);
      } else if (searchResult.flag) {
        request = {
          ...params,
          apiUrl: '/clinical-trials/v0/search/getStatisticalSummary',
          headers: queryString.stringify({
            ...params.headers,
            field_name: 'status',
          }),
        };
        debounceCall(() => {
          dispatch(getGraphDataAction(request));
        }, 1200);
      }
    }
  }, [currentExpanded, JSON.stringify(searchResult)]);

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  const renderLegends = () => graphData.data.map((item) => {
    if (item.value !== 0 || item?.percentage !== 0) {
      return (
        <div className="legend" key={item?.key}>
          <div className="circle" style={{ backgroundColor: graphData.data.length === 1 ? '#5252FA' : colorScale(item?.key || item.name) }} />
          <span className="legend-name">{item?.displayName || item?.key}</span>
        </div>
      );
    }
    return null;
  });

  return (
    <div className="Widget" onMouseLeave={() => setshowTooltip(false)}>
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">{selected.label}</div>
        </div>
        <div className="icons" />
      </div>
      <Loader
        error={graphData.error}
        loading={graphData.loading || searchResult.loading}
        noData={graphData.data?.length === 0 && graphData.flag}
        height="250px"
        className="white-loader"
        message="No records found"
      >
        <div id="pie-chart" />
        {renderTooltip()}
        <div className="legends">
          {graphData?.data.length ? renderLegends() : null}
        </div>
      </Loader>
      <div className="drag-expaned-icon" />
    </div>
  );
}

PieChart.propTypes = {
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
    body: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired,
  currentExpanded: PropTypes.string.isRequired,
};

export default PieChart;
