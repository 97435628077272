import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { updateFilterAction } from '../../container/PowerSearch/logic';
import Mixpanel from '../../../utils/mixpanel';
import { getTerminalName } from '../../../utils';

function replaceQuotes(str) {
  return str.replace(/"/g, "'");
}
function FreeTextSearch({ id, selectedValue, removedValues }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const [freeTextVal, setFreeTextVal] = useState('');
  const query = searchParams.get('query');

  useEffect(() => {
    if (query && !removedValues?.includes(selectedValue)) {
      const q = JSON.parse(query);
      q.forEach((item) => {
        if (item.name === selectedValue) {
          setFreeTextVal(...item.currentFilter);
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(updateFilterAction({
      filterOption: { currentFilter: freeTextVal?.length ? [encodeURIComponent(freeTextVal.trim())] : [], currentKeys: freeTextVal?.length ? [encodeURIComponent(freeTextVal.trim())] : [] },
      id,
    }));
  }, [freeTextVal]);

  const handleInput = (value) => {
    setFreeTextVal(replaceQuotes(value));
  };

  const handleTap = () => {
    Mixpanel.track('field_tap_event', {
      action: 'user taped on search box to fill data for power search',
      field_name: selectedValue,
      terminal_name: getTerminalName(location),
      page_name: 'Search Page',
      search_type: 'Power Search',
    });
  };

  return (
    <input type="text" className="free-text-input" value={freeTextVal} onClick={() => handleTap()} onChange={(e) => handleInput(e.target.value)} />
  );
}

FreeTextSearch.propTypes = {
  id: PropTypes.number.isRequired,
  selectedValue: PropTypes.string.isRequired,
  removedValues: PropTypes.instanceOf(Array).isRequired,
};

export default FreeTextSearch;
